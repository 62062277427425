import React, { Component } from 'react'
import {
  Button, TextField, Dialog,
  DialogActions, DialogContent,
  DialogContentText, DialogTitle,
  withStyles, Grid, Card
} from '@material-ui/core'
import update from 'immutability-helper'
import API from '../../../../API'
import ItemList from '../item-list'
import CreateBadgeGroupTranslationForm from './create-badge-group-translation-form'
import DeleteItemForm from './delete-item-form'
import uniqid from 'uniqid'

const styles = theme => ({
  textFields: {
    minWidth: 300
  }
})

const nameMaxCharCount = 50
const descriptionMaxCharCount = 50

class CreateBadgeGroupForm extends Component {
  constructor(props){
    super(props)
    let item = this.props.item || {
      name: '',
      description: '',
      translations: []
    }
    this.state = {
      item,
      errorName: false,
      errorDescription: false,
      nameCharCount: item.name.length + "/" + nameMaxCharCount,
      descriptionCharCount: item.description.length + "/" + descriptionMaxCharCount,
      languages: [],
      formItem: null,
      renderCreateTranslationForm: false,
      renderDeleteForm: false,
      deletedSubItems: []
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  componentDidUpdate(prevProps) {
    if(this.props.item && this.props.item !== prevProps.item) {
      let nameCharCount = "0/" + nameMaxCharCount
      if(this.props.item.name) {
        nameCharCount = this.props.item.name.length + "/" + nameMaxCharCount
      }
      let descriptionCharCount = "0/" + descriptionMaxCharCount
      if(this.props.item.description) {
        descriptionCharCount = this.props.item.description.length
          + "/" + descriptionMaxCharCount
      }
      this.setState({
        item: this.props.item,
        nameCharCount,
        descriptionCharCount
      })
    }
  }

  handleOnEnter() {
    API.getAllLanguages(response => {
      this.setState({ languages: response.languages })
    })
  }

  handleChange(value, input) {
    if(input === "name" && value.length > nameMaxCharCount) {
      value = value.substring(0, nameMaxCharCount)
    } else if(input === "description" && value.length > descriptionMaxCharCount) {
      value = value.substring(0, descriptionMaxCharCount)
    }

    let newItem = Object.assign({}, this.state.item)
    newItem[input] = value
    this.setState({ item: newItem, ...this.isItemValid(newItem) })

    if(input === "name") {
      this.setState({
        nameCharCount:
          value.length + "/" + nameMaxCharCount
      })
    } else if(input === "description") {
      this.setState({
        descriptionCharCount:
          value.length + "/" + descriptionMaxCharCount
      })
    }
  }

  handleTranslationClick(translation) {
    this.handleTranslationEdit(translation)
  }

  handleTranslationEdit(translation) {
    this.toggleCreateTranslationForm(translation)
  }

  handleTranslationAdd() {
    this.toggleCreateTranslationForm()
  }

  handleTranslationDelete(translation) {
    this.toggleDeleteForm(translation)
  }

  handleFormSave(translation) {
    if(!translation.id || translation.id === -1) {
      translation.id = uniqid()
      this.setState(update(this.state, {
        item: {
          translations: {
            $push: [ translation ]
          }
        }
      }))
    } else {
      const index = this.state.item.translations
        .findIndex(oldTranslation =>
          oldTranslation.id === translation.id
        )
       this.setState(update(this.state, {
        item: {
          translations: {
            $splice: [
              [index, 1, translation]
            ]
          }
        }
      }))
    }
  }

  handleFormDelete() {
    const index = this.props.item.translations.findIndex(translation =>
      translation.id === this.state.formItem.id
    )
    this.setState(update(this.state, {
      item: {
        translations: {
          $splice: [
            [index, 1]
          ]
        }
      },
      deletedSubItems: {
        $push: [ this.state.formItem ]
      }
    }))
  }

  toggleCreateTranslationForm(formItem) {
    this.setState({
      renderCreateTranslationForm:
        !this.state.renderCreateTranslationForm,
      formItem
    })
  }

  toggleDeleteForm(formItem) {
    this.setState({
      renderDeleteForm: !this.state.renderDeleteForm,
      formItem
    })
  }

  handleSubmit(e){
    let errorState = this.isItemValid(this.state.item)
    if(Object.values(errorState).includes(true)) {
      this.setState({ ...errorState })
      return
    }
    this.props.onSave(this.state.item, this.state.deletedSubItems)
    this.handleClose()
  }

  handleClose() {
    this.setState({
      item: {
        name: '',
        description: '',
        translations: []
      },
      errorName: false,
      errorDescription: false,
      nameCharCount: "0/" + nameMaxCharCount,
      descriptionCharCount: "0/" + descriptionMaxCharCount,
      languages: [],
      formItem: null,
      renderCreateTranslationForm: false,
      renderDeleteForm: false,
      deletedSubItems: []
    })
    this.props.onClose()
  }

  isItemValid(item) {
    let newState = {}
    if(item.name === '') {
      newState.errorName = true
    } else {
      newState.errorName = false
    }
    if(item.description === '') {
      newState.errorDescription = true
    } else {
      newState.errorDescription = false
    }
    return newState
  }

  render(){
    //Add language name to translations
    //array for user convenience.
    let translations = this.state.item.translations
    if(this.state.languages.length > 0) {
      translations = translations.map(translation => {
        const language = this.state.languages.find(language =>
          language.id === translation.languageId
        )
        translation.languageName = language.name
        return translation
      })
    }

    return(
      <Dialog
        open={this.props.open}
        onClose={this.handleClose}
        maxWidth="lg"
        onEnter={this.handleOnEnter.bind(this)}
      >
        <CreateBadgeGroupTranslationForm
          open={this.state.renderCreateTranslationForm}
          item={this.state.formItem}
          languages={this.state.languages}
          onClose={this.toggleCreateTranslationForm.bind(this)}
          onSave={this.handleFormSave.bind(this)}
        />
        <DeleteItemForm
          open={this.state.renderDeleteForm}
          item={this.state.formItem}
          onClose={this.toggleDeleteForm.bind(this)}
          onDelete={this.handleFormDelete.bind(this)}
        />
        <DialogTitle>
          Create/Edit Badge Group
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Feel free to make any changes below.
          </DialogContentText>
          <Grid container spacing={3}>
            <Grid item>
              <Grid
                container
                direction="column"
                className={this.props.classes.textFields}
              >
                <Grid item>
                  <TextField
                    required
                    autoFocus
                    multiline
                    rows="2"
                    fullWidth
                    margin="normal"
                    id="name"
                    label="Name"
                    type="text"
                    value={this.state.item.name}
                    helperText={this.state.nameCharCount}
                    onChange={e => this.handleChange(e.target.value, 'name')}
                    error={this.state.errorName}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    required
                    multiline
                    rows="2"
                    fullWidth
                    margin="normal"
                    id="description"
                    label="Description"
                    type="text"
                    value={this.state.item.description}
                    helperText={this.state.descriptionCharCount}
                    onChange={e => this.handleChange(e.target.value, 'description')}
                    error={this.state.errorDescription}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Card>
                <ItemList
                  mountAnimation = {true}
                  items = {translations}
                  itemAttributeForLabel = {"languageName"}
                  allowAdding = {true}
                  addItemLabel = {"Add a Translation"}
                  allowEditing = {true}
                  allowDeleting = {true}
                  onItemClick = {this.handleTranslationClick.bind(this)}
                  onItemEdit = {this.handleTranslationEdit.bind(this)}
                  onItemAdd = {this.handleTranslationAdd.bind(this)}
                  onItemDelete = {this.handleTranslationDelete.bind(this)}
                />
              </Card>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.handleClose}
            color="secondary">
            Cancel
          </Button>
          <Button
            onClick={this.handleSubmit}
            color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(CreateBadgeGroupForm)
