import React, { Component } from 'react'
import {
  withStyles, Fade, Typography,
  FormControl, InputLabel, Select,
  MenuItem, Button, AppBar,
  Tabs, Tab, CircularProgress,
  Snackbar, SnackbarContent,
  IconButton, LinearProgress
} from '@material-ui/core'
import {
  ViewColumn as ColumnIcon,
  ViewQuilt as GridIcon,
  Close as CloseIcon,
  CheckCircle as CheckCircleIcon,
  Error as ErrorIcon
} from '@material-ui/icons'
import green from '@material-ui/core/colors/green'
import PropTypes from 'prop-types'
import ManageContent from './action/manage-content/index'
import AddLesson from './action/add-lesson'
import AddQuiz from './action/add-quiz'
import ManageFunFacts from './action/manage-fun-facts'
import { DragDropContext } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import API from '../../API'
import update from 'immutability-helper'
import CopyPackForm from './item/item-form/copy-pack-form'
import CopyFlightForm from './item/item-form/copy-flight-form'
import CopyLessonForm from './item/item-form/copy-lesson-form'
import ModuleTypes from './item/item-editor/module/module-types'

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  wrapper: {
    padding: '60px 60px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '60px'
  },
  headerTitle: {
    flexBasis: '70%'
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-evenly',
    marginBottom: '60px'
  },
  tips: {
    textAlign: 'center'
  },
  appBarPadding: {
    paddingBottom: '64px'
  },
  cmsNav: {
    padding: '10px',
    backgroundColor: '#C0C0C0',
    display: 'grid',
    gridTemplateColumns: '50% 50%'
  },
  rightContainer: {
    paddingRight: '20px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  rightContainerDropdown: {
    paddingRight: '20px'
  },
  rightContainerButton: {
    marginRight: '20px'
  },
  progress: {
    color: theme.palette.common.white,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -8,
    marginLeft: -8
  },
  snackbarSuccess: {
    backgroundColor: green[600]
  },
  snackbarError: {
    backgroundColor: theme.palette.error.dark
  },
  snackbarMessage: {
    display: 'flex',
    alignItems: 'center'
  },
  snackbarIcon: {
    marginRight: theme.spacing(1)
  },
  selectLayout: {
    padding: '3px 25px 0 10px',
    color: theme.palette.common.white
  },
  selectLanguage: {
    color: theme.palette.common.white
  },
  progressContainer: {
    flexGrow: 1
  },
  initProgress: {
    width: '50%',
    margin: '10px auto'
  },
})

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

class CMS extends Component {
  state = {
    selectedLayout: 0,
    selectedLanguageId: this.props.loadLanguageId,
    selectedAction: {
      showManageContent: false,
      showAddLesson: false,
      showAddQuiz: false,
      showManageFunFacts: false
    },
    completed: 0,
    selectedTab: 0,
    publishInProgress: false,
    openSnackbar: false,
    snackbarMessage: '',
    snackbarTheme: '',
    selectedPack: null,
    selectedFlight: null,
    selectedLesson: null,
    packs: [],
    flights: [],
    lessons: [],
    lessonModules: [],
    questions: [],
    answers: [],
    funFacts: [],
    renderCopyPackForm: false,
    renderCopyFlightForm: false,
    renderCopyLessonForm: false,
    copyInProgress: false,
    downloadContentScriptsInProgres: false
  }
  componentDidMount(){
    API.getAllPacks(packs => {
      this.setState({ packs, completed: this.state.completed + 15 })
    })
    API.getAllFlights(flights => {
      this.setState({ flights, completed: this.state.completed + 15 })
    })

    this.getLessonsByLang(this.state.selectedLanguageId);
    this.getModulesByLang(this.state.selectedLanguageId);

    API.getAllQuestions(questions => {
      this.setState({ questions, completed: this.state.completed + 14 })
    })
    API.getAllAnswers(answers => {
      this.setState({ answers, completed: this.state.completed + 14 })
    })
    API.getAllFunFacts(funFacts => {
      this.setState({ funFacts, completed: this.state.completed + 14 })
    })
  }
  handleLayoutChange(input) {
    this.setState({
      selectedLayout: input.target.value,
      selectedPack: null
    })
  }

  getLessonsByLang(languageId) {
    API.getLessons(response => { 
      if(response) {
        if(response.lessons) {
          this.setState(update(this.state, {
            lessons: {
              $push: response.lessons
            }
          }))
        }
        if(response.pagination) {
          const page = response.pagination.page
          const pageCount = response.pagination.pageCount
          const pageSize = response.pagination.pageSize
          for(let count = page + 1; count <= pageCount; count++) {
            API.getLessons(response => { 
              this.setState(update(this.state, {
                lessons: {
                  $push: response.lessons
                }
              }))
              if(count === pageCount) {
                this.setState({
                  completed: this.state.completed + 14
                })
              }
            }, `?languageId=${languageId}&page=${count}&limit=${pageSize}`)
          }
        }
      }
    }, `?languageId=${languageId}`);
  }

  getModulesByLang(languageId) {
    API.getLessonModules(response => { 
      if(response) {
        if(response.lessonModules) {
          this.setState(update(this.state, {
            lessonModules: {
              $push: response.lessonModules
            }
          }))
        }
        if(response.pagination) {
          const page = response.pagination.page
          const pageCount = response.pagination.pageCount
          const pageSize = response.pagination.pageSize
          for(let count = page + 1; count <= pageCount; count++) {
            API.getLessonModules(response => { 
              this.setState(update(this.state, {
                lessonModules: {
                  $push: response.lessonModules
                }
              }))
              if(count === pageCount) {
                this.setState({
                  completed: this.state.completed + 14
                })
              }
            }, `?languageId=${languageId}&page=${count}&limit=${pageSize}`)
          }
        }
      }
    }, `?languageId=${languageId}`);
  }

  handleLanguageChange(input) { 
    // TODO handle progress indicator reset, check if lessons/modules of lang already fetched
    // trigger lessons/modules of lang refetch and merge if not fetched
    const languageId = input.target.value;
    if (this.state.selectedLanguageId !== languageId){
      const checkLessonLang = this.state.lessons.find(item => item.languageId === languageId) === undefined;
      const checkModulesLang = this.state.lessonModules.find(item => item.languageId === languageId) === undefined;
      console.log(checkLessonLang, checkModulesLang);
      if(checkLessonLang){
        let delta = 14;
        if(this.state.completed < 14){
          delta = this.state.completed;
        }
        this.setState({
          completed: this.state.completed - delta
        });
        this.getLessonsByLang(languageId);
      }
      if(checkModulesLang){
        let delta = 14;
        if(this.state.completed < 14){
          delta = this.state.completed;
        }
        this.setState({
          completed: this.state.completed - delta
        });
        this.getModulesByLang(languageId);
      }
      this.setState({
        selectedLanguageId: languageId,
        selectedPack: null
      })
    }
  }

  handleActionClick(input, value) {
    const selectedObj = this.state.selectedAction
    for (let action in selectedObj){
      if (action === input && action !== 'showManageContent') {
        this.setState({[input]: true, selectedTab: value})
      } else {
        this.setState({
          [action] : false,
          selectedTab: value,
          selectedPack: null
        })
      }
    }
  }

  toggleCopyPackForm() {
    console.log('toggleCopyPackForm');
    this.setState(state => {
      return {
        renderCopyPackForm: !state.renderCopyPackForm
      }
    })
  }

  toggleCopyFlightForm() {
    console.log('toggleCopyFlightForm');
    this.setState(state => {
      return {
        renderCopyFlightForm: !state.renderCopyFlightForm
      }
    })
  }

  toggleCopyLessonForm() {
    console.log('toggleCopyLessonForm');
    this.setState(state => {
      return {
        renderCopyLessonForm: !state.renderCopyLessonForm
      }
    })
  }

  downloadContentScripts() {
    this.setState({ downloadContentScriptsInProgres: true }, () => {
      API.getDownloadContentScripts(this.state.selectedLanguageId, (response) => {
        let snackbarMessage = ''
        let snackbarTheme = ''
        if(response) {
          snackbarMessage = 'Success generating content scripts document!'
          snackbarTheme = 'success'
        } else {
          snackbarMessage = 'Something went wrong. Please try again.'
          snackbarTheme = 'error'
        }
        setTimeout(() => {
          this.setState({
            downloadContentScriptsInProgres: false,
            openSnackbar: true,
            snackbarMessage,
            snackbarTheme
          })
        }, 500)
      });

    })
  }

  handlePublish() {
    this.setState({ publishInProgress: true }, () => {
      API.postPublish(
        this.state.selectedLanguageId,
        this.state.selectedPack.id,
        response => {
          let snackbarMessage = ''
          let snackbarTheme = ''
          if(response && response.published === true) {
            snackbarMessage = 'Content has been published live!'
            snackbarTheme = 'success'
          } else {
            snackbarMessage = 'Something went wrong. Please try again.'
            snackbarTheme = 'error'
          }
          setTimeout(() => {
            this.setState({
              publishInProgress: false,
              openSnackbar: true,
              snackbarMessage,
              snackbarTheme
            })
          }, 500)
        }
      )
    })
  }

  handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    this.setState({
      openSnackbar: false
    })
  }

  handlePackClick(pack){
    this.setState({
      selectedPack: pack,
      selectedFlight: null,
      selectedLesson: null
    })
  }

  handleFlightClick(flight){
    this.setState({
      selectedFlight: flight,
      selectedLesson: null
    })
  }

  handleLessonClick(lesson){
    this.setState({
      selectedLesson: lesson
    })
  }

  // ===============PACKS==================
  handlePackSave(pack, stateAlreadyUpdated) {
    if(pack.id) {
      if(!stateAlreadyUpdated) {
        const index = this.state.packs.findIndex(
          item => item.id === pack.id
        )
        this.setState(update(this.state, {
          packs: {
            $splice: [
              [index, 1, pack]
            ]
          }
        }))
      }
      API.putPack(pack, response => {
      })
    } else {
      API.postPack(pack, response => {
        this.setState(update(this.state, {
          packs: {
            $push: [ response.pack ]
          }
        }))
      })
    }
  }

  handlePackCopy(packId, languageId) {
    this.setState({ copyInProgress: true }, () => {
      API.copyPack(packId, languageId, response => {
        this.setState({
          copyInProgress: false
        })
      })
    })
  }

  handleFlightCopy(flightId, languageId, targetPackId) {
    let newOrder = this.state.flights.filter(f=> f.packId === targetPackId).sort((a,b)=> b.order - a.order)[0].order + 1 || 1;
    console.log(flightId, languageId, targetPackId, newOrder);

    this.setState({ copyInProgress: true }, () => {
      API.copyFlight(flightId, languageId, targetPackId, newOrder, response => {
        this.setState({
          copyInProgress: false
        })
      })
    })
  }

  handleLessonCopy(lessonId, languageId, targetFlightId) {
    let newOrder = this.state.lessons.filter(l=> l.flightId === targetFlightId).sort((a,b)=> b.order - a.order)[0].order + 1 || 1;
    console.log(lessonId, languageId, targetFlightId, newOrder);

    this.setState({ copyInProgress: true }, () => {
      API.copyLesson(lessonId, languageId, targetFlightId, newOrder, response => {
        this.setState({
          copyInProgress: false
        })
      })
    })
  }

  handlePackDelete(packId) {
    const index = this.state.packs.findIndex(
      item => item.id === packId
    )
    this.setState(update(this.state, {
      packs: {
        $splice: [
          [index, 1]
        ]
      }
    }))
    API.deletePack(packId, response => {
    })
  }

  handlePackMove(pack) {
    const index = this.state.packs.findIndex(
      item => item.id === pack.id
    )
    this.setState(update(this.state, {
      packs: {
        $splice: [
          [index, 1, pack]
        ]
      }
    }))
  }

  // ===============FLIGHTS==================
  handleFlightSave(flight, stateAlreadyUpdated) {
    if(flight.id) {
      if(!stateAlreadyUpdated) {
        const index = this.state.flights.findIndex(
          item => item.id === flight.id
        )
        this.setState(update(this.state, {
          flights: {
            $splice: [
              [index, 1, flight]
            ]
          }
        }))
      }
      API.putFlight(flight, response => {
      })
    } else {
      API.postFlight(flight, response => {
        this.setState(update(this.state, {
          flights: {
            $push: [ response.flight ]
          }
        }))
      })
    }
  }

  handleFlightDelete(flightId) {
    const index = this.state.flights.findIndex(
      item => item.id === flightId
    )
    this.setState(update(this.state, {
      flights: {
        $splice: [
          [index, 1]
        ]
      }
    }))
    API.deleteFlight(flightId, response => {
    })
  }

  handleFlightMove(flight) {
    const index = this.state.flights.findIndex(
      item => item.id === flight.id
    )
    this.setState(update(this.state, {
      flights: {
        $splice: [
          [index, 1, flight]
        ]
      }
    }))
  }

  // ===============LESSONS==================
  handleLessonSave(lesson, cb, stateAlreadyUpdated) {
    if(lesson.id) {
      if(!stateAlreadyUpdated) {
        const index = this.state.lessons.findIndex(
          item => item.id === lesson.id
        )
        this.setState(update(this.state, {
          lessons: {
            $splice: [
              [index, 1, lesson]
            ]
          }
        }), cb)
      }
      API.putLesson(lesson, response => {
      })
    } else {
      API.postLesson(lesson, response => {
        const lesson = response
        this.setState(update(this.state, {
          lessons: {
            $push: [ lesson ]
          }
        }), () => { cb(lesson) })
      })
    }
  }

  handleLessonDelete(lessonId) {
    const index = this.state.lessons.findIndex(
      item => item.id === lessonId
    )
    this.setState(update(this.state, {
      lessons: {
        $splice: [
          [index, 1]
        ]
      }
    }))
    API.deleteLesson(lessonId, response => {
    })
  }

  handleLessonMove(lesson) {
    const index = this.state.lessons.findIndex(
      item => item.id === lesson.id
    )
    this.setState(update(this.state, {
      lessons: {
        $splice: [
          [index, 1, lesson]
        ]
      }
    }))
  }

  async handleLessonModuleSave(lessonModules, cb) {
    let newModules = Object.assign([], this.state.lessonModules)
    for(let lessonModule of lessonModules) {
      let file = false;
      if(lessonModule.contentTypeId === ModuleTypes.FILE_DOWNLOAD){
        file = !!lessonModule.content && !!lessonModule.content.file && lessonModule.content.file;
        const aux = typeof lessonModule.content === 'string' ? JSON.parse(lessonModule.content) : lessonModule.content;
        lessonModule.content = JSON.stringify({
          fileName: aux.fileName,
          fileType: aux.fileType,
          fileSize: aux.fileSize
        });
      }
      if(lessonModule.id) {
        const index = newModules.findIndex(
          item => item.id === lessonModule.id
        )
        newModules[index] = lessonModule
        API.putLessonModule({...lessonModule, file}, response => {});
        // newModules[index] = await API.putLessonModule({...lessonModule, file});
      } else {
        const response = await API.postLessonModule({...lessonModule, file});
        newModules.push(response.lessonModule)
      }
    }
    this.setState({ lessonModules: newModules }, cb);
  }

  handleLessonModuleDelete(lessonModules) {
    let newModules = Object.assign([], this.state.lessonModules)
    lessonModules.forEach(lessonModule => {
      const index = newModules.findIndex(
        item => !!item && !!lessonModule && item.id === lessonModule.id // FIXME sometimes try to read id of undefined
      )
      newModules.splice(index, 1)
      API.deleteLessonModule(lessonModule.id, response => {
      })
    })
    this.setState({ lessonModules: newModules })
  }

  // ===============QUESTIONS==================
  async handleQuestionSave(questions, cb) {
    let newQuestions = Object.assign([], this.state.questions)
    for(let question of questions) {
      if(question.id) {
        const index = newQuestions.findIndex(
          item => item.id === question.id
        )
        newQuestions[index] = question
        API.putQuestion(question, response => {
        })
      } else {
        //Save old ID when a new question is created.
        //When a new question is created, the ID changes.
        //Keep the old ID so that the question's answers
        //can find the question (using old id) and
        //retrieve the new ID.
        const oldId = question.oldId
        delete question.oldId
        let response = await API.postQuestion(question)
        if(oldId !== null) {
          response.question.oldId = oldId
        }
        newQuestions.push(response.question)
      }
    }
    this.setState({ questions: newQuestions }, cb)
  }

  handleQuestionDelete(questions, cb) {
    let newQuestions = Object.assign([], this.state.questions)
    questions.forEach(question => {
      const index = newQuestions.findIndex(
        item => item.id === question.id
      )
      newQuestions.splice(index, 1)
      API.deleteQuestion(question.id, response => {
      })
    })
    this.setState({ questions: newQuestions }, cb)
  }

  // ===============ANSWERS=====================
  async handleAnswerSave(answers, cb) {
    let newAnswers = Object.assign([], this.state.answers)
    for(let answer of answers) {
      if(answer.id) {
        const index = newAnswers.findIndex(
          item => item.id === answer.id
        )
        newAnswers[index] = answer
        API.putAnswer(answer, response => {
        })
      } else {
        if(answer.oldQuestionId) {
          const question = this.state.questions.find(
            item => item.oldId === answer.oldQuestionId
          )
          answer.questionId = question.id
          delete answer.oldQuestionId
        }
        const response = await API.postAnswer(answer)
        newAnswers.push(response.answer)
      }
    }
    this.setState({ answers: newAnswers }, cb)
  }

  handleAnswerDelete(answers, cb) {
    let newAnswers = Object.assign([], this.state.answers)
    answers.forEach(answer => {
      const index = newAnswers.findIndex(
        item => item.id === answer.id
      )
      newAnswers.splice(index, 1)
      API.deleteAnswer(answer.id, response => {
      })
    })
    this.setState({ answers: newAnswers }, cb)
  }
  
  // ===============FUN FACTS=====================
  handleFunFactSave(funFact) {
    if(funFact.id) {
      API.putFunFact(funFact, response => {
        console.log(response)
        API.getAllFunFacts(funFacts => {
          this.setState({ funFacts })
        })
      })
    } else {
      API.postFunFact(funFact, response => {
        console.log(response)
        API.getAllFunFacts(funFacts => {
          this.setState({ funFacts })
        })
      })
    }
  }

  handleFunFactDelete(funFactId) {
    API.deleteFunFact(funFactId, response => {
      API.getAllFunFacts(funFacts => {
        this.setState({ funFacts })
      })
    })
  }

  render() {
    const languages = this.props.languages.map(language => {
      return (
        <MenuItem
          key={language.id}
          value={language.id}
        >
          {language.name}
        </MenuItem>
      )
    })

    const style = {
      container: {
        display: 'flex',
        alignItems: 'center',
      },
      grow: {
        flexGrow: 1
      }
    }

    const selectedTab = this.state.selectedTab
    return(
      <Fade in={true}>
        <div style={style.grow}>
          <CopyPackForm
            open = {this.state.renderCopyPackForm}
            item = {this.state.selectedPack}
            languages = {this.props.languages}
            copyInProgress = {this.state.copyInProgress}
            onClose = {this.toggleCopyPackForm.bind(this)}
            onCopy = {this.handlePackCopy.bind(this)} />

          <CopyFlightForm // select lang, and pack in selected lang to copy into
            open = {this.state.renderCopyFlightForm}
            item = {this.state.selectedFlight}
            languages = {this.props.languages}
            packs = {this.state.packs}
            copyInProgress = {this.state.copyInProgress}
            onClose = {this.toggleCopyFlightForm.bind(this)}
            onCopy = {this.handleFlightCopy.bind(this)} />

          <CopyLessonForm // select lang, pack in said lang, and flight in said pack to copy into
            open = {this.state.renderCopyLessonForm}
            item = {this.state.selectedLesson}
            languages = {this.props.languages}
            packs = {this.state.packs}
            flights = {this.state.flights}
            copyInProgress = {this.state.copyInProgress}
            onClose = {this.toggleCopyLessonForm.bind(this)}
            onCopy = {this.handleLessonCopy.bind(this)} />

          <AppBar position="sticky" className={this.props.classes.cmsNav}>
            <Tabs value={selectedTab} onChange={this.handleActionClick.bind(this)}>
              <Tab label="Manage Content" />
              <Tab label="Add New Lesson" />
              <Tab label="Add New Quiz" />
              <Tab label="Manage Fun Fact" />
            </Tabs>
            <div className={this.props.classes.rightContainer}>
              {selectedTab === 0 &&
                <FormControl className={this.props.classes.rightContainerDropdown}>
                  <InputLabel htmlFor="layout">Layout</InputLabel>
                  <Select
                    value={this.state.selectedLayout}
                    onChange={this.handleLayoutChange.bind(this)}
                    inputProps={{ id: 'layout' }}
                    classes={{
                      selectMenu: this.props.classes.selectLayout
                    }}
                  >
                    <MenuItem value={0}>
                      <ColumnIcon />
                    </MenuItem>
                    <MenuItem value={1}>
                      <GridIcon />
                    </MenuItem>
                  </Select>
                </FormControl>
              }
              <FormControl className={this.props.classes.rightContainerDropdown}>
                <InputLabel htmlFor="language">Language</InputLabel>
                <Select
                  value={this.state.selectedLanguageId}
                  onChange={this.handleLanguageChange.bind(this)}
                  inputProps={{ id: 'language' }}
                  classes={{
                    selectMenu: this.props.classes.selectLanguage
                  }}
                >
                  {languages}
                </Select>
              </FormControl>
              <Button
                variant="contained"
                color="primary"
                className={this.props.classes.rightContainerButton}
                onClick={this.downloadContentScripts.bind(this)}
                disabled={
                  this.state.publishInProgress ||
                  this.state.downloadContentScriptsInProgres
                }
              >
                <div
                  style={{ opacity: this.state.downloadContentScriptsInProgres ? 0 : 1 }}
                >
                  Download Scripts
                </div>
                {this.state.downloadContentScriptsInProgres &&
                  <CircularProgress size={16} className={this.props.classes.progress} />
                }
                
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={this.props.classes.rightContainerButton}
                onClick={ !this.state.selectedLesson ? 
                  (!this.state.selectedFlight ? 
                    this.toggleCopyPackForm.bind(this) 
                    : this.toggleCopyFlightForm.bind(this) 
                  ) 
                  : this.toggleCopyLessonForm.bind(this) 
                }
                disabled={
                  !this.state.selectedPack ||
                  this.state.publishInProgress ||
                  this.state.selectedTab !== 0
                }
              >
                Copy {!this.state.selectedLesson ? (!this.state.selectedFlight ? 'Pack' : 'Flight' ) : 'Lesson'}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={this.handlePublish.bind(this)}
                disabled={
                  !this.state.selectedPack ||
                  this.state.publishInProgress ||
                  this.state.selectedTab !== 0
                }
              >
                <div
                  style={{ opacity: this.state.publishInProgress ? 0 : 1 }}
                >
                  Publish Pack
                </div>
                {this.state.publishInProgress &&
                  <CircularProgress size={16} className={this.props.classes.progress} />
                }
              </Button>
            </div>
          </AppBar>
          {selectedTab === 0 &&
            <div className={this.props.classes.appBarPadding}>
              <ManageContent
                showList = {!this.state.selectedLayout}
                mountAnimation = {true}
                languageId = {this.state.selectedLanguageId}
                packs = {this.state.packs}
                flights = {this.state.flights}
                lessons = {this.state.lessons}
                lessonModules = {this.state.lessonModules}
                questions = {this.state.questions}
                answers = {this.state.answers}
                onPackSave = {this.handlePackSave.bind(this)}
                onPackDelete = {this.handlePackDelete.bind(this)}
                onPackClick = {this.handlePackClick.bind(this)}
                onPackMove = {this.handlePackMove.bind(this)}
                onFlightSave = {this.handleFlightSave.bind(this)}
                onFlightDelete = {this.handleFlightDelete.bind(this)}
                onFlightClick = {this.handleFlightClick.bind(this)}
                onFlightMove = {this.handleFlightMove.bind(this)}
                onLessonSave = {this.handleLessonSave.bind(this)}
                onLessonDelete = {this.handleLessonDelete.bind(this)}
                onLessonClick = {this.handleLessonClick.bind(this)}
                onLessonMove = {this.handleLessonMove.bind(this)}
                onLessonModuleSave = {this.handleLessonModuleSave.bind(this)}
                onLessonModuleDelete = {this.handleLessonModuleDelete.bind(this)}
                onQuestionSave = {this.handleQuestionSave.bind(this)}
                onQuestionDelete = {this.handleQuestionDelete.bind(this)}
                onAnswerSave = {this.handleAnswerSave.bind(this)}
                onAnswerDelete = {this.handleAnswerDelete.bind(this)} />
                <Fade in={this.state.completed < 100 }>
                  <LinearProgress 
                    variant="determinate"
                    value={this.state.completed}
                    className= {this.props.classes.initProgress}/>
                 </Fade>
            </div>
          }
          {selectedTab === 1 &&
            <div>
              <AddLesson
                className={this.props.classes.appBarPadding}
                mountAnimation = {true}
                languageId = {this.state.selectedLanguageId}
                packs = {this.state.packs}
                flights = {this.state.flights}
                lessons = {this.state.lessons}
                lessonModules = {this.state.lessonModules}
                onLessonSave = {this.handleLessonSave.bind(this)}
                onLessonModuleSave = {this.handleLessonModuleSave.bind(this)} />
                <Fade in={this.state.completed < 100 }>
                  <LinearProgress 
                    variant="determinate"
                    value={this.state.completed}
                    className= {this.props.classes.initProgress}/>
                </Fade>
            </div>
          }
          {selectedTab === 2 &&
            <div>
              <AddQuiz
                className={this.props.classes.appBarPadding}
                mountAnimation = {true}
                languageId = {this.state.selectedLanguageId}
                packs = {this.state.packs}
                flights = {this.state.flights}
                lessons = {this.state.lessons}
                questions = {this.state.questions}
                answers = {this.state.answers}
                onQuestionSave = {this.handleQuestionSave.bind(this)}
                onQuestionDelete = {this.handleQuestionDelete.bind(this)}
                onAnswerSave = {this.handleAnswerSave.bind(this)}
                onAnswerDelete = {this.handleAnswerDelete.bind(this)} />
                <Fade in={this.state.completed < 100 }>
                  <LinearProgress 
                    variant="determinate"
                    value={this.state.completed}
                    className= {this.props.classes.initProgress}/>
                </Fade>
            </div>
          }
          {selectedTab === 3 &&
            <div>
              <ManageFunFacts
                className={this.props.classes.appBarPadding}
                mountAnimation = {true}
                languageId = {this.state.selectedLanguageId}
                funFacts = {this.state.funFacts}
                onFunFactSave = {this.handleFunFactSave.bind(this)}
                onFunFactDelete = {this.handleFunFactDelete.bind(this)} />
                <Fade in={this.state.completed < 100 }>
                  <LinearProgress 
                    variant="determinate"
                    value={this.state.completed}
                    className= {this.props.classes.initProgress}/>
                </Fade>
            </div>
          }
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={this.state.openSnackbar}
            autoHideDuration={6000}
            onClose={this.handleSnackbarClose}
            ContentProps={{
              'aria-describedby': 'message-id',
            }}
          >
            <SnackbarContent
              className={
                this.state.snackbarTheme === 'success' ?
                  this.props.classes.snackbarSuccess :
                  this.props.classes.snackbarError
              }
              message={
                <span
                  id="message-id"
                  className={this.props.classes.snackbarMessage}
                >
                  {this.state.snackbarTheme === 'success' ? (
                    <CheckCircleIcon
                      className={this.props.classes.snackbarIcon}
                    />
                  ) : (
                    <ErrorIcon
                      className={this.props.classes.snackbarIcon}
                    />
                  )}
                  {this.state.snackbarMessage}
                </span>
              }
              action={[
                <IconButton
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  onClick={this.handleSnackbarClose}
                >
                  <CloseIcon />
                </IconButton>
              ]}
            >
            </SnackbarContent>
          </Snackbar>
        </div>
      </Fade>
    )
  }
}

export default withStyles(styles)(
  DragDropContext(HTML5Backend)(CMS)
)
