import React, { Component } from 'react'
import {
  Button, TextField, Dialog,
  DialogActions, DialogContent,
  DialogContentText, DialogTitle,
  withStyles, FormControl,
  InputLabel, Select, MenuItem
} from '@material-ui/core'

const styles = theme => ({
  dialogContent: {
    maxWidth: 400
  }
})

const titleMaxCharCount = 200
const messageMaxCharCount = 200

class CreateTranslationForm extends Component {
  constructor(props){
    super(props)
    let item = this.props.item || {
      id: -1,
      title: '',
      message: '',
      languageCode: ''
    }
    this.state = {
      item,
      errorTitle: false,
      errorMessage: false,
      errorLanguage: false,
      titleCharCount: item.title.length + "/" + titleMaxCharCount,
      messageCharCount: item.message.length + "/" + messageMaxCharCount
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  componentDidUpdate(prevProps) {
    if(
      this.props.item &&
      JSON.stringify(this.props.item) !== JSON.stringify(prevProps.item)
    ) {
      let titleCharCount = "0/" + titleMaxCharCount
      if(this.props.item.title) {
        titleCharCount = this.props.item.title.length + "/" + titleMaxCharCount
      }
      let messageCharCount = "0/" + messageMaxCharCount
      if(this.props.item.message) {
        messageCharCount = this.props.item.message.length
          + "/" + messageMaxCharCount
      }
      this.setState({
        item: this.props.item,
        titleCharCount,
        messageCharCount
      })
    }
  }

  handleChange(value, input) {
    if(input === "title" && value.length > titleMaxCharCount) {
      value = value.substring(0, titleMaxCharCount)
    } else if(input === "message" && value.length > messageMaxCharCount) {
      value = value.substring(0, messageMaxCharCount)
    }
    let newItem = Object.assign({}, this.state.item)
    newItem[input] = value
    this.setState({ item: newItem, ...this.isItemValid(newItem) })

    if(input === "title") {
      this.setState({
        titleCharCount:
          value.length + "/" + titleMaxCharCount
      })
    } else if(input === "message") {
      this.setState({
        messageCharCount:
          value.length + "/" + messageMaxCharCount
      })
    }
  }

  handleSubmit(e){
    let errorState = this.isItemValid(this.state.item)
    if(Object.values(errorState).includes(true)) {
      this.setState({ ...errorState })
      return
    }
    this.props.onSave(this.state.item)
    this.handleClose()
  }

  handleClose() {
    this.setState({
      item: {
        title: '',
        message: '',
        languageCode: ''
      },
      errorTitle: false,
      errorMessage: false,
      errorLanguage: false,
      titleCharCount: "0/" + titleMaxCharCount,
      messageCharCount: "0/" + messageMaxCharCount
    })
    this.props.onClose()
  }

  isItemValid(item) {
    let newState = {}
    if(item.title === '') {
      newState.errorTitle = true
    } else {
      newState.errorTitle = false
    }
    if(item.message === '') {
      newState.errorMessage = true
    } else {
      newState.errorMessage = false
    }
    if(item.languageCode === '') {
      newState.errorLanguage = true
    } else {
      newState.errorLanguage = false
    }
    return newState
  }

  render(){
    return(
      <Dialog
        open={this.props.open}
        onClose={this.handleClose}
      >
        <DialogTitle>
          Create/Edit Translation
        </DialogTitle>
        <DialogContent className={this.props.classes.dialogContent}>
          <DialogContentText>
            Feel free to make any changes below.
          </DialogContentText>
          <FormControl
            margin="normal"
            required
            fullWidth
            error={this.props.errorLanguage}
          >
            <InputLabel>Language</InputLabel>
            <Select
              value={this.state.item.languageCode}
              onChange={e => this.handleChange(e.target.value, 'languageCode')}
            >
              { this.props.languages.map(item =>
                <MenuItem
                  key={item.id}
                  value={item.code.substring(0, 2)}
                >
                  {item.name}
                </MenuItem>
                )
              }
            </Select>
          </FormControl>
          <TextField
            required
            margin="normal"
            multiline
            rows="4"
            id="title"
            label="Title"
            type="text"
            value={this.state.item.title}
            helperText={this.state.titleCharCount}
            onChange={e => this.handleChange(e.target.value, 'title')}
            fullWidth
            error={this.state.errorTitle}
          />
          <TextField
            required
            margin="normal"
            multiline
            rows="4"
            id="message"
            label="Message"
            type="text"
            value={this.state.item.message}
            helperText={this.state.messageCharCount}
            onChange={e => this.handleChange(e.target.value, 'message')}
            fullWidth
            error={this.state.errorMessage}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.handleClose}
            color="secondary">
            Cancel
          </Button>
          <Button
            onClick={this.handleSubmit}
            color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(CreateTranslationForm)
