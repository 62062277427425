import React, { Component } from 'react'
import { withStyles, Card, CardMedia, CardContent, Typography } from '@material-ui/core'

const styles = theme => ({
  cardMedia: {
    maxHeight: '300px',
    width: 'auto',
    margin: '0 auto'
  }
})

const isImage = content => {
  return content.includes("base64")
}

class VideoModule extends Component {
  render() {
    return(
      <div>
        <Card>
          { isImage(this.props.module.content) &&
            <CardMedia
              className={this.props.classes.cardMedia}
              component="img"
              image={this.props.module.content} />
          }
          { !isImage(this.props.module.content) &&
            <CardContent>
              <Typography component="p">
                Video
              </Typography>
            </CardContent>
          }
        </Card>
      </div>
    )
  }
}

export default withStyles(styles)(VideoModule)
