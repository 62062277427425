import React, { Component } from 'react'
import {
  withStyles, Grid, TextField
} from '@material-ui/core'

const styles = theme => ({
  wrapper: {
    paddingTop: theme.spacing(4.5)
  }
})

class CountRepeatForm extends Component {
  render() {
    return(
      <Grid
        container
        direction="column"
        spacing={2}
        className={this.props.classes.wrapper}
      >
        <Grid item>
          <TextField
            label="Number of Repeated Completions"
            margin="none"
            fullWidth
            value={this.props.repeatCount || ''}
            onChange={this.props.onRepeatCountChange} />
        </Grid>
        <Grid item>
          <TextField
            label="Beercoin Amount Earned By User"
            margin="normal"
            fullWidth
            value={this.props.beercoinEarnAmount || ''}
            onChange={this.props.onBeercoinChange} />
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(CountRepeatForm)
