import React, { Component } from 'react'
import {
  Fade, Stepper, Step,
  StepLabel, withStyles
} from '@material-ui/core'
import ItemList from '../../item/item-list/index'
import CreatePackForm from '../../item/item-form/create-pack-form'
import CreateFlightForm from '../../item/item-form/create-flight-form'
import CreateLessonForm from '../../item/item-form/create-lesson-form'
import CreateQuizForm from '../../item/item-form/create-quiz-form'
import DeleteItemForm from '../../item/item-form/delete-item-form'

const styles = theme => ({
  columnContainer: {
    width: '100%',
    display:'flex',
    padding: '0 20px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
    borderRadius: '4px'
  },
  column: {
    flexBasis: '25%'
  },
  stepper: {
    backgroundColor: 'unset'
  }
})

const columnNames = ['PACK', 'FLIGHT', 'LESSON', 'QUIZ QUESTIONS']

class ManageContentList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeStep: 0,
      renderCreatePackForm: false,
      renderCreateFlightForm: false,
      renderCreateLessonForm: false,
      renderCreateQuizForm: false,
      renderDeleteForm: false,
      renderFlights: false,
      renderLessons: false,
      renderQuestions: false,
      openPackId: -1,
      openPackIndex: -1,
      openFlightId: -1,
      openFlightIndex: -1,
      openLessonId: -1,
      openLessonIndex: -1,
      formItemType: '',
      formItemId: -1
    }
  }
    /*************************
    UPDATE STATE IF LANGUAGE CHANGE HAS BEEN MADE
    **************************/
  componentDidUpdate(prev){
    if(prev.languageId !== this.props.languageId){
      this.setState({
        activeStep: 0,
        renderCreatePackForm: false,
        renderCreateFlightForm: false,
        renderCreateLessonForm: false,
        renderCreateQuizForm: false,
        renderDeleteForm: false,
        renderFlights: false,
        renderLessons: false,
        renderQuestions: false,
        openPackId: -1,
        openPackIndex: -1,
        openFlightId: -1,
        openFlightIndex: -1,
        openLessonId: -1,
        openLessonIndex: -1,
        formItemType: '',
        formItemId: -1
      })
    }
  }
  /*************************
    PROGRESS BAR / STEPPER
  **************************/

  handleStep(step) {
    let newStep = this.state.activeStep - 1
    if(typeof step === "number") {
      newStep = step
    }
    this.setState({
      activeStep: newStep
    })
  }

  /*******
    PACKS
  ********/

  handlePackClick(pack) {
    if(this.state.renderFlights &&
       this.state.openPackId === pack.id) {
      this.setState({
        renderFlights: false,
        renderLessons: false,
        renderQuestions: false,
        openPackId: -1,
        openPackIndex: -1,
        openFlightId: -1,
        openFlightIndex: -1,
        openLessonId: -1,
        openLessonIndex: -1
      })
      this.handleStep(0)
      this.props.onPackClick('')
    } else {
      this.setState({
        renderFlights: true,
        renderLessons: false,
        renderQuestions: false,
        openPackId: pack.id,
        openPackIndex: pack.order - 1,
        openFlightId: -1,
        openFlightIndex: -1,
        openLessonId: -1,
        openLessonIndex: -1
      })
      this.handleStep(1)
      this.props.onPackClick(pack)
    }
  }

  handlePackEdit(pack) {
    this.toggleCreatePackForm(pack.id)
  }

  handlePackAdd() {
    this.toggleCreatePackForm()
  }

  handlePackDelete(pack) {
    this.toggleDeleteForm('pack', pack.id)
  }

  /*********
    FLIGHTS
  **********/

  handleFlightClick(flight) {
    if(this.state.renderLessons &&
       this.state.openFlightId === flight.id) {
      this.setState({
        renderLessons: false,
        renderQuestions: false,
        openFlightId: -1,
        openFlightIndex: -1,
        openLessonId: -1,
        openLessonIndex: -1
      })
      this.handleStep(1)
      this.props.onFlightClick('')
    } else {
      this.setState({
        renderLessons: true,
        renderQuestions: false,
        openFlightId: flight.id,
        openFlightIndex: flight.order - 1,
        openLessonId: -1,
        openLessonIndex: -1
      })
      this.handleStep(2)
      this.props.onFlightClick(flight)
    }
  }

  handleFlightEdit(flight) {
    this.toggleCreateFlightForm(flight.id)
  }

  handleFlightAdd() {
    this.toggleCreateFlightForm()
  }

  handleFlightDelete(flight) {
    this.toggleDeleteForm('flight', flight.id)
  }

  /*********
    LESSONS
  **********/

  handleLessonClick(lesson) {
    if(this.state.renderQuestions &&
       this.state.openLessonId === lesson.id) {
      this.setState({
        renderQuestions: false,
        openLessonId: -1,
        openLessonIndex: -1
      })
      this.handleStep(2)
      this.props.onLessonClick('')
    } else {
      this.setState({
        renderQuestions: true,
        openLessonId: lesson.id,
        openLessonIndex: lesson.order - 1
      })
      this.handleStep(3)
      this.props.onLessonClick(lesson)
    }
  }

  handleLessonEdit(lesson) {
    this.toggleCreateLessonForm(lesson.id)
  }

  handleLessonDelete(lesson) {
    this.toggleDeleteForm('lesson', lesson.id)
  }

  /*********
    QUESTIONS
  **********/

  handleQuestionClick(question) { }

  handleQuestionEdit(question) {
    this.toggleCreateQuizForm(question.id)
  }

  handleQuestionDelete(question) {
    this.toggleDeleteForm('question', question.id)
  }

  /******************
    SAVE/DELETE ITEM
  *******************/

  handleFormSave(newItem, deletedSubItems) {
    if(!newItem) { return }
    newItem.languageId = this.props.languageId
    switch(this.state.formItemType) {
      case "pack":
        if(!newItem.order) {
          newItem.order = this.props
                              .packs
                              .filter(pack =>
                                pack.languageId === this.props.languageId
                              )
                              .length + 1
        }
        this.props.onPackSave(newItem)
        break
      case "flight":
        if(!newItem.order) {
          newItem.order = this.props
                              .flights
                              .filter(flight =>
                                flight.packId === this.state.openPackId
                              )
                              .length + 1
        }
        if(!newItem.packId) {
          newItem.packId = this.state.openPackId
        }
        this.props.onFlightSave(newItem)
        break
      case "lesson":
        if(!newItem.order) {
          newItem.order = this.props.lessons.filter(lesson =>
            lesson.flightId === this.state.openFlightId
          ).length + 1
        }
        if(!newItem.flightId) {
          newItem.flightId = this.state.openFlightId
        }
        if(!newItem.difficultyLevel) {
          newItem.difficultyLevel = 1
        }
        let newModules = Object.assign([], newItem.modules);
        delete newItem.modules;
        this.props.onLessonSave(newItem, () => {
          newModules = newModules.map(module => {
            let id = parseInt(module.id, 10)
            if(Number.isNaN(id)) { delete module.id }
            module.lessonId = newItem.id
            module.languageId = newItem.languageId
            return module
          });
          this.props.onLessonModuleSave(newModules, () => {
            if(deletedSubItems && deletedSubItems.length > 0) {
              this.props.onLessonModuleDelete(deletedSubItems)
            }
          })
        })
        break
      default:
        break
    }
  }

  handleQuizFormSave(items, deletedItems, deletedSubItems) {
    let answers = []
    let questions = items.map(item => {
      let id = parseInt(item.id, 10)
      if(Number.isNaN(id)) {
        item.oldId = item.id
        delete item.id
      }
      item.languageId = this.props.languageId
      item.questionTypeId = 1
      if(!item.lessonId) {
        item.lessonId = this.state.openLessonId
      }
      if(item.answers) {
        item.answers = item.answers.map(answer => {
          let id = parseInt(answer.id, 10)
          if(Number.isNaN(id)) { delete answer.id }
          if(item.id) { answer.questionId = item.id }
          else { answer.oldQuestionId = item.oldId }
          answer.languageId = item.languageId
          return answer
        })
        answers = answers.concat(item.answers)
        delete item.answers
      }
      return item
    })

    this.props.onQuestionSave(questions, () => {
      this.props.onAnswerSave(answers, () => {
        if(deletedItems && deletedItems.length > 0) {
          this.props.onQuestionDelete(deletedItems, () => {
            if(deletedSubItems && deletedSubItems.length > 0) {
              this.props.onAnswerDelete(deletedSubItems)
            }
          })
        } else if(deletedSubItems && deletedSubItems.length > 0) {
          this.props.onAnswerDelete(deletedSubItems)
        }
      })
    })
  }

  handleFormDelete() {
    switch(this.state.formItemType) {
      case "pack":
        this.props.onPackDelete(this.state.formItemId)
        break
      case "flight":
        this.props.onFlightDelete(this.state.formItemId)
        break
      case "lesson":
        this.props.onLessonDelete(this.state.formItemId)
        break
      case "question":
        this.props.onQuestionDelete([ { id: this.state.formItemId } ])
        break
      default:
        break
    }
  }

  /********
    FORMS
  *********/

  toggleCreatePackForm(itemId) {
    this.setState({
      renderCreatePackForm: !this.state.renderCreatePackForm,
      formItemType: 'pack',
      formItemId: itemId ? itemId : -1
    })
  }

  toggleCreateFlightForm(itemId) {
    this.setState({
      renderCreateFlightForm: !this.state.renderCreateFlightForm,
      formItemType: 'flight',
      formItemId: itemId ? itemId : -1
    })
  }

  toggleCreateLessonForm(itemId) {
    this.setState({
      renderCreateLessonForm: !this.state.renderCreateLessonForm,
      formItemType: 'lesson',
      formItemId: itemId ? itemId : -1
    })
  }

  toggleCreateQuizForm(itemId) {
    this.setState({
      renderCreateQuizForm: !this.state.renderCreateQuizForm,
      formItemType: 'question',
      formItemId: itemId ? itemId : -1
    })
  }

  toggleDeleteForm(itemType, itemId) {
    this.setState({
      renderDeleteForm: !this.state.renderDeleteForm,
      formItemType: itemType,
      formItemId: itemId ? itemId : -1
    })
  }

  render() {
    //Sort and filter data
    let packs = this.props.packs.filter(pack => pack.languageId === this.props.languageId)
    packs = [...packs].sort((a,b) => a.order - b.order)
    let flights = this.props.flights.filter(flight => flight.packId === this.state.openPackId)
    flights = [...flights].sort((a,b) => a.order - b.order)
    let lessons = this.props.lessons.filter(lesson => lesson.flightId === this.state.openFlightId)
    lessons = [...lessons].sort((a,b) => a.order - b.order)
    let questions = this.props.questions.filter(question => question.lessonId === this.state.openLessonId)
    questions = [...questions].sort((a,b) => a.id - b.id)
    questions = questions.map(question => {
      question.answers = this.props.answers.filter(answer => answer.questionId === question.id)
      question.answers = [...question.answers].sort((a,b) => a.id - b.id)
      return question
    })

    //If a form needs to be opened, find the
    //item that the form can consume.
    let formItem
    if(this.state.formItemId > -1) {
      switch(this.state.formItemType) {
        case "pack":
          formItem = packs.find(pack => pack.id === this.state.formItemId)
          break
        case "flight":
          formItem = flights.find(flight => flight.id === this.state.formItemId)
          break
        case "lesson":
          formItem = lessons.find(lesson => lesson.id === this.state.formItemId)
          formItem.modules = this.props.lessonModules.filter(module => module.lessonId === this.state.formItemId)
          formItem.modules = [...formItem.modules].sort((a,b) => a.order - b.order)
          break
        case "question":
          formItem = questions.find(question => question.id === this.state.formItemId)
          break
        default:
          formItem = null
          break
      }
    }

    //When rendering between the item form
    //and the delete form, make sure that
    //the input data is not shared between
    //the forms. Each form should have
    //its own data input to ensure that
    //there are no unexpected data changes.
    let deleteItem = null
    if(this.state.renderDeleteForm) {
      deleteItem = Object.assign({}, formItem)
      formItem = null
    }

    const style = {
      container: {
        width: '90%',
        margin: 'auto'
      }
    }

    return(
      <Fade in={this.props.mountAnimation} className={this.props.className}>
        <div style={style.container}>
          <CreatePackForm
            open = {this.state.renderCreatePackForm}
            item = {formItem}
            onClose = {this.toggleCreatePackForm.bind(this)}
            onSave = {this.handleFormSave.bind(this)} />
          <CreateFlightForm
            open = {this.state.renderCreateFlightForm}
            item = {formItem}
            onClose = {this.toggleCreateFlightForm.bind(this)}
            onSave = {this.handleFormSave.bind(this)} />
          <CreateLessonForm
            open = {this.state.renderCreateLessonForm}
            item = {formItem}
            onClose = {this.toggleCreateLessonForm.bind(this)}
            onSave = {this.handleFormSave.bind(this)}
            onDelete = {this.handleFormDelete.bind(this)} />
          <CreateQuizForm
            open = {this.state.renderCreateQuizForm}
            items = {questions}
            openItem = {formItem}
            onClose = {this.toggleCreateQuizForm.bind(this)}
            onSave = {this.handleQuizFormSave.bind(this)} />
          <DeleteItemForm
            open = {this.state.renderDeleteForm}
            item = {deleteItem}
            onClose = {this.toggleDeleteForm.bind(this)}
            onDelete = {this.handleFormDelete.bind(this)} />

          {!this.state.renderCreateLessonForm &&
           !this.state.renderCreateQuizForm &&
            <div>
              <Stepper
                className={this.props.classes.stepper}
                activeStep={this.state.activeStep}
                alternativeLabel>
                {columnNames.map(label => {
                  return (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
              <div className={this.props.classes.columnContainer}>
                <div className={this.props.classes.column}>
                  <ItemList
                    mountAnimation = {true}
                    items = {packs}
                    allowAdding = {true}
                    addItemLabel = {"Add a Pack"}
                    allowEditing = {true}
                    allowDeleting = {true}
                    selectedItemId = {this.state.openPackId}
                    onItemClick = {this.handlePackClick.bind(this)}
                    onItemEdit = {this.handlePackEdit.bind(this)}
                    onItemAdd = {this.handlePackAdd.bind(this)}
                    onItemDelete = {this.handlePackDelete.bind(this)} />
                </div>
                <div className={this.props.classes.column}>
                  <ItemList
                    mountAnimation = {this.state.renderFlights}
                    items = {flights}
                    allowAdding = {true}
                    addItemLabel = {"Add a Flight"}
                    allowEditing = {true}
                    allowDeleting = {true}
                    marginTop = {this.state.openPackIndex}
                    selectedItemId = {this.state.openFlightId}
                    onItemClick = {this.handleFlightClick.bind(this)}
                    onItemEdit = {this.handleFlightEdit.bind(this)}
                    onItemAdd = {this.handleFlightAdd.bind(this)}
                    onItemDelete = {this.handleFlightDelete.bind(this)} />
                </div>
                <div className={this.props.classes.column}>
                  <ItemList
                    mountAnimation = {this.state.renderLessons}
                    items = {lessons}
                    allowEditing = {true}
                    allowDeleting = {true}
                    marginTop = {this.state.openPackIndex + this.state.openFlightIndex}
                    selectedItemId = {this.state.openLessonId}
                    onItemClick = {this.handleLessonClick.bind(this)}
                    onItemEdit = {this.handleLessonEdit.bind(this)}
                    onItemDelete = {this.handleLessonDelete.bind(this)} />
                </div>
                <div className={this.props.classes.column}>
                  <ItemList
                    mountAnimation = {this.state.renderQuestions}
                    items = {questions}
                    allowEditing = {true}
                    allowDeleting = {true}
                    marginTop = {
                      this.state.openPackIndex +
                      this.state.openFlightIndex +
                      this.state.openLessonIndex
                    }
                    onItemClick = {this.handleQuestionClick.bind(this)}
                    onItemEdit = {this.handleQuestionEdit.bind(this)}
                    onItemDelete = {this.handleQuestionDelete.bind(this)} />
                </div>
              </div>
            </div>
          }
        </div>
      </Fade>
    )
  }
}

export default withStyles(styles)(ManageContentList)
