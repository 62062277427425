import React, { Component } from 'react'
import {
  Button, TextField, Dialog,
  DialogActions, DialogContent,
  DialogContentText, DialogTitle,
  withStyles, FormControl,
  InputLabel, Select, MenuItem
} from '@material-ui/core'

const styles = theme => ({
  dialogContent: {
    maxWidth: 400
  }
})

const nameMaxCharCount = 200
const descriptionMaxCharCount = 200

class CreateBadgeGroupTranslationForm extends Component {
  constructor(props){
    super(props)
    let item = {
      id: -1,
      languageId: -1,
      name: '',
      description: ''
    }
    if(this.props.item) { item = this.props.item }
    this.state = {
      item,
      errorLanguage: false,
      errorName: false,
      errorDescription: false,
      nameCharCount: item.name.length + "/" + nameMaxCharCount,
      descriptionCharCount: item.description.length + "/" + descriptionMaxCharCount
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  componentDidUpdate(prevProps) {
    if(
      this.props.item &&
      JSON.stringify(this.props.item) !== JSON.stringify(prevProps.item)
    ) {
      let nameCharCount = "0/" + nameMaxCharCount
      if(this.props.item.name) {
        nameCharCount = this.props.item.name.length + "/" + nameMaxCharCount
      }
      let descriptionCharCount = "0/" + descriptionMaxCharCount
      if(this.props.item.description) {
        descriptionCharCount = this.props.item.description.length
          + "/" + descriptionMaxCharCount
      }
      this.setState({
        item: this.props.item,
        nameCharCount,
        descriptionCharCount
      })
    }
  }

  handleChange(value, input) {
    if(input === "name" && value.length > nameMaxCharCount) {
      value = value.substring(0, nameMaxCharCount)
    } else if(input === "description" && value.length > descriptionMaxCharCount) {
      value = value.substring(0, descriptionMaxCharCount)
    }
    let newItem = Object.assign({}, this.state.item)
    newItem[input] = value
    this.setState({ item: newItem, ...this.isItemValid(newItem) })

    if(input === "name") {
      this.setState({
        nameCharCount:
          value.length + "/" + nameMaxCharCount
      })
    } else if(input === "description") {
      this.setState({
        descriptionCharCount:
          value.length + "/" + descriptionMaxCharCount
      })
    }
  }

  handleSubmit(e){
    let errorState = this.isItemValid(this.state.item)
    if(Object.values(errorState).includes(true)) {
      this.setState({ ...errorState })
      return
    }
    this.props.onSave(this.state.item)
    this.handleClose()
  }

  handleClose() {
    this.setState({
      item: {
        languageId: -1,
        name: '',
        description: ''
      },
      errorLanguage: false,
      errorName: false,
      errorDescription: false,
      nameCharCount: "0/" + nameMaxCharCount,
      descriptionCharCount: "0/" + descriptionMaxCharCount
    })
    this.props.onClose()
  }

  isItemValid(item) {
    let newState = {}
    if(item.languageId === -1) {
      newState.errorLanguage = true
    } else {
      newState.errorLanguage = false
    }
    if(!item.name || (item.name && item.name.length < 2)) {
      newState.errorName = true
    } else {
      newState.errorName = false
    }
    if(!item.description || (item.description && item.description.length < 2)) {
      newState.errorDescription = true
    } else {
      newState.errorDescription = false
    }
    return newState
  }

  render(){
    return(
      <Dialog
        open={this.props.open}
        onClose={this.handleClose}
      >
        <DialogTitle>
          Create/Edit Badge Translation
        </DialogTitle>
        <DialogContent className={this.props.classes.dialogContent}>
          <DialogContentText>
            Feel free to make any changes below.
          </DialogContentText>
          <FormControl
            margin="normal"
            required
            fullWidth
            error={this.props.errorLanguage}
          >
            <InputLabel>Language</InputLabel>
            <Select
              value={this.state.item.languageId}
              onChange={e => this.handleChange(e.target.value, 'languageId')}
            >
              { this.props.languages.map(item =>
                <MenuItem
                  key={item.id}
                  value={item.id}
                >
                  {item.name}
                </MenuItem>
                )
              }
            </Select>
          </FormControl>
          <TextField
            required
            margin="normal"
            multiline
            rows="4"
            id="name"
            label="Name"
            type="text"
            value={this.state.item.name}
            helperText={this.state.nameCharCount}
            onChange={e => this.handleChange(e.target.value, 'name')}
            fullWidth
            error={this.state.errorName}
          />
          <TextField
            required
            margin="normal"
            multiline
            rows="4"
            id="description"
            label="Description"
            type="text"
            value={this.state.item.description}
            helperText={this.state.descriptionCharCount}
            onChange={e => this.handleChange(e.target.value, 'description')}
            fullWidth
            error={this.state.errorDescription}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.handleClose}
            color="secondary">
            Cancel
          </Button>
          <Button
            onClick={this.handleSubmit}
            color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(CreateBadgeGroupTranslationForm)
