import React, { Component } from 'react'
import Fade from '@material-ui/core/Fade'
import Typography from '@material-ui/core/Typography'
export default class HomePage extends Component {
  render(){

    return(
      <Fade in={this.props.mountAnimation}>
        <div style={{ marginTop: '150px', textAlign: 'center' }}>
        <Typography variant="h3">Welcome {this.props.name}</Typography>
        </div>
      </Fade>
    )
  }
}
