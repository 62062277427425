import React, { Component } from 'react'
import {Fade, Button, AppBar, withStyles, CircularProgress} from '@material-ui/core'
import UsersTable from './users-table'
import DeleteUserForm from './forms/user-delete-form'
import EditUserForm from './forms/user-edit-form'
import AddUserForm from './forms/user-add-form'
import DetailsUserForm from './forms/user-details-form'
import API from '../../API'
import XLSX from 'xlsx'

const styles = theme => ({
  cmsNav: {
    padding: '10px',
    backgroundColor: '#C0C0C0',
    display: 'grid',
    gridTemplateColumns: '50% 50%'
  },
  rightContainer: {
    paddingRight: '20px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gridColumnStart: 2
  },
  rightContainerButton: {
    marginRight: '20px'
  },
  progress: {
    color: theme.palette.common.white,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -8,
    marginLeft: -8
  },
});
class Users extends Component {
  constructor(props){
    super(props)
    this.state= {
      appFacingData: {},
      appFacingDataModal: false,
      renderDetailsForm: false,
      renderEditForm: false,
      renderAddForm: false,
      renderDeleteForm: false,
      detailsItem: {},
      editItem: {},
      deleteItem: {},
      downloadUsersXLSInProgress: false
    }
    this.toggleDetailsForm = this.toggleDetailsForm.bind(this)
    this.toggleEditForm = this.toggleEditForm.bind(this)
    this.toggleAddForm = this.toggleAddForm.bind(this)
    this.toggleDeleteForm = this.toggleDeleteForm.bind(this)
    this.handleAppFacingData = this.handleAppFacingData.bind(this)
    this.closeAppFacingDataModal = this.closeAppFacingDataModal.bind(this)
  }

  toggleDetailsForm(item) {
    console.log(this.state);
    this.setState({
      renderDetailsForm: !this.state.renderDetailsForm,
      detailsItem: item
    })
  }

  toggleEditForm(item){
    console.log(this.state);
    this.setState({
      renderEditForm: !this.state.renderEditForm,
      editItem: item
    })
  }

  toggleAddForm(){
    console.log(this.state);
    this.setState({
      renderAddForm: !this.state.renderAddForm
    })
  }

  toggleDeleteForm(item){
    this.setState({
      renderDeleteForm: !this.state.renderDeleteForm,
      deleteItem: item
    })
  }

  handleAppFacingData(id){
    API.getUserSimulation(id, appFacingData => {
      if(!appFacingData) { return }
      this.setState({
        appFacingData,
        appFacingDataModal: true
      })
    })
  }

  closeAppFacingDataModal(){
    this.setState({
      appFacingDataModal: false,
      appFacingData: {}
    })
  }

  downloadUsersXLS(){
    // these are the headers
    let arrUsers = [['User ID', 'First Name', 'Last Name', 'Email', 'Country', 'Office', 'Department', 'Beercoins Balance', 'User Type']];
    this.setState({ downloadUsersXLSInProgress: true }, () => {
      this.props.users.forEach(user => {
        let userRow = [
          user.id, 
          user.firstName, 
          user.lastName, 
          user.emailAddress, 
          (user.country === 'PUT' ? null : user.country),
          user.office.name,
          user.department.name,
          user.balance,
          user.userType === 1 ? 'Internal User' : 'External User'
        ];
        arrUsers.push(userRow);
      });
      let worksheet = XLSX.utils.aoa_to_sheet(arrUsers);
      worksheet['!cols'] = this.fitToColumn(arrUsers);
      var workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "UserList");
      XLSX.writeFile(workbook,'HoppyUsersList.xlsx');
      this.setState({downloadUsersXLSInProgress: false});
    });
  }

  fitToColumn(arrayOfArray) {
    // get maximum character of each column
    return arrayOfArray[0].map((a, i) => ({ wch: Math.max(...arrayOfArray.map(a2 => `${a2[i]}`.length)) }));
  }

  render(){
    return(
      <Fade in={this.props.mountAnimation}>
        <div>
          <DetailsUserForm
            open={this.state.renderDetailsForm}
            openModal={this.state.appFacingDataModal}
            appFacingData={this.state.appFacingData}
            closeModal={this.closeAppFacingDataModal}
            handleAppFacingData = {this.handleAppFacingData}
            handleClose = {this.toggleDetailsForm}
            item = {this.state.detailsItem}
            userBadges = {this.state.userBadges}/>
          <EditUserForm
            open={this.state.renderEditForm}
            handleClose = {this.toggleEditForm}
            updateData = {this.props.updateData}
            handleSubmit = {this.props.handleSubmit}
            item = {this.state.editItem}
            url = {this.props.userURL}
            offices = {this.props.offices}
            departments = {this.props.departments}
            languages = {this.props.languages}
            sections = {this.props.sections}
            zones = {this.props.zones}
            countries = {this.props.countries}/>
          <AddUserForm
            open={this.state.renderAddForm}
            handleClose = {this.toggleAddForm}
            handleSubmit = {this.props.handleSubmit}
            item = {{}}
            url = {this.props.userURL}
            offices = {this.props.offices}
            departments = {this.props.departments}
            languages = {this.props.languages}
            zones = {this.props.zones}
            countries = {this.props.countries}/>
          <DeleteUserForm
            open = {this.state.renderDeleteForm}
            onClose = {this.toggleDeleteForm}
            onDelete = {this.props.handleDelete}
            item = {this.state.deleteItem}
            url = {this.props.userURL}/>

          <AppBar position="sticky" className={this.props.classes.cmsNav}>
            <div className={this.props.classes.rightContainer}>
              <Button
                variant="contained"
                color="primary"
                className={this.props.classes.rightContainerButton}
                onClick={this.downloadUsersXLS.bind(this)}
                disabled={this.state.downloadUsersXLSInProgress || this.props.users.length === 0}
              >
                <div
                  style={{ opacity: this.state.downloadUsersXLSInProgress ? 0 : 1 }}
                >
                  Export Users
                </div>
                {this.state.downloadUsersXLSInProgress &&
                  <CircularProgress size={16} className={this.props.classes.progress} />
                }
                
              </Button>
            </div>
          </AppBar>
          <UsersTable
            users={this.props.users}
            userURL={this.props.userURL}
            renderAddForm={this.toggleAddForm}
            renderDetailsForm={this.toggleDetailsForm}
            renderEditForm={this.toggleEditForm}
            renderDeleteForm={this.toggleDeleteForm}/>
        </div>
      </Fade>
    )
  }
}

export default withStyles(styles)(Users);