export default {
  ZONEADD: {
    NAME: "zoneAdd",
    FIELDS: ["name", "description"],
    LANGUAGEDROPDOWN: false,
    ZONEDROPDOWN: false,
    RENDERCHECKBOX: true,
    READONLY: [],
  },
  ADMINSECTIONADD: {
    NAME: "adminSectionAdd",
    FIELDS: ["name", "description"],
    LANGUAGEDROPDOWN: false,
    ZONEDROPDOWN: false,
    RENDERCHECKBOX: false,
    ADMINSECTION: true,
  },
  DEPARTMENTSECTIONADD: {
      NAME: "departmentAdd", 
      FIELDS: ["name"],
      LANGUAGEDROPDOWN: false,
      ZONEDROPDOWN: false,
      RENDERCHECKBOX: false,
      READONLY: [], 
  },
  OFFICESECTIONADD: {
    NAME: "officeAdd", 
    FIELDS: ["name", "address1", "address2", "city", "stateProvince", "postalCode", "country"],
    LANGUAGEDROPDOWN: false,
    ZONEDROPDOWN: true,
    RENDERCHECKBOX: false,
    READONLY: [],
  },
  LANGUAGESECTIONADD: {
    NAME: "languageAdd",
    FIELDS:["name", "description", "code"],
    LANGUAGEDROPDOWN: false,
    ZONEDROPDOWN: false,
    RENDERCHECKBOX: true,
    READONLY: [],
  },
  ZONEEDIT: {
    NAME: "zoneEdit",
    FIELDS: ["name", "description"],
    LANGUAGEDROPDOWN: false,
    ZONEDROPDOWN: false,
    RENDERCHECKBOX: true,
    READONLY: [],
  },
  ADMINSECTIONEDIT: {
    NAME: "adminSectionEdit",
    FIELDS: ["name", "description"],
    LANGUAGEDROPDOWN: false,
    ZONEDROPDOWN: false,
    RENDERCHECKBOX: false,
    READONLY: ["name"],
  },
  DEPARTMENTSECTIONEDIT: {
    NAME: "departmentEdit", 
    FIELDS: ["name"],
    LANGUAGEDROPDOWN: false,
    ZONEDROPDOWN: false,
    RENDERCHECKBOX: false,
    READONLY: [],
  },
  OFFICESECTIONEDIT: {
    NAME: "officeEdit", 
    FIELDS: ["name", "address1", "address2", "city", "stateProvince", "postalCode", "country"],
    LANGUAGEDROPDOWN: false,
    ZONEDROPDOWN: true,
    RENDERCHECKBOX: false,
    READONLY: [],
  },
  LANGUAGESECTIONEDIT: {
    NAME: "languageEdit",
    FIELDS:["name", "description", "code"],
    LANGUAGEDROPDOWN: false,
    ZONEDROPDOWN: false,
    RENDERCHECKBOX: true,
    READONLY: [],
  },
  HEADERS: {
    "Content-Type": "application/json"
  }
};
