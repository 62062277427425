import React, { Component } from 'react'
import {
  Fade, Stepper, Step,
  StepLabel, withStyles
} from '@material-ui/core'
import ItemList from '../item/item-list/index'
import CreateQuizForm from '../item/item-form/create-quiz-form'

const styles = theme => ({
  step: {
    flex: '0 1 33%'
  },
  columnContainer: {
    width: '80%',
    margin: 'auto',
    display:'flex',
    padding: '0 20px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
    borderRadius: '4px'
  },
  column: {
    flexBasis: '33%'
  },
  stepper: {
    backgroundColor: 'unset'
  }
})

const columnNames = ['PACK', 'FLIGHT', 'LESSON']

class AddQuiz extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeStep: 0,
      renderCreateQuizForm: false,
      renderFlights: false,
      renderLessons: false,
      openPackId: -1,
      openPackIndex: -1,
      openFlightId: -1,
      openFlightIndex: -1,
      openLessonId: -1,
      lessonName: ''
    }
  }

  /*************************
    PROGRESS BAR / STEPPER
  **************************/

  handleStep(step) {
    let newStep = this.state.activeStep - 1
    if(typeof step === "number") {
      newStep = step
    }
    this.setState({
      activeStep: newStep
    })
  }

  /*******
    PACKS
  ********/

  handlePackClick(pack) {
    if(this.state.renderFlights &&
       this.state.openPackId === pack.id) {
      this.setState({
        renderFlights: false,
        openPackId: -1,
        openPackIndex: -1,
        openFlightId: -1,
        openFlightIndex: -1,
        openLessonId: -1
      })
      this.handleStep(0)
    } else {
      this.setState({
        renderFlights: true,
        openPackId: pack.id,
        openPackIndex: pack.order - 1,
        openFlightId: -1,
        openFlightIndex: -1,
        openLessonId: -1
      })
      this.handleStep(1)
    }
  }

  /*********
    FLIGHTS
  **********/

  handleFlightClick(flight) {
    if(this.state.renderLessons &&
       this.state.openFlightId === flight.id) {
      this.setState({
        renderLessons: false,
        openFlightId: -1,
        openFlightIndex: -1,
        openLessonId: -1
      })
      this.handleStep(1)
    } else {
      this.setState({
        renderLessons: true,
        openFlightId: flight.id,
        openFlightIndex: flight.order - 1,
        openLessonId: -1
      })
      this.handleStep(2)
    }
  }

  /*********
    LESSONS
  **********/

  handleLessonClick(lesson) {
    this.setState({
      openLessonId: lesson.id,
      lessonName: lesson.name
    })
    this.toggleCreateQuizForm()
  }

  /***********
    SAVE ITEM
  ************/

  handleFormSave(items, deletedItems, deletedSubItems) {
    let answers = []
    let questions = items.map(item => {
      let id = parseInt(item.id, 10)
      if(Number.isNaN(id)) {
        item.oldId = item.id
        delete item.id
      }
      item.languageId = this.props.languageId
      item.questionTypeId = 1
      if(!item.lessonId) {
        item.lessonId = this.state.openLessonId
      }
      if(item.answers) {
        item.answers = item.answers.map(answer => {
          let id = parseInt(answer.id, 10)
          if(Number.isNaN(id)) { delete answer.id }
          if(item.id) { answer.questionId = item.id }
          else { answer.oldQuestionId = item.oldId }
          answer.languageId = item.languageId
          return answer
        })
        answers = answers.concat(item.answers)
        delete item.answers
      }
      return item
    })

    this.props.onQuestionSave(questions, () => {
      this.props.onAnswerSave(answers, () => {
        if(deletedItems && deletedItems.length > 0) {
          this.props.onQuestionDelete(deletedItems, () => {
            if(deletedSubItems && deletedSubItems.length > 0) {
              this.props.onAnswerDelete(deletedSubItems)
            }
          })
        } else if(deletedSubItems && deletedSubItems.length > 0) {
          this.props.onAnswerDelete(deletedSubItems)
        }
      })
    })
  }

  /********
    FORM
  *********/

  toggleCreateQuizForm() {
    this.setState({
      renderCreateQuizForm: !this.state.renderCreateQuizForm
    })
  }

  render() {
    //Sort and filter data
    let packs = this.props.packs.filter(pack => pack.languageId === this.props.languageId)
    packs = [...packs].sort((a,b) => a.order - b.order)
    let flights = this.props.flights.filter(flight => flight.packId === this.state.openPackId)
    flights = [...flights].sort((a,b) => a.order - b.order)
    let lessons = this.props.lessons.filter(lesson => lesson.flightId === this.state.openFlightId)
    lessons = [...lessons].sort((a,b) => a.order - b.order)
    let questions = this.props.questions.filter(question => question.lessonId === this.state.openLessonId)
    questions = [...questions].sort((a,b) => a.id - b.id)
    questions = questions.map(question => {
      question.answers = this.props.answers.filter(answer => answer.questionId === question.id)
      question.answers = [...question.answers].sort((a,b) => a.id - b.id)
      return question
    })

    return(
      <Fade in={this.props.mountAnimation} className={this.props.className}>
        <div>
          <CreateQuizForm
            open = {this.state.renderCreateQuizForm}
            items = {questions}
            lessonName = {this.state.lessonName}
            openItem = {{}}
            onClose = {this.toggleCreateQuizForm.bind(this)}
            onSave = {this.handleFormSave.bind(this)} />
          {!this.state.renderCreateQuizForm &&
            <div>
              <Stepper
                className={this.props.classes.stepper}
                activeStep={this.state.activeStep}
                alternativeLabel>
                {columnNames.map(label => {
                  return (
                    <Step className={this.props.classes.step} key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
              <div className={this.props.classes.columnContainer}>
                <div className = {this.props.classes.column}>
                  <ItemList
                    mountAnimation = {true}
                    items = {packs}
                    selectedItemId = {this.state.openPackId}
                    onItemClick = {this.handlePackClick.bind(this)} />
                </div>
                <div className = {this.props.classes.column}>
                  <ItemList
                    mountAnimation = {this.state.renderFlights}
                    items = {flights}
                    selectedItemId = {this.state.openFlightId}
                    marginTop = {this.state.openPackIndex}
                    onItemClick = {this.handleFlightClick.bind(this)} />
                </div>
                <div className = {this.props.classes.column}>
                  <ItemList
                    mountAnimation = {this.state.renderLessons}
                    items = {lessons}
                    selectedItemId = {this.state.openLessonId}
                    marginTop = {this.state.openPackIndex + this.state.openFlightIndex}
                    onItemClick = {this.handleLessonClick.bind(this)} />
                </div>
              </div>
            </div>
          }
        </div>
      </Fade>
    )
  }
}

export default withStyles(styles)(AddQuiz)
