import React, { Component } from 'react'
import {
  Fade, Stepper, Step,
  StepLabel, withStyles
} from '@material-ui/core'
import ItemList from '../item/item-list'
import CreateBadgeGroupForm from '../item/item-form/create-badge-group-form'
import CreateBadgeForm from '../item/item-form/create-badge-form'
import DeleteItemForm from '../item/item-form/delete-item-form'

const styles = theme => ({
  mainContainer: {
    width: '90%',
    margin: 'auto'
  },
  columnContainer: {
    width: '100%',
    display:'flex',
    padding: '0 20px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
    borderRadius: '4px'
  },
  column: {
    flexBasis: '50%'
  },
  stepper: {
    backgroundColor: 'unset'
  }
})

const columnNames = ['GROUP', 'BADGE']

class ManageContentList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeStep: 0,
      renderCreateBadgeGroupForm: false,
      renderCreateBadgeForm: false,
      renderDeleteForm: false,
      renderBadges: false,
      openBadgeGroupId: -1,
      openBadgeGroupIndex: -1,
      formItemType: '',
      formItemId: -1
    }
  }

  /*************************
    PROGRESS BAR / STEPPER
  **************************/

  handleStep(step) {
    let newStep = this.state.activeStep - 1
    if(typeof step === "number") {
      newStep = step
    }
    this.setState({
      activeStep: newStep
    })
  }

  /***************
    BADGE GROUPS
  ****************/

  handleBadgeGroupClick(badgeGroup) {
    if(this.state.renderBadges &&
       this.state.openBadgeGroupId === badgeGroup.id) {
      this.setState({
        renderBadges: false,
        openBadgeGroupId: -1,
        openBadgeGroupIndex: -1
      })
      this.handleStep(0)
    } else {
      this.setState({
        renderBadges: true,
        openBadgeGroupId: badgeGroup.id,
        openBadgeGroupIndex: badgeGroup.order - 1
      })
      this.handleStep(1)
    }
  }

  handleBadgeGroupEdit(badgeGroup) {
    this.toggleCreateBadgeGroupForm(badgeGroup.id)
  }

  handleBadgeGroupAdd() {
    this.toggleCreateBadgeGroupForm()
  }

  handleBadgeGroupDelete(badgeGroup) {
    this.toggleDeleteForm('badgeGroup', badgeGroup.id)
  }

  /*********
    BADGES
  **********/

  handleBadgeClick(badge) {
    this.handleBadgeEdit(badge)
  }

  handleBadgeEdit(badge) {
    this.toggleCreateBadgeForm(badge.id)
  }

  handleBadgeAdd() {
    this.toggleCreateBadgeForm()
  }

  handleBadgeDelete(badge) {
    this.toggleDeleteForm('badge', badge.id)
  }

  /******************
    SAVE/DELETE ITEM
  *******************/

  handleFormSave(newItem, deletedSubItems) {
    if(!newItem) { return }
    switch(this.state.formItemType) {
      case "badgeGroup":
        if(!newItem.order) {
          newItem.order = this.props.badgeGroups.length + 1
        }
        this.props.onBadgeGroupSave(newItem, newBadgeGroup => {
          const translations = newItem.translations.map(translation => {
            let id = parseInt(translation.id, 10)
            if(Number.isNaN(id)) { delete translation.id }
            translation.badgegroupId = newBadgeGroup.id
            return translation
          })
          this.props.onBadgeGroupTranslationSave(translations, () => {
            if(deletedSubItems && deletedSubItems.length > 0) {
              this.props.onBadgeGroupTranslationDelete(deletedSubItems)
            }
          })
        })
        break
      case "badge":
        if(!newItem.badgegroupId) {
          newItem.badgegroupId = this.state.openBadgeGroupId
        }
        this.props.onBadgeSave(newItem, newBadge => {
          const translations = newItem.translations.map(translation => {
            let id = parseInt(translation.id, 10)
            if(Number.isNaN(id)) { delete translation.id }
            translation.badgeId = newBadge.id
            return translation
          })
          this.props.onBadgeTranslationSave(translations, () => {
            if(deletedSubItems && deletedSubItems.length > 0) {
              this.props.onBadgeTranslationDelete(deletedSubItems)
            }
          })
        })
        break
      default:
        break
    }
  }

  handleFormDelete() {
    switch(this.state.formItemType) {
      case "badgeGroup":
        this.props.onBadgeGroupDelete(this.state.formItemId)
        break
      case "badge":
        this.props.onBadgeDelete(this.state.formItemId)
        break
      default:
        break
    }
  }

  /********
    FORMS
  *********/

  toggleCreateBadgeGroupForm(itemId) {
    this.setState({
      renderCreateBadgeGroupForm: !this.state.renderCreateBadgeGroupForm,
      formItemType: 'badgeGroup',
      formItemId: itemId ? itemId : -1
    })
  }

  toggleCreateBadgeForm(itemId) {
    this.setState({
      renderCreateBadgeForm: !this.state.renderCreateBadgeForm,
      formItemType: 'badge',
      formItemId: itemId ? itemId : -1
    })
  }

  toggleDeleteForm(itemType, itemId) {
    this.setState({
      renderDeleteForm: !this.state.renderDeleteForm,
      formItemType: itemType,
      formItemId: itemId ? itemId : -1
    })
  }

  render() {
    //Sort and filter data
    const badgeGroups = this.props.badgeGroups
    badgeGroups.sort((a,b) => a.order - b.order)
    const badges = this.props.badges.filter(badge =>
      badge.badgegroupId === this.state.openBadgeGroupId
    )

    //If a form needs to be opened, find the
    //item that the form can consume.
    let badgeGroupFormItem
    let badgeFormItem
    if(this.state.formItemId > -1) {
      switch(this.state.formItemType) {
        case "badgeGroup":
          badgeGroupFormItem = badgeGroups.find(badgeGroup =>
            badgeGroup.id === this.state.formItemId
          )
          badgeGroupFormItem.translations =
            this.props.badgeGroupTranslations.filter(translation =>
              translation.badgegroupId === this.state.formItemId
            )
          break
        case "badge":
          badgeFormItem = badges.find(badge =>
            badge.id === this.state.formItemId
          )
          badgeFormItem.translations =
            this.props.badgeTranslations.filter(translation =>
              translation.badgeId === this.state.formItemId
            )
          break
        default:
          break
      }
    }

    //When rendering between the item form
    //and the delete form, make sure that
    //the input data is not shared between
    //the forms. Each form should have
    //its own data input to ensure that
    //there are no unexpected data changes.
    let deleteItem = null
    if(this.state.renderDeleteForm) {
      deleteItem = Object.assign({}, badgeGroupFormItem || badgeFormItem)
      badgeGroupFormItem = null
      badgeFormItem = null
    }

    return(
      <Fade in={this.props.mountAnimation}>
        <div className={this.props.classes.mainContainer}>
          <CreateBadgeGroupForm
            open = {this.state.renderCreateBadgeGroupForm}
            item = {badgeGroupFormItem}
            onClose = {this.toggleCreateBadgeGroupForm.bind(this)}
            onSave = {this.handleFormSave.bind(this)} />
          <CreateBadgeForm
            open = {this.state.renderCreateBadgeForm}
            item = {badgeFormItem}
            onClose = {this.toggleCreateBadgeForm.bind(this)}
            onSave = {this.handleFormSave.bind(this)}
            badges = {this.props.badges} />
          <DeleteItemForm
            open = {this.state.renderDeleteForm}
            item = {deleteItem}
            onClose = {this.toggleDeleteForm.bind(this)}
            onDelete = {this.handleFormDelete.bind(this)} />
          {!this.state.renderCreateBadgeForm &&
            <div>
              <Stepper
                className={this.props.classes.stepper}
                activeStep={this.state.activeStep}
                alternativeLabel>
                {columnNames.map(label => {
                  return (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
              <div className={this.props.classes.columnContainer}>
                <div className={this.props.classes.column}>
                  <ItemList
                    mountAnimation = {true}
                    items = {badgeGroups}
                    allowAdding = {true}
                    addItemLabel = {"Add a Group"}
                    allowEditing = {true}
                    allowDeleting = {true}
                    selectedItemId = {this.state.openBadgeGroupId}
                    onItemClick = {this.handleBadgeGroupClick.bind(this)}
                    onItemEdit = {this.handleBadgeGroupEdit.bind(this)}
                    onItemAdd = {this.handleBadgeGroupAdd.bind(this)}
                    onItemDelete = {this.handleBadgeGroupDelete.bind(this)} />
                </div>
                <div className={this.props.classes.column}>
                  <ItemList
                    mountAnimation = {this.state.renderBadges}
                    items = {badges}
                    allowAdding = {true}
                    addItemLabel = {"Add a Badge"}
                    allowEditing = {true}
                    allowDeleting = {true}
                    marginTop = {this.state.openBadgeGroupIndex}
                    onItemClick = {this.handleBadgeClick.bind(this)}
                    onItemEdit = {this.handleBadgeEdit.bind(this)}
                    onItemAdd = {this.handleBadgeAdd.bind(this)}
                    onItemDelete = {this.handleBadgeDelete.bind(this)} />
                </div>
              </div>
            </div>
          }
        </div>
      </Fade>
    )
  }
}

export default withStyles(styles)(ManageContentList)
