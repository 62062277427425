import React, { Component } from 'react'
import {
  Button, TextField, Dialog,
  DialogActions, DialogContent,
  DialogTitle, Grid, MenuItem,
  FormControl, InputLabel,
  Select, withStyles
} from '@material-ui/core'

const styles = theme => ({
  wrapper: {
    maxWidth: 600
  },
  adminSectionDropdown: {
    maxWidth: 300
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing(0.25),
  },
})

class UserAddForm extends Component {
  constructor(props){
    super(props)
    this.state = {
      item: this.props.item || null,
      zones: this.props.zones || null,
      officeId: null,
      zoneId: null,
      country: 'PUT',
    }
    this.handleClose = this.handleClose.bind(this)
    this.handleInput = this.handleInput.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleClose(e) {
    this.props.handleClose(this.props.item)
  }

  handleInput(e, input) {

    if(input !== "zoneId"){
      if(input === "officeId"){
        this.setState({
          officeId: e.target.value
        });
      }
      let newItem = Object.assign({}, this.state.item)
      newItem[input] = e.target.value // Assign the choise to the user and send to endpoint to save then
      this.setState({
        item: newItem
      })
    } else {
      let newItem = Object.assign({}, this.state.item);
      if(e.target.value === 'ext'){
        newItem.adminSections = [];
        newItem.userType = 2;
      } else {
        newItem.userType = 1;
      }
      this.setState({
        item: newItem,
        country: 'PUT',
        officeId: null,
        zoneId: e.target.value
      });
    }

  }

  handleSubmit(e) {
    // TODO check if need changes to edit external user
    if(this.state.zoneId !== 'ext' && !this.state.officeId){
      alert('Please chose an office');
      return;
    }
    //Remove all unnecessary properties
    //from the user object.
    const {
      current, beercode, id,
      creationDate, lastLoginDate, lastModifiedDate,
      zone, language, adminSections,
      _pivot_adminSectionId, _pivot_userId,
      userHash, office, department,
      ...newItem
    } = this.state.item

    newItem.userHash = newItem.emailAddress; // non employees will need to use the forgot password flow

    this.props.handleSubmit(
      e, newItem, 'POST',
      { 'Content-Type': 'application/json' },
      `${this.props.url}`
    )
    this.props.handleClose(this.props.item)
  }

  render(){
    return(
      <Dialog
        open={this.props.open}
        onClose={this.handleClose}
      >
        <DialogTitle id="form-dialog-title">Add User</DialogTitle>
          <DialogContent>
            <Grid
              container
              className={this.props.classes.wrapper}
              spacing={3}
            >
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="First Name"
                  value={this.state.item.firstName || ""}
                  onChange={e=> this.handleInput(e, 'firstName')}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Last Name"
                  value={this.state.item.lastName || ""}
                  onChange={e=> this.handleInput(e, 'lastName')}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Email Address"
                  value={this.state.item.emailAddress || ""}
                  onChange={e=> this.handleInput(e, 'emailAddress')}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="language">Language</InputLabel>
                  <Select
                    value={this.state.item.languageId || ""}
                    onChange={e => this.handleInput(e, 'languageId')}
                    inputProps={{ id: 'language' }}>
                    {this.props.languages.map(language => {
                      return(
                        <MenuItem
                          key={language.id}
                          value={language.id}
                        >
                          {language.name}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="zone">Zone</InputLabel> { /* ZONES DROPDOWN */ }
                  <Select
                    value={ this.state.zoneId || "" }
                    onChange={e => this.handleInput(e, 'zoneId')}
                    inputProps={{ id: 'zone' }}>
                    {this.state.zones.map(zones => {
                      return(
                        <MenuItem
                          key={zones.id}
                          value={zones.id}
                        >
                          {zones.name}
                        </MenuItem>
                      )
                    })}
                    <MenuItem
                      key='ext'
                      value='ext'
                    >
                      External User
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="office">Office</InputLabel> { /* OFFICE DROPDOWN */ }
                  <Select
                    value={this.state.officeId || ""}
                    onChange={e => this.handleInput(e, 'officeId')}
                    inputProps={{ id: 'office' }}
                    required={true}>
                    {this.props.offices.map(office => {
                      let show = !!this.state.zoneId
                      if(show){
                        if(this.state.zoneId !== office.zoneId){
                          show = false;
                        }
                        if(this.state.zoneId === 'ext' && office.name === 'Other'){
                          show = true;
                        }
                      }
                      if(show){
                        return(
                          <MenuItem
                            key={office.id}
                            value={office.id}
                          >
                            {office.name}
                          </MenuItem>
                        )
                      }else{
                        return "";
                      }
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="department">Department</InputLabel>
                  <Select
                    value={this.state.item.departmentId || ""}
                    onChange={e => this.handleInput(e, 'departmentId')}
                    inputProps={{ id: 'department' }}>
                    {this.props.departments.map(department => {
                      let show = !!this.state.officeId && (this.state.zoneId !== 'ext' || department.name === 'Other');
                      if(show){
                        return(
                          <MenuItem
                            key={department.id}
                            value={department.id}
                          >
                            {department.name}
                          </MenuItem>
                        )
                      } else {
                        return "";
                      }
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="country">Country</InputLabel>
                  <Select
                    value={this.state.item.country || ""}
                    onChange={e => this.handleInput(e, 'country')}
                    inputProps={{ id: 'country' }}>
                    {this.props.countries.map(country => {
                      return(
                        <MenuItem
                          key={country.id}
                          value={country.id}
                        >
                          {country.name}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={this.handleClose}
            >
              Cancel
            </Button>
          <Button
            color="primary"
            onClick={this.handleSubmit}
          >
              Save
            </Button>
          </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(UserAddForm)
