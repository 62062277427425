import React, { Component } from 'react'
import {
  Button, TextField, Dialog,
  DialogActions, DialogContent,
  DialogContentText, DialogTitle,
  MenuItem, FormControl, InputLabel,
  Select, FormControlLabel, Checkbox } from '@material-ui/core'
import FormTypes from '../form-types'

export default class EditForm extends Component {
  constructor(props){
    super(props)
    this.state = {
      item: React.createRef(),
    }
    this.onClose = this.onClose.bind(this)
    this.handleInput = this.handleInput.bind(this)
  }

  onClose(e){
    this.props.handleClose('edit', this.state.item)
    this.setState({
      item: React.createRef()
    })
  }

  componentWillMount(){
    if (this.props.renderZone){
      this.setState({
        item: {
          zoneId: this.props.item.zoneId
        }
      })
    }
    if (this.props.tableName === 'zoneEdit') {
      this.setState({
        item: {
          visibleInLeaderboardBool: this.props.item.visibleInLeaderboardBool
        }
      })
    }
    if (this.props.tableName === 'languageEdit') {
      this.setState({
        item: {
          activeBool: this.props.item.activeBool
        }
      })
    }
  }

  componentDidUpdate(prevProps){
    if (this.props.renderZone && this.props.renderZone !== prevProps.renderZone){
      this.setState({
        item: {
          zoneId: this.props.item.zoneId
        }
      })
    }
  }

  handleInput(e, input){
    const val = e.target.value;
    let newItem = Object.assign({}, this.state.item)
    newItem[input] = val
    newItem['id'] = this.state.item.id
    const {current, beercode, id, creationDate,
      lastLoginDate, lastModifiedDate, zone,
      language, adminSections, _pivot_adminSectionId,
      _pivot_userId, ...inputs} = newItem
      this.setState({
        item: inputs
      })
  }

  handleCheck(e, type){
    let newItem = Object.assign({}, this.state.item)
    if (type === 'visibleInLeaderboardBool'){
      newItem['visibleInLeaderboardBool'] = !this.state.item.visibleInLeaderboardBool
      this.setState({
        item: newItem
      })
    }
    else if ( type === 'activeBool') {
      newItem['activeBool'] = !this.state.item.activeBool
      this.setState({
        item: newItem
      })
    }
  }

  renderZoneField(renderZone, zoneArr, id){
      if (renderZone) {
        const zones = zoneArr.map(zone => {
          return <MenuItem key={zone.id} value={zone.id}>{zone.name}</MenuItem>
        })
        return (
          <FormControl>
            <InputLabel htmlFor="zone">Zone</InputLabel>
            <Select
              value={id}
              onChange={e => this.handleInput(e, 'zoneId')}
              inputProps={{ id: 'zone' }}>
              {zones}
            </Select>
          </FormControl>
        )
      } else {
        return (
          <div></div>
        )
      }
  }

  renderLanguageField(langArr,id){
      const languages = langArr.map(language => {
        return <MenuItem key={language.id} value={language.id}>{language.name}</MenuItem>
      })
      return (
        <FormControl>
          <InputLabel htmlFor="language">Language</InputLabel>
          <Select
            value={id}
            onChange={e => this.handleInput(e, 'languageId')}
            inputProps={{ id: 'language' }}>
            {languages}
          </Select>
        </FormControl>
      )
  }

  renderCheckbox(bool, type) {
    if (bool){
      if (type === 'zoneEdit') {
      return (
        <FormControlLabel
        control={
          <Checkbox
            checked={this.state.item.visibleInLeaderboardBool}
            onChange={e => this.handleCheck(e, 'visibleInLeaderboardBool')}
            color="primary"
          />
        }
        label="Visible in Leaderboard"
      />
      )
    } else if (type === 'languageEdit') {
      return (
        <FormControlLabel
        control={
          <Checkbox
            checked={this.state.item.activeBool}
            onChange={e => this.handleCheck(e, 'activeBool')}
            color="primary"
          />
        }
        label="Active Language"
      />
      )
    } 
   }
  }

  render(){
    return(
      <Dialog open={this.props.open} onClose={this.onClose}>
        <DialogTitle id="form-dialog-title">Edit</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Edit {this.props.tableSection}
                    </DialogContentText>
                    {this.props.formFields.map((field, index)=> {
                      return (
                        <TextField
                          key={index}
                          disabled={this.props.readOnly.find(readOnlyField => readOnlyField === field) ? true : false}
                          margin="dense"
                          id={field}
                          label={field}
                          type="text"
                          name={field}
                          defaultValue={this.props.item[field]}
                          ref={this.input}
                          onChange={e=> this.handleInput(e, field)}
                          fullWidth/>
                      )
                    })}
                    {this.renderZoneField(this.props.renderZone, this.props.zones, this.state.item['zoneId'])}
                    {this.renderCheckbox(this.props.renderCheckbox, this.props.tableName)}
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={e => this.props.handleClose('', '')} color="primary">
                      Cancel
                    </Button>
                  <Button onClick={e => {
                    this.props.handleSubmit(e, {...this.state.item}, 'PUT', FormTypes.HEADERS, `${this.props.url}/${this.props.item.id}`)
                  }} color="primary">
                      Save
                    </Button>
                  </DialogActions>
      </Dialog>
    )
  }
}
