import React, { Component } from 'react'
import 'typeface-roboto'
import CssBaseline from '@material-ui/core/CssBaseline'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import NavBar from './components/navigation'
import Homepage from './components/homepage'
import CMS from './components/cms'
import Events from './components/events/events'
import Beercoin from './components/beercoin'
import Analytics from './components/analytics'
import PushNotifications from './components/push-notifications/index'
import CalNotifications from './components/calendar-notifications/index'
import Settings from './components/settings/settings'
import Users from './components/users/users'
import Badges from './components/badges'
import DailyChallenge from './components/daily-challenge'
import Achievements from './components/achievements'
import API from './API'
import {Dialog, DialogContent, DialogContentText, DialogTitle} from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import update from 'immutability-helper'

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    suppressDeprecationWarnings: true
  },
})

const COUNTDOWN_DEFAULT_TIMER = 3600

export default class Admin extends Component {
  constructor() {
    super()

    //Override defaults if localStorage
    //is set.
    let page = 'home'
    let loadLanguageId = 1
    let langLoaded = false
    try {
      const onLoad = JSON.parse(localStorage.getItem("onLoad"))
      if(onLoad) {
        if(onLoad.page) {
          page = onLoad.page
        }
        if(onLoad.options && onLoad.options.languageId) {
          loadLanguageId = onLoad.options.languageId
          langLoaded = true
        }
        localStorage.removeItem("onLoad");
      }
    } catch(err) { }

    this.state = {
      page,
      loadLanguageId,
      langLoaded,
      mountAnimation: false,
      userURL: API.url.user,
      zoneURL: API.url.zone,
      sectionURL: API.url.section,
      departmentURL: API.url.department,
      languageURL: API.url.language,
      officeURL: API.url.office,
      addFormState: false,
      editFormState: false,
      deleteFormState: false,
      appFacingDataModal: false,
      events: [],
      zones: [],
      users: [],
      currentUser: {adminSections: []},
      form: '',
      table: '',
      editItem: {},
      sections: [],
      languages: [],
      departments: [],
      offices: [],
      countries: [],
    }
  }

  countdownTime = COUNTDOWN_DEFAULT_TIMER

  /**
   * Initi all controllers
   */
  componentDidMount() {
    API.getCurrentUser(this.props.currentUser.id, currentUser => {
      if(!currentUser) { return }
      this.setState({
        currentUser,
        loadLanguageId: !this.state.langLoaded ? (!!currentUser.languageId ? currentUser.languageId : this.state.loadLanguageId) : this.state.loadLanguageId
      })
    })
    API.getAllUsers(users => {
      this.setState({ users })
    })
    API.getAllZones(zones => {
      this.setState({ zones })
    })
    API.getAllLanguages(response => {
      const languages = response.languages
      languages.sort((a,b) => {
        if(a.name > b.name) { return 1 }
        else if(b.name > a.name) { return -1 }
        else { return 0 }
      })
      this.setState({ languages })
    })
    API.getAllSections(sections => {
      sections.sort((a,b) => {
        if(a.description > b.description) { return 1 }
        else if(b.description > a.description) { return -1 }
        else { return 0 }
      })
      this.setState({ sections })
    })
    API.getAllDepartments(departments => {
      departments.sort((a,b) => {
        if(a.name > b.name) { return 1 }
        else if(b.name > a.name) { return -1 }
        else { return 0 }
      })
      this.setState({ departments })
    })
    API.getAllOffices(offices => {
      offices.sort((a,b) => {
        if(a.name > b.name) { return 1 }
        else if(b.name > a.name) { return -1 }
        else { return 0 }
      })
      this.setState({ offices })
    })
    API.getAllCountries(countries => {
      countries.sort((a,b) => {
        if(a.name > b.name) { return 1 }
        else if(b.name > a.name) { return -1 }
        else { return 0 }
      })
      this.setState({ countries })
    })

    this.countDown()
  }

  countDown() {
    const self = this
    setInterval(() => {
      const timeLeft = self.countdownTime - 1
      if (timeLeft === 0) {
        this.props.clearJwtToken()
        window.location.reload()
      }
      self.countdownTime = timeLeft
    }, 1000)
  }

  activityCheck(e, time) {
    if(e) {
      this.countdownTime = COUNTDOWN_DEFAULT_TIMER
    }
  }

  handleCloseTimeoutModal() {
    this.countdownTime = COUNTDOWN_DEFAULT_TIMER
  }

  // FORM HANDLERS FOR SETTINGS SECTION
  toggleAddForm(form, table){
    this.setState({
      form: form,
      table: table,
      addFormState: !this.state.addFormState
    })
  }

  toggleEditForm(form, item){
    this.setState({
      form: form,
      editFormState: !this.state.editFormState,
      editItem: item
    })
  }

  toggleDeleteForm(form, item){
    this.setState({
      form: form,
      deleteFormState: !this.state.deleteFormState,
      deleteItem: item
    })
  }

  //HANDLE SUBMIT FOR ALL SETTINGS FORMS
  handleSubmit(e, inputs, method, headers, url){
    e.preventDefault()
    const settings = {
      method: method,
      body: JSON.stringify(inputs),
      headers: headers
    }
    this.fetchItem(url, settings)
    this.toggleAddForm('', '')
    this.toggleEditForm('', '')
  }

  //HANDLE DELETE IN ALL TABLES IN SETTINGS
  handleDelete(url, body) {
    if(body) { body = JSON.stringify(body) }
    const settings = {
      method: 'DELETE',
      body
    }
    this.fetchItem(url, settings)
  }

  //FETCH FOR ALL SETTINGS FORMS
  async fetchItem(url, settings){
    if(!settings.headers) { settings.headers = {} }
    settings.headers.authorization = 'Bearer ' + API.getJwtToken()
    try {
      const promise = await fetch(`${url}`, settings)
      const data = await promise.json()
      let body = settings.body
      if(body) { body = JSON.parse(body) }
      this.updateData(
        settings.method,
        body,
        data
      )
    } catch (error) {
      console.log(`Error: ${error}`)
    }
  }

  // HANDLE UPDATE TO DATA OBJECT
  updateData(method, body, response){
    if (this.state.page === 'events'){
      API.getAllEvents((events) => {
        this.setState({ events })
      })
    }
    if (this.state.page === 'settings'){
      API.getAllUsers((users) => {
        this.setState({ users })
      })
      API.getAllZones((zones) => {
        this.setState({ zones })
      })
      API.getAllSections((sections) => {
        this.setState({ sections })
      })
      API.getAllDepartments((departments) => {
        this.setState({ departments })
      })
      API.getAllOffices((offices) => {
        this.setState({ offices })
      })
      API.getAllLanguages(response => {
        this.setState({ languages: response.languages })
      })
      //for getting all sections based on current user
      API.getCurrentUser(this.props.currentUser.id, currentUser => {
        if(!currentUser) { return }
        this.setState({
          currentUser
        })
      })
    }
    if (this.state.page === 'users') {
      switch(method) {
        case "POST":
          if(response.userAdminSectionAccess) {
            const newSection = response.userAdminSectionAccess
            const userIndex = this.state.users.findIndex(
              item => item.id === newSection.userId
            )
            const sectionToAdd = this.state.sections.find(
              section => section.id === newSection.adminSectionId
            )
            let newUser = Object.assign({}, this.state.users[userIndex])
            newUser.adminSections.push(sectionToAdd)
            this.setState(update(this.state, {
              users: {
                $splice: [
                  [userIndex, 1, newUser]
                ]
              }
            }))
            if(newUser.id === this.props.currentUser.id) {
              this.setState({
                currentUser: newUser
              })
            }
          }
          break
        case "PUT":
          if(response.user) {
            const newUser = response.user
            const index = this.state.users.findIndex(
              item => item.id === newUser.id
            )
            newUser.adminSections = this.state.users[index].adminSections
            this.setState(update(this.state, {
              users: {
                $splice: [
                  [index, 1, newUser]
                ]
              }
            }))
            if(newUser.id === this.props.currentUser.id) {
              this.setState({
                currentUser: newUser
              })
            }
          }
          break
        case "DELETE":
          if(response.hasOwnProperty("userAdminSectionAccess")) {
            const deletedSection = body
            const userIndex = this.state.users.findIndex(
              item => item.id === deletedSection._pivot_userId
            )
            let newUser = Object.assign({}, this.state.users[userIndex])
            const sectionToRemove = newUser.adminSections.findIndex(
              section => section.id === deletedSection.id
            )
            newUser.adminSections.splice(sectionToRemove, 1)
            this.setState(update(this.state, {
              users: {
                $splice: [
                  [userIndex, 1, newUser]
                ]
              }
            }))
            if(newUser.id === this.props.currentUser.id) {
              this.setState({
                currentUser: newUser
              })
            }
          } else if(response.hasOwnProperty("user")) {
            const deletedUser = body
            const index = this.state.users.findIndex(
              item => item.id === deletedUser.id
            )
            this.setState(update(this.state, {
              users: {
                $splice: [
                  [index, 1]
                ]
              }
            }))
          }
          break
        default:
          console.log("unknown method", method)
          break
      }
    }
  }

  handleNavChange(navSelection){
    this.setState({
      page: navSelection,
      mountAnimation: true
    })
  }


  render() {
    let page
    switch(this.state.page){
      case 'CMS':
        page = <CMS
          mountAnimation = {this.state.mountAnimation}
          languages = {this.state.languages}
          loadLanguageId = {this.state.loadLanguageId}
        />
        break
      case 'events':
        page = <Events
          mountAnimation = {this.state.mountAnimation}
          languages = {this.state.languages}
          zones = {this.state.zones}
          updateData = {this.updateData.bind(this)}/>
        break
      case 'beercoin':
        page = <Beercoin
          mountAnimation = {this.state.mountAnimation}/>
        break
      case 'calendar-notification':
        page = <CalNotifications
          mountAnimation = {this.state.mountAnimation}/>
        break
      case 'push-notification':
        page = <PushNotifications
          mountAnimation = {this.state.mountAnimation}/>
      break
      case 'analytics':
        page = <Analytics />
        break
      case 'settings':
        page = <Settings
          users = {this.state.users}
          languages = {this.state.languages}
          sections = {this.state.sections}
          zones = {this.state.zones}
          departments = {this.state.departments}
          offices = {this.state.offices}
          addFormState = {this.state.addFormState}
          editFormState = {this.state.editFormState}
          deleteFormState = {this.state.deleteFormState}
          table = {this.state.table}
          form = {this.state.form}
          mountAnimation = {this.state.mountAnimation}
          userInfo = {this.state.currentUser}
          sectionURL = {this.state.sectionURL}
          zoneURL = {this.state.zoneURL}
          userURL = {this.state.userURL}
          departmentURL = {this.state.departmentURL}
          officeURL = {this.state.officeURL}
          languageURL = {this.state.languageURL}
          editItem = {this.state.editItem}
          deleteItem = {this.state.deleteItem}
          handleSubmit = {this.handleSubmit.bind(this)}
          handleDelete = {this.handleDelete.bind(this)}
          toggleEditForm = {this.toggleEditForm.bind(this)}
          toggleAddForm = {this.toggleAddForm.bind(this)}
          toggleDeleteForm = {this.toggleDeleteForm.bind(this)}
          />
        break
      case 'users':
        page = <Users
          mountAnimation = {this.state.mountAnimation}
          users = {this.state.users}
          userURL = {this.state.userURL}
          languages = {this.state.languages}
          zones = {this.state.zones}
          offices = {this.state.offices}
          departments = {this.state.departments}
          countries = {this.state.countries}
          sections = {this.state.sections}
          updateData = {this.updateData.bind(this)}
          handleSubmit = {this.handleSubmit.bind(this)}
          handleDelete = {this.handleDelete.bind(this)}
          />
        break
      case 'badges':
        page = <Badges
          mountAnimation = {this.state.mountAnimation}
        />
      break
      case 'daily-challenge':
        page = <DailyChallenge
          mountAnimation = {this.state.mountAnimation}
        />
      break
      case "achievements":
        page = <Achievements
            mountAnimation = {this.state.mountAnimation}
            usersList = {this.state.users}
        />
        break;
      default:
        page = <Homepage
          mountAnimation = {true}
          name = {this.state.currentUser ? this.state.currentUser.firstName : ''}
          userId = {this.state.currentUser ? this.state.currentUser.id : ''}/>
      break
    }

    return (
      <div 
        onMouseMove={e => this.activityCheck(e)}
        onKeyDown={e => this.activityCheck(e)}
        onClick={e => this.activityCheck(e)}
        tabIndex="0"
        >
      <React.Fragment>
        <CssBaseline />
        <MuiThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <NavBar
              sections={this.state.currentUser.adminSections}
              onNavChange={this.handleNavChange.bind(this)}/>
            <Dialog
              open={this.state.time <= 60}
              onClose={this.handleCloseTimeoutModal}>
              <DialogTitle id="alert-dialog-title">Timing Out!</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    You will be logged out in {this.state.time} seconds. Please move your mouse to stay logged in.
                  </DialogContentText>
                </DialogContent>
            </Dialog>
            {page}
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      </React.Fragment>
      </div>
    );
  }
}
