import React, { Component } from 'react'
import {
  Card, CardActions,
  CardActionArea, CardMedia,
  CardContent, Typography,
  IconButton, Zoom, withStyles
} from '@material-ui/core'
import {
  Wallpaper as WallpaperIcon,
  Delete as DeleteIcon,
  DragIndicator as DragIndicatorIcon,
  Edit as EditIcon
} from '@material-ui/icons'
import { DragDropContext, DragSource, DropTarget } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import { findDOMNode } from 'react-dom'

const styles = theme => ({
  card: {
    margin: theme.spacing(1),
    width: 275,
  },
  cardActions: {
    justifyContent: 'flex-end'
  },
  dragIndicatorIcon: {
    color: 'rgba(0, 0, 0, 0.54) !important',
    cursor: 'grab !important',
    pointerEvents: 'all !important'
  },
  cardActionArea: {
    width: '100%'
  },
  image: {
    height: 162,
  },
  imagePlaceholder: {
    height: '150px',
    width: '150px',
    margin: '0 auto',
    display: 'block'
  }
})

const isImage = content => {
  return content.includes("base64")
}

const itemSource = {
  beginDrag(props) {
    return {
      index: props.index,
      contents: props.item
    }
  },
  endDrag(props) {
    props.onItemMoveFinished()
  }
}

/******
  Handles sorting between items in a container.
  Taken from below sources:
  http://rafaelquintanilha.com/sortable-targets-with-react-dnd/
  https://github.com/rafaelquintanilha/experiments/tree/master/sortable-target
  https://github.com/react-dnd/react-dnd/issues/593
*****/
const itemTarget = {
  hover(props, monitor, component) {
    if(!props.allowMoving) { return }

    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Determine rectangle on screen
    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();

    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

    // Get horizontal middle
    const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;

    // Determine mouse position
    const clientOffset = monitor.getClientOffset();

    // Get pixels to the top
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;

    // Get pixels to the left
    const hoverClientX = clientOffset.x - hoverBoundingRect.left;

    // Only perform the move when the mouse has crossed half of the items height
    // When dragging, only move when the cursor passes the halfway point.
    const draggingDownwards = dragIndex < hoverIndex && hoverClientY < hoverMiddleY
    const draggingUpwards = dragIndex > hoverIndex && hoverClientY > hoverMiddleY
    const draggingRightwards = dragIndex < hoverIndex && hoverClientX < hoverMiddleX
    const draggingLeftwards = dragIndex > hoverIndex && hoverClientX > hoverMiddleX

    if(draggingUpwards && (draggingLeftwards || draggingRightwards)) {
      return
    }

    if(draggingDownwards && (draggingLeftwards || draggingRightwards)) {
      return
    }

    // Time to actually perform the action
    if(props.onItemMove) {
      props.onItemMove(dragIndex, hoverIndex)
    }

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex;
  }
};

const collectSource = (connect, monitor) => {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  }
}

const collectTarget = (connect, monitor) => {
  return {
    connectDropTarget: connect.dropTarget()
  }
}

class Item extends Component {
  handleOpen() {
    this.props.onItemClick(this.props.item)
  }

  handleEdit() {
    this.props.onItemEdit(this.props.item)
  }

  handleDelete() {
    this.props.onItemDelete(this.props.item)
  }

  render() {
    const thumbnail = this.props.item.thumbnail
    let itemHasThumbnail = true
    if(typeof thumbnail === 'undefined') {
      itemHasThumbnail = false
    }
    const { isDragging, connectDragSource, connectDropTarget } = this.props

    return connectDragSource(connectDropTarget(
      <div>
        <Zoom
          in={this.props.mountAnimation}
          timeout={this.props.animationDuration}
          style={{
            transitionDelay:
              this.props.animationDuration * this.props.transitionDelay
          }}
        >
          <Card
            className={this.props.classes.card}
            style={{ 
              opacity: isDragging ? 0 : 1
            }}
          >
            <CardActions className={this.props.classes.cardActions}>
              { this.props.allowMoving &&
                <IconButton
                  className={this.props.classes.dragIndicatorIcon}
                  disabled>
                  <DragIndicatorIcon />
                </IconButton>
              }
              { this.props.allowEditing &&
                <IconButton
                  onClick={this.handleEdit.bind(this)}>
                  <EditIcon />
                </IconButton>
              }
              { this.props.allowDeleting &&
                <IconButton
                  onClick={this.handleDelete.bind(this)}>
                  <DeleteIcon />
                </IconButton>
              }
            </CardActions>
            <CardActionArea
              className={this.props.classes.cardActionArea}
              onClick={this.handleOpen.bind(this)}
            >
              { itemHasThumbnail && isImage(thumbnail) &&
                <CardMedia
                  className={this.props.classes.image}
                  image={thumbnail}
                  title={this.props.item.name}
                />
              }
              { itemHasThumbnail && !isImage(thumbnail) &&
                <WallpaperIcon
                  className={this.props.classes.imagePlaceholder}
                  color="primary"
                />
              }
              <CardContent>
                <Typography gutterBottom variant="h6">
                  {this.props.item.name}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Zoom>
      </div>
    ))
  }
}

export default withStyles(styles)(
  DragDropContext(HTML5Backend)(
    DragSource("ITEM", itemSource, collectSource)(
      DropTarget("ITEM", itemTarget, collectTarget)(
        Item
      )
    )
  )
)
