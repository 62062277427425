import React, { Component } from 'react'
import Fade from '@material-ui/core/Fade'

export default class Beercoin extends Component {
  render(){
    return(
      <Fade in={this.props.mountAnimation}>
        <div>beercoin component</div>
      </Fade>
    )
  }
}
