import React, { Component } from 'react'
import {
  TextField, Dialog, AppBar,
  Toolbar, withStyles, IconButton,
  Button, Grid, Typography, Slide,
  DialogContent, DialogContentText,
  DialogActions, Paper
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { CalendarPicker } from './'
import moment from 'moment'
import ItemList from './item-list'
import API from '../../../API'
import CreateTranslationForm from './create-translation-form'
import DeleteItemForm from './delete-form'
import uniqid from 'uniqid'
import update from 'immutability-helper'

const styles = theme => ({
  appBar: {
    position: 'relative'
  },
  flex: {
    flex: 1
  },
  container: {
    marginTop: theme.spacing(4)
  }
})

const titleMaxCharCount = 200
const messageMaxCharCount = 200

class CreateForm extends Component {
  constructor(props){
    super(props)
    let item = this.props.item || {
      title: '',
      message: '',
      date: moment(),
      translations: []
    }
    this.state = {
      item,
      titleCharCount: item.title.length + "/" + titleMaxCharCount,
      messageCharCount: item.message.length + "/" + messageMaxCharCount,
      languages: [],
      deletedSubItems: [],
      formItem: null,
      renderCreateTranslationForm: false,
      renderDeleteForm: false,
      errorTitle: false,
      errorMessage: false,
      errorDialog: false,
      errorDialogContentText: '',
      closeDialog: false
    }
  }

  componentDidUpdate(prevProps) {
    if(
      this.props.item &&
      JSON.stringify(this.props.item) !== JSON.stringify(prevProps.item)
    ) {
      let titleCharCount = "0/" + titleMaxCharCount
      if(this.props.item.title) {
        titleCharCount = this.props.item.title.length + "/" + titleMaxCharCount
      }
      let messageCharCount = "0/" + messageMaxCharCount
      if(this.props.item.message) {
        messageCharCount = this.props.item.message.length
          + "/" + messageMaxCharCount
      }
      this.setState({
        item: this.props.item,
        titleCharCount,
        messageCharCount
      })
    }
  }

  handleOnEnter() {
    API.getAllLanguages(response => {
      const languages = response.languages
      languages.sort((a,b) => {
        if(a.name > b.name) { return 1 }
        else if(b.name > a.name) { return -1 }
        else { return 0 }
      })
      this.setState({ languages })
    })
  }

  handleChange(value, input){
    if(input === "title" && value.length > titleMaxCharCount) {
      value = value.substring(0, titleMaxCharCount)
    } else if(input === "message" && value.length > messageMaxCharCount) {
      value = value.substring(0, messageMaxCharCount)
    }
    let newItem = Object.assign({}, this.state.item)
    newItem[input] = value
    const {current, ...inputs} = newItem
    this.setState({
      item: inputs,
      ...this.isItemValid(inputs)
    })

    if(input === "title") {
      this.setState({
        titleCharCount:
          value.length + "/" + titleMaxCharCount
      })
    } else if(input === "message") {
      this.setState({
        messageCharCount:
          value.length + "/" + messageMaxCharCount
      })
    }
  }


  handleTranslationClick(translation) {
    this.handleTranslationEdit(translation)
  }

  handleTranslationEdit(translation) {
    this.toggleCreateTranslationForm(translation)
  }

  handleTranslationAdd() {
    this.toggleCreateTranslationForm()
  }

  handleTranslationDelete(translation) {
    this.toggleDeleteForm(translation)
  }

  handleFormSave(translation) {
    if(!translation.id || translation.id === -1) {
      translation.id = uniqid()
      this.setState(update(this.state, {
        item: {
          translations: {
            $push: [ translation ]
          }
        }
      }))
    } else {
      const index = this.state.item.translations
        .findIndex(oldTranslation =>
          oldTranslation.id === translation.id
        )
       this.setState(update(this.state, {
        item: {
          translations: {
            $splice: [
              [index, 1, translation]
            ]
          }
        }
      }))
    }
  }

  handleFormDelete() {
    const index = this.props.item.translations.findIndex(translation =>
      translation.id === this.state.formItem.id
    )
    this.setState(update(this.state, {
      item: {
        translations: {
          $splice: [
            [index, 1]
          ]
        }
      },
      deletedSubItems: {
        $push: [ this.state.formItem ]
      }
    }))
  }

  handleTransition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  })

  handleSubmit() {
    let newState = this.isItemValid(this.state.item)
    if(Object.values(newState).includes(true)) {
      let errorMessage
      if(newState.errorTitle === true) {
        errorMessage = "Please add a notification title."
      }
      if(newState.errorMessage === true) {
        errorMessage = "Please add a notification message."
      }
      if(newState.errorDate === true) {
        errorMessage = "Please add a notification date."
      }
      if(newState.errorTranslations === true) {
        errorMessage = "Please add a notification translation."
      }
      this.setState({
        ...newState,
        errorDialog: true,
        errorDialogContentText: errorMessage
      })
      return
    }
    this.props.onSave(this.state.item, this.state.deletedSubItems)
    this.handleClose()
  }

  handleErrorDialogClose() {
    this.setState({ errorDialog: false })
  }

  handleCloseButtonClick() {
    this.setState({ closeDialog: true })
  }

  handleCloseDialogCloseNo() {
    this.setState({ closeDialog: false })
  }

  handleCloseDialogCloseYes() {
    this.setState({ closeDialog: false })
    this.handleClose()
  }

  handleClose() {
    this.setState({
      item: {
        title: '',
        message: '',
        date: moment(),
        translations: []
      },
      titleCharCount: "0/" + titleMaxCharCount,
      messageCharCount: "0/" + messageMaxCharCount,
      deletedSubItems: [],
      errorTitle: false,
      errorMessage: false,
      errorDialog: false,
      errorDialogContentText: '',
      closeDialog: false
    })

    this.props.onClose()
  }

  toggleCreateTranslationForm(formItem) {
    this.setState({
      renderCreateTranslationForm:
        !this.state.renderCreateTranslationForm,
      formItem
    })
  }

  toggleDeleteForm(formItem) {
    this.setState({
      renderDeleteForm: !this.state.renderDeleteForm,
      formItem
    })
  }

  isItemValid(item) {
    let newState = {}
    if(item.title === '') {
      newState.errorTitle = true
    } else {
      newState.errorTitle = false
    }
    if(item.message === '') {
      newState.errorMessage = true
    } else {
      newState.errorMessage = false
    }
    if(item.date === '') {
      newState.errorDate = true
    } else {
      newState.errorDate = false
    }
    if(item.translations.length === 0) {
      newState.errorTranslations = true
    } else {
      newState.errorTranslations = false
    }
    return newState
  }


  render(){
    //Add language name to translations
    //array for user convenience.
    let translations = this.state.item.translations
    if(this.state.languages.length > 0) {
      translations = translations.map(translation => {
        const language = this.state.languages.find(language =>
          language.id === translation.languageId
        )
        translation.languageName = language.name
        return translation
      })
    }

    return(
      <Dialog
        open={this.props.open}
        onClose={this.handleCloseButtonClick.bind(this)}
        fullScreen
        TransitionComponent={this.handleTransition}
        onEnter={this.handleOnEnter.bind(this)}
      >
        <AppBar className={this.props.classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={this.handleCloseButtonClick.bind(this)}
              aria-label="Close">
              <CloseIcon />
            </IconButton>
            <Typography
              variant="h5"
              color="inherit"
              className={this.props.classes.flex}>
              Notification Editor
            </Typography>
            <Button
              color="inherit"
              onClick={this.handleSubmit.bind(this)}>
              Save
            </Button>
          </Toolbar>
        </AppBar>
        <Grid
          container
          className={this.props.classes.container}
          justify="space-evenly"
        >
          <Grid item>
            <CalendarPicker
              date = {moment(this.state.item.date, "YYYY-MM-DD").toISOString()}
              notifications = {this.props.notifications}
              currentNotificationId = {this.state.item.id}
              onDateChange = {e => {
                this.handleChange(
                  moment(e).format("YYYY-MM-DD"),
                  'date'
                )
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              required
              margin="normal"
              multiline
              rows="4"
              id="title"
              label="Title (for internal use)"
              type="text"
              value={this.state.item.title}
              helperText={this.state.titleCharCount}
              onChange={e => this.handleChange(e.target.value, 'title')}
              fullWidth
              error={this.state.errorTitle}
            />
            <TextField
              required
              margin="normal"
              multiline
              rows="4"
              id="message"
              label="Message (for internal use)"
              type="text"
              value={this.state.item.message}
              helperText={this.state.messageCharCount}
              onChange={e => this.handleChange(e.target.value, 'message')}
              fullWidth
              error={this.state.errorMessage}
            />
          </Grid>
          <Grid item>
            <Paper>
              <ItemList
                mountAnimation = {true}
                items = {translations}
                itemAttributeForLabel = {"languageName"}
                allowAdding = {true}
                addItemLabel = {"Add a Translation"}
                allowEditing = {true}
                allowDeleting = {true}
                onItemClick = {this.handleTranslationClick.bind(this)}
                onItemEdit = {this.handleTranslationEdit.bind(this)}
                onItemAdd = {this.handleTranslationAdd.bind(this)}
                onItemDelete = {this.handleTranslationDelete.bind(this)}
              />
            </Paper>
          </Grid>
        </Grid>
        <Dialog
          open={this.state.errorDialog}
          onClose={this.handleErrorDialogClose.bind(this)}
        >
          <DialogContent>
            <DialogContentText>
              {this.state.errorDialogContentText}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleErrorDialogClose.bind(this)}
              color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.closeDialog}
          onClose={this.handleCloseDialogCloseNo.bind(this)}
        >
          <DialogContent>
            <DialogContentText>
              Are you sure that you want to close this screen?
              All of the changes you made will be lost.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleCloseDialogCloseNo.bind(this)}
              color="primary">
              No. I want to keep my changes.
            </Button>
            <Button
              onClick={this.handleCloseDialogCloseYes.bind(this)}
              color="secondary">
              Yes. I want to lose my changes.
            </Button>
          </DialogActions>
        </Dialog>
        <CreateTranslationForm
          open={this.state.renderCreateTranslationForm}
          item={this.state.formItem}
          languages={this.state.languages}
          onClose={this.toggleCreateTranslationForm.bind(this)}
          onSave={this.handleFormSave.bind(this)}
        />
        <DeleteItemForm
          open={this.state.renderDeleteForm}
          item={this.state.formItem}
          onClose={this.toggleDeleteForm.bind(this)}
          onDelete={this.handleFormDelete.bind(this)}
        />
      </Dialog>
    )
  }
}

export default withStyles(styles)(CreateForm)
