import React, { Component } from "react";
import { DateTimePicker } from "@material-ui/pickers"

class CalendarPicker extends Component {
  render() {
    return (
      <DateTimePicker
        value={this.props.date}
        variant="inline"
        disablePast
        onChange={this.props.onDateChange}
        fullWidth
        label="Choose a date/time"
        shouldDisableDate = {date =>
          this.props.notifications.length !== 0 ? (
            this.props.notifications.find(
              notification =>
                date.toISOString().substring(0,10) ===
                notification.date.substring(0,10) &&
                notification.id !== this.props.currentNotificationId
            )
          ) : false
        }
      />
    );
  }
}

export default CalendarPicker
