import React, { Component } from 'react'
import { ListItem, ListItemText, IconButton, withStyles } from '@material-ui/core'
import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'

const styles = theme => ({
  selected: {
    backgroundColor: theme.palette.action.selected
  }
})

class Item extends Component {
  render(){
    return(
      <ListItem
        className={this.props.selected ? this.props.classes.selected : ""}
        button
        onClick={e => this.props.onItemClick(this.props.item)}>
        <ListItemText
          primary={this.props.item.name || this.props.item.question} />
        {this.props.allowEditing &&
          <IconButton
            onClick={e => {
              e.stopPropagation()
              this.props.onItemEdit(this.props.item)
            }}>
            <EditIcon />
          </IconButton>
        }
        {this.props.allowDeleting &&
          <IconButton
            onClick={e => {
              e.stopPropagation()
              this.props.onItemDelete(this.props.item)
            }}>
            <DeleteIcon />
          </IconButton>
        }
      </ListItem>
    )
  }
}

export default withStyles(styles)(Item)
