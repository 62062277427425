import React, { Component } from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'

export default class NavBar extends Component {
  render(){
    const listItems = this.props.sections.map((navItem, key) =>
      <Button
        key={key}
        color="inherit"
        onClick={(e) => {
          this.props.onNavChange(navItem.name)
        }}
      >
        {navItem.description}
      </Button>
    )

    const style = {
      container: {
        display: 'flex',
        alignItems: 'center'
      },
      toolBar: {
        justifyContent: 'space-between',
        backgroundColor: '#F09723'
      }
    }

    return(
      <AppBar position="sticky">
        <Toolbar style={style.toolBar}>
          <div style={style.container}>
              <Button
                color="inherit"
                onClick={(e) => this.props.onNavChange(e.target.textContent)}>
                Hoppy
              </Button>
          </div>
          <div style={style.container}>
            {listItems}
          </div>
        </Toolbar>
      </AppBar>
    )
  }
}
