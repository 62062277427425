import React, { Component } from 'react'
import { Fade, withStyles } from '@material-ui/core'
import CreateForm from './create-form'
import ItemTable from './item-table'
import DeleteForm from './delete-form'
import API from '../../API'
import update from 'immutability-helper'

const styles = theme => ({
  itemTable: {
    margin: '0 auto',
    width: '75%',
    marginTop: theme.spacing(3)
  }
})

class DailyChallenge extends Component {
  constructor(props){
    super(props)
    this.state = {
      selectedLanguageId: 1,
      languages: [],
      questions: [],
      answers: [],
      renderCreateForm: false,
      renderDeleteForm: false,
      formItemId: -1
    }
    this.toggleCreateForm = this.toggleCreateForm.bind(this)
    this.toggleDeleteForm = this.toggleDeleteForm.bind(this)
  }

  componentDidMount(){
    API.getAllLanguages(response => {
      this.setState({ languages: response.languages })
    })
    API.getAllDailyChallengeQuestions(questions => {
      this.setState({ questions })
    })
    API.getAllAnswers(answers => {
      this.setState({ answers })
    })
  }

  handleItemEdit(question) {
    this.toggleCreateForm(question.id)
  }

  handleItemAdd() {
    this.toggleCreateForm()
  }

  handleItemDelete(question) {
    this.toggleDeleteForm(question.id)
  }

  toggleCreateForm(itemId){
    this.setState({
      renderCreateForm: !this.state.renderCreateForm,
      formItemId: itemId ? itemId : -1
    })
  }

  toggleDeleteForm(itemId){
    this.setState({
      renderDeleteForm: !this.state.renderDeleteForm,
      formItemId: itemId ? itemId : -1
    })
  }

  handleLanguageChange(input) {
    if (this.state.selectedLanguageId !== input.target.value){
      this.setState({ selectedLanguageId: input.target.value })
    }
  }

  handleFormSave(item, deletedSubItems) {
    let id = parseInt(item.id, 10)
    if(Number.isNaN(id)) {
      delete item.id
    }
    item.languageId = this.state.selectedLanguageId
    item.questionTypeId = 1
    let answers = item.answers || []
    delete item.answers
    this.handleQuestionSave(item, result => {
      answers = answers.map(answer => {
        let id = parseInt(answer.id, 10)
        if(Number.isNaN(id)) { delete answer.id }
        if(result && result.id) {
          answer.questionId = result.id
        }
        answer.languageId = item.languageId
        return answer
      })
      this.handleAnswerSave(answers, () => {
        if(deletedSubItems && deletedSubItems.length > 0) {
          this.handleAnswerDelete(deletedSubItems)
        }
      })
    })
  }

  handleFormDelete() {
    this.handleQuestionDelete(this.state.formItemId)
  }

  handleQuestionSave(question, cb) {
    if(question.id) {
      const index = this.state.questions.findIndex(
        item => item.id === question.id
      )
      this.setState(update(this.state, {
        questions: {
          $splice: [
            [index, 1, question]
          ]
        }
      }), () => { cb && cb(question) })
      API.putQuestion(question, response => {
      })
    } else {
      API.postQuestion(question, response => {
        this.setState(update(this.state, {
          questions: {
            $push: [ response.question ]
          }
        }), () => { cb && cb(response.question) })
      })
    }
  }

  handleQuestionDelete(questionId) {
    const index = this.state.questions.findIndex(
      item => item.id === questionId
    )
    this.setState(update(this.state, {
      questions: {
        $splice: [
          [index, 1]
        ]
      }
    }))
    API.deleteQuestion(questionId, response => {
    })
  }

  async handleAnswerSave(answers, cb) {
    let newAnswers = Object.assign([], this.state.answers)
    for(let answer of answers) {
      if(answer.id) {
        const index = newAnswers.findIndex(
          item => item.id === answer.id
        )
        newAnswers[index] = answer
        API.putAnswer(answer, response => {
        })
      } else {
        const response = await API.postAnswer(answer)
        newAnswers.push(response.answer)
      }
    }
    this.setState({ answers: newAnswers }, cb)
  }

  handleAnswerDelete(answers, cb) {
    let newAnswers = Object.assign([], this.state.answers)
    answers.forEach(answer => {
      const index = newAnswers.findIndex(
        item => item.id === answer.id
      )
      newAnswers.splice(index, 1)
      API.deleteAnswer(answer.id, response => {
      })
    })
    this.setState({ answers: newAnswers }, cb)
  }

  render() {
    let questions = this.state.questions.filter(question =>
      question.languageId === this.state.selectedLanguageId
    )
    questions = [...questions].sort((a,b) => a.id - b.id)
    questions = questions.map(question => {
      question.answers = this.state.answers.filter(answer => answer.questionId === question.id)
      question.answers = [...question.answers].sort((a,b) => a.id - b.id)
      return question
    })

    //If a form needs to be opened, find the
    //item that the form can consume.
    let formItem
    if(this.state.formItemId > -1) {
      formItem = this.state.questions.find(
        question => question.id === this.state.formItemId
      )
    }

    //When rendering between the item form
    //and the delete form, make sure that
    //the input data is not shared between
    //the forms. Each form should have
    //its own data input to ensure that
    //there are no unexpected data changes.
    let deleteItem = null
    if(this.state.renderDeleteForm) {
      deleteItem = Object.assign({}, formItem)
      formItem = null
    }

    return(
      <Fade
        in={this.props.mountAnimation}
        className={this.props.className}>
        <div>
          <CreateForm
            open = {this.state.renderCreateForm}
            item = {formItem}
            onClose = {this.toggleCreateForm.bind(this)}
            onSave = {this.handleFormSave.bind(this)} />
          <DeleteForm
            open = {this.state.renderDeleteForm}
            item = {deleteItem}
            onClose = {this.toggleDeleteForm.bind(this)}
            onDelete = {this.handleFormDelete.bind(this)} />
          <div className={this.props.classes.itemTable}>
            <ItemTable
              mountAnimation = {true}
              languages = {this.state.languages}
              selectedLanguageId = {this.state.selectedLanguageId}
              onLanguageChange = {this.handleLanguageChange.bind(this)}
              items = {questions}
              onItemEdit = {this.handleItemEdit.bind(this)}
              onItemAdd = {this.handleItemAdd.bind(this)}
              onItemDelete = {this.handleItemDelete.bind(this)} />
          </div>
        </div>
      </Fade>
    )
  }
}

export default withStyles(styles)(DailyChallenge)
