import React, { Component } from "react";
import {
    Dialog, DialogContent,
    DialogActions, Button, Slide,
    withStyles, TableCell, TableRow, Table, TableHead, TableBody, Typography, Paper,
    CardMedia, Tooltip, CardContent, Card, Grid,
    FormControl, Select, MenuItem,  Chip, InputLabel
} from "@material-ui/core";
import API from "../../../API";
import InfoIcon from "@material-ui/icons/Info";
import { Wallpaper as WallpaperIcon} from "@material-ui/icons";
import XLSX from 'xlsx';

const styles = theme => ({
    dialogContainer: {
        paddingTop: 10,
        paddingRight: 10,
        paddingBottom: 10,
        paddingLeft: 10,
        flexGrow: 0
    },
    flex: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    column: {
        flexDirection: 'column',
        width: '60vw',
    },
    userInfoColumn: {
        flexDirection: 'column',
    },
    beerCode: {
        width: '200px',
        margin: '30px auto'
    },
    beercodePlaceholder: {
        width: '100%',
        height: '300px',
        color: '#C0C0C0'
    },
    userInfo: {
        padding: '20px',
        width: 'calc(100% - 60vw - 16px)'
    },
    userDetailsNav: {
        backgroundColor: '#C0C0C0'
    },
    span: {
        display: 'block'
    },
    userDataButton: {
        marginBottom: theme.spacing(3)
    },
    image: {
        height: '150px',
        backgroundSize: 'contain',
        width: '100%'
    },
    largeCard: {
        height: '400px',
        overflow: 'auto'
    },
    headerContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        position: 'sticky',
        top: 0,
        backgroundColor: 'white',
        paddingTop: '16px',
        marginTop: '-16px',
        height: '48px'
    },
    textAlignCenter:{
        textAlign: 'center'
    },
    firstRow: {
        position: 'sticky',
        top: '48px',
        backgroundColor: 'white',
        boxShadow: '0px 1px #e3e3e3',
    },
    filter_grid: {
        paddingTop: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        width: '100%'
    },
    title_grid: {
        height: "50px"
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    }
});

class BadgesDetailsForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            badge: {},
            selectedAction: {
                showBadges: false
            },
            open: false,
            users: [],
            filteredUsers: [],
            zones: [],
            availableZones: [],
            selectedZones: [],
            lastSelectedZones: [],
            offices: [],
            availableOffices: [],
            selectedOffices: [],
            lastSelectedOffices: [],
            departments: [],
            availableDepartments: [],
            selectedDepartments: [],
            lastSelectedDepartments: [],
            countries: [],
            availableCountries: [],
            selectedCountries: [],
            lastSelectedCountries: []
        }
        this.toggleDetailsForm = this.toggleDetailsForm.bind(this)
        this.handleBadgeState = this.handleBadgeState.bind(this)
    }

    handleBadgeState() {
        if(typeof this.props.badge.id !== "undefined"){
            API.getAllBadgeAchievements(this.props.badge.id,usersBadgeAchievements => {
                class userBadge{
                    constructor({
                        id,
                        firstName,
                        lastName,
                        country_name,
                        department_name,
                        office_name,
                        zone_name
                    }) {
                        this.id = id;
                        this.firstName = firstName;
                        this.lastName = lastName;
                        this.country_name = country_name;
                        this.department_name = department_name;
                        this.office_name = office_name;
                        this.zone_name = zone_name;
                    }
                }
                let uBadges = [];
                if(usersBadgeAchievements.length > 0){
                    usersBadgeAchievements.forEach(ub => {
                        uBadges.push(new userBadge({...ub}));
                    });
                }

                let arrZones = [...new Set(uBadges.map((u) => u.zone_name))];
                let arrOffices = [...new Set(uBadges.map((u) => u.office_name))];
                let arrDepartments = [...new Set(uBadges.map((u) => u.department_name))];
                let arrCountries = [...new Set(uBadges.map((u) => u.country_name))];

                this.setState({
                   users: uBadges,
                   filteredUsers: uBadges,
                   zones: arrZones,
                   availableZones: arrZones,
                   offices: arrOffices,
                   availableOffices: arrOffices,
                   departments: arrDepartments,
                   availableDepartments: arrDepartments,
                   countries: arrCountries,
                   availableCountries: arrCountries
                });
            });
        }
    }

    handleZoneFilterChange(event){
        let selection = [];
        if (event.target.value.length > 0){
            if(!!event.target.value.find(item => item === 'ext')){
                if(this.state.selectedZones[0] === 'ext'){
                    selection = [...event.target.value.filter(item2 => (item2 !== 0 && item2 !== 'ext') )];
                } else {
                    selection = ['ext'];
                }
            } else if(event.target.value.filter(item => item === 0).length === 0){
                selection = [...event.target.value];
            }
        }
        this.setState({
          selectedZones: [...selection],
        });
    }
    
    handleCloseMultiselectZones(){
        let newZoneSelection, oldZoneSelection;
        newZoneSelection = [...this.state.selectedZones].sort();
        oldZoneSelection = [...this.state.lastSelectedZones].sort();
        
        console.log(newZoneSelection, oldZoneSelection);
        
        let sameSize = newZoneSelection.length === oldZoneSelection.length;
        let sameContent = newZoneSelection.every((value, index) => value === oldZoneSelection[index]);

        if(!sameSize || !sameContent) {
            
            this.setState({
                lastSelectedZones: newZoneSelection,
            });

            this.filterUsers();
        }
    }

    handleOfficeFilterChange(event){
        let selection = [];
        if (event.target.value.length > 0){
          if(event.target.value.filter(item => item === 0).length === 0){
            selection = [...event.target.value];
          }
        }
        this.setState({
          selectedOffices: [...selection],
        });
    }
    
    handleCloseMultiselectOffices(){
        let newOfficeSelection, oldOfficeSelection;
        newOfficeSelection = [...this.state.selectedOffices].sort();
        oldOfficeSelection = [...this.state.lastSelectedOffices].sort();
        
        
        let sameSize = newOfficeSelection.length === oldOfficeSelection.length;
        let sameContent = newOfficeSelection.every((value, index) => value === oldOfficeSelection[index]);

        if(!sameSize || !sameContent) {
            
            this.setState({
                lastSelectedOffices: newOfficeSelection,
            });

            this.filterUsers();
        }
    }

    handleDepartmentFilterChange(event){
        let selection = [];
        if (event.target.value.length > 0){
          if(event.target.value.filter(item => item === 0).length === 0){
            selection = [...event.target.value];
          }
        }
        this.setState({
          selectedDepartments: [...selection],
        });
    }
    
    handleCloseMultiselectDepartments(){
        let newDeptSelection, oldDeptSelection;
        newDeptSelection = [...this.state.selectedDepartments].sort();
        oldDeptSelection = [...this.state.lastSelectedDepartments].sort();
        
        
        let sameSize = newDeptSelection.length === oldDeptSelection.length;
        let sameContent = newDeptSelection.every((value, index) => value === oldDeptSelection[index]);

        if(!sameSize || !sameContent) {
            
            this.setState({
                lastSelectedDepartments: newDeptSelection,
            });

            this.filterUsers();
        }
    }

    handleCountryFilterChange(event){
        let selection = [];
        if (event.target.value.length > 0){
          if(event.target.value.filter(item => item === 0).length === 0){
            selection = [...event.target.value];
          }
        }
        this.setState({
            selectedCountries: [...selection],
        });
    }
    
    handleCloseMultiselectCountries(){
        let newCountrySelection, oldCountrySelection;
        newCountrySelection = [...this.state.selectedCountries].sort();
        oldCountrySelection = [...this.state.lastSelectedCountries].sort();
        
        
        let sameSize = newCountrySelection.length === oldCountrySelection.length;
        let sameContent = newCountrySelection.every((value, index) => value === oldCountrySelection[index]);

        if(!sameSize || !sameContent) {
            
            this.setState({
                lastSelectedCountries: newCountrySelection,
            });

            this.filterUsers();
        }
    }

    filterUsers(){
        // console.log(this.state.users);
        let isExt = this.state.selectedZones.length > 0 && this.state.selectedZones[0] === 'ext';
        // console.log(' isExternal filter:', isExt);
        let filteredUsersTmp = this.state.users.filter((user)=>{
            let zoneFilter = false;
            let officeFilter = false;
            let deptoFilter = false;
            let extUserFilter = false;
            let countryFilter = false;
            if(isExt){
                const userData = this.props.usersList.find((item)=> user.id === item.id);
                if(!!userData && userData.userType === 2){
                    extUserFilter = true;
                }
                if(this.state.selectedCountries.length > 0){
                    if(this.state.selectedCountries.find((country) => country === user.country_name) !== undefined){
                        countryFilter = true;
                    }
                } else{
                    countryFilter = true;
                }
            } else {
                if(this.state.selectedZones.length > 0){
                    if(this.state.selectedZones.find((zone) => zone === user.zone_name) !== undefined){
                        zoneFilter = true;
                    }
                } else {
                    zoneFilter = true;
                }
                if(this.state.selectedOffices.length > 0){
                    if(this.state.selectedOffices.find((office) => office === user.office_name) !== undefined){
                        officeFilter = true;
                    }
                } else {
                    officeFilter = true;
                }
                if(this.state.selectedDepartments.length > 0){
                    if(this.state.selectedDepartments.find((dept) => dept === user.department_name) !== undefined){
                        deptoFilter = true;
                    }
                } else {
                    deptoFilter = true;
                }
            }

            return (extUserFilter && countryFilter) || (zoneFilter && officeFilter && deptoFilter);
        });
        // console.log(' filteredUsersTmp: ', filteredUsersTmp);

        let arrZones = isExt ? [] : [...new Set(filteredUsersTmp.map((u) => u.zone_name))];
        let arrOffices = isExt ? [] : [...new Set(filteredUsersTmp.map((u) => u.office_name))];
        let arrDepartments = isExt ? [] : [...new Set(filteredUsersTmp.map((u) => u.department_name))];
        let arrCountries = !isExt ? [] : [...new Set(filteredUsersTmp.map((u) => u.country_name))];

        // console.log(arrZones, arrOffices, arrDepartments, arrCountries);
        this.setState({
            filteredUsers: filteredUsersTmp,
            availableZones: arrZones,
            availableOffices: arrOffices,
            availableDepartments: arrDepartments,
            countries: arrCountries,
            availableCountries: arrCountries
        });
    }

    downloadUsersXLS(bolFull){
        const listName = bolFull ? 'users' : 'filteredUsers';
        const usersData = this.state[listName].map((item)=>{
            return this.props.usersList.find((user)=> user.id === item.id);
        }).filter((user)=>{
            return !!user;
        });
        let arrUsers = [['User ID', 'First Name', 'Last Name', 'Email', 'Country', 'Office', 'Department', 'Beercoins Balance', 'User Type']];
        usersData.forEach(user => {
            let userRow = [
                user.id, 
                user.firstName, 
                user.lastName, 
                user.emailAddress, 
                (user.country === 'PUT' ? null : user.country),
                user.office.name,
                user.department.name,
                user.balance,
                user.userType === 1 ? 'Internal User' : 'External User'
            ];
            arrUsers.push(userRow);
        });
        let worksheet = XLSX.utils.aoa_to_sheet(arrUsers);
        worksheet['!cols'] = this.fitToColumn(arrUsers);
        var workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "UserList");
        const badgeName = `${this.props.badge.name}`.replace(' ', '');
        XLSX.writeFile(workbook,`HoppyBadge_${badgeName}_${bolFull ? '': 'Filtered'}UsersList.xlsx`);
    }

    fitToColumn(arrayOfArray) {
        // get maximum character of each column
        return arrayOfArray[0].map((a, i) => ({ wch: Math.max(...arrayOfArray.map(a2 => `${a2[i]}`.length)) }));
    }


    handleTransition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    })

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.open !== prevProps.open) {
            this.setState({
                open: this.props.open
            });
        }
    }

    /**
     * When modal closes run this
     * @param e
     */
    toggleDetailsForm(e) {
        this.setState({
            open: !this.state.open,
            selectedZones: [],
            lastSelectedZones: [],
            selectedOffices: [],
            lastSelectedOffices: [],
            selectedDepartments: [],
            lastSelectedDepartments: [],
            selectedCountries: [],
            lastSelectedCountries: []
        });
        this.props.toggleDetailsForm("");
        this.setState({
            badge: {}
        });
    }

    /**
     * Render the img for bitcoin
     */
    renderBeercode(svg, width = 40, height = 48) {
        return <img src={svg} width={width} height={height}  alt={'Teste'}/>
    }

    render() {
        return (
            <Dialog

                fullWidth={true}
                maxWidth={"lg"}
                open={this.state.open}
                onClose={this.handleClose}
                TransitionComponent={this.handleTransition}
                onEnter={this.handleBadgeState}
            >
                <DialogContent
                    className={`${this.props.classes.dialogContainer} ${this.props.classes.userInfoColumn} ${this.props.classes.flex}`}
                >
                    
                    <Grid
                        container
                        spacing={3}
                        className={this.props.classes.filter_grid}
                    >
                        <Grid item xs={12} className={this.props.classes.title_grid} >
                            <h3>Filters</h3>
                        </Grid>

                        <Grid item xs={4}>
                            <FormControl fullWidth>
                                <InputLabel htmlFor="zone">Zone</InputLabel> { /* Zone DROPDOWN */ }
                                <Select
                                    value={this.state.selectedZones} 
                                    required={true}
                                    onChange={this.handleZoneFilterChange.bind(this)}
                                    multiple
                                    onClose={this.handleCloseMultiselectZones.bind(this)}
                                    renderValue={(selected) => (
                                        <div className={this.props.classes.chips}>
                                            {selected.map((value) => (
                                                <Chip key={value} label={value === 'ext' ? 'External Users': value} className={this.props.classes.chip} />
                                            ))}
                                        </div>
                                    )}
                                >
                                <MenuItem value={0}> Select a zone... </MenuItem>
                                {
                                    this.state.availableZones.map((zone, i) =>
                                    <MenuItem key={i} value={zone} > {zone} </MenuItem>
                                    )
                                }
                                {
                                    this.state.selectedOffices.length === 0 && 
                                    this.state.selectedDepartments.length === 0 &&
                                    <MenuItem value={'ext'}> External Users </MenuItem>
                                }
                                </Select>
                            </FormControl>
                        </Grid>
                        {
                            this.state.selectedZones.length > 0 && this.state.selectedZones[0] === 'ext' ?
                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="country">Country</InputLabel> { /* Country DROPDOWN */ /* TODO country selection and filter */}
                                    <Select
                                        value={this.state.selectedCountries}
                                        required={true}
                                        onChange={this.handleCountryFilterChange.bind(this)}
                                        multiple
                                        onClose={this.handleCloseMultiselectCountries.bind(this)}
                                        renderValue={(selected) => (
                                            <div className={this.props.classes.chips}>
                                                {selected.map((value) => (
                                                    <Chip key={value} label={value} className={this.props.classes.chip} />
                                                ))}
                                            </div>
                                        )}
                                    >
                                    <MenuItem value={0}> Select a country... </MenuItem>
                                    {
                                        this.state.availableCountries.map((country, i) =>
                                            <MenuItem key={i} value={country} > {country} </MenuItem>
                                        )
                                    }
                                    </Select>
                                </FormControl>
                            </Grid>
                            :
                            [<Grid item xs={4} key={0}>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="office">Office</InputLabel> { /* Office DROPDOWN */ }
                                    <Select
                                        value={this.state.selectedOffices}
                                        required={true}
                                        onChange={this.handleOfficeFilterChange.bind(this)}
                                        multiple
                                        onClose={this.handleCloseMultiselectOffices.bind(this)}
                                        renderValue={(selected) => (
                                            <div className={this.props.classes.chips}>
                                                {selected.map((value) => (
                                                    <Chip key={value} label={value} className={this.props.classes.chip} />
                                                ))}
                                            </div>
                                        )}
                                    >
                                    <MenuItem value={0}> Select an office... </MenuItem>
                                    {
                                        this.state.availableOffices.map((office, i) =>
                                            <MenuItem key={i} value={office} > {office} </MenuItem>
                                        )
                                    }
                                    </Select>
                                </FormControl>
                            </Grid> ,
                            <Grid item xs={4} key={1}>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="department">Function</InputLabel> { /* Department DROPDOWN */ }
                                    <Select
                                        value={this.state.selectedDepartments}
                                        required={true}
                                        onChange={this.handleDepartmentFilterChange.bind(this)}
                                        multiple
                                        onClose={this.handleCloseMultiselectDepartments.bind(this)}
                                        renderValue={(selected) => (
                                            <div className={this.props.classes.chips}>
                                                {selected.map((value) => (
                                                    <Chip key={value} label={value} className={this.props.classes.chip} />
                                                ))}
                                            </div>
                                        )}
                                    >
                                    <MenuItem value={0}> Select a function... </MenuItem>
                                    {
                                        this.state.availableDepartments.map((depto, i) =>
                                            <MenuItem key={i} value={depto} > {depto} </MenuItem>
                                        )
                                    }
                                    </Select>
                                </FormControl>
                            </Grid>]
                        }

                    </Grid>

                    <div className={this.props.classes.flex}>
                        {/* BADGES DETAILS */}
                        <Paper className={this.props.classes.userInfo}>
                            { this.props.badge.icon ? (
                                <CardMedia
                                    className={this.props.classes.image}
                                    image={this.props.badge.icon}
                                    alt={"Badge Icon"}
                                    title={"Badge Icon"}
                                />
                            ) : (
                                <WallpaperIcon
                                    className={this.props.classes.imagePlaceholder}
                                    color={this.props.errorIcon ? "secondary" : "primary"} />
                            )}
                            <br/>
                            <div className={`${this.props.classes.userInfoColumn} ${this.props.classes.flex} ${this.props.classes.textAlignCenter}`}>
                                <Typography variant="h5">
                                    Badge: {this.props.badge.name}
                                </Typography>
                                <Typography variant="body2">
                                    Earning Count: {this.state.users.length}<br/>
                                </Typography>
                            </div>
                        </Paper>
                        {/* USERS TABLE */}
                        <div className={this.props.classes.column}>

                            <Card
                                className={`${
                                    this.props.classes.largeCard
                                }`}
                            >
                                <CardContent>
                                    <div className={this.props.classes.headerContainer}>
                                        <Typography variant="h5">
                                            Users Who Won Medals
                                        </Typography>
                                        <Tooltip title={'Show the users earned this badge.'}>
                                            <InfoIcon color="action"/>
                                        </Tooltip>
                                    </div>

                                    <Table
                                        size={"small"}
                                    >
                                        <TableHead>
                                            <TableRow>
                                                {['Cod','First Name', 'Last Name', 'Country', 'Function', 'Office', 'Zone'].map((column, index) =>
                                                    <TableCell key={index} className={this.props.classes.firstRow}>{column}</TableCell>
                                                )}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                this.state.filteredUsers.map((user, index) => {
                                                    return (<TableRow
                                                        key={index}
                                                    >
                                                        <TableCell>{user.id}</TableCell>
                                                        <TableCell>{user.firstName}</TableCell>
                                                        <TableCell>{user.lastName}</TableCell>
                                                        <TableCell>{user.country_name}</TableCell>
                                                        <TableCell>{user.department_name}</TableCell>
                                                        <TableCell>{user.office_name}</TableCell>
                                                        <TableCell>{user.zone_name}</TableCell>
                                                    </TableRow>)
                                                })
                                            }
                                        </TableBody>
                                    </Table> {/* # tables badge */}

                                </CardContent>
                            </Card>

                        </div> {/* # div tables badge */}
                    </div>



                </DialogContent> {/* # dialog content badge details */}
                <DialogActions>
                    <Button onClick={this.downloadUsersXLS.bind(this, true)} variant="contained" color="primary">
                        Extract Full List
                    </Button>
                    <Button onClick={this.downloadUsersXLS.bind(this, false)} variant="contained" color="primary">
                        Extract Filtered List
                    </Button>
                    <Button onClick={this.toggleDetailsForm} variant="outlined" color="secondary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles)(BadgesDetailsForm);
