import React, { Component } from 'react'
import {
  withStyles, Grid,
  Typography, TextField
} from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'

const styles = theme => ({
  wrapper: {
    paddingTop: theme.spacing(4)
  },
  date: {
    marginTop: theme.spacing(1),
    color: theme.palette.text.secondary,
    lineHeight: 0,
    letterSpacing: 'normal',
    fontSize: theme.typography.fontSize * 0.85
  },
  picker: {
    width: 120
  },
  pickerInput: {
    padding: '1px 0 8px',
    cursor: 'pointer'
  }
})

class DateForm extends Component {
  render() {
    return(
      <Grid
        container
        direction="column"
        spacing={2}
        className={this.props.classes.wrapper}
      >
        <Grid item>
          <Typography
            variant="body1"
            className={this.props.classes.date}
          >
            Choose a Date
          </Typography>
        </Grid>
        <Grid item>
          <DatePicker
            className={this.props.classes.picker}
            value={this.props.date}
            variant="inline"
            onChange={(date) => {
              this.props.onDateChange("date", date)
            }}
            InputProps={{
              classes: {
                input: this.props.classes.pickerInput
              }
            }}
          />
        </Grid>
        <Grid item>
          <TextField
            label="Beercoin Amount Earned By User"
            margin="normal"
            fullWidth
            value={this.props.beercoinEarnAmount || ''}
            onChange={this.props.onBeercoinChange} />
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(DateForm)
