import React, { Component } from 'react'
import { withStyles, CardMedia, Typography, TextField } from '@material-ui/core'
import { Wallpaper as WallpaperIcon } from '@material-ui/icons'
import ImageUpload from '../../../../../utils/image-upload'
import API from '../../../../../../API'

const styles = theme => ({
  image: {
    height: '250px',
    backgroundSize: 'contain'
  },
  imagePlaceholder: {
    height: '150px',
    width: '150px',
    margin: '0 auto',
    display: 'block'
  },
  imageEditor: {
    width: '50vw',
    padding: '50px'
  },
  imageUpload: {
    textAlign: 'center'
  },
  imageUploadText: {
    textAlign: 'center'
  },
  textField: {
    width: '100%',
    height: '100%'
  }
})

const isImage = content => {
  // console.log(content);
  // return content.includes("base64")
  return content.includes("base64") || /^m_img_\d+(_\w+)?$/.test(content);
}

const getImageUrl = content => {
  if(content.includes("base64")){
    return content;
  }
  return API.getImageUrl(content);
}

class ImageEditor extends Component {
  handleTextChange(input) {
    this.props.onChange(input.target.value)
  }

  render() {
    return(
      <div className={this.props.classes.imageEditor}>
        { isImage(this.props.module.content) &&
          <CardMedia
            className={this.props.classes.image}
            image={getImageUrl(this.props.module.content)} />
        }
        { !isImage(this.props.module.content) &&
          <WallpaperIcon
            className={this.props.classes.imagePlaceholder}
            color="primary" />
        }
        <ImageUpload
          label="Upload Image"
          className={this.props.classes.imageUpload}
          onImageChange={this.props.onChange} />
        <Typography
          className={this.props.classes.imageUploadText}
          variant="subtitle1">
          Note: the optimal sizes are 1080 pixels wide by 566 to 1350 pixels high.
        </Typography>
        <TextField
          className={this.props.classes.textField}
          multiline
          rows="4"
          label="Enter Caption"
          margin="normal"
          value={this.props.module.caption || ""}
          onChange={this.handleTextChange.bind(this)}
          inputProps={{maxLength :140}} />
      </div>
    )
  }
}

export default withStyles(styles)(ImageEditor)
