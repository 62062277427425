import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import RichTextEditor from 'react-rte'
import './text-module.css'

const styles = theme => ({
})

class TextModule extends Component {
  render() {
    return(
      <div className="text-module">
        <RichTextEditor
          readOnly={true}
          rootStyle={{
            border: 'none',
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
          }}
          editorStyle={{
            fontSize: '0.875rem',
            lineHeight: '1.46429em',
            cursor: 'unset'
          }}
          value={
            RichTextEditor.createValueFromString(
              this.props.module.content, 'html'
            )
          }/>
      </div>
    )
  }
}

export default withStyles(styles)(TextModule)
