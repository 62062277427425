import React, { Component } from 'react'
import Container from '../cms/item/item-editor/container'
import uniqid from 'uniqid'
import {
  TextField, withStyles, Drawer,
  Button, Typography
} from '@material-ui/core'
import update from 'immutability-helper'
import ModuleTypes from '../cms/item/item-editor/module/module-types'
import AnswerEditor from '../cms/item/item-editor/module/module-editors/answer-editor'
import { DragDropContext } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'

/***********
  Constants
************/
const questionMaxCharCount = 400

const styles = theme => ({
  wrapper: {
    padding: '20px 50px'
  },
  wrapperQuestion: {
    width: '35%',
    display: 'inline-flex',
    flexDirection: 'column',
    marginRight: '100px',
    textAlign: 'center'
  },
  wrapperQuestionModules: {
    width: '40%',
    display: 'inline-flex',
    flexDirection: 'column',
    marginTop: '-20px',
    textAlign: 'center'
  },
  question: {
    marginBottom: '30px',
    width: '100%'
  },
  addAnswer: {
    marginTop: '30px',
    width: '100%'
  }
})

class QuestionEditor extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openDrawer: false,
      openModule: {},
    }
    this.questionModulesContainerId = uniqid()
  }

  handleQuestionChange(input) {
    let value = input.target.value
    if(value.length > questionMaxCharCount) {
      value = value.substring(0, questionMaxCharCount)
    }
    const newQuestion = update(this.props.question, {
      question: {
        $set: value
      }
    })
    this.props.onChange(newQuestion)
  }

  handleAddAnswer() {
    this.handleAddModule()
  }

  handleAddModule(module) {
    if(!module) {
      module = {
        id: uniqid(),
        contentTypeId: ModuleTypes.ANSWER,
        answer: 'New Answer'
      }
    }
    module.correctBool = false
    const newQuestion = update(this.props.question, {
      answers: {
        $push: [ module ]
      }
    })
    this.props.onChange(newQuestion)
  }

  handleRemoveModule(module) {
    const index = this.props.question.answers
                  .findIndex(obj => obj.id === module.id)
    const newQuestion = update(this.props.question, {
      answers: {
        $splice: [
          [index, 1]
        ]
      }
    })
    this.props.onDelete(newQuestion, module)
  }

  toggleDrawer(module) {
    if(this.state.openDrawer) {
      this.handleEditModule()
    }

    this.setState({
      openDrawer: !this.state.openDrawer,
      openModule: module || {}
    })
  }

  handleOpenModuleChange(value) {
    let newModule = Object.assign({}, this.state.openModule)
    if(newModule.contentTypeId === ModuleTypes.ANSWER) {
      if(typeof value === "boolean") {
        newModule.correctBool = value
      } else {
        newModule.answer = value
      }
    } else {
      newModule.content = value
    }
    this.setState({ openModule: newModule})
  }

  handleEditModule() {
    const module = this.state.openModule
    const index = this.props.question.answers
                  .findIndex(obj => obj.id === module.id)
    const newQuestion = update(this.props.question, {
      answers: {
        $splice: [
          [index, 1, module]
        ]
      }
    })
    this.props.onChange(newQuestion)
    this.setState({ openModule: {} })
  }

  render() {
    let answers = Object.assign([], this.props.question.answers)
    answers.forEach(answer => {
      answer.contentTypeId = ModuleTypes.ANSWER
    })
    answers.sort((a,b) => a.id - b.id)

    return(
      <div className={this.props.classes.wrapper}>
        <div className={this.props.classes.wrapperQuestion}>
          <TextField
            required
            autoFocus
            error={this.props.errorQuestion}
            multiline
            rows="8"
            className={this.props.classes.question}
            label="Enter Your Question"
            margin="normal"
            value={this.props.question.question}
            helperText={this.props.question.question.length + "/" + questionMaxCharCount}
            onChange={this.handleQuestionChange.bind(this)} />
        </div>
        <div className={this.props.classes.wrapperQuestionModules}>
          <Typography
            variant="h6"
            gutterBottom>
            Answers
          </Typography>
          <Container
            id={this.questionModulesContainerId}
            modules={answers}
            column={true}
            allowEdits={true}
            onAddModule={this.handleAddModule.bind(this)}
            onRemoveModule={this.handleRemoveModule.bind(this)}
            onEditModule={this.toggleDrawer.bind(this)}
            emptyContainerLabel="No answers yet. Please add one." />
          <Button
            variant="contained"
            color="primary"
            onClick={this.handleAddAnswer.bind(this)}
            className={this.props.classes.addAnswer}>
            Add Answer
          </Button>
        </div>
        <Drawer
          anchor="left"
          open={this.state.openDrawer}
          onClose={this.toggleDrawer.bind(this)}
        >
          <AnswerEditor
            module={this.state.openModule}
            onChange={this.handleOpenModuleChange.bind(this)} />
        </Drawer>
      </div>
    )
  }
}

export default withStyles(styles)(
  DragDropContext(HTML5Backend)(QuestionEditor)
)
