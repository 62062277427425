import React, { Component } from 'react'
import {
  Button, TextField, Dialog,
  DialogActions, DialogContent,
  DialogContentText, DialogTitle,
  withStyles } from '@material-ui/core'

const styles = theme => ({
})

const nameMaxCharCount = 50

class CreateFlightForm extends Component {
  constructor(props){
    super(props)
    let item = {
      name: ''
    }
    if(this.props.item) { item = this.props.item }
    this.state = {
      item,
      errorName: false,
      nameCharCount: item.name.length + "/" + nameMaxCharCount
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  componentDidUpdate(prevProps) {
    if(this.props.item && this.props.item !== prevProps.item) {
      let nameCharCount = "0/" + nameMaxCharCount
      if(this.props.item.name) {
        nameCharCount = this.props.item.name.length + "/" + nameMaxCharCount
      }
      this.setState({
        item: this.props.item,
        nameCharCount
      })
    }
  }

  handleChange(value, input) {
    if(input === "name" && value.length > nameMaxCharCount) {
      value = value.substring(0, nameMaxCharCount)
    }

    let newItem = Object.assign({}, this.state.item)
    newItem[input] = value
    this.setState({ item: newItem, ...this.isItemValid(newItem) })

    if(input === "name") {
      this.setState({
        nameCharCount:
          value.length + "/" + nameMaxCharCount
      })
    }
  }

  handleSubmit(e){
    let errorState = this.isItemValid(this.state.item)
    if(Object.values(errorState).includes(true)) {
      this.setState({ ...errorState })
      return
    }
    this.props.onSave(this.state.item)
    this.handleClose()
  }

  handleClose() {
    this.setState({
      item: {
        name: ''
      },
      errorName: false,
      nameCharCount: "0/" + nameMaxCharCount
    })
    this.props.onClose()
  }

  isItemValid(item) {
    let newState = {}
    if(item.name === '') {
      newState.errorName = true
    } else {
      newState.errorName = false
    }
    return newState
  }

  render(){
    return(
      <Dialog open={this.props.open} onClose={this.handleClose}>
        <DialogTitle>
          Create/Edit Flight
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Feel free to make any changes below.
          </DialogContentText>
          <TextField
            required
            autoFocus
            margin="normal"
            multiline
            rows="2"
            id="name"
            label="Name"
            type="text"
            value={this.state.item.name}
            helperText={this.state.nameCharCount}
            onChange={e => this.handleChange(e.target.value, 'name')}
            fullWidth
            error={this.state.errorName}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.handleClose}
            color="secondary">
            Cancel
          </Button>
          <Button
            onClick={this.handleSubmit}
            color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(CreateFlightForm)
