import React, { Component } from 'react'
import {
  withStyles, FormControl,
  InputLabel, Select,
  MenuItem, Grid, Input,
  Checkbox, ListItemText,
  Chip, TextField
} from '@material-ui/core'

const styles = theme => ({
  wrapper: {
    paddingTop: theme.spacing(2)
  },
  badges: {
    width: '100%'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing(0.25),
  },
})

class BadgeCollectionForm extends Component {
  render() {
    return(
      <Grid
        container
        direction="column"
        spacing={2}
        className={this.props.classes.wrapper}
      >
        <Grid item>
          <FormControl
            className={this.props.classes.badges}
            margin="normal"
          >
            <InputLabel htmlFor="badges">Badges to Earn</InputLabel>
            { this.props.badges.length > 0 &&
              <Select
                multiple
                value={this.props.selectedBadges}
                onChange={this.props.onDataChange}
                input={<Input id="badges" />}
                renderValue={selected => {
                  const selectedBadges = []
                  for(const id of selected) {
                    const badge = this.props.badges.find(badge =>
                      badge.id === id
                    )
                    selectedBadges.push(badge)
                  }
                  return (
                    <div className={this.props.classes.chips}>
                      {selectedBadges.map(badge => (
                        <Chip
                          key={badge.id}
                          label={badge.name}
                          className={this.props.classes.chip}
                          color="primary"
                        />
                      ))}
                    </div>
                  )
                }}
              >
                { this.props.badges.concat().sort((a,b) => {
                    if(a.name < b.name) { return -1 }
                    if(a.name > b.name) { return 1 }
                    return 0
                  }).map(item =>
                    <MenuItem
                      key={item.id}
                      value={item.id}
                    >
                      <Checkbox
                        checked={
                          this.props.selectedBadges.indexOf(item.id) > -1
                        }
                      />
                      <ListItemText primary={item.name} />
                    </MenuItem>
                  )
                }
              </Select>
            }
          </FormControl>
        </Grid>
        <Grid item>
          <TextField
            label="Beercoin Amount Earned By User"
            margin="normal"
            fullWidth
            value={this.props.beercoinEarnAmount || ''}
            onChange={this.props.onBeercoinChange} />
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(BadgeCollectionForm)
