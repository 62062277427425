import React, { Component } from "react";
import {
  Fade,
  withStyles,
  Typography
} from "@material-ui/core";
import update from 'immutability-helper'
import { CreateForm, DeleteForm } from './forms/index'
import Table from './calendar-notifications-table'
import API from '../../API'
import moment from 'moment'

const styles = theme => ({
  container: {
    width: '90%',
    margin: '20px auto'
  },
  headerText: {
    margin: '2% 0'
  }
});

class CalendarNotifications extends Component {
  constructor(props){
    super(props)
    this.state = {
      notifications: [],
      translations: [],
      selectedDate: moment(),
      createItem: null,
      deleteItem: null,
      renderCreateForm: false,
      renderDeleteForm: false
    }
  }

  componentDidMount(){
    API.getAllCalendarNotifications(notifications => {
      this.setState({ notifications })
    })
    API.getAllCalendarNotificationTranslations(translations => {
      this.setState({ translations })
    })
  }

  toggleCreateForm(item){
    if(item) {
      item.translations = this.state.translations.filter(translation =>
        translation.calendarNotificationId === item.id
      )
    }
    this.setState({
      renderCreateForm: !this.state.renderCreateForm,
      createItem: item
    })
  }

  toggleDeleteForm(item){
    this.setState({
      renderDeleteForm: !this.state.renderDeleteForm,
      deleteItem: item
    })
  }

  handleDateChange(selectedDate) {
    this.setState({ selectedDate })
  }

  handleFormSave(newItem, deletedSubItems) {
    if(!newItem) { return }
    this.handleNotificationSave(newItem, newNotification => {
      const translations = newItem.translations.map(translation => {
        let id = parseInt(translation.id, 10)
        if(Number.isNaN(id)) { delete translation.id }
        translation.calendarNotificationId = newNotification.id
        return translation
      })
      this.handleTranslationSave(translations, () => {
        if(deletedSubItems && deletedSubItems.length > 0) {
          this.handleTranslationDelete(deletedSubItems)
        }
      })
    })
  }

  handleFormDelete() {
    this.handleNotificationDelete(this.state.deleteItem.id)
  }

  handleNotificationSave(notification, cb) {
    if(notification.id) {
      const index = this.state.notifications.findIndex(
        item => item.id === notification.id
      )
      this.setState(update(this.state, {
        notifications: {
          $splice: [
            [index, 1, notification]
          ]
        },
        selectedDate: {
          $set: moment(notification.date)
        }
      }), () => { cb && cb(notification) })
      API.putCalendarNotification(notification, response => {
      })
    } else {
      API.postCalendarNotification(notification, response => {
        this.setState(update(this.state, {
          notifications: {
            $push: [ response ]
          },
          selectedDate: {
            $set: moment(response.date) 
          }
        }), () => { cb && cb(response) })
      })
    }
  }

  handleNotificationDelete(notificationId) {
    const index = this.state.notifications.findIndex(
      item => item.id === notificationId
    )
    this.setState(update(this.state, {
      notifications: {
        $splice: [
          [index, 1]
        ]
      }
    }))
    API.deleteCalendarNotification(notificationId, response => {
    })
  }

  async handleTranslationSave(translations, cb) {
    let newTranslations = Object.assign([], this.state.translations)
    for(let translation of translations) {
      if(translation.id) {
        const index = newTranslations.findIndex(
          item => item.id === translation.id
        )
        newTranslations[index] = translation
        API.putCalendarNotificationTranslation(translation, response => {
        })
      } else {
        const response = await API.postCalendarNotificationTranslation(translation)
        newTranslations.push(response)
      }
    }
    this.setState({ translations: newTranslations }, cb && cb)
  }

  handleTranslationDelete(translations) {
    let newTranslations = Object.assign([], this.state.translations)
    for(let translation of translations) {
      let id = parseInt(translation.id, 10)
      if(!Number.isNaN(id)) { 
        const index = newTranslations.findIndex(
          item => item.id === translation.id
        )
        newTranslations.splice(index, 1)
        API.deleteCalendarNotificationTranslation(translation.id, response => {
        })
      }
    }
    this.setState({ translations: newTranslations })
  }

  render() {
    return (
      <Fade in={this.props.mountAnimation}>
        <div className={this.props.classes.container}>
          <Typography variant="h2" className={this.props.classes.headerText}>
            Calendar Notifications
          </Typography>
          <CreateForm 
            open = {this.state.renderCreateForm}
            item = {this.state.createItem}
            notifications = {this.state.notifications}
            onClose = {this.toggleCreateForm.bind(this)}
            onSave = {this.handleFormSave.bind(this)}
          />
          <DeleteForm
            open = {this.state.renderDeleteForm}
            item = {this.state.deleteItem}
            onClose = {this.toggleDeleteForm.bind(this)}
            onDelete = {this.handleFormDelete.bind(this)}
          />
          <Table 
            notifications={this.state.notifications}
            onAdd = {this.toggleCreateForm.bind(this)}
            onEdit = {this.toggleCreateForm.bind(this)}
            onDelete = {this.toggleDeleteForm.bind(this)}
          />
        </div>
      </Fade>
    )
  }
}

export default withStyles(styles)(CalendarNotifications);
