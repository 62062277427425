import React, { Component } from 'react'
import { withStyles, CardMedia, CardContent, Typography } from '@material-ui/core'
import API from '../../../../../API'

const styles = theme => ({
  cardMedia: {
    height: '250px',
    backgroundSize: 'contain'
  }
})

const isImage = content => {
  // console.log(content);
  // return content.includes("base64")
  return content.includes("base64") || /^m_img_\d+(_\w+)?$/.test(content);
}

const getImageUrl = content => {
  if(content.includes("base64")){
    return content;
  }
  return API.getImageUrl(content);
}

class ImageModule extends Component {
  render() {
    return(
      <div>
        { isImage(this.props.module.content) &&
          <div>
            <CardMedia
              className={this.props.classes.cardMedia}
              image={getImageUrl(this.props.module.content)} />
            {this.props.module.caption &&
              <CardContent>
                {this.props.module.caption}
              </CardContent>
            }
          </div>
        }
        { !isImage(this.props.module.content) &&
          <Typography component="p">
            {this.props.module.content}
          </Typography>
        }
      </div>
    )
  }
}

export default withStyles(styles)(ImageModule)
