import React, { Component } from 'react'
import API from '../../API'
import {
  Fade, AppBar, FormControl,
  InputLabel, Select,
  MenuItem, withStyles,
  CircularProgress
} from '@material-ui/core'
import {
  ViewColumn as ColumnIcon,
  ViewQuilt as GridIcon,
} from '@material-ui/icons'
import ManageContent from './manage-content'
import update from 'immutability-helper'

const styles = theme => ({
  cmsNav: {
    padding: '10px',
    backgroundColor: '#C0C0C0',
    display: 'flex',
  },
  rightContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  dropDownPadding: {
    paddingRight: '20px'
  },
  selectLayout: {
    padding: '3px 25px 0 10px',
    color: theme.palette.common.white
  },
  appBarPadding: {
    paddingBottom: '64px'
  },
  progressContainer: {
    width: '100%',
    position: 'absolute'
  },
  progress: {
    margin: '35vh auto',
    display: 'block'
  }
})

class Badges extends Component {
  state = {
    selectedLayout: 0,
    badgeGroups: [],
    badgeGroupTranslations: [],
    badges: [],
    badgeTranslations: []
  }

  componentDidMount() {
    API.getAllBadgeGroups(badgeGroups => {
      this.setState({ badgeGroups })
    })
    API.getAllBadgeGroupTranslations(badgeGroupTranslations => {
      this.setState({ badgeGroupTranslations })
    })
    API.getAllBadges(badges => {
      this.setState({ badges })
    })
    API.getAllBadgeTranslations(badgeTranslations => {
      this.setState({ badgeTranslations })
    })
  }

  handleLayoutChange(input) {
    this.setState({ selectedLayout: input.target.value })
  }

  handleBadgeGroupSave(badgeGroup, cb) {
    if(badgeGroup.id) {
      const index = this.state.badgeGroups.findIndex(
        item => item.id === badgeGroup.id
      )
      this.setState(update(this.state, {
        badgeGroups: {
          $splice: [
            [index, 1, badgeGroup]
          ]
        }
      }), () => { cb && cb(badgeGroup) })
      API.putBadgeGroup(badgeGroup, response => {
      })
    } else {
      API.postBadgeGroup(badgeGroup, response => {
        const newBadgeGroup = response
        this.setState(update(this.state, {
          badgeGroups: {
            $push: [ newBadgeGroup ]
          }
        }), () => { cb && cb(newBadgeGroup) })
      })
    }
  }

  handleBadgeGroupDelete(badgeGroupId) {
    const index = this.state.badgeGroups.findIndex(
      item => item.id === badgeGroupId
    )
    this.setState(update(this.state, {
      badgeGroups: {
        $splice: [
          [index, 1]
        ]
      }
    }))
    API.deleteBadgeGroup(badgeGroupId, response => {
    })
  }

  async handleBadgeGroupTranslationSave(translations, cb) {
    let newTranslations = Object.assign([], this.state.badgeGroupTranslations)
    for(let translation of translations) {
      if(translation.id) {
        const index = newTranslations.findIndex(
          item => item.id === translation.id
        )
        newTranslations[index] = translation
        API.putBadgeGroupTranslation(translation, response => {
        })
      } else {
        const response = await API.postBadgeGroupTranslation(translation)
        newTranslations.push(response)
      }
    }
    this.setState({ badgeGroupTranslations: newTranslations }, cb && cb)
  }

  handleBadgeGroupTranslationDelete(translations) {
    let newTranslations = Object.assign([], this.state.badgeGroupTranslations)
    for(let translation of translations) {
      let id = parseInt(translation.id, 10)
      if(!Number.isNaN(id)) { 
        const index = newTranslations.findIndex(
          item => item.id === translation.id
        )
        newTranslations.splice(index, 1)
        API.deleteBadgeGroupTranslation(translation.id, response => {
        })
      }
    }
    this.setState({ badgeGroupTranslations: newTranslations })
  }

  handleBadgeSave(badge, cb) {
    if(badge.id) {
      const index = this.state.badges.findIndex(
        item => item.id === badge.id
      )
      this.setState(update(this.state, {
        badges: {
          $splice: [
            [index, 1, badge]
          ]
        }
      }), () => { cb && cb(badge) })
      API.putBadge(badge, response => {
      })
    } else {
      API.postBadge(badge, response => {
        const newBadge = response
        this.setState(update(this.state, {
          badges: {
            $push: [ newBadge ]
          }
        }), () => { cb && cb(newBadge) })
      })
    }
  }

  handleBadgeDelete(badgeId) {
    const index = this.state.badges.findIndex(
      item => item.id === badgeId
    )
    this.setState(update(this.state, {
      badges: {
        $splice: [
          [index, 1]
        ]
      }
    }))
    API.deleteBadge(badgeId, response => {
    })
  }

  async handleBadgeTranslationSave(translations, cb) {
    let newTranslations = Object.assign([], this.state.badgeTranslations)
    for(let translation of translations) {
      if(translation.id) {
        const index = newTranslations.findIndex(
          item => item.id === translation.id
        )
        newTranslations[index] = translation
        API.putBadgeTranslation(translation, response => {
        })
      } else {
        const response = await API.postBadgeTranslation(translation)
        newTranslations.push(response)
      }
    }
    this.setState({ badgeTranslations: newTranslations }, cb && cb)
  }

  handleBadgeTranslationDelete(translations) {
    let newTranslations = Object.assign([], this.state.badgeTranslations)
    for(let translation of translations) {
      let id = parseInt(translation.id, 10)
      if(!Number.isNaN(id)) { 
        const index = newTranslations.findIndex(
          item => item.id === translation.id
        )
        newTranslations.splice(index, 1)
        API.deleteBadgeTranslation(translation.id, response => {
        })
      }
    }
    this.setState({ badgeTranslations: newTranslations })
  }

  render() {
    return(
      <div>
        <Fade in={this.state.badges.length === 0}>
          <div className={this.props.classes.progressContainer}>
            <CircularProgress
              className={this.props.classes.progress}
              size={64}
            />
          </div>
        </Fade>
        <Fade in={this.state.badges.length > 0}>
          <div>
            <AppBar position="sticky" className={this.props.classes.cmsNav}>
              <div className={this.props.classes.rightContainer}>
                <FormControl className={this.props.classes.dropDownPadding}>
                  <InputLabel htmlFor="layout">Layout</InputLabel>
                  <Select
                    value={this.state.selectedLayout}
                    onChange={this.handleLayoutChange.bind(this)}
                    inputProps={{ id: 'layout' }}
                    classes={{
                      selectMenu: this.props.classes.selectLayout
                    }}
                  >
                    <MenuItem value={0}>
                      <ColumnIcon />
                    </MenuItem>
                    <MenuItem value={1}>
                      <GridIcon />
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
            </AppBar>
            <div className={this.props.classes.appBarPadding}>
              <ManageContent
                showList = {!this.state.selectedLayout}
                mountAnimation = {true}
                badgeGroups = {this.state.badgeGroups}
                badgeGroupTranslations = {this.state.badgeGroupTranslations}
                badges = {this.state.badges}
                badgeTranslations = {this.state.badgeTranslations}
                onBadgeGroupSave = {this.handleBadgeGroupSave.bind(this)}
                onBadgeGroupDelete = {this.handleBadgeGroupDelete.bind(this)}
                onBadgeGroupTranslationSave = {this.handleBadgeGroupTranslationSave.bind(this)}
                onBadgeGroupTranslationDelete = {this.handleBadgeGroupTranslationDelete.bind(this)}
                onBadgeSave = {this.handleBadgeSave.bind(this)}
                onBadgeDelete = {this.handleBadgeDelete.bind(this)}
                onBadgeTranslationSave = {this.handleBadgeTranslationSave.bind(this)}
                onBadgeTranslationDelete = {this.handleBadgeTranslationDelete.bind(this)}
              />
            </div>
          </div>
        </Fade>
      </div>
    )
  }
}

export default withStyles(styles)(Badges)
