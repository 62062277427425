import React, { Component } from 'react'
import { withStyles, CardMedia, Typography, TextField } from '@material-ui/core'
import { Wallpaper as WallpaperIcon } from '@material-ui/icons'

const styles = theme => ({
  cardMedia: {
    height: '100%'
  },
  imagePlaceholder: {
    height: '150px',
    width: '150px',
    margin: '0 auto',
    display: 'block'
  },
  imageEditor: {
    width: '50vw',
    padding: '50px'
  },
  imageUpload: {
    textAlign: 'center'
  },
  imageUploadText: {
    textAlign: 'center'
  },
  textField: {
    width: '100%'
  }
})

const isYoutubeLink = content => {
  // 'https://youtu.be/'
  // 'https://www.youtube.com/watch?v='
  const linkMatch = content.match(/^(?<url>(?:https?:\/\/)?(?:www\.)?(?:youtu.be\/|youtube\.com\/(?:watch\?v=|embed\/)))(?<id>.*)/);
  const id = !!linkMatch && !!linkMatch.groups && !!linkMatch.groups.id && linkMatch.groups.id;
  return { isLink: !!id, linkId: id };
}

class EmbedVideoEditor extends Component {
  handleLinkChange(input) {
    this.props.onChange({value: input.target.value, field: 'LINK'})
  }
  handleTextChange(input) {
    this.props.onChange({value: input.target.value, field: 'CAPTION'})
  }

  render() {
    const { isLink, linkId } = isYoutubeLink(this.props.module.content); 
    return(
      <div className={this.props.classes.imageEditor}>
        { isLink &&
          <CardMedia
          className={this.props.classes.cardMedia}
          component={'iframe'}
          src={`https://www.youtube.com/embed/${linkId}`}
          />
        }
        { !isLink &&
          <WallpaperIcon
            className={this.props.classes.imagePlaceholder}
            color="primary" />
        }
        <TextField
          className={this.props.classes.textField}
          label="Enter youtube link"
          margin="normal"
          value={isLink && (this.props.module.content || "")}
          onChange={this.handleLinkChange.bind(this)} />
        <Typography
          className={this.props.classes.imageUploadText}
          variant="subtitle1">
          Note: the supported link formats are: https://youtu.be/{'<VIDEO_ID>'} and https://www.youtube.com/watch?v={'<VIDEO_ID>'}
        </Typography>
        <TextField
          className={this.props.classes.textField}
          multiline
          rows="4"
          label="Enter Caption"
          margin="normal"
          value={this.props.module.caption || ""}
          onChange={this.handleTextChange.bind(this)}
          inputProps={{maxLength :140}} />
      </div>
    )
  }
}

export default withStyles(styles)(EmbedVideoEditor)
