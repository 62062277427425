import React, { Component } from "react";
import {
  Fade,
  withStyles,
  Typography
} from "@material-ui/core";
import update from 'immutability-helper'
import { CreateForm, DeleteForm } from './forms/index'
import Table from './push-notifications-table'
import API from '../../API'
import moment from 'moment'

const styles = theme => ({
  container: {
    width: '90%',
    margin: '20px auto'
  },
  headerText: {
    margin: '2% 0'
  }
});

class PushNotifications extends Component {
  constructor(props){
    super(props)
    this.state = {
      notifications: [],
      selectedDate: moment(),
      createItem: null,
      deleteItem: null,
      renderCreateForm: false,
      renderDeleteForm: false
    }
  }

  componentDidMount(){
    API.getAllPushNotifications(notifications => {
      const newNotifications = notifications.map(notification => {
        notification.date = moment(notification.date).toISOString()
        const translation = notification.translations.find(translation =>
          translation.languageCode === "en"
        )
        if(translation) {
          notification.title = translation.title
          notification.message = translation.message
        } else {
          notification.title = "Notification"
          notification.message = "This is a notification."
        }
        return notification
      })
      this.setState({ notifications: newNotifications })
    })
  }

  toggleCreateForm(item){
    this.setState({
      renderCreateForm: !this.state.renderCreateForm,
      createItem: item
    })
  }

  toggleDeleteForm(item){
    this.setState({
      renderDeleteForm: !this.state.renderDeleteForm,
      deleteItem: item
    })
  }

  handleDateChange(selectedDate) {
    this.setState({ selectedDate })
  }

  handleFormSave(newItem, deletedSubItems) {
    if(!newItem) { return }
    this.handleNotificationSave(newItem)
  }

  handleFormDelete() {
    this.handleNotificationDelete(this.state.deleteItem.id)
  }

  handleNotificationSave(notification, cb) {
    notification.time_of_day = moment(notification.date).format("h:mm a")
    API.postPushNotification(notification, response => {
      response.date = moment(response.date).toISOString()
      const translation = response.translations.find(translation =>
        translation.languageCode === "en"
      )
      if(translation) {
        response.title = translation.title
        response.message = translation.message
      } else {
        response.title = "Notification"
        response.message = "This is a notification."
      }
      this.setState(update(this.state, {
        notifications: {
          $push: [ response ]
        },
        selectedDate: {
          $set: moment(response.date) 
        }
      }), () => { cb && cb(response) })
    })
  }

  handleNotificationDelete(notificationId) {
    const index = this.state.notifications.findIndex(
      item => item.id === notificationId
    )
    this.setState(update(this.state, {
      notifications: {
        $splice: [
          [index, 1]
        ]
      }
    }))
    API.deletePushNotification(notificationId, response => {
    })
  }

  render() {
    return (
      <Fade in={this.props.mountAnimation}>
        <div className={this.props.classes.container}>
          <Typography variant="h2" className={this.props.classes.headerText}>
            Push Notifications
          </Typography>
          <CreateForm 
            open = {this.state.renderCreateForm}
            item = {this.state.createItem}
            notifications = {this.state.notifications}
            onClose = {this.toggleCreateForm.bind(this)}
            onSave = {this.handleFormSave.bind(this)}
          />
          <DeleteForm
            open = {this.state.renderDeleteForm}
            item = {this.state.deleteItem}
            onClose = {this.toggleDeleteForm.bind(this)}
            onDelete = {this.handleFormDelete.bind(this)}
          />
          <Table 
            notifications={this.state.notifications}
            onAdd = {this.toggleCreateForm.bind(this)}
            onDelete = {this.toggleDeleteForm.bind(this)}
          />
        </div>
      </Fade>
    )
  }
}

export default withStyles(styles)(PushNotifications);
