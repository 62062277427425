import React, { Component } from 'react'
import {
  Fade,  withStyles
} from '@material-ui/core'
import ItemTable from '../item/item-table'
import CreateFunFactForm from '../item/item-form/create-fun-fact-form'
import DeleteFunFactForm from '../item/item-form/delete-fun-fact-form'

const styles = theme => ({
  itemTable: {
    margin: '0 auto',
    width: '75%',
    marginTop: theme.spacing(3)
  }
})

class ManageFunFacts extends Component {
  constructor(props) {
    super(props)
    this.state = {
      renderCreateForm: false,
      renderDeleteForm: false,
      formItemId: -1
    }
  }

  /***********
    FUN FACTS
  ************/

  handleFunFactEdit(funFact) {
    this.toggleCreateForm(funFact.id)
  }

  handleFunFactAdd() {
    this.toggleCreateForm()
  }

  handleFunFactDelete(funFact) {
    this.toggleDeleteForm(funFact.id)
  }

  /******************
    SAVE/DELETE ITEM
  *******************/

  handleFormSave(newItem) {
    if(!newItem) { return }
    newItem.languageId = this.props.languageId
    this.props.onFunFactSave(newItem)
  }

  handleFormDelete() {
    this.props.onFunFactDelete(this.state.formItemId)
  }

  /********
    FORM
  *********/

  toggleCreateForm(itemId) {
    this.setState({
      renderCreateForm: !this.state.renderCreateForm,
      formItemId: itemId ? itemId : -1
    })
  }

  toggleDeleteForm(itemId) {
    this.setState({
      renderDeleteForm: !this.state.renderDeleteForm,
      formItemId: itemId ? itemId : -1
    })
  }

  render() {
    let funFacts = this.props.funFacts.filter(funFact =>
      funFact.languageId === this.props.languageId
    )

    //If a form needs to be opened, find the
    //item that the form can consume.
    let formItem
    if(this.state.formItemId > -1) {
      formItem = this.props.funFacts.find(
        funFact => funFact.id === this.state.formItemId
      )
    }

    //When rendering between the item form
    //and the delete form, make sure that
    //the input data is not shared between
    //the forms. Each form should have
    //its own data input to ensure that
    //there are no unexpected data changes.
    let deleteItem = null
    if(this.state.renderDeleteForm) {
      deleteItem = Object.assign({}, formItem)
      formItem = null
    }

    return(
      <Fade
        in={this.props.mountAnimation}
        className={this.props.className}>
        <div>
          <CreateFunFactForm
            open = {this.state.renderCreateForm}
            item = {formItem}
            onClose = {this.toggleCreateForm.bind(this)}
            onSave = {this.handleFormSave.bind(this)} />
          <DeleteFunFactForm
            open = {this.state.renderDeleteForm}
            item = {deleteItem}
            onClose = {this.toggleDeleteForm.bind(this)}
            onDelete = {this.handleFormDelete.bind(this)} />
          <div className={this.props.classes.itemTable}>
            <ItemTable
              mountAnimation = {true}
              items = {funFacts}
              onItemEdit = {this.handleFunFactEdit.bind(this)}
              onItemAdd = {this.handleFunFactAdd.bind(this)}
              onItemDelete = {this.handleFunFactDelete.bind(this)} />
          </div>
        </div>
      </Fade>
    )
  }
}

export default withStyles(styles)(ManageFunFacts)
