
// from https://material-ui.com/demos/tables/ sorting and selection demo
import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { withStyles, Table, TableBody, TableCell, TableHead,
  TablePagination, TableRow, TableSortLabel, Toolbar,
  Typography, Paper, IconButton, Tooltip, CircularProgress,
  Fade} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { lighten } from '@material-ui/core/styles/colorManipulator'

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const columnData = [
  { id: 'id', align: 'left', numeric: false, disablePadding: false, label: 'Event ID' },
  { id: 'zoneId', align: 'left', numeric: false, disablePadding: false, label: 'Zone ID' },
  { id: 'name', align: 'left', numeric: false, disablePadding: false, label: 'Event Name' },
  { id: 'description', align: 'left', numeric: false, disablePadding: false, label: 'Description' },
];

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const {order, orderBy} = this.props;

    return (
      <TableHead>
        <TableRow>
          {columnData.map(column => {
            return (
              <TableCell
                key={column.id}
                align={column.align}
                padding={column.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === column.id ? order : false}
              >
                <Tooltip
                  title="Sort"
                  placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={order}
                    onClick={this.createSortHandler(column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  title: {
    flex: '0 0 auto',
  },
});

let EnhancedTableToolbar = props => {
  const { classes, renderAddForm } = props;
  return (
    <Toolbar
      className={classNames(classes.root)}
    >
      <div className={classes.title}>
          <Typography variant="h5" id="tableTitle">
            Events
          </Typography>
      </div>
      <div className={classes.spacer} />
      <Tooltip title="Add">
        <IconButton aria-label="Add item" onClick={e => renderAddForm()} style={{'margin': '5px'}}>
          <AddIcon/>
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
  root: {
    width: '90%',
    marginTop: theme.spacing(3),
    margin: 'auto'
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  tableRowHover: {
    cursor: 'pointer'
  },
  progressContainer: {
    width: '100%',
  },
  progress: {
    margin: '100px 35%',
    display: 'block',
    position: 'absolute'
  },
  hide: {
    display: 'none'
  }
});

class EnhancedTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      order: 'desc',
      orderBy: 'id',
      data: this.props.users,
      page: 0,
      rowsPerPage: 5,
    };
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  render() {
    const { classes } = this.props;
    const data = this.props.events
    const { order, orderBy, rowsPerPage, page } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    return (
      <Paper className={classes.root}>
        <EnhancedTableToolbar renderAddForm={this.props.renderAddForm}/>
        <div className={classes.tableWrapper}>
          <Fade in={data.length === 0}>
                <div className={data.length === 0 ? classes.progressContainer : classes.hide}>
                <CircularProgress
                      className={classes.progress}
                      size={64}
                    />
                </div>
          </Fade>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={this.handleRequestSort}
              rowCount={data.length}
            />
            <Fade in={data.length > 0}>
            <TableBody>
              {stableSort(data, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(event => {
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={event.id}
                      onClick={e => this.props.renderEditForm(event)}
                      classes={{ hover: this.props.classes.tableRowHover }}
                    >
                      <TableCell component="th" scope="row">
                        {event.id}
                      </TableCell>
                     <TableCell>{event.zoneId}</TableCell>
                      <TableCell>{event.name}</TableCell>
                      <TableCell>{event.description}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            </Fade>
          </Table>
        </div>
        <TablePagination
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />

      </Paper>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EnhancedTable);
