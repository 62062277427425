import React, { Component } from 'react'
import {
  withStyles, TextField,
  FormControlLabel, Checkbox
} from '@material-ui/core'

const styles = theme => ({
  answerEditor: {
    width: '50vw',
    padding: '50px'
  },
  answerField: {
    width: '100%',
    height: '100%'
  }
})

class AnswerEditor extends Component {
  handleChange(input) {
    if(typeof input.target.checked !== "undefined") {
      this.props.onChange(input.target.checked)
    } else {
      this.props.onChange(input.target.value)
    }
  }

  render() {
    return(
      <div className={this.props.classes.answerEditor}>
        <TextField
          className={this.props.classes.answerField}
          multiline
          rows="10"
          label="Enter Answer"
          margin="normal"
          value={this.props.module.answer}
          onChange={this.handleChange.bind(this)} />
        <FormControlLabel
          control={
            <Checkbox
              checked={this.props.module.correctBool}
              onChange={this.handleChange.bind(this)} />
          }
          label="Correct" />
      </div>
    )
  }
}

export default withStyles(styles)(AnswerEditor)
