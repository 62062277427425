import React, { Component } from 'react'
import {
  Button, Dialog, AppBar, Toolbar,
  IconButton, Typography, Slide,
  withStyles, DialogContent,
  DialogContentText, DialogActions } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import LessonEditor from '../item-editor/lesson-editor'
import update from 'immutability-helper'

const styles = {
  appBar: {
    position: 'relative'
  },
  flex: {
    flex: 1
  }
}

class CreateLessonForm extends Component {
  state = {
    item: this.props.item || {
      name: '',
      image: '',
      thumbnail: '',
      modules : [],
      contentLength: ''
    },
    deletedSubItems: [],
    errorName: false,
    errorImage: false,
    errorThumbnail: false,
    errorContentLength: false,
    errorDialog: false,
    errorDialogContentText: '',
    closeDialog: false
  }

  componentDidUpdate(prevProps) {
    if(this.props.item && this.props.item !== prevProps.item) {
      this.setState({
        item: this.props.item
      })
    }
  }

  handleChange(item) {
    this.setState({ item, ...this.isItemValid(item) })
  }

  handleDelete(item, subItem) {
    this.setState(update(this.state, {
      item: { $set: item },
      deletedSubItems: { $push: [ subItem ] }
    }))
  }

  handleSubmit() {
    let newState = this.isItemValid(this.state.item)
    if(Object.values(newState).includes(true)) {
      let errorMessage
      if(newState.errorName === true) {
        errorMessage = "Please add a lesson name."
      }
      if(newState.errorImage === true) {
        errorMessage = "Please add a lesson image."
      }
      if(newState.errorThumbnail === true) {
        errorMessage = "Please add a lesson thumbnail."
      }
      if(newState.errorContentLength === true) {
        errorMessage = "Please add a lesson content length."
      }
      this.setState({
        ...newState,
        errorDialog: true,
        errorDialogContentText: errorMessage
      })
      return
    }
    this.props.onSave(this.state.item, this.state.deletedSubItems)
    this.handleClose()
  }

  handleTransition = React.forwardRef(function Transition(props, ref) {
    let newProps = Object.assign({}, props)
    newProps.onEntered = node => {
      setTimeout(() => node.style.transform = "none", 10)
    }
    return <Slide direction="up" ref={ref} {...newProps} />
  })

  handleErrorDialogClose() {
    this.setState({ errorDialog: false })
  }

  handleCloseButtonClick() {
    this.setState({ closeDialog: true })
  }

  handleCloseDialogCloseNo() {
    this.setState({ closeDialog: false })
  }

  handleCloseDialogCloseYes() {
    this.setState({ closeDialog: false })
    this.handleClose()
  }

  handleClose() {
    this.setState({
      item: {
        name: '',
        image: '',
        thumbnail: '',
        modules: [],
        contentLength: ''
      },
      deletedSubItems: [],
      errorName: false,
      errorImage: false,
      errorThumbnail: false,
      errorContentLength: false,
      errorDialog: false,
      errorDialogContentText: '',
      closeDialog: false
    })

    this.props.onClose()
  }

  isItemValid(item) {
    let newState = {}
    if(item.name === '') {
      newState.errorName = true
    } else {
      newState.errorName = false
    }
    if(item.image === '') {
      newState.errorImage = true
    } else {
      newState.errorImage = false
    }
    if(item.thumbnail === '') {
      newState.errorThumbnail = true
    } else {
      newState.errorThumbnail = false
    }
    if(item.contentLength === '') {
      newState.errorContentLength = true
    } else {
      newState.errorContentLength = false
    }
    return newState
  }

  render() {
    return (
      <Dialog
        fullScreen
        open={this.props.open}
        onClose={this.handleCloseButtonClick.bind(this)}
        TransitionComponent={this.handleTransition}>
        <AppBar className={this.props.classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={this.handleCloseButtonClick.bind(this)}
              aria-label="Close">
              <CloseIcon />
            </IconButton>
            <Typography
              variant="h5"
              color="inherit"
              className={this.props.classes.flex}>
              Lesson Editor
            </Typography>
            <Button
              color="inherit"
              onClick={this.handleSubmit.bind(this)}>
              Save
            </Button>
          </Toolbar>
        </AppBar>
        <LessonEditor
          lesson={this.state.item}
          onChange={this.handleChange.bind(this)}
          onDelete={this.handleDelete.bind(this)}
          errorName={this.state.errorName}
          errorImage={this.state.errorImage}
          errorThumbnail={this.state.errorThumbnail}
          errorContentLength={this.state.errorContentLength}
        />
        <Dialog
          open={this.state.errorDialog}
          onClose={this.handleErrorDialogClose.bind(this)}
        >
          <DialogContent>
            <DialogContentText>
              {this.state.errorDialogContentText}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleErrorDialogClose.bind(this)}
              color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.closeDialog}
          onClose={this.handleCloseDialogCloseNo.bind(this)}
        >
          <DialogContent>
            <DialogContentText>
              Are you sure that you want to close this screen?
              All of the changes you made will be lost.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleCloseDialogCloseNo.bind(this)}
              color="primary">
              No. I want to keep my changes.
            </Button>
            <Button
              onClick={this.handleCloseDialogCloseYes.bind(this)}
              color="secondary">
              Yes. I want to lose my changes.
            </Button>
          </DialogActions>
        </Dialog>
      </Dialog>
    );
  }
}

export default withStyles(styles)(CreateLessonForm)
