import React, { Component } from 'react'
import ListItem from '@material-ui/core/ListItem'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import AddIcon from '@material-ui/icons/Add'

export default class AddButton extends Component {
  render(){
    return(
      <ListItem>
        <Tooltip title="Add item">
          <IconButton
            aria-label="Add item"
            onClick={this.props.renderAddForm}>
            <AddIcon />
          </IconButton>
        </Tooltip>
      </ListItem>
    )
  }
}
