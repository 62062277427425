import React, {Component} from 'react'
import {
    Card,
    CardContent,
    Grid, IconButton, Table, TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    withStyles
} from '@material-ui/core'
import API from '../../API'
import InfoIcon from "@material-ui/icons/Info";

const styles = theme => ({
    itemTable: {
        margin: '0 auto',
        width: '75%',
        marginTop: theme.spacing(3)
    },
    grid: {
        paddingTop: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        width: '100%'
    },
    card: {
        height: '200px',
        overflow: 'auto'
    },
    largeCard: {
        height: '400px',
        overflow: 'auto'
    },
    pickerContainer: {
        paddingBottom: '7px'
    },
    picker: {
        width: '45px'
    },
    pickerInput: {
        padding: '1px 0 3px',
        cursor: 'pointer'
    },
    pickerFrom: {
        paddingRight: '6px'
    },
    pickerTo: {
        paddingRight: '6px',
        paddingLeft: '6px'
    },
    progressContainer: {
        width: '100%',
        position: 'absolute'
    },
    progress: {
        margin: '35vh auto',
        display: 'block'
    },
    dropdown: {
        padding: '0 23px 2px 0'
    },
    headerContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    modal_detail: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
})

class BadgesTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            badges: []
        }
    }

    componentDidMount() {
        API.getAllBadges(response => {
            this.setState({badges: response});
        });
    }

    renderBeercode(svg) {
        return <img src={svg} width={40} height={48}  alt={'Teste'}/>
    }

    renderDetailsForm(badge){
        console.log('Is Rendering: ', badge);
    }

    render() {

        return (
            <div>
                <div className={this.props.classes.itemTable}>

                    <Grid
                        container
                        className={this.props.classes.grid}
                        spacing={3}
                    >
                        <Grid item xs={12}>

                            <Card
                                className={`${
                                    this.props.classes.largeCard
                                }`}
                            >
                                <CardContent>
                                    <div className={this.props.classes.headerContainer}>
                                        <Typography variant="h5">
                                            Badges Achievements
                                        </Typography>
                                        <Tooltip title={'Show the quantity of users earned each badge and who earn.'}>
                                            <InfoIcon color="action"/>
                                        </Tooltip>
                                    </div>

                                    { /* TABLE BADGES */ }
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                {['Id', 'Name', 'Badge', ''].map((column, index) =>
                                                    <TableCell key={index}>{column}</TableCell>
                                                )}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                this.state.badges.map((badge, index) =>
                                                    <TableRow
                                                        key={index}
                                                    >
                                                        <TableCell>{badge.id}</TableCell>
                                                        <TableCell>{badge.name}</TableCell>
                                                        <TableCell>
                                                            {this.renderBeercode(badge.icon)}
                                                        </TableCell>
                                                        <TableCell>
                                                            <Tooltip title="Info">
                                                                <IconButton aria-label="Info" onClick={e => this.props.renderDetailsForm(badge)}>
                                                                    <InfoIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            }
                                        </TableBody>
                                    </Table> { /* # table badges */ }

                                </CardContent> { /* # content card badges */}
                            </Card> {/* # card badges */}

                        </Grid>  {/* # grid badges card */}
                    </Grid>  {/* # main grid */}
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(BadgesTable)
