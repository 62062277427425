import React, { Component } from 'react'
import {
  Fade, Stepper, Step,
  StepLabel, withStyles
} from '@material-ui/core'
import ItemList from '../item/item-list/index'
import CreateLessonForm from '../item/item-form/create-lesson-form'

const styles = theme => ({
  step: {
    flex: '0 1 50%'
  },
  columnContainer: {
    width: '80%',
    margin: 'auto',
    display:'flex',
    padding: '0 20px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
    borderRadius: '4px'
  },
  column: {
    flexBasis: '50%'
  },
  stepper: {
    backgroundColor: 'unset'
  }
})

const columnNames = ['PACK', 'FLIGHT']

class AddLesson extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeStep: 0,
      renderCreateLessonForm: false,
      renderFlights: false,
      openPackId: -1,
      openPackIndex: -1,
      openFlightId: -1
    }
  }

  /*************************
    PROGRESS BAR / STEPPER
  **************************/

  handleStep(step) {
    let newStep = this.state.activeStep - 1
    if(typeof step === "number") {
      newStep = step
    }
    this.setState({
      activeStep: newStep
    })
  }

  /*******
    PACKS
  ********/

  handlePackClick(pack) {
    if(this.state.renderFlights &&
       this.state.openPackId === pack.id) {
      this.setState({
        renderFlights: false,
        openPackId: -1,
        openPackIndex: -1,
        openFlightId: -1
      })
      this.handleStep(0)
    } else {
      this.setState({
        renderFlights: true,
        openPackId: pack.id,
        openPackIndex: pack.order - 1,
        openFlightId: -1
      })
      this.handleStep(1)
    }
  }

  /*********
    FLIGHTS
  **********/

  handleFlightClick(flight) {
    this.setState({
      openFlightId: flight.id
    })
    this.toggleCreateLessonForm()
  }

  /***********
    SAVE ITEM
  ************/

  handleFormSave(newItem) {
    if(!newItem) { return }
    newItem.languageId = this.props.languageId
    if(!newItem.order) {
      newItem.order = this.props.lessons.filter(lesson =>
        lesson.flightId === this.state.openFlightId
      ).length + 1
    }
    if(!newItem.flightId) {
      newItem.flightId = this.state.openFlightId
    }
    if(!newItem.difficultyLevel) {
      newItem.difficultyLevel = 1
    }
    let newModules = Object.assign([], newItem.modules)
    delete newItem.modules
    this.props.onLessonSave(newItem, savedItem => {
      newModules = newModules.map(module => {
        let id = parseInt(module.id, 10)
        if(Number.isNaN(id)) { delete module.id }
        module.lessonId = savedItem.id
        module.languageId = savedItem.languageId
        return module
      })
      this.props.onLessonModuleSave(newModules, () => {
      })
    })
  }

  /********
    FORM
  *********/

  toggleCreateLessonForm() {
    this.setState({
      renderCreateLessonForm: !this.state.renderCreateLessonForm
    })
  }

  render() {
    //Sort and filter data
    let packs = this.props.packs.filter(pack => pack.languageId === this.props.languageId)
    packs = [...packs].sort((a,b) => a.order - b.order)
    let flights = this.props.flights.filter(flight => flight.packId === this.state.openPackId)
    flights = [...flights].sort((a,b) => a.order - b.order)

    return(
      <Fade in={this.props.mountAnimation} className={this.props.className}>
        <div>
          <CreateLessonForm
            open = {this.state.renderCreateLessonForm}
            newItem = {true}
            onClose = {this.toggleCreateLessonForm.bind(this)}
            onSave = {this.handleFormSave.bind(this)} />
          {!this.state.renderCreateLessonForm &&
            <div>
              <Stepper
                className={this.props.classes.stepper}
                activeStep={this.state.activeStep}
                alternativeLabel>
                {columnNames.map(label => {
                  return (
                    <Step className={this.props.classes.step} key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
              <div className={this.props.classes.columnContainer}>
                <div className = {this.props.classes.column}>
                  <ItemList
                    mountAnimation = {true}
                    items = {packs}
                    selectedItemId = {this.state.openPackId}
                    onItemClick = {this.handlePackClick.bind(this)} />
                </div>
                <div className = {this.props.classes.column}>
                  <ItemList
                    mountAnimation = {this.state.renderFlights}
                    items = {flights}
                    selectedItemId = {this.state.openFlightId}
                    marginTop = {this.state.openPackIndex}
                    onItemClick = {this.handleFlightClick.bind(this)} />
                </div>
              </div>
            </div>
          }
        </div>
      </Fade>
    )
  }
}

export default withStyles(styles)(AddLesson)
