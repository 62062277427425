import React, { Component } from 'react'
import { Button, withStyles } from '@material-ui/core'
import uniqid from 'uniqid'

const styles = theme => ({
  button: {
    margin: theme.spacing(1)
  },
  input: {
    display: 'none'
  }
})

class ImageUpload extends Component {
  constructor(props) {
    super(props)
    this.imageInput = React.createRef()
    this.inputId = uniqid()
  }

  handleImageChange() {
    if(this.props.multiple) {
      let images = []
      const total = this.imageInput.current.files.length
      Array.from(this.imageInput.current.files).forEach(file => {
        this.getBase64(file, (result) => {
          images.push(result)
          if(images.length === total) {
            this.props.onImagesChange(images)
          }
        })
      })
    } else {
      this.getBase64(this.imageInput.current.files[0], (result) => {
        this.props.onImageChange(result)
      })
    }
    this.imageInput.current.value = ""
  }

  getBase64(file, cb) {
    if(!file) { return }
    let reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      cb(reader.result)
    }
  }

  render() {
    return(
      <div className={this.props.className}>
        <input
          accept="image/*"
          id={this.inputId}
          type="file"
          className={this.props.classes.input}
          ref={this.imageInput}
          onChange={this.handleImageChange.bind(this)}
          multiple={this.props.multiple} />
        <label htmlFor={this.inputId}>
          <Button
            variant="contained"
            color={this.props.error ? "secondary" : "primary"}
            component="span"
            className={this.props.classes.button}>
            {this.props.label}
          </Button>
        </label>
      </div>
    )
  }
}

export default withStyles(styles)(ImageUpload)
