import React, { Component } from 'react'
import { Button, Checkbox, TextField, Dialog, FormControlLabel,
  DialogActions, DialogContent, DialogContentText, 
  DialogTitle, MenuItem } from '@material-ui/core'
import FormTypes from '../form-types'

export default class AddForm extends Component {
  constructor(props){
    super()
    this.state = {
      inputs: {
        zoneId: 1
      }
    }
    this.handleInput = this.handleInput.bind(this)
    this.onClose = this.onClose.bind(this)
  }

  componentWillMount(){
    if (this.props.zoneForm){
      this.setState({
        inputs: {
          visibleInLeaderboardBool: true
        }
      })
    }
    if (this.props.languageForm){
      this.setState({
        inputs: {
          activeBool: true
        }
      })
    }
  }

  handleInput(e, input){
    const val = e.target.value;
      this.setState(prev => {
        prev.inputs[input] = val;
        return prev
      })
  }

  handleCheck(e, type){
    let newItem = Object.assign({}, this.state.inputs)
    if (type === 'visibleInLeaderboardBool'){
      newItem['visibleInLeaderboardBool'] = !this.state.inputs.visibleInLeaderboardBool
      this.setState({
        inputs: newItem
      })
    }
    if (type === 'activeBool'){
      newItem['activeBool'] = !this.state.inputs.activeBool
      this.setState({
        inputs: newItem
      })
    }
  }

  onClose (){
    this.props.handleClose('', '')
  }

  renderZoneField(zoneArr, bool, id){
    if (bool) {
      return (
        <TextField
          select
          margin="normal"
          id="zoneId"
          label="Zone Id"
          type="text"
          value={id}
          ref={this.input}
          onChange={e => this.handleInput(e, 'zoneId')}
        >
          {zoneArr.map(zone => (
              <MenuItem key={zone.id} value={zone.id}>
                {zone.name}
              </MenuItem>
          ))}
        </TextField>
      )
    } else {
      return (<div></div>)
    }
  }

  renderLanguageField(langArr, bool, id){
    if (bool) {
      return (
        <TextField
          select
          margin="normal"
          id="languageId"
          label="Lang Id"
          type="text"
          value={id}
          ref={this.input}
          onChange={e => this.handleInput(e, 'languageId')}
        >
          {langArr.map(lang => (
              <MenuItem key={lang.id} value={lang.id}>
                {lang.name}
              </MenuItem>
          ))}
        </TextField>
      )
    }
  }

  renderCheckbox(bool, type) {
    if (bool) {
      if (type === 'zoneAdd') {
        return (
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.inputs.visibleInLeaderboardBool}
                onChange={e => this.handleCheck(e, 'visibleInLeaderboardBool')}
                color="primary"
              />
            }
            label="Visible in Leaderboard"
          />
        )
      } else if (type === 'languageAdd') {
        return (
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.inputs.activeBool}
                onChange={e => this.handleCheck(e, 'activeBool')}
                color="primary"
              />
            }
            label="Active Language"
          />
        )
      }
    }
  }

  render(){
    return(
      <Dialog open={this.props.open} onClose={this.onClose}>
        <DialogTitle id="form-dialog-title">Create {this.props.table}</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Create a {this.props.table}
                    </DialogContentText>
                    {this.props.formFields.map((field, index) => {
                      return (
                        <TextField
                          margin="dense"
                          id={field}
                          key={index}
                          label={field}
                          type="text"
                          onChange={e => this.handleInput(e, field)}
                          fullWidth/>)
                    })}
                    {this.renderLanguageField(this.props.languages, this.props.renderLanguage, this.state.inputs.languageId)}
                    {this.renderZoneField(this.props.zones, this.props.renderZone, this.state.inputs.zoneId)}
                    {this.renderCheckbox(this.props.renderCheckbox, this.props.tableName)}
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={e => this.props.handleClose()} color="primary">
                      Cancel
                    </Button>
                    <Button onClick={e => this.props.handleSubmit(e, {...this.state.inputs}, 'POST', FormTypes.HEADERS, this.props.url)} color="primary">
                      Save
                    </Button>
                  </DialogActions>
      </Dialog>
    )
  }
}
