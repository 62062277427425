import React, { Component } from 'react'
import { withStyles, /*CardMedia,*/ CardContent, Typography, Button } from '@material-ui/core'
import API from '../../../../../API';

const styles = theme => ({
  cardMedia: {
    height: '250px'
  }
})

const getContentObj = content => {
  /*
  contentObj = {
    fileName: 'name of file',
    fileType: 'MIME type',
    fileSize: 1024, //in bytes
    // thumbnail: 'base64' // TODO, if image, or if inputed, get small size thumbnail as base64 for preview
  }
  */
  try {
    if(!!content.fileName || (typeof content === 'string' && content.includes('"fileName":'))){
      let contentObj = typeof content === 'string' ? JSON.parse(content) : content;
      // if(!contentObj.thumbnail.includes("base64")){
      //   contentObj.thumbnail = false;
      // }
      return contentObj;
    }
    return false;
  } catch(err) {
    console.error(err);
    return false;
  }
}


class FileDownloadModule extends Component {
  handleDownloadClick(fileName){
    console.log(fileName);
    API.downloadFile('lessonModuleUploads', fileName);
  }

  render() {
    const contentObj = getContentObj(this.props.module.content);
    // console.log(isLink, linkId, this.props.module);
    return(
      <div>
        { contentObj &&
          <div>
            <CardContent>
              <Button onClick={this.handleDownloadClick.bind(this, contentObj.fileName)}>
                Download: {contentObj.fileName}
              </Button>
            </CardContent>
            {/* {contentObj.thumbnail && // TODO, thumbnail for preview
              <CardMedia
                className={this.props.classes.cardMedia}
                image={contentObj.thumbnail} />
            } */}
            {this.props.module.caption &&
              <CardContent>
                {this.props.module.caption}
              </CardContent>
            }
          </div>
        }
        { !contentObj &&
          <Typography component="p">
            {this.props.module.content}
          </Typography>
        }
      </div>
    )
  }
}

export default withStyles(styles)(FileDownloadModule)
