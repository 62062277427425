import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { DragSource, DropTarget } from 'react-dnd'
import ModuleTypes from './module-types'
import { findDOMNode } from 'react-dom'
import {
  withStyles, Card, CardContent,
  IconButton
} from '@material-ui/core'
import {
  Delete as DeleteIcon,
  DragIndicator as DragIndicatorIcon,
  Edit as EditIcon
} from '@material-ui/icons'
import {
  TextModule, ImageModule,
  CarouselModule, AnswerModule,
  QuestionModule, EmbedVideoModule, FileDownloadModule
} from './'

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  },
  moduleContainer: {
    alignSelf: 'flex-start',
    width: '100%'
  },
  dragIndicatorIcon: {
    color: 'rgba(0, 0, 0, 0.54) !important',
    cursor: 'grab !important',
    pointerEvents: 'all !important'
  }
})

const moduleSource = {
  beginDrag(props) {
    return {
      index: props.index,
      containerId: props.containerId,
      contents: props.module
    }
  },
  endDrag(props, monitor, component) {
    const module = monitor.getItem()
    const container = monitor.getDropResult()
    if (container &&
        container.containerId !== module.containerId &&
        container.enableDrop &&
        props.onRemoveModule) {
      props.onRemoveModule(module.index)
    }
  }
}

/******
  Handles sorting between modules in a container.
  Taken from below source:
  http://rafaelquintanilha.com/sortable-targets-with-react-dnd/
  https://github.com/rafaelquintanilha/experiments/tree/master/sortable-target
*****/
const moduleTarget = {
  hover(props, monitor, component) {
    if(!props.enableSort) { return }

    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;
    const sourceContainerId = monitor.getItem().containerId;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Determine rectangle on screen
    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();

    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

    // Determine mouse position
    const clientOffset = monitor.getClientOffset();

    // Get pixels to the top
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;

    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%

    // Dragging downwards
    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return;
    }

    // Dragging upwards
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return;
    }

    // Time to actually perform the action
    if ( props.containerId === sourceContainerId ) {
      if(props.onMoveModule) {
        props.onMoveModule(dragIndex, hoverIndex)
      }

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      monitor.getItem().index = hoverIndex;
    }
  }
};

const collectSource = (connect, monitor) => {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  }
}

const collectTarget = (connect, monitor) => {
  return {
    connectDropTarget: connect.dropTarget()
  }
}

class BaseModule extends Component {
  handleRemove(e) {
    this.props.onRemoveModule(this.props.module)
  }

  toggleDrawer() {
    if(!this.props.allowEdits) { return }
    this.props.onEditModule(this.props.module)
  }

  render(){
    const { isDragging, connectDragSource, connectDropTarget } = this.props
    const module = this.props.module
    let moduleContainer
    switch(module.contentTypeId) {
      case ModuleTypes.TEXT:
        moduleContainer = <TextModule module={module} />
        break
      case ModuleTypes.IMAGE:
        moduleContainer = <ImageModule module={module} />
        break
      case ModuleTypes.CAROUSEL:
        moduleContainer = <CarouselModule module={module} />
        break
      case ModuleTypes.ANSWER:
        moduleContainer = <AnswerModule module={module} />
        break
      case ModuleTypes.QUESTION:
        moduleContainer = <QuestionModule module={module} />
        break
      case ModuleTypes.EMBED_VIDEO:
        moduleContainer = <EmbedVideoModule module={module} />
        break
      case ModuleTypes.FILE_DOWNLOAD:
        moduleContainer = <FileDownloadModule module={module} />
        break
      default:
        moduleContainer = <div></div>
        break
    }

    return connectDragSource(connectDropTarget(
      <div>
        <Card
          className={this.props.classes.container}
          style={{ 
            opacity: isDragging ? 0 : 1,
            cursor: !this.props.allowEdits && 'grab',
            backgroundColor: this.props.selected ? 'rgba(0, 0, 0, 0.14)' : 'initial'
          }}>
          {this.props.allowEdits &&
            <div>
              <IconButton
                className={this.props.classes.dragIndicatorIcon}
                style={{ display: this.props.enableSort ? 'unset' : 'none' }}
                disabled>
                <DragIndicatorIcon />
              </IconButton>
              <IconButton
                onClick={this.toggleDrawer.bind(this)}>
                <EditIcon />
              </IconButton>
              <IconButton
                onClick={this.handleRemove.bind(this)}>
                <DeleteIcon />
              </IconButton>
            </div>
          }
          <CardContent className={this.props.classes.moduleContainer}>
            {moduleContainer}
          </CardContent>
        </Card>
      </div>
    ))
  }
}

BaseModule.propTypes = {
  connectDragSource: PropTypes.func.isRequired,
  connectDropTarget: PropTypes.func.isRequired,
  isDragging: PropTypes.bool.isRequired
}

export default withStyles(styles)(
  DragSource(ModuleTypes.BASE, moduleSource, collectSource)(
    DropTarget(ModuleTypes.BASE, moduleTarget, collectTarget)(
      BaseModule
    )
  )
)
