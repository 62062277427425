import React, { Component } from 'react'
import {
  withStyles, Card, IconButton,
  CardMedia, CardContent, Grid,
  TextField, FormControlLabel,
  Switch, FormControl, InputLabel,
  Select, MenuItem, Typography,
  Chip, Checkbox, Input, ListItemText
} from '@material-ui/core'
import {
  Delete as DeleteIcon,
  Wallpaper as WallpaperIcon
} from '@material-ui/icons'
import ImageUpload from '../../../utils/image-upload'
import update from 'immutability-helper'
import API from '../../../../API'
import {
  DataForm,
  RangeTimeForm,
  RangeDayForm,
  CountStreakForm,
  CountRepeatForm,
  BadgeCollectionForm,
  RangeDateForm,
  DateForm,
  TotalBeercoinsForm
} from './badge-type-form'
import ItemList from '../item-list'
import CreateBadgeTranslationForm from '../item-form/create-badge-translation-form'
import DeleteItemForm from '../item-form/delete-item-form'
import uniqid from 'uniqid'

const styles = theme => ({
  wrapper: {
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  wrapperColumn: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column'
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  },
  image: {
    height: '150px',
    backgroundSize: 'contain',
    width: '100%'
  },
  imagePlaceholder: {
    height: '150px',
    width: '150px',
    margin: '0 auto',
    display: 'block'
  },
  imageUpload: {
    textAlign: 'center'
  },
  translations: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    color: theme.palette.text.secondary,
    lineHeight: 0,
    letterSpacing: 'normal',
    fontSize: theme.typography.fontSize * 0.85
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing(0.25),
  },
})

const nameMaxCharCount = 50
const descriptionMaxCharCount = 150

class BadgeEditor extends Component {
  state = {
    types: [],
    languages: [],
    flights: [],
    shapes: ["hexagon", "oval"],
    userActivityActions: [],
    nameCharCount: this.props.badge.name.length + "/"
                    + nameMaxCharCount,
    descriptionCharCount: this.props.badge.description.length
                        + "/" + descriptionMaxCharCount,
    renderCreateTranslationForm: false,
    formItem: null,
    renderDeleteForm: false,
    timeStart: null,
    timeEnd: null,
    dateStart: null,
    dateEnd: null,
    date: null
  }

  componentDidMount() {
    API.getAllBadgeTypes(types => {
      types.sort((a,b) => {
        if(a.name < b.name) { return -1 }
        if(a.name > b.name) { return 1 }
        return 0
      })
      this.setState({ types })
    })
    API.getAllLanguages(response => {
      const languages = response.languages.concat()
      languages.sort((a,b) => {
        if(a.name < b.name) { return -1 }
        if(a.name > b.name) { return 1 }
        return 0
      })
      this.setState({ languages })
    })
    API.getAllFlights(flights => {
      if(!!flights){ // if null return, then skip sort
        flights.sort((a,b) => {
          if(a.name < b.name) { return -1 }
          if(a.name > b.name) { return 1 }
          return 0
        })
        this.setState({ flights })
      }
    })
    API.getAllUserActivityActions(userActivityActions => {
      userActivityActions.sort((a,b) => {
        if(a.description < b.description) { return -1 }
        if(a.description > b.description) { return 1 }
        return 0
      })
      this.setState({ userActivityActions })
    })

    //Convert time strings to date objects.
    let timeStart = this.props.badge.timeStart
    let timeEnd = this.props.badge.timeEnd
    if(timeStart) {
      const newTime = timeStart.split(":")
      timeStart = new Date()
      timeStart.setHours(newTime[0], newTime[1], newTime[2])
    }
    if(timeEnd) {
      const newTime = timeEnd.split(":")
      timeEnd = new Date()
      timeEnd.setHours(newTime[0], newTime[1], newTime[2])
    }
    this.setState({ timeStart, timeEnd })

    //Convert date strings to date objects.
    let dateStart = this.props.badge.dateStart
    let dateEnd = this.props.badge.dateEnd
    let date = this.props.badge.date
    if(dateStart) {
      const newDate = dateStart.split("-")
      dateStart = new Date()
      dateStart.setFullYear(
        parseInt(newDate[0], 10),
        parseInt(newDate[1], 10) - 1,
        parseInt(newDate[2], 10)
      )
    }
    if(dateEnd) {
      const newDate = dateEnd.split("-")
      dateEnd = new Date()
      dateEnd.setFullYear(
        parseInt(newDate[0], 10),
        parseInt(newDate[1], 10) - 1,
        parseInt(newDate[2], 10)
      )
    }
    if(date) {
      const newDate = date.split("-")
      date = new Date()
      date.setFullYear(
        parseInt(newDate[0], 10),
        parseInt(newDate[1], 10) - 1,
        parseInt(newDate[2].split("T")[0], 10)
      )
    }
    this.setState({ dateStart, dateEnd, date })
  }

  handleNameChange(input) {
    let value = input.target.value
    if(value.length > nameMaxCharCount) {
      value = value.substring(0, nameMaxCharCount)
    }
    const newBadge = update(this.props.badge, {
      name: {
        $set: value
      }
    })
    this.props.onChange(newBadge)

    this.setState(
      update(this.state, {
        nameCharCount: {
          $set: value.length + "/"
                + nameMaxCharCount
        }
      })
    )
  }

  handleDescriptionChange(input) {
    let value = input.target.value
    if(value.length > descriptionMaxCharCount) {
      value = value.substring(0, descriptionMaxCharCount)
    }
    const newBadge = update(this.props.badge, {
      description: {
        $set: value
      }
    })
    this.props.onChange(newBadge)

    this.setState(
      update(this.state, {
        descriptionCharCount: {
          $set: value.length + "/"
                + descriptionMaxCharCount
        }
      })
    )
  }

  handleUserActivityActionChange(input) {
    let valueArray = input.target.value
    valueArray = valueArray.map(value =>
      this.state.userActivityActions.find(action =>
        value === action.id
      )
    )
    const newBadge = update(this.props.badge, {
      activityActions: {
        $set: valueArray
      }
    })
    this.props.onChange(newBadge)
  }

  handleParentChange(input) {
    const value = input.target.checked
    const newBadge = update(this.props.badge, {
      parentBool: {
        $set: value
      }
    })
    this.props.onChange(newBadge)
  }

  handleSecretChange(input) {
    const value = input.target.checked
    const newBadge = update(this.props.badge, {
      secretBool: {
        $set: value
      }
    })
    this.props.onChange(newBadge)
  }

  handleRepeatableChange(input) {
    const value = input.target.checked
    const newBadge = update(this.props.badge, {
      repeatableBool: {
        $set: value
      }
    })
    this.props.onChange(newBadge)
  }

  handleTypeChange(input) {
    const value = input.target.value
    const newBadge = update(this.props.badge, {
      badgeType: {
        $set: value
      }
    })
    this.props.onChange(newBadge)
  }

  handleIconChange(value) {
    const newBadge = update(this.props.badge, {
      icon: {
        $set: typeof value === 'string' ? value : ''
      }
    })
    this.props.onChange(newBadge)
  }

  handleShapeChange(input) {
    const value = input.target.value
    const newBadge = update(this.props.badge, {
      shape: {
        $set: value
      }
    })
    this.props.onChange(newBadge)
  }

  handleDataChange(type, input) {
    const value = input.target.value
    let data = { }
    if(type === "flights") {
      data = {
        "flightId": {
          "in": value
        }
      }
    } else if(type === "badges") {
      data = {
        "badgeIds": value
      }
    }
    const newBadge = update(this.props.badge, {
      data: {
        $set: data
      }
    })
    this.props.onChange(newBadge)
  }

  handleBeercoinChange(input) {
    const value = input.target.value
    const newBadge = update(this.props.badge, {
      badgeBeercoinEarnAmount: {
        $set: value === '' ? null : value
      }
    })
    this.props.onChange(newBadge)
  }

  handleTimeChange(type, time) {
    this.setState({ [type]: time })
    if(time) {
      time = "" + (time.getHours() < 10 ? "0" : "") +
        time.getHours() + ":" +
        (time.getMinutes() < 10 ? "0" : "") +
        time.getMinutes() + ":00"
    }
    const newBadge = update(this.props.badge, {
      [type]: {
        $set: time
      }
    })
    this.props.onChange(newBadge)
  }

  handleDayChange(type, day) {
    const newBadge = update(this.props.badge, {
      [type]: {
        $set: day === '' ? null : day
      }
    })
    this.props.onChange(newBadge)
  }

  handleStreakCountChange(input) {
    const value = input.target.value
    const newBadge = update(this.props.badge, {
      countTrigger: {
        $set: value === '' ? null : value
      }
    })
    this.props.onChange(newBadge)
  }

  handleStreakIntervalChange(input) {
    const value = input.target.value
    const newBadge = update(this.props.badge, {
      countInterval: {
        $set: value === '' ? null : value
      }
    })
    this.props.onChange(newBadge)
  }

  handleRepeatCountChange(input) {
    const value = input.target.value
    const newBadge = update(this.props.badge, {
      countTrigger: {
        $set: value === '' ? null : value
      }
    })
    this.props.onChange(newBadge)
  }

  handleTotalBeercoinsAmountChange(input) {
    const value = input.target.value
    let data = { }
    // const newBadge = update(this.props.badge, {
    //   beercoinsAmountTrigger: {
    //     $set: value === '' ? null : value
    //   }
    // })
    // this.props.onChange(newBadge)
    data = {
      beercoinsAmountTrigger: value === '' ? null : value
    }
    
    const newBadge = update(this.props.badge, {
      data: {
        $set: data
      }
    })
    this.props.onChange(newBadge)
  }

  handleDateChange(type, date) {
    this.setState({ [type]: date })
    if(date) {
      date = "" + date.getFullYear() + "-" +
        (date.getMonth() + 1 < 10 ? "0" : "") +
        (date.getMonth() + 1) + "-" +
        (date.getDate() < 10 ? "0" : "") +
        date.getDate()
    }
    const newBadge = update(this.props.badge, {
      [type]: {
        $set: date
      }
    })
    this.props.onChange(newBadge)
  }

  handleTranslationClick(translation) {
    this.handleTranslationEdit(translation)
  }

  handleTranslationEdit(translation) {
    this.toggleCreateTranslationForm(translation)
  }

  handleTranslationAdd() {
    this.toggleCreateTranslationForm()
  }

  handleTranslationDelete(translation) {
    this.toggleDeleteForm(translation)
  }

  handleFormSave(translation) {
    let newBadge
    if(!translation.id || translation.id === -1) {
      translation.id = uniqid()
      newBadge = update(this.props.badge, {
        translations: {
          $push: [ translation ]
        }
      })
    } else {
      const index = this.props.badge.translations
        .findIndex(oldTranslation =>
          oldTranslation.id === translation.id
        )
      newBadge = update(this.props.badge, {
        translations: {
          $splice: [
            [index, 1, translation]
          ]
        }
      })
    }
    this.props.onChange(newBadge)
  }

  handleFormDelete() {
    const index = this.props.badge.translations.findIndex(translation =>
      translation.id === this.state.formItem.id
    )
    const newBadge = update(this.props.badge, {
      translations: {
        $splice: [
          [index, 1]
        ]
      }
    })
    this.props.onDelete(newBadge, this.state.formItem)
  }

  toggleCreateTranslationForm(formItem) {
    this.setState({
      renderCreateTranslationForm:
        !this.state.renderCreateTranslationForm,
      formItem
    })
  }

  toggleDeleteForm(formItem) {
    this.setState({
      renderDeleteForm: !this.state.renderDeleteForm,
      formItem
    })
  }

  render() {
    //Parse data attribute for
    //particular information,
    //such as selected flights
    //or badges.
    let selectedFlights = []
    let selectedBadges= []
    let beercoinsAmountTrigger = ''
    const data = this.props.badge.data
    if(data) {
      if(this.props.badge.badgeType === "data") {
        if(data.flightId && data.flightId.in) {
          selectedFlights = data.flightId.in
        }
      } else if(this.props.badge.badgeType === "badge-collection") {
        if(data.badgeIds) {
          selectedBadges = data.badgeIds
        }
      } else if(this.props.badge.badgeType === "total-beercoin"){
        if(data.beercoinsAmountTrigger){
          beercoinsAmountTrigger = data.beercoinsAmountTrigger;
        }
      }
    }

    //Add language name to translations
    //array for user convenience.
    let translations = this.props.badge.translations
    if(this.state.languages.length > 0) {
      translations = translations.map(translation => {
        const language = this.state.languages.find(language =>
          language.id === translation.languageId
        )
        translation.languageName = language.name
        return translation
      })
    }

    return(
      <div className={this.props.classes.wrapper}>
        <CreateBadgeTranslationForm
          open={this.state.renderCreateTranslationForm}
          item={this.state.formItem}
          languages={this.state.languages}
          onClose={this.toggleCreateTranslationForm.bind(this)}
          onSave={this.handleFormSave.bind(this)}
        />
        <DeleteItemForm
          open={this.state.renderDeleteForm}
          item={this.state.formItem}
          onClose={this.toggleDeleteForm.bind(this)}
          onDelete={this.handleFormDelete.bind(this)}
        />
        <Grid container justify="space-between">
          <Grid
            item
            xs={4}
            className={this.props.classes.wrapperColumn}
          >
            <Card>
              { this.props.badge.icon ? (
                <div className={this.props.classes.imageContainer}>
                  <IconButton
                    onClick={this.handleIconChange.bind(this)}>
                    <DeleteIcon />
                  </IconButton>
                  <CardMedia
                    className={this.props.classes.image}
                    image={this.props.badge.icon} />
                </div>
              ) : (
                <WallpaperIcon
                  className={this.props.classes.imagePlaceholder}
                  color={this.props.errorIcon ? "secondary" : "primary"} />
              )}
              <CardContent className={this.props.classes.imageUpload}>
                <ImageUpload
                  label="Upload Badge Icon"
                  error={this.props.errorIcon}
                  onImageChange={this.handleIconChange.bind(this)} />
              </CardContent>
            </Card>
            <FormControl
              margin="normal"
              required
              error={this.props.errorShape}
            >
              <InputLabel>Badge Shape</InputLabel>
              <Select
                value={this.props.badge.shape}
                onChange={this.handleShapeChange.bind(this)}
              >
                { this.state.shapes.map(item =>
                  <MenuItem
                    key={item}
                    value={item}
                  >
                    {item[0].toUpperCase() + item.slice(1)}
                  </MenuItem>
                  )
                }
              </Select>
            </FormControl>
            <FormControlLabel
              control={
                <Switch
                  checked={this.props.badge.parentBool}
                  onChange={this.handleParentChange.bind(this)}
                  color="primary" />
              }
              label="Is this a parent badge?" />
            <FormControlLabel
              control={
                <Switch
                  checked={this.props.badge.secretBool}
                  onChange={this.handleSecretChange.bind(this)}
                  color="primary" />
              }
              label="Is this a secret badge?" />
            <FormControlLabel
              control={
                <Switch
                  checked={this.props.badge.repeatableBool}
                  onChange={this.handleRepeatableChange.bind(this)}
                  color="primary" />
              }
              label="Is this a repeatable badge?" />
          </Grid>
          <Grid
            item
            xs={4}
            className={this.props.classes.wrapperColumn}
          >
            <TextField
              required
              error={this.props.errorName}
              id="name"
              label="Badge Name"
              margin="normal"
              value={this.props.badge.name}
              helperText={this.state.nameCharCount}
              onChange={this.handleNameChange.bind(this)} />
            <TextField
              required
              multiline
              error={this.props.errorDescription}
              id="description"
              label="Badge Description"
              margin="normal"
              value={this.props.badge.description}
              helperText={this.state.descriptionCharCount}
              onChange={this.handleDescriptionChange.bind(this)} />
            <FormControl
              margin="normal"
              required
              error={this.props.errorUserActivityAction}
            >
              <InputLabel>Associated User Actions</InputLabel>
              { this.state.userActivityActions.length > 0 &&
                <Select
                  multiple
                  value={this.props.badge.activityActions.map(action =>
                    action.id
                  )}
                  onChange={this.handleUserActivityActionChange.bind(this)}
                  input={<Input id="userActivityActions" />}
                  renderValue={selected => {
                    const selectedActions = []
                    for(const id of selected) {
                      const action = this.state.userActivityActions.find(action =>
                        action.id === id
                      )
                      selectedActions.push(action)
                    }
                    return (
                      <div className={this.props.classes.chips}>
                        {selectedActions.map(action => (
                          <Chip
                            key={action.id}
                            label={action.description}
                            className={this.props.classes.chip}
                            color="primary"
                          />
                        ))}
                      </div>
                    )
                  }}
                >
                  { this.state.userActivityActions.map(action =>
                      <MenuItem
                        key={action.id}
                        value={action.id}
                      >
                        <Checkbox
                          checked={
                            this.props.badge.activityActions
                              .map(action => action.id)
                              .indexOf(action.id) > -1
                          }
                        />
                        <ListItemText primary={action.description} />
                      </MenuItem>
                    )
                  }
                </Select>
              }
            </FormControl>
            <Typography
              variant="body1"
              className={this.props.classes.translations}
            >
              Translations
            </Typography>
            <Card>
              <ItemList
                mountAnimation = {true}
                items = {translations}
                itemAttributeForLabel = {"languageName"}
                allowAdding = {true}
                addItemLabel = {"Add a Translation"}
                allowEditing = {true}
                allowDeleting = {true}
                onItemClick = {this.handleTranslationClick.bind(this)}
                onItemEdit = {this.handleTranslationEdit.bind(this)}
                onItemAdd = {this.handleTranslationAdd.bind(this)}
                onItemDelete = {this.handleTranslationDelete.bind(this)}
              />
            </Card>
          </Grid>
          <Grid
            item
            xs={4}
            className={this.props.classes.wrapperColumn}
          >
            <FormControl
              margin="normal"
              required
              error={this.props.errorType}
            >
              <InputLabel>Badge Type</InputLabel>
              <Select
                value={this.props.badge.badgeType}
                onChange={this.handleTypeChange.bind(this)}
              >
                { this.state.types.map(item =>
                  <MenuItem
                    key={item.id}
                    value={item.id}
                  >
                    {item.name}
                  </MenuItem>
                  )
                }
              </Select>
            </FormControl>
            { this.props.badge.badgeType === "data" &&
              <DataForm
                languages={this.state.languages}
                flights={this.state.flights}
                selectedFlights={selectedFlights}
                onDataChange={data => { this.handleDataChange("flights", data) }}
                beercoinEarnAmount={this.props.badge.badgeBeercoinEarnAmount}
                onBeercoinChange={this.handleBeercoinChange.bind(this)}
              />
            }
            { this.props.badge.badgeType === "range-time" &&
              <RangeTimeForm
                timeStart={this.state.timeStart}
                timeEnd={this.state.timeEnd}
                onTimeChange={this.handleTimeChange.bind(this)}
                beercoinEarnAmount={this.props.badge.badgeBeercoinEarnAmount}
                onBeercoinChange={this.handleBeercoinChange.bind(this)}
              />
            }
            { this.props.badge.badgeType === "range-day" &&
              <RangeDayForm
                dayStart={this.props.badge.dayStart}
                dayEnd={this.props.badge.dayEnd}
                onDayChange={this.handleDayChange.bind(this)}
                beercoinEarnAmount={this.props.badge.badgeBeercoinEarnAmount}
                onBeercoinChange={this.handleBeercoinChange.bind(this)}
              />
            }
            { this.props.badge.badgeType === "count-streak" &&
              <CountStreakForm
                streakCount={this.props.badge.countTrigger}
                streakInterval={this.props.badge.countInterval}
                onStreakCountChange={this.handleStreakCountChange.bind(this)}
                onStreakIntervalChange={this.handleStreakIntervalChange.bind(this)}
                beercoinEarnAmount={this.props.badge.badgeBeercoinEarnAmount}
                onBeercoinChange={this.handleBeercoinChange.bind(this)}
              />
            }
            { this.props.badge.badgeType === "count-repeat" &&
              <CountRepeatForm
                repeatCount={this.props.badge.countTrigger}
                onRepeatCountChange={this.handleRepeatCountChange.bind(this)}
                beercoinEarnAmount={this.props.badge.badgeBeercoinEarnAmount}
                onBeercoinChange={this.handleBeercoinChange.bind(this)}
              />
            }
            { this.props.badge.badgeType === "badge-collection" &&
              <BadgeCollectionForm
                badges={this.props.badges}
                selectedBadges={selectedBadges}
                onDataChange={data => { this.handleDataChange("badges", data) }}
                beercoinEarnAmount={this.props.badge.badgeBeercoinEarnAmount}
                onBeercoinChange={this.handleBeercoinChange.bind(this)}
              />
            }
            { this.props.badge.badgeType === "range-date" &&
              <RangeDateForm
                dateStart={this.state.dateStart}
                dateEnd={this.state.dateEnd}
                onDateChange={this.handleDateChange.bind(this)}
                beercoinEarnAmount={this.props.badge.badgeBeercoinEarnAmount}
                onBeercoinChange={this.handleBeercoinChange.bind(this)}
              />
            }
            { this.props.badge.badgeType === "date" &&
              <DateForm
                date={this.state.date}
                onDateChange={this.handleDateChange.bind(this)}
                beercoinEarnAmount={this.props.badge.badgeBeercoinEarnAmount}
                onBeercoinChange={this.handleBeercoinChange.bind(this)}
              />
            }
            { this.props.badge.badgeType === "total-beercoin" &&
              <TotalBeercoinsForm
                totalBeercoins={beercoinsAmountTrigger}
                onTotalBeercoinsChange={this.handleTotalBeercoinsAmountChange.bind(this)}
                beercoinEarnAmount={this.props.badge.badgeBeercoinEarnAmount}
                onBeercoinChange={this.handleBeercoinChange.bind(this)}
              />
            }
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default withStyles(styles)(BadgeEditor)
