import React, { Component } from 'react'
import {
  withStyles, Grid,
  Typography, TextField,
  FormControl, Select,
  MenuItem
} from '@material-ui/core'

const DAYS = [
  { id: "1", name: "Monday" },
  { id: "2", name: "Tuesday" },
  { id: "3", name: "Wednesday" },
  { id: "4", name: "Thursday" },
  { id: "5", name: "Friday" },
  { id: "6", name: "Saturday" },
  { id: "7", name: "Sunday" }
]

const styles = theme => ({
  wrapper: {
    paddingTop: theme.spacing(4)
  },
  dayRange: {
    marginTop: theme.spacing(1),
    color: theme.palette.text.secondary,
    lineHeight: 0,
    letterSpacing: 'normal',
    fontSize: theme.typography.fontSize * 0.85
  },
  picker: {
    width: 105
  },
  pickerInput: {
    padding: '2px 0 8px'
  },
  pickerFrom: {
    paddingRight: 6
  },
  pickerTo: {
    paddingRight: 6,
    paddingLeft: 6
  }
})

class RangeDayForm extends Component {
  render() {
    return(
      <Grid
        container
        direction="column"
        spacing={2}
        className={this.props.classes.wrapper}
      >
        <Grid item>
          <Typography
            variant="body1"
            className={this.props.classes.dayRange}
          >
            Choose Day Range
          </Typography>
        </Grid>
        <Grid item>
          <Grid container>
            <Grid item>
              <Typography
                variant="body1"
                className={this.props.classes.pickerFrom}
              >
                From
              </Typography>
            </Grid>
            <Grid item>
              <FormControl
                margin="none"
              >
                <Select
                  className={this.props.classes.picker}
                  value={this.props.dayStart}
                  onChange={e => {
                    this.props.onDayChange("dayStart", e.target.value)
                  }}
                  inputProps={{
                    classes: {
                      select: this.props.classes.pickerInput
                    }
                  }}
                >
                  { DAYS.map(item =>
                    <MenuItem
                      key={item.id}
                      value={item.id}
                    >
                      {item.name}
                    </MenuItem>
                    )
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <Typography
                variant="body1"
                className={this.props.classes.pickerTo}
              >
                to
              </Typography>
            </Grid>
            <Grid item>
              <FormControl
                margin="none"
              >
                <Select
                  className={this.props.classes.picker}
                  value={this.props.dayEnd}
                  onChange={e => {
                    this.props.onDayChange("dayEnd", e.target.value)
                  }}
                  inputProps={{
                    classes: {
                      select: this.props.classes.pickerInput
                    }
                  }}
                >
                  {
                    DAYS.filter(item =>
                      item.id > (this.props.dayStart || 0)
                    ).map(item =>
                      <MenuItem
                        key={item.id}
                        value={item.id}
                      >
                        {item.name}
                      </MenuItem>
                    )
                  }
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <TextField
            label="Beercoin Amount Earned By User"
            margin="normal"
            fullWidth
            value={this.props.beercoinEarnAmount || ''}
            onChange={this.props.onBeercoinChange} />
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(RangeDayForm)
