import React, { Component } from 'react'
import {
  Button, Dialog,
  DialogActions, DialogContent,
  DialogContentText, DialogTitle } from '@material-ui/core'

export default class DeleteForm extends Component {
  state = {
    item: this.props.item || {}
  }

  componentDidUpdate(prevProps) {
    if(this.props.item && this.props.item !== prevProps.item) {
      this.setState({
        item: this.props.item
      })
    }
  }

  handleSubmit(e){
    e.preventDefault()
    this.props.onDelete(
      `${this.props.url}/${this.state.item.id}`,
      this.state.item
    )
    this.props.onClose()
  }

  handleClose(){
    this.props.onClose('','')
  }

  render(){
    let itemName = "this item"
    if(this.state.item) {
      if(this.state.item.name) {
        itemName = this.state.item.name
      } else if(this.state.item.question) {
        itemName = "\"" + this.state.item.question + "\""
      }
    }

    return(
      <Dialog open={this.props.open} onClose={this.handleClose.bind(this)}>
        <DialogTitle>
          Delete
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure that you want
            to delete {itemName}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.handleClose.bind(this)}
            color="primary">
            Cancel
          </Button>
          <Button
            onClick={this.handleSubmit.bind(this)}
            color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}
