import React, { Component } from 'react'
import {
  Grid, Button, withStyles
} from '@material-ui/core'
import { Add as AddIcon } from '@material-ui/icons'
import Item from './item'

const styles = theme => ({
  button: {
    margin: theme.spacing(2),
    position: 'fixed',
    bottom: theme.spacing(4),
    right: theme.spacing(4)
  }
})

class ItemGrid extends Component {
  render() {
    return(
      <div>
      <Grid container spacing={16}>
        {this.props.items.map((item, index) => {
          return(
            <Grid key={item.id} item>
              <Item
                item = {item}
                index = {index}
                allowEditing = {this.props.allowEditing}
                allowDeleting = {this.props.allowDeleting}
                allowMoving = {this.props.allowMoving}
                onItemClick = {this.props.onItemClick}
                onItemEdit = {this.props.onItemEdit}
                onItemDelete = {this.props.onItemDelete}
                onItemMove = {this.props.onItemMove}
                onItemMoveFinished = {this.props.onItemMoveFinished}
                mountAnimation = {this.props.mountAnimation}
                animationDuration = {this.props.animationDuration}
                transitionDelay = {index}
              />
            </Grid>
          )
        })}
      </Grid>
      {this.props.allowAdding &&
        <Button
          variant="fab"
          color="primary"
          onClick={this.props.onItemAdd}
          className={this.props.classes.button}>
          <AddIcon />
        </Button>
      }
      </div>
    )
  }
}

export default withStyles(styles)(ItemGrid)
