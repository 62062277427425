import React, { Component } from 'react'
import {
  Button, Dialog,
  DialogActions, DialogContent,
  DialogContentText, DialogTitle } from '@material-ui/core'

export default class DeleteForm extends Component {
  state = {
    item: this.props.item || {}
  }

  componentDidUpdate(prevProps) {
    if(this.props.item && this.props.item !== prevProps.item) {
      this.setState({
        item: this.props.item
      })
    }
  }

  handleSubmit(e){
    e.preventDefault()
    this.props.onDelete(this.state.item.id)
    this.props.onClose()
  }

  render(){
    let itemName = "this item"
    if(this.state.item) {
      if(this.state.item.title) {
        itemName = this.state.item.title
      } else if(this.state.item.question) {
        itemName = "\"" + this.state.item.question + "\""
      }
    }

    return(
      <Dialog open={this.props.open} onClose={this.props.onClose}>
        <DialogTitle>
          Delete
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure that you want
            to delete {itemName}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.props.onClose}
            color="primary">
            Cancel
          </Button>
          <Button
            onClick={this.handleSubmit.bind(this)}
            color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}
