import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { DropTarget } from 'react-dnd'
import ModuleTypes from './module/module-types'
import BaseModule from './module/base-module'
import uniqid from 'uniqid'
import { withStyles, Grid, Typography } from '@material-ui/core'
import { blue } from '@material-ui/core/colors'

const styles = theme => ({
  containerActive: {
    backgroundColor: blue[500],
    paddingBottom: '100px',
    transition: 'background-color 0.4s'
  },
  containerHover: {
    backgroundColor: '#F09723',
    paddingBottom: '100px',
    transition: 'background-color 0.4s'
  },
  placeholderText: {
    marginTop: '30px',
    marginBottom: '30px'
  }
})

const moduleTarget = {
  drop(props, monitor) {
    const module = monitor.getItem()
    if ( props.id !== module.containerId && props.enableDrop) {
      let newModule = Object.assign({}, module.contents)
      newModule.id = uniqid()
      props.onAddModule(newModule)
    }
    return {
      containerId: props.id,
      enableDrop: props.enableDrop
    }
  }
}

const collect = (connect, monitor) => {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop()
  }
}

class Container extends Component {
  render(){
    const { connectDropTarget, isOver, canDrop } = this.props
    const isActive = canDrop && this.props.enableDrop
    const isHover = isOver && this.props.enableDrop
    let containerClassName = ""
    if(isHover) {
      containerClassName = this.props.classes.containerHover
    } else if(isActive) {
      containerClassName = this.props.classes.containerActive
    }

    let modules = <div></div>
    let selectedId = -1
    if(this.props.selectedModule) {
      selectedId = this.props.selectedModule.id
    }
    if(this.props.modules) {
      modules = this.props.modules.map((module, index) => {
        return <Grid key={module.id} item>
          <BaseModule
            index={index}
            containerId={this.props.id}
            module={module}
            onRemoveModule={this.props.onRemoveModule}
            onMoveModule={this.props.onMoveModule}
            onEditModule={this.props.onEditModule}
            allowEdits={this.props.allowEdits}
            enableSort={this.props.enableSort}
            selected={selectedId === module.id}
          />
        </Grid>
        }
      )
    }

    return connectDropTarget(
      <div>
        <Grid
          container
          className={containerClassName}
          direction={this.props.column ? "column" : "row"}
          justify="center"
          spacing={3}
        >
          {modules}
          {modules.length === 0 &&
            <Typography component="h2" className={this.props.classes.placeholderText}>
              {this.props.emptyContainerLabel ?
                this.props.emptyContainerLabel : 'Please drag and drop a module.'}
            </Typography>
          }
        </Grid>
      </div>
    )
  }
}

Container.propTypes = {
  connectDropTarget: PropTypes.func.isRequired,
  isOver: PropTypes.bool.isRequired,
  canDrop: PropTypes.bool.isRequired
};

export default withStyles(styles)(
                 DropTarget(ModuleTypes.BASE, moduleTarget, collect)(Container)
               )
