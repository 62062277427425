import React, { Component } from 'react'
import {
  Dialog, AppBar,
  Toolbar, withStyles, IconButton,
  Button, Grid, Typography, Slide,
  DialogContent, DialogContentText,
  DialogActions, Card, FormControl,
  Select, MenuItem, Paper
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { CalendarPicker } from './'
import moment from 'moment'
import ItemList from './item-list'
import API from '../../../API'
import CreateTranslationForm from './create-translation-form'
import DeleteItemForm from './delete-form'
import uniqid from 'uniqid'
import update from 'immutability-helper'

const styles = theme => ({
  appBar: {
    position: 'relative'
  },
  flex: {
    flex: 1
  },
  container: {
    marginTop: theme.spacing(4)
  },
  form: {
    padding: theme.spacing(4)
  }
})

class CreateForm extends Component {
  constructor(props){
    super(props)
    let item = this.props.item || {
      date: moment(),
      translations: []
    }
    this.state = {
      item,
      zones: [],
      offices: [],
      languages: [],
      countries: [],
      deletedSubItems: [],
      formItem: null,
      renderCreateTranslationForm: false,
      renderDeleteForm: false,
      errorDialog: false,
      errorDialogContentText: '',
      closeDialog: false
    }
  }

  componentDidUpdate(prevProps) {
    if(
      this.props.item &&
      JSON.stringify(this.props.item) !== JSON.stringify(prevProps.item)
    ) {
      this.setState({
        item: this.props.item
      })
    }
  }

  handleOnEnter() {
    API.getAllZones(zones => {
      zones.sort((a,b) => {
        if(a.name > b.name) { return 1 }
        else if(b.name > a.name) { return -1 }
        else { return 0 }
      })
      this.setState({ zones })
    })
    API.getAllOffices(offices => {
      offices.sort((a,b) => {
        if(a.name > b.name) { return 1 }
        else if(b.name > a.name) { return -1 }
        else { return 0 }
      })
      this.setState({ offices })
    })
    API.getAllCountries(countries => {
      countries.sort((a,b) => {
        if(a.name > b.name) { return 1 }
        else if(b.name > a.name) { return -1 }
        else { return 0 }
      })
      this.setState({ countries })
    })
    API.getAllLanguages(response => {
      const languages = response.languages
      languages.sort((a,b) => {
        if(a.name > b.name) { return 1 }
        else if(b.name > a.name) { return -1 }
        else { return 0 }
      })
      this.setState({ languages })
    })
  }

  handleChange(value, input){
    let newItem = Object.assign({}, this.state.item)
    switch(input) {
      case "zoneId":
        if(value === "") {
          delete newItem[input]
        } else {
          newItem[input] = value
          if(value === 'ext'){
            delete newItem['officeId']
          } else{
            const officeId = this.state.item.officeId
            if(officeId && officeId !== "") {
              const office = this.state.offices.find(office =>
                office.id === officeId
              )
              if(office.zoneId !== value) {
                delete newItem["officeId"]
              }
            } 
          }
        }
        break
      case "officeId":
        if(value === "") {
          delete newItem[input]
        } else {
          newItem[input] = value
        }
        break
      case 'countryId':
        if(value === "") {
          delete newItem[input]
        } else {
          newItem[input] = value
        }
        break
      default:
        newItem[input] = value
        break
    }

    const {current, ...inputs} = newItem
    this.setState({
      item: inputs,
      ...this.isItemValid(inputs)
    })
  }

  handleTranslationClick(translation) {
    this.handleTranslationEdit(translation)
  }

  handleTranslationEdit(translation) {
    this.toggleCreateTranslationForm(translation)
  }

  handleTranslationAdd() {
    this.toggleCreateTranslationForm()
  }

  handleTranslationDelete(translation) {
    this.toggleDeleteForm(translation)
  }

  handleFormSave(translation) {
    if(!translation.id || translation.id === -1) {
      translation.id = uniqid()
      this.setState(update(this.state, {
        item: {
          translations: {
            $push: [ translation ]
          }
        }
      }))
    } else {
      const index = this.state.item.translations
        .findIndex(oldTranslation =>
          oldTranslation.id === translation.id
        )
       this.setState(update(this.state, {
        item: {
          translations: {
            $splice: [
              [index, 1, translation]
            ]
          }
        }
      }))
    }
  }

  handleFormDelete() {
    const index = this.props.item.translations.findIndex(translation =>
      translation.id === this.state.formItem.id
    )
    this.setState(update(this.state, {
      item: {
        translations: {
          $splice: [
            [index, 1]
          ]
        }
      },
      deletedSubItems: {
        $push: [ this.state.formItem ]
      }
    }))
  }

  handleTransition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  })

  handleSubmit() {
    let newState = this.isItemValid(this.state.item)
    if(Object.values(newState).includes(true)) {
      let errorMessage
      if(newState.errorDate === true) {
        errorMessage = "Please add a notification date."
      }
      if(newState.errorTranslations === true) {
        errorMessage = "Please add a notification translation."
      }
      this.setState({
        ...newState,
        errorDialog: true,
        errorDialogContentText: errorMessage
      })
      return
    }
    this.props.onSave(this.state.item, this.state.deletedSubItems)
    this.handleClose()
  }

  handleErrorDialogClose() {
    this.setState({ errorDialog: false })
  }

  handleCloseButtonClick() {
    this.setState({ closeDialog: true })
  }

  handleCloseDialogCloseNo() {
    this.setState({ closeDialog: false })
  }

  handleCloseDialogCloseYes() {
    this.setState({ closeDialog: false })
    this.handleClose()
  }

  handleClose() {
    this.setState({
      item: {
        date: moment(),
        translations: []
      },
      deletedSubItems: [],
      errorDialog: false,
      errorDialogContentText: '',
      closeDialog: false
    })

    this.props.onClose()
  }

  toggleCreateTranslationForm(formItem) {
    this.setState({
      renderCreateTranslationForm:
        !this.state.renderCreateTranslationForm,
      formItem
    })
  }

  toggleDeleteForm(formItem) {
    this.setState({
      renderDeleteForm: !this.state.renderDeleteForm,
      formItem
    })
  }

  isItemValid(item) {
    let newState = {}
    if(item.date === '') {
      newState.errorDate = true
    } else {
      newState.errorDate = false
    }
    if(item.translations.length === 0) {
      newState.errorTranslations = true
    } else {
      newState.errorTranslations = false
    }
    return newState
  }


  render(){
    //Add language name to translations
    //array for user convenience.
    let translations = this.state.item.translations
    if(this.state.languages.length > 0) {
      translations = translations.map(translation => {
        const language = this.state.languages.find(language =>
          language.code.substring(0, 2) === translation.languageCode
        )
        translation.languageName = language.name
        return translation
      })
    }

    //Filter offices by zone if zone
    //is selected.
    let offices = this.state.offices
    let countries = this.state.countries
    const zoneId = this.state.item.zoneId
    if(zoneId && zoneId !== "" && zoneId !== 'ext') {
      offices = offices.filter(office =>
        office.zoneId === zoneId
      )
    }
    offices = offices.map(office =>
      <MenuItem
        key={office.id}
        value={office.id}
      >
        {office.name}
      </MenuItem>
    )
    countries = countries.map(country =>
      <MenuItem
        key={country.id}
        value={country.id}
      >
        {country.name}
      </MenuItem>
    )

    return(
      <Dialog
        open={this.props.open}
        onClose={this.handleCloseButtonClick.bind(this)}
        fullScreen
        TransitionComponent={this.handleTransition}
        onEnter={this.handleOnEnter.bind(this)}
      >
        <AppBar className={this.props.classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={this.handleCloseButtonClick.bind(this)}
              aria-label="Close">
              <CloseIcon />
            </IconButton>
            <Typography
              variant="h5"
              color="inherit"
              className={this.props.classes.flex}>
              Notification Editor
            </Typography>
            <Button
              color="inherit"
              onClick={this.handleSubmit.bind(this)}>
              Save
            </Button>
          </Toolbar>
        </AppBar>
        <Grid
          container
          className={this.props.classes.container}
          justify="space-evenly"
        >
          <Grid item>
            <Paper className={this.props.classes.form}>
              <Grid
                container
                direction="column"
                spacing={4}
              >
                <Grid item>
                  <CalendarPicker
                    date = {moment(this.state.item.date).toISOString()}
                    notifications = {this.props.notifications}
                    currentNotificationId = {this.state.item.id}
                    onDateChange = {e => this.handleChange(
                      e.toISOString(),
                      'date'
                    )}
                  />
                </Grid>
                <Grid item>
                  <FormControl>
                    <Select
                      value={this.state.item.zoneId || ""}
                      onChange={e => this.handleChange(
                        e.target.value,
                        'zoneId'
                      )}
                      displayEmpty
                    >
                      <MenuItem value="">
                        <em>All Zones</em>
                      </MenuItem>
                      <MenuItem key='ext' value='ext'>
                        <em>External Users</em>
                      </MenuItem>
                      {
                        this.state.zones.map(zone =>
                          <MenuItem
                            key={zone.id}
                            value={zone.id}
                          >
                            {zone.name}
                          </MenuItem>
                        )
                      }
                    </Select>
                  </FormControl>
                </Grid>
                {
                  zoneId !== 'ext' ? 
                  <Grid item>
                    <FormControl>
                      <Select
                        value={this.state.item.officeId || ""}
                        onChange={e => this.handleChange(
                          e.target.value,
                          'officeId'
                        )}
                        displayEmpty
                      >
                        <MenuItem value="">
                          <em>All Offices</em>
                        </MenuItem>
                        { offices }
                      </Select>
                    </FormControl>
                  </Grid>
                  :
                  <Grid item>
                    <FormControl>
                      <Select
                        value={this.state.item.countryId || ""}
                        onChange={e => this.handleChange(
                          e.target.value,
                          'countryId'
                        )}
                        displayEmpty
                      >
                        <MenuItem value="">
                          <em>All Countries</em>
                        </MenuItem>
                        { countries }
                      </Select>
                    </FormControl>
                  </Grid>
                }
              </Grid>
            </Paper>
          </Grid>
          <Grid item>
            <Card>
              <ItemList
                mountAnimation = {true}
                items = {translations}
                itemAttributeForLabel = {"languageName"}
                allowAdding = {true}
                addItemLabel = {"Add a Translation"}
                allowEditing = {true}
                allowDeleting = {true}
                onItemClick = {this.handleTranslationClick.bind(this)}
                onItemEdit = {this.handleTranslationEdit.bind(this)}
                onItemAdd = {this.handleTranslationAdd.bind(this)}
                onItemDelete = {this.handleTranslationDelete.bind(this)}
              />
            </Card>
          </Grid>
        </Grid>
        <Dialog
          open={this.state.errorDialog}
          onClose={this.handleErrorDialogClose.bind(this)}
        >
          <DialogContent>
            <DialogContentText>
              {this.state.errorDialogContentText}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleErrorDialogClose.bind(this)}
              color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.closeDialog}
          onClose={this.handleCloseDialogCloseNo.bind(this)}
        >
          <DialogContent>
            <DialogContentText>
              Are you sure that you want to close this screen?
              All of the changes you made will be lost.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleCloseDialogCloseNo.bind(this)}
              color="primary">
              No. I want to keep my changes.
            </Button>
            <Button
              onClick={this.handleCloseDialogCloseYes.bind(this)}
              color="secondary">
              Yes. I want to lose my changes.
            </Button>
          </DialogActions>
        </Dialog>
        <CreateTranslationForm
          open={this.state.renderCreateTranslationForm}
          item={this.state.formItem}
          languages={this.state.languages}
          onClose={this.toggleCreateTranslationForm.bind(this)}
          onSave={this.handleFormSave.bind(this)}
        />
        <DeleteItemForm
          open={this.state.renderDeleteForm}
          item={this.state.formItem}
          onClose={this.toggleDeleteForm.bind(this)}
          onDelete={this.handleFormDelete.bind(this)}
        />
      </Dialog>
    )
  }
}

export default withStyles(styles)(CreateForm)
