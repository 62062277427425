import React, { Component } from 'react'
import ManageContentList from './manage-content-list'
import ManageContentGrid from './manage-content-grid'

export default class ManageContent extends Component {
  render() {
    return (
      <div>
      {this.props.showList ? (
        <ManageContentList
          mountAnimation = {this.props.mountAnimation}
          languageId = {this.props.languageId}
          packs = {this.props.packs}
          flights = {this.props.flights}
          lessons = {this.props.lessons}
          lessonModules = {this.props.lessonModules}
          questions = {this.props.questions}
          answers = {this.props.answers}
          onPackSave = {this.props.onPackSave}
          onPackDelete = {this.props.onPackDelete}
          onPackClick = {this.props.onPackClick}
          onFlightSave = {this.props.onFlightSave}
          onFlightDelete = {this.props.onFlightDelete}
          onFlightClick = {this.props.onFlightClick}
          onLessonSave = {this.props.onLessonSave}
          onLessonDelete = {this.props.onLessonDelete}
          onLessonClick = {this.props.onLessonClick}
          onLessonModuleSave = {this.props.onLessonModuleSave}
          onLessonModuleDelete = {this.props.onLessonModuleDelete}
          onQuestionSave = {this.props.onQuestionSave}
          onQuestionDelete = {this.props.onQuestionDelete}
          onAnswerSave = {this.props.onAnswerSave}
          onAnswerDelete = {this.props.onAnswerDelete} />
      ) : (
        <ManageContentGrid
          mountAnimation = {this.props.mountAnimation}
          languageId = {this.props.languageId}
          packs = {this.props.packs}
          flights = {this.props.flights}
          lessons = {this.props.lessons}
          lessonModules = {this.props.lessonModules}
          questions = {this.props.questions}
          answers = {this.props.answers}
          onPackSave = {this.props.onPackSave}
          onPackDelete = {this.props.onPackDelete}
          onPackClick = {this.props.onPackClick}
          onPackMove = {this.props.onPackMove}
          onFlightSave = {this.props.onFlightSave}
          onFlightDelete = {this.props.onFlightDelete}
          onFlightClick = {this.props.onFlightClick}
          onFlightMove = {this.props.onFlightMove}
          onLessonSave = {this.props.onLessonSave}
          onLessonDelete = {this.props.onLessonDelete}
          onLessonClick = {this.props.onLessonClick}
          onLessonMove = {this.props.onLessonMove}
          onLessonModuleSave = {this.props.onLessonModuleSave}
          onLessonModuleDelete = {this.props.onLessonModuleDelete}
          onQuestionSave = {this.props.onQuestionSave}
          onQuestionDelete = {this.props.onQuestionDelete}
          onAnswerSave = {this.props.onAnswerSave}
          onAnswerDelete = {this.props.onAnswerDelete} />
      )}
      </div>
    )
  }
}
