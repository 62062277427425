import React, { Component } from 'react'
import {
  withStyles, Grid,
  Typography, TextField
} from '@material-ui/core'
import { TimePicker } from '@material-ui/pickers'

const styles = theme => ({
  wrapper: {
    paddingTop: theme.spacing(4)
  },
  timeRange: {
    marginTop: theme.spacing(1),
    color: theme.palette.text.secondary,
    lineHeight: 0,
    letterSpacing: 'normal',
    fontSize: theme.typography.fontSize * 0.85
  },
  picker: {
    width: 70
  },
  pickerInput: {
    padding: '1px 0 8px',
    cursor: 'pointer'
  },
  pickerFrom: {
    paddingRight: 6
  },
  pickerTo: {
    paddingRight: 6,
    paddingLeft: 6
  }
})

class RangeTimeForm extends Component {
  render() {
    return(
      <Grid
        container
        direction="column"
        spacing={2}
        className={this.props.classes.wrapper}
      >
        <Grid item>
          <Typography
            variant="body1"
            className={this.props.classes.timeRange}
          >
            Choose Time Range
          </Typography>
        </Grid>
        <Grid item>
          <Grid container>
            <Grid item>
              <Typography
                variant="body1"
                className={this.props.classes.pickerFrom}
              >
                From
              </Typography>
            </Grid>
            <Grid item>
              <TimePicker
                className={this.props.classes.picker}
                value={this.props.timeStart}
                variant="inline"
                onChange={(time) => {
                  this.props.onTimeChange("timeStart", time)
                }}
                InputProps={{
                  classes: {
                    input: this.props.classes.pickerInput
                  }
                }}
              />
            </Grid>
            <Grid item>
              <Typography
                variant="body1"
                className={this.props.classes.pickerTo}
              >
                to
              </Typography>
            </Grid>
            <Grid item>
              <TimePicker
                className={this.props.classes.picker}
                value={this.props.timeEnd}
                variant="inline"
                onChange={(time) => {
                  this.props.onTimeChange("timeEnd", time)
                }}
                InputProps={{
                  classes: {
                    input: this.props.classes.pickerInput
                  }
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <TextField
            label="Beercoin Amount Earned By User"
            margin="normal"
            fullWidth
            value={this.props.beercoinEarnAmount || ''}
            onChange={this.props.onBeercoinChange} />
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(RangeTimeForm)
