import React, { Component } from 'react'
import { withStyles, /*CardMedia,*/ CardContent, Typography, TextField, Button } from '@material-ui/core'
import FileUpload from '../../../../../utils/file-upload'
import API from '../../../../../../API'

const styles = theme => ({
  cardMedia: {
    height: '100%'
  },
  imagePlaceholder: {
    height: '150px',
    width: '150px',
    margin: '0 auto',
    display: 'block'
  },
  imageEditor: {
    width: '50vw',
    padding: '50px'
  },
  imageUpload: {
    textAlign: 'center'
  },
  imageUploadText: {
    textAlign: 'center'
  },
  textField: {
    width: '100%'
  }
})

const getContentObj = content => {
  /*
  contentObj = {
    fileName: 'name of file',
    fileType: 'MIME type',
    fileSize: 1024, //in bytes
    // thumbnail: 'base64' // TODO, if image, or if inputed, get small size thumbnail as base64 for preview
  }
  */
  try {
    if(!!content.fileName || (typeof content === 'string' && content.includes('"fileName":'))){
      let contentObj = typeof content === 'string' ? JSON.parse(content) : content;;
      // if(!contentObj.thumbnail.includes("base64")){
      //   contentObj.thumbnail = false;
      // }
      return contentObj;
    }
    return false;
  } catch(err) {
    console.error(err);
    return false;
  }
}

class FileDownloadEditor extends Component {
  handleFileChange(input) {
    console.log(input);
    this.props.onChange({value: input, field: 'FILE'});
  }
  handleTextChange(input) {
    this.props.onChange({value: input.target.value, field: 'CAPTION'})
  }

  handleDownloadClick(evt, fileName){
    console.log(fileName);
    API.downloadFile('lessonModuleUploads', fileName);
  }

  render() {
    const contentObj = getContentObj(this.props.module.content);
    return(
      <div className={this.props.classes.imageEditor}>
        { contentObj.fileName &&
          <CardContent>
            <Button onClick={this.handleDownloadClick.bind(this, contentObj.fileName)}>
                {contentObj.fileName}
            </Button>
          </CardContent>
        }
        <FileUpload
          label={contentObj.fileName ? "Upload a Different File" : "Upload a File"}
          className={this.props.classes.imageUpload}
          onFileChange={this.handleFileChange.bind(this)} />
        <Typography
          className={this.props.classes.imageUploadText}
          variant="subtitle1">
          Note: only images and pdf files allowed
        </Typography>
        <TextField
          className={this.props.classes.textField}
          multiline
          rows="4"
          label="Enter Caption"
          margin="normal"
          value={this.props.module.caption || ""}
          onChange={this.handleTextChange.bind(this)}
          inputProps={{maxLength :140}} />
      </div>
    )
  }
}

export default withStyles(styles)(FileDownloadEditor)
