import React, { Component } from 'react'
import {
  Fade, Stepper, Step,
  StepButton, withStyles
} from '@material-ui/core'
import ItemGrid from '../../item/item-grid/index'
import CreatePackForm from '../../item/item-form/create-pack-form'
import CreateFlightForm from '../../item/item-form/create-flight-form'
import CreateLessonForm from '../../item/item-form/create-lesson-form'
import CreateQuizForm from '../../item/item-form/create-quiz-form'
import DeleteItemForm from '../../item/item-form/delete-item-form'
import update from 'immutability-helper'

const styles = theme => ({
  stepper: {
    backgroundColor: 'unset'
  },
  itemGrid: {
    width: '90%',
    margin: 'auto'
  }
})

const defaultColumnNames = [
  'PACK',
  'FLIGHT',
  'LESSON',
  'QUIZ QUESTIONS'
]

const animationDuration = 125

class ManageContent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeStep: 0,
      columnNames: defaultColumnNames,
      renderCreatePackForm: false,
      renderCreateFlightForm: false,
      renderCreateLessonForm: false,
      renderCreateQuizForm: false,
      renderDeleteForm: false,
      renderItemType: 'pack',
      openPackId: -1,
      openFlightId: -1,
      openLessonId: -1,
      formItemType: '',
      formItemId: -1,
      mountGridAnimation: true
    }
  }
  componentDidUpdate(prev){
    if(prev.languageId !== this.props.languageId){
      this.setState({
        activeStep: 0,
        columnNames: defaultColumnNames,
        renderCreatePackForm: false,
        renderCreateFlightForm: false,
        renderCreateLessonForm: false,
        renderCreateQuizForm: false,
        renderDeleteForm: false,
        renderItemType: 'pack',
        openPackId: -1,
        openFlightId: -1,
        openLessonId: -1,
        formItemType: '',
        formItemId: -1,
        mountGridAnimation: true
      })
    }
  }
  /*************************
    PROGRESS BAR / STEPPER
  **************************/

  handleStep(step) {
    let newStep = this.state.activeStep - 1
    if(typeof step === "number") {
      newStep = step
    }
    this.setState({
      activeStep: newStep
    })
  }

  handleStepClick(step) {
    if(step === this.state.activeStep) { return }
    switch(step) {
      case 0:
        let numOfPacks = this.props.packs.filter(pack =>
          pack.languageId === this.props.languageId
        ).length

        this.setState({
          mountGridAnimation: false
        }, () => {
          setTimeout(() => {
            this.setState({
              renderItemType: 'pack',
              openPackId: -1,
              openFlightId: -1,
              openLessonId: -1,
              columnNames: defaultColumnNames,
              mountGridAnimation: true,
              activeStep: step
            })
          }, animationDuration * numOfPacks)
        })
        this.props.onPackClick('')
        break
      case 1:
        let numOfFlights = this.props.flights.filter(flight =>
          flight.packId === this.state.openPackId
        ).length

        this.setState({
          mountGridAnimation: false
        }, () => {
          setTimeout(() => {
            this.setState({
              renderItemType: 'flight',
              openFlightId: -1,
              openLessonId: -1,
              columnNames: update(this.state.columnNames, {
                $splice: [
                  [1, 1, defaultColumnNames[1]],
                  [2, 1, defaultColumnNames[2]]
                ]
              }),
              mountGridAnimation: true,
              activeStep: step
            })
          }, animationDuration * numOfFlights)
        })
        this.props.onFlightClick('')
        break
      case 2:
        let numOfLessons = this.props.lessons.filter(lesson =>
          lesson.flightId === this.state.openFlightId
        ).length

        this.setState({
          mountGridAnimation: false
        }, () => {
          setTimeout(() => {
            this.setState({
              renderItemType: 'lesson',
              openLessonId: -1,
              columnNames: update(this.state.columnNames, {
                $splice: [
                  [2, 1, defaultColumnNames[2]]
                ]
              }),
              mountGridAnimation: true,
              activeStep: step
            })
          }, animationDuration * numOfLessons)
        })
        this.props.onLessonClick('')
        break
      default:
        break
    }
  }

  /*******
    PACKS
  ********/

  handlePackClick(pack) {
    let numOfPacks = this.props.packs.filter(pack =>
      pack.languageId === this.props.languageId
    ).length

    this.setState({
      mountGridAnimation: false
    }, () => {
      setTimeout(() => {
        this.setState({
          renderItemType: 'flight',
          openPackId: pack.id,
          openFlightId: -1,
          openLessonId: -1,
          columnNames: update(this.state.columnNames, {
            $splice: [
              [0, 1, pack.name]
            ]
          }),
          mountGridAnimation: true
        })
      }, animationDuration * numOfPacks)
    })
  
    this.handleStep(1)
    this.props.onPackClick(pack)
  }

  handlePackEdit(pack) {
    this.toggleCreatePackForm(pack.id)
  }

  handlePackAdd() {
    this.toggleCreatePackForm()
  }

  handlePackDelete(pack) {
    this.toggleDeleteForm('pack', pack.id)
  }

  handlePackMove(dragIndex, hoverIndex) {
    let items = this.props.packs.filter(pack =>
      pack.languageId === this.props.languageId
    )
    items = [...items].sort((a,b) => a.order - b.order)
    const dragItem = items[dragIndex]
    const newItems = update(items, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, dragItem]
      ]
    })
    newItems.forEach((item, index) => {
      item.order = index + 1
      this.props.onPackMove(item)
    })
  }

  handlePackMoveFinished() {
    let items = this.props.packs.filter(pack =>
      pack.languageId === this.props.languageId
    )
    items = [...items].sort((a,b) => a.order - b.order)
    items.forEach(item => {
      this.props.onPackSave(item, true)
    })
  }

  /*********
    FLIGHTS
  **********/

  handleFlightClick(flight) {
    let numOfFlights = this.props.flights.filter(flight =>
      flight.packId === this.state.openPackId
    ).length

    this.setState({
      mountGridAnimation: false
    }, () => {
      setTimeout(() => {
        this.setState({
          renderItemType: 'lesson',
          openFlightId: flight.id,
          openLessonId: -1,
          columnNames: update(this.state.columnNames, {
            $splice: [
              [1, 1, flight.name]
            ]
          }),
          mountGridAnimation: true
        })
      }, animationDuration * numOfFlights)
    })

    this.handleStep(2)
    this.props.onFlightClick(flight)
  }

  handleFlightEdit(flight) {
    this.toggleCreateFlightForm(flight.id)
  }

  handleFlightAdd() {
    this.toggleCreateFlightForm()
  }

  handleFlightDelete(flight) {
    this.toggleDeleteForm('flight', flight.id)
  }

  handleFlightMove(dragIndex, hoverIndex) {
    let items = this.props.flights.filter(flight =>
      flight.packId === this.state.openPackId
    )
    items = [...items].sort((a,b) => a.order - b.order)
    const dragItem = items[dragIndex]
    const newItems = update(items, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, dragItem]
      ]
    })
    newItems.forEach((item, index) => {
      item.order = index + 1
      this.props.onFlightMove(item)
    })
  }

  handleFlightMoveFinished() {
    let items = this.props.flights.filter(flight =>
      flight.packId === this.state.openPackId
    )
    items = [...items].sort((a,b) => a.order - b.order)
    items.forEach(item => {
      this.props.onFlightSave(item, true)
    })
  }

  /*********
    LESSONS
  **********/

  handleLessonClick(lesson) {
    let numOfLessons = this.props.lessons.filter(lesson =>
      lesson.flightId === this.state.openFlightId
    ).length

    this.setState({
      mountGridAnimation: false
    }, () => {
      setTimeout(() => {
        this.setState({
          renderItemType: 'question',
          openLessonId: lesson.id,
          columnNames: update(this.state.columnNames, {
            $splice: [
              [2, 1, lesson.name]
            ]
          }),
          mountGridAnimation: true
        })
      }, animationDuration * numOfLessons)
    })

    this.handleStep(3)
    this.props.onLessonClick(lesson)
  }

  handleLessonEdit(lesson) {
    this.toggleCreateLessonForm(lesson.id)
  }

  handleLessonDelete(lesson) {
    this.toggleDeleteForm('lesson', lesson.id)
  }

  handleLessonMove(dragIndex, hoverIndex) {
    let items = this.props.lessons.filter(lesson =>
      lesson.flightId === this.state.openFlightId
    )
    items = [...items].sort((a,b) => a.order - b.order)
    const dragItem = items[dragIndex]
    const newItems = update(items, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, dragItem]
      ]
    })
    newItems.forEach((item, index) => {
      item.order = index + 1
      this.props.onLessonMove(item)
    })
  }

  handleLessonMoveFinished() {
    let items = this.props.lessons.filter(lesson =>
      lesson.flightId === this.state.openFlightId
    )
    items = [...items].sort((a,b) => a.order - b.order)
    items.forEach(item => {
      this.props.onLessonSave(item, null, true)
    })
  }

  /*********
    QUESTIONS
  **********/

  handleQuestionClick(question) {
    this.handleQuestionEdit(question)
  }

  handleQuestionEdit(question) {
    this.toggleCreateQuizForm(question.id)
  }

  handleQuestionDelete(question) {
    this.toggleDeleteForm('question', question.id)
  }

  /******************
    SAVE/DELETE ITEM
  *******************/

  handleFormSave(newItem, deletedSubItems) {
    if(!newItem) { return }
    newItem.languageId = this.props.languageId
    switch(this.state.formItemType) {
      case "pack":
        if(!newItem.order) {
          newItem.order = this.props.packs.filter(pack =>
            pack.languageId === this.props.languageId
          ).length + 1
        }
        this.props.onPackSave(newItem)
        break
      case "flight":
        if(!newItem.order) {
          newItem.order = this.props.flights.filter(flight =>
            flight.packId === this.state.openPackId
          ).length + 1
        }
        if(!newItem.packId) {
          newItem.packId = this.state.openPackId
        }
        this.props.onFlightSave(newItem)
        break
      case "lesson":
        if(!newItem.order) {
          newItem.order = this.props.lessons.filter(lesson =>
            lesson.flightId === this.state.openFlightId
          ).length + 1
        }
        if(!newItem.flightId) {
          newItem.flightId = this.state.openFlightId
        }
        if(!newItem.difficultyLevel) {
          newItem.difficultyLevel = 1
        }
        let newModules = Object.assign([], newItem.modules)
        delete newItem.modules
        this.props.onLessonSave(newItem, () => {
          newModules = newModules.map(module => {
            let id = parseInt(module.id, 10)
            if(Number.isNaN(id)) { delete module.id }
            module.lessonId = newItem.id
            module.languageId = newItem.languageId
            return module
          })
          this.props.onLessonModuleSave(newModules, () => {
            if(deletedSubItems && deletedSubItems.length > 0) {
              this.props.onLessonModuleDelete(deletedSubItems)
            }
          })
        })
        break
      default:
        break
    }
  }

  handleQuizFormSave(items, deletedItems, deletedSubItems) {
    let answers = []
    let questions = items.map(item => {
      let id = parseInt(item.id, 10)
      if(Number.isNaN(id)) {
        item.oldId = item.id
        delete item.id
      }
      item.languageId = this.props.languageId
      item.questionTypeId = 1
      if(!item.lessonId) {
        item.lessonId = this.state.openLessonId
      }
      if(item.answers) {
        item.answers = item.answers.map(answer => {
          let id = parseInt(answer.id, 10)
          if(Number.isNaN(id)) { delete answer.id }
          if(item.id) { answer.questionId = item.id }
          else { answer.oldQuestionId = item.oldId }
          answer.languageId = item.languageId
          return answer
        })
        answers = answers.concat(item.answers)
        delete item.answers
      }
      return item
    })

    this.props.onQuestionSave(questions, () => {
      this.props.onAnswerSave(answers, () => {
        if(deletedItems && deletedItems.length > 0) {
          this.props.onQuestionDelete(deletedItems, () => {
            if(deletedSubItems && deletedSubItems.length > 0) {
              this.props.onAnswerDelete(deletedSubItems)
            }
          })
        } else if(deletedSubItems && deletedSubItems.length > 0) {
          this.props.onAnswerDelete(deletedSubItems)
        }
      })
    })
  }

  handleFormDelete() {
    switch(this.state.formItemType) {
      case "pack":
        this.props.onPackDelete(this.state.formItemId)
        break
      case "flight":
        this.props.onFlightDelete(this.state.formItemId)
        break
      case "lesson":
        this.props.onLessonDelete(this.state.formItemId)
        break
      case "question":
        this.props.onQuestionDelete([ { id: this.state.formItemId } ])
        break
      default:
        break
    }
  }

  /********
    FORMS
  *********/

  toggleCreatePackForm(itemId) {
    this.setState({
      renderCreatePackForm: !this.state.renderCreatePackForm,
      formItemType: 'pack',
      formItemId: itemId ? itemId : -1
    })
  }

  toggleCreateFlightForm(itemId) {
    this.setState({
      renderCreateFlightForm: !this.state.renderCreateFlightForm,
      formItemType: 'flight',
      formItemId: itemId ? itemId : -1
    })
  }

  toggleCreateLessonForm(itemId) {
    this.setState({
      renderCreateLessonForm: !this.state.renderCreateLessonForm,
      formItemType: 'lesson',
      formItemId: itemId ? itemId : -1
    })
  }

  toggleCreateQuizForm(itemId) {
    this.setState({
      renderCreateQuizForm: !this.state.renderCreateQuizForm,
      formItemType: 'question',
      formItemId: itemId ? itemId : -1
    })
  }

  toggleDeleteForm(itemType, itemId) {
    this.setState({
      renderDeleteForm: !this.state.renderDeleteForm,
      formItemType: itemType,
      formItemId: itemId ? itemId : -1
    })
  }

  render() {
    //Sort and filter data
    let packs = this.props.packs.filter(pack =>
      pack.languageId === this.props.languageId
    )
    packs = [...packs].sort((a,b) => a.order - b.order)
    let flights = this.props.flights.filter(flight =>
      flight.packId === this.state.openPackId
    )
    flights = [...flights].sort((a,b) => a.order - b.order)
    let lessons = this.props.lessons.filter(lesson =>
      lesson.flightId === this.state.openFlightId
    )
    lessons = [...lessons].sort((a,b) => a.order - b.order)
    let questions = this.props.questions.filter(question =>
      question.lessonId === this.state.openLessonId
    )
    questions = [...questions].sort((a,b) => a.id - b.id)
    questions = questions.map(question => {
      question.answers = this.props.answers.filter(answer =>
        answer.questionId === question.id
      )
      question.answers = [...question.answers].sort((a,b) => a.id - b.id)
      return question
    })

    let itemsToShow = []
    let allowEditing = true
    let allowAdding = true
    let allowDeleting = true
    let allowMoving = true
    let handleClick = () => {}
    let handleEdit = () => {}
    let handleAdd = () => {}
    let handleDelete = () => {}
    let handleMove = () => {}
    let handleMoveFinished = () => {}

    switch(this.state.renderItemType) {
      case "pack":
        itemsToShow = packs
        handleClick = this.handlePackClick.bind(this)
        handleEdit = this.handlePackEdit.bind(this)
        handleAdd = this.handlePackAdd.bind(this)
        handleDelete = this.handlePackDelete.bind(this)
        handleMove = this.handlePackMove.bind(this)
        handleMoveFinished = this.handlePackMoveFinished.bind(this)
        break
      case "flight":
        itemsToShow = flights
        handleClick = this.handleFlightClick.bind(this)
        handleEdit = this.handleFlightEdit.bind(this)
        handleAdd = this.handleFlightAdd.bind(this)
        handleDelete = this.handleFlightDelete.bind(this)
        handleMove = this.handleFlightMove.bind(this)
        handleMoveFinished = this.handleFlightMoveFinished.bind(this)
        break
      case "lesson":
        itemsToShow = lessons
        allowAdding = false
        handleClick = this.handleLessonClick.bind(this)
        handleEdit = this.handleLessonEdit.bind(this)
        handleDelete = this.handleLessonDelete.bind(this)
        handleMove = this.handleLessonMove.bind(this)
        handleMoveFinished = this.handleLessonMoveFinished.bind(this)
        break
      case "question":
        itemsToShow = questions
        allowAdding = false
        allowMoving = false
        handleClick = this.handleQuestionClick.bind(this)
        handleEdit = this.handleQuestionEdit.bind(this)
        handleDelete = this.handleQuestionDelete.bind(this)
        break
      default:
        itemsToShow = packs
        handleClick = this.handlePackClick.bind(this)
        handleEdit = this.handlePackEdit.bind(this)
        handleAdd = this.handlePackAdd.bind(this)
        handleDelete = this.handlePackDelete.bind(this)
        handleMove = this.handlePackMove.bind(this)
        break
    }

    //If a form needs to be opened, find the
    //item that the form can consume.
    let formItem
    if(this.state.formItemId > -1) {
      switch(this.state.formItemType) {
        case "pack":
          formItem = packs.find(pack => pack.id === this.state.formItemId)
          break
        case "flight":
          formItem = flights.find(flight => flight.id === this.state.formItemId)
          break
        case "lesson":
          formItem = lessons.find(lesson => lesson.id === this.state.formItemId)
          formItem.modules = this.props.lessonModules.filter(module =>
            module.lessonId === this.state.formItemId
          )
          formItem.modules = [...formItem.modules].sort((a,b) => a.order - b.order)
          break
        case "question":
          formItem = questions.find(question => question.id === this.state.formItemId)
          break
        default:
          formItem = null
          break
      }
    }

    //When rendering between the item form
    //and the delete form, make sure that
    //the input data is not shared between
    //the forms. Each form should have
    //its own data input to ensure that
    //there are no unexpected data changes.
    let deleteItem = null
    if(this.state.renderDeleteForm) {
      deleteItem = Object.assign({}, formItem)
      formItem = null
    }

    const style = {
      container: {
        width: '90%',
        margin: 'auto'
      }
    }

    return(
      <Fade in={this.props.mountAnimation} className={this.props.className}>
        <div style={style.container}>
          <CreatePackForm
            open = {this.state.renderCreatePackForm}
            item = {formItem}
            onClose = {this.toggleCreatePackForm.bind(this)}
            onSave = {this.handleFormSave.bind(this)} />
          <CreateFlightForm
            open = {this.state.renderCreateFlightForm}
            item = {formItem}
            onClose = {this.toggleCreateFlightForm.bind(this)}
            onSave = {this.handleFormSave.bind(this)} />
          <CreateLessonForm
            open = {this.state.renderCreateLessonForm}
            item = {formItem}
            onClose = {this.toggleCreateLessonForm.bind(this)}
            onSave = {this.handleFormSave.bind(this)}
            onDelete = {this.handleFormDelete.bind(this)} />
          <CreateQuizForm
            open = {this.state.renderCreateQuizForm}
            items = {questions}
            openItem = {formItem}
            onClose = {this.toggleCreateQuizForm.bind(this)}
            onSave = {this.handleQuizFormSave.bind(this)} />
          <DeleteItemForm
            open = {this.state.renderDeleteForm}
            item = {deleteItem}
            onClose = {this.toggleDeleteForm.bind(this)}
            onDelete = {this.handleFormDelete.bind(this)} />
          {!this.state.renderCreateLessonForm &&
           !this.state.renderCreateQuizForm &&
            <div>
              <Stepper
                className={this.props.classes.stepper}
                activeStep={this.state.activeStep}
                alternativeLabel
              >
                {this.state.columnNames.map((label, index) => {
                  return (
                    <Step key={label}>
                      <StepButton
                        onClick={e => { this.handleStepClick(index) } }
                      >
                        {label}
                      </StepButton>
                    </Step>
                  )
                })}
              </Stepper>
              <div className={this.props.classes.itemGrid}>
                <ItemGrid
                  mountAnimation = {this.state.mountGridAnimation}
                  animationDuration = {animationDuration}
                  items = {itemsToShow}
                  allowAdding = {allowAdding}
                  allowEditing = {allowEditing}
                  allowDeleting = {allowDeleting}
                  allowMoving = {allowMoving}
                  onItemClick = {handleClick}
                  onItemEdit = {handleEdit}
                  onItemAdd = {handleAdd}
                  onItemDelete = {handleDelete}
                  onItemMove = {handleMove}
                  onItemMoveFinished = {handleMoveFinished}
                />
              </div>
            </div>
          }
        </div>
      </Fade>
    )
  }
}

export default withStyles(styles)(ManageContent)
