// from https://material-ui.com/demos/tables/ sorting and selection demo
import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import {
  withStyles, Table, TableBody, TableCell,
  TableHead, TablePagination, TableRow,
  TableSortLabel, Toolbar, Typography,
  Paper, IconButton, Tooltip,
  TextField, CircularProgress, Fade
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import AddIcon from '@material-ui/icons/Add'
import SearchIcon from '@material-ui/icons/Search'
import InfoIcon from '@material-ui/icons/Info'
import { lighten } from '@material-ui/core/styles/colorManipulator'

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const columnData = [
  { id: 'id', align: 'left', numeric: false, disablePadding: false, label: 'User ID' },
  { id: 'firstName', align: 'left', numeric: true, disablePadding: false, label: 'First Name' },
  { id: 'lastName', align: 'left', numeric: true, disablePadding: false, label: 'Last Name' },
  { id: 'emailAddress', align: 'left', numeric: true, disablePadding: false, label: 'Email Address'},
  { id: 'edit', align: 'left', numeric: true, disablePadding: false}
];

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const {order, orderBy} = this.props;

    return (
      <TableHead>
        <TableRow>
          {columnData.map(column => {
            return (
              <TableCell
                key={column.id}
                align={column.align}
                padding={column.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === column.id ? order : false}
              >
                <Tooltip
                  title="Sort"
                  placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={order}
                    onClick={this.createSortHandler(column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const styles = theme => ({
  root: {
    width: '90%',
    marginTop: theme.spacing(3),
    margin: 'auto'
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  toolbar: {
    paddingRight: theme.spacing(1),
  },
  margin: {
    margin: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  title: {
    flex: '0 0 auto',
  },
  textField: {
    width: '35%',
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
  },
  searchIcon: {
    paddingTop: '7px'
  },
  progressContainer: {
    width: '100%',
  },
  progress: {
    margin: '135px 40%',
    display: 'block',
    position: 'absolute'
  },
});

class EnhancedTable extends React.Component {
  state = {
    order: 'asc',
    orderBy: 'id',
    page: 0,
    rowsPerPage: 5,
    searchTerms: "",
    dataLoaded: false
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';
    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }
    this.setState({ order, orderBy });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleInput(e) {
    this.setState({
      searchTerms: e.target.value
    })
  };

  toTitleCase = phrase => {
    return phrase
      .toLowerCase()
      .split(' ')
      .map(word =>
        word.charAt(0).toUpperCase() + word.slice(1)
      )
      .join(' ');
  };

render() {
    const { classes } = this.props;
    const { order, orderBy, rowsPerPage, page } = this.state;

    let data = this.props.users;
    if(this.state.searchTerms !== "") {
      const searchedVal = this.toTitleCase(this.state.searchTerms);
      data = data.filter(user =>
        user.firstName.toLowerCase().includes(`${searchedVal.toLowerCase()}`) ||
        user.lastName.toLowerCase().includes(`${searchedVal.toLowerCase()}`)
      );
      // this.state.page = 0;
      if(this.state.page !== 0){
        this.setState({
          page: 0
        });
      }
    }

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    return (
      <Paper className={classes.root}>
        <Toolbar
          className={classNames(classes.toolbar)}
        >
          <div className={classes.title}>
              <Typography variant="h5" id="tableTitle">
                Users
              </Typography>
          </div>
          <div className={classes.spacer}/>
          <div className={classes.textField}>
            <SearchIcon className={classes.searchIcon}/>
            <TextField
              fullWidth
              value={this.state.searchTerms}
              label="User Search"
              placeholder="Search by first or last name"
              onChange={e => this.handleInput(e)}/>
          </div>
        </Toolbar>
        <div className={classes.tableWrapper}>
          <Fade in={data.length === 0 && this.state.dataLoaded === false}>
            <div className={data.length === 0 ? classes.progressContainer : classes.hide}>
              <CircularProgress
                  className={classes.progress}
                  size={64}
              />
            </div>
          </Fade>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={this.handleRequestSort}
              rowCount={data.length}
            />

            <Fade in={data.length > 0}>
              <TableBody>
                  {stableSort(data, getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(user => {
                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={user.id}
                      >
                        <TableCell component="th" scope="row">
                          {user.id}
                        </TableCell>
                        <TableCell>{user.firstName}</TableCell>
                        <TableCell>{user.lastName}</TableCell>
                        <TableCell>{user.emailAddress}</TableCell>
                        <TableCell>
                        <Tooltip title="Info">
                            <IconButton aria-label="Info" onClick={e => this.props.renderDetailsForm(user)}>
                              <InfoIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Edit">
                            <IconButton aria-label="Edit item" onClick={e => {return this.props.renderEditForm(user);} }>
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete">
                            <IconButton aria-label="Delete item" onClick={e => this.props.renderDeleteForm(user)}>
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}

                {emptyRows > 0 && (
                  <TableRow style={{ height: 49 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Fade>
          </Table>
        </div>
        <TablePagination
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
        <Tooltip title="Add">
          <IconButton aria-label="Add item" onClick={e => this.props.renderAddForm()} style={{'margin': '5px'}}>
            <AddIcon/>
          </IconButton>
        </Tooltip>

      </Paper>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EnhancedTable);
