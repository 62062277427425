import React, { Component } from 'react'
import {
  Button, Dialog, AppBar, Toolbar,
  IconButton, Typography, Slide,
  withStyles, DialogContent,
  DialogContentText, DialogActions } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import QuizEditor from '../item-editor/quiz-editor'
import update from 'immutability-helper'

const styles = {
  appBar: {
    position: 'relative'
  },
  flex: {
    flex: 1
  }
}

class CreateQuizForm extends Component {
  state = {
    items: [],
    deletedItems: [],
    deletedSubItems: [],
    openItem: {},
    errorQuestion: false,
    errorDialog: false,
    errorDialogContentText: '',
    closeDialog: false
  }

  componentDidUpdate(prevProps) {
    //Only compare props when array has an element.
    //This check is here to improve performance.
    if(this.props.items && this.props.items.length > 0) {
      let oldItems = [...prevProps.items].sort((a, b) => a.id - b.id)
      let newItems = [...this.props.items].sort((a, b) => a.id - b.id)
      if(JSON.stringify(oldItems) !== JSON.stringify(newItems)) {
        this.setState({
          items: this.props.items
        })
      }
    }
    if(this.props.openItem && !prevProps.openItem) {
      this.setState({
        openItem: this.props.openItem
      })
    }
  }

  handleAdd(item) {
    this.setState({
      items: update(this.state.items, {
        $push: [ item ]
      }),
      openItem: item
    })
  }

  handleChange(item) {
    const index = this.state.items.findIndex(
      obj => obj.id === item.id
    )
    this.setState({
      items: update(this.state.items, {
        $splice: [
          [ index, 1, item ]
        ]
      }),
      openItem: item,
      ...this.isItemValid(item)
    })
  }

  handleItemDelete(item) {
    const index = this.state.items.findIndex(
      obj => obj.id === item.id
    )
    let openItem = this.state.openItem
    if(openItem.id === item.id) {
      openItem = {}
    }
    this.setState(update(this.state, {
      items: { $splice: [ [ index, 1 ] ] },
      openItem: { $set: openItem },
      deletedItems: { $push: [ item ] }
    }))
  }

  handleSubItemDelete(item, subItem) {
    const index = this.state.items.findIndex(
      obj => obj.id === item.id
    )
    this.setState(update(this.state, {
      items: { $splice: [ [ index, 1, item ] ] },
      openItem: { $set: item },
      deletedSubItems: { $push: [ subItem ] }
    }))
  }

  handleSubmit() {
    let submitForm = true
    this.state.items.forEach(item => {
      let newState = this.isItemValid(item)
      if(Object.values(newState).includes(true)) {
        let errorMessage
        if(newState.errorQuestion === true) {
          errorMessage = "Please enter a question."
        }
        this.setState({
          ...newState,
          openItem: item,
          errorDialog: true,
          errorDialogContentText: errorMessage
        })
        submitForm = false
        return
      }
    })
    if(!submitForm) { return }
    this.props.onSave(
      this.state.items,
      this.state.deletedItems,
      this.state.deletedSubItems
    )
    this.handleClose()
  }

  isItemValid(item) {
    let newState = {}
    if(item.question === '') {
      newState.errorQuestion = true
    } else {
      newState.errorQuestion = false
    }
    return newState
  }

  handleTransition = React.forwardRef(function Transition(props, ref) {
    let newProps = Object.assign({}, props)
    newProps.onEntered = node => {
      setTimeout(() => node.style.transform = "none", 10)
    }
    return <Slide direction="up" ref={ref} {...newProps} />
  })

  handleErrorDialogClose() {
    this.setState({ errorDialog: false })
  }

  handleCloseButtonClick() {
    this.setState({ closeDialog: true })
  }

  handleCloseDialogCloseNo() {
    this.setState({ closeDialog: false })
  }

  handleCloseDialogCloseYes() {
    this.setState({ closeDialog: false })
    this.handleClose()
  }

  handleClose() {
    this.setState({
      items: [],
      deletedItems: [],
      deletedSubItems: [],
      openItem: {},
      errorQuestion: false,
      errorDialog: false,
      errorDialogContentText: '',
      closeDialog: false
    })
    this.props.onClose()
  }

  render() {
    return (
      <Dialog
        fullScreen
        open={this.props.open}
        onClose={this.handleCloseButtonClick.bind(this)}
        TransitionComponent={this.handleTransition}>
        <AppBar className={this.props.classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={this.handleCloseButtonClick.bind(this)}
              aria-label="Close">
              <CloseIcon />
            </IconButton>
            <Typography
              variant="h5"
              color="inherit"
              className={this.props.classes.flex}>
              Quiz Editor
            </Typography>
            <Button
              color="inherit"
              onClick={this.handleSubmit.bind(this)}>
              Save
            </Button>
          </Toolbar>
        </AppBar>
        <QuizEditor
          questions={this.state.items}
          openQuestion={this.state.openItem}
          onAdd={this.handleAdd.bind(this)}
          onChange={this.handleChange.bind(this)}
          onAnswerDelete={this.handleSubItemDelete.bind(this)}
          onQuestionDelete={this.handleItemDelete.bind(this)}
          errorQuestion={this.state.errorQuestion}
          lessonName = {this.props.lessonName}/>
        <Dialog
          open={this.state.errorDialog}
          onClose={this.handleErrorDialogClose.bind(this)}
        >
          <DialogContent>
            <DialogContentText>
              {this.state.errorDialogContentText}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleErrorDialogClose.bind(this)}
              color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.closeDialog}
          onClose={this.handleCloseDialogCloseNo.bind(this)}
        >
          <DialogContent>
            <DialogContentText>
              Are you sure that you want to close this screen?
              All of the changes you made will be lost.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleCloseDialogCloseNo.bind(this)}
              color="primary">
              No. I want to keep my changes.
            </Button>
            <Button
              onClick={this.handleCloseDialogCloseYes.bind(this)}
              color="secondary">
              Yes. I want to lose my changes.
            </Button>
          </DialogActions>
        </Dialog>
      </Dialog>
    );
  }
}

export default withStyles(styles)(CreateQuizForm)
