import React, { Component } from 'react'
import {
  Button, TextField, MenuItem,
  Dialog, DialogActions,
  DialogContent, DialogContentText,
  DialogTitle, CircularProgress,
  withStyles
} from '@material-ui/core'

const styles = theme => ({
  zoneField: {
    marginRight: theme.spacing(2)
  },
  circularProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -8,
    marginLeft: -8
  }
})

class EventsAddForm extends Component {
  state = {
    inputs: {
      name: '',
      description: '',
      zoneId: 1,
      languageId: 1
    },
    loading: false,
    formIsValid: {
      name: true,
      description: true
    },
    errors: {
      name: false,
      description: false
    }
  }

  handleInput(e, input){
    const val = e.target.value;

    this.setState(prev => {
      prev.inputs[input] = val;
      return prev
    }, () => {
      if(input === 'name' || input === 'description'){
        this.validateNotEmpty(input);
      } 
    });
    
  }

  validateNotEmpty(input){
    let fields = this.state.inputs;
    let errors = this.state.errors;
    let formIsValid = this.state.formIsValid;
    // console.log('=>', fields, errors, formIsValid);

    if(!fields[input]){
      formIsValid[input] = false;
      errors[input] = "Cannot be empty";
    }else{
      errors[input] = false;
      formIsValid[input] = true;
    }

    this.setState({
      errors: errors, 
      formIsValid: formIsValid
    });

    return formIsValid[input];
  }

  handleValidation(){
    let formIsValid = true;

    formIsValid = this.validateNotEmpty('name');
    formIsValid = this.validateNotEmpty('description');

    return formIsValid;
  }

  handleSubmit() {
    this.setState({ loading: true })
    if(this.handleValidation()){
      this.props.onEventSave(
        this.state.inputs,
        this.handleClose.bind(this)
      )
    }
  }

  handleClose(response) {
    this.setState({ loading: false })
    this.props.handleClose()
  }

  renderZoneField(zoneArr, id){
    return (
      <TextField
        select
        margin="normal"
        id="zoneId"
        label="Zone Id"
        type="text"
        ref={this.input}
        value={id}
        onChange={e => this.handleInput(e, 'zoneId')}
        className={this.props.classes.zoneField}
      >
        {zoneArr.map(zone => (
            <MenuItem key={zone.id} value={zone.id}>
              {zone.name}
            </MenuItem>
        ))}
      </TextField>
    )
  }

  renderLanguageField(langArr, id){
    return (
      <TextField
        select
        margin="normal"
        id="languageId"
        label="Language"
        type="text"
        value={id}
        ref={this.input}
        onChange={e => this.handleInput(e, 'languageId')}
      >
        {langArr.map(lang => (
            <MenuItem key={lang.id} value={lang.id}>
              {lang.name}
            </MenuItem>
        ))}
      </TextField>
    )
  }

  render(){
    // console.log(this.state.inputs, this.state.errors, this.state.formIsValid);
    return(
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        disableBackdropClick={this.state.loading}
        disableEscapeKeyDown={this.state.loading}
      >
        <DialogTitle id="form-dialog-title">
          Create a New Event
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Create a new event here!
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            helperText={!this.state.formIsValid.name ? this.state.errors.name : "(Max Length: 50)"}
            type="text"
            value={this.state.name}
            inputProps={ {maxLength: 50} }
            onChange={e => this.handleInput(e, 'name')}
            fullWidth
            required
            error={!this.state.formIsValid.name}
          />
          <TextField
            margin="dense"
            id="description"
            label="Description"
            helperText={!this.state.formIsValid.description ? this.state.errors.description : "(Max Length: 50)"}
            type="text"
            value={this.state.description}
            inputProps={ {maxLength: 50} }
            onChange={e => this.handleInput(e, 'description')}
            fullWidth
            required
            error={!this.state.formIsValid.description}
          />
          <TextField
            helperText="Note: Once created, copy on beercode cannot be edited. (Max Length: 200)"
            margin="dense"
            id="copy"
            label="Copy"
            type="text"
            value={this.state.copy}
            inputProps={ {maxLength: 200} }
            onChange={e => this.handleInput(e, 'copy')}
            fullWidth
          />
          {this.renderZoneField(this.props.zones, this.state.inputs.zoneId)}
          {this.renderLanguageField(this.props.languages, this.state.inputs.languageId)}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.props.handleClose}
            color="primary"
            disabled={this.state.loading}
          >
            Cancel
          </Button>
          <Button
            onClick={this.handleSubmit.bind(this)}
            color="primary"
            disabled={this.state.loading}
          >
            <div
              style={{ opacity: this.state.loading ? 0 : 1 }}
            >
              Save
            </div>
            {this.state.loading &&
              <CircularProgress
                size={16}
                className={this.props.classes.circularProgress}
              />
            }
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(EventsAddForm)
