import React, { Component } from 'react'
import { Fade, Typography} from '@material-ui/core'
import AddButton from '../buttons/add'
import AddEventsForm from './events-add-form'
import EditEventsForm from './events-edit-form'
import EventsTable from './eventsTable'
import DeleteEventForm from './events-delete-form'
import API from '../../API'
import update from 'immutability-helper'

export default class Events extends Component {
  constructor(props){
    super(props)
    this.state= {
        form: '',
        events: []
    }
    this.toggleAddForm = this.toggleAddForm.bind(this)
    this.toggleEditForm = this.toggleEditForm.bind(this)
    this.toggleDeleteForm = this.toggleDeleteForm.bind(this)
  }

  componentDidMount(){
    API.getAllEvents(events => {
      this.setState({ events })
    })
  }

  toggleAddForm(){
    this.setState({
      form: 'add',
      renderAddForm: !this.state.renderAddForm
    })
  }

  toggleEditForm(item){
    this.setState({
      form: 'edit',
      renderEditForm: !this.state.renderEditForm,
      editItem: item
    })
  }

  toggleDeleteForm(item){
    this.setState({
      form: 'delete',
      renderDeleteForm: !this.state.renderDeleteForm,
      deleteItem: item
    })
  }

  handleEventSave(event, cb) {
    if(event.id) {
      const index = this.state.events.findIndex(
        item => item.id === event.id
      )
      this.setState(update(this.state, {
        events: {
          $splice: [
            [index, 1, event]
          ]
        }
      }), () => { cb && cb() })
      API.putEvent(event, response => {
      })
    } else {
      API.postEvent(event, response => {
        this.setState(update(this.state, {
          events: {
            $push: [ response.event ]
          }
        }), () => { cb && cb(response) })
      })
    }
  }

  handleEventDelete(eventId) {
    const index = this.state.events.findIndex(
      item => item.id === eventId
    )
    this.setState(update(this.state, {
      events: {
        $splice: [
          [index, 1]
        ]
      }
    }))
    API.deleteEvent(eventId, response => {
    })
  }

  render(){
    const styles = {
      container: {
        width: '90%',
        margin: '20px auto'
      },
      headerText: {
        margintop: '20px'
      }
    }

    let form
    switch (this.state.form){
      case "add":
        form = <AddEventsForm
                open={this.state.renderAddForm}
                handleClose = {this.toggleAddForm}
                onEventSave = {this.handleEventSave.bind(this)}
                updateData = {this.props.updateData}
                zones = {this.props.zones}
                languages = {this.props.languages}/>
      break
      case "edit":
        form = <EditEventsForm
                open={this.state.renderEditForm}
                handleClose = {this.toggleEditForm}
                updateData = {this.props.updateData}
                onEventSave = {this.handleEventSave.bind(this)}
                item = {this.state.editItem}
                url = {this.state.eventURL}
                zones = {this.props.zones}
                languages = {this.props.languages}
                renderDeleteForm={this.toggleDeleteForm}/>
      break
      case "delete":
        form = <DeleteEventForm
                open = {this.state.renderDeleteForm}
                onClose = {this.toggleDeleteForm}
                onDelete = {this.handleEventDelete.bind(this)}
                item = {this.state.deleteItem}/>
      break
      default:
        form = this.state.form
      break
    }
    if (this.state.events !== undefined){
      return(
        <Fade in={this.props.mountAnimation}>
          <div style={styles.container}>
            {form}
            <EventsTable
            events = {this.state.events}
            renderEditForm = {this.toggleEditForm}
            renderAddForm={this.toggleAddForm}
            />
          </div>
        </Fade>
      )
    }
    else {
      return (
        <Fade in={this.props.mountAnimation}>
          <div style={styles.container}>
            <Typography
              variant="h3"
              style={styles.headerText}
              gutterBottom>Events</Typography>
              {form}
              <Typography variant="h5">Add event here</Typography>
              <AddButton renderAddForm = {this.toggleAddForm}/>
          </div>
        </Fade>
      )
    }
  }
}
