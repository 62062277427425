import React, { Component } from 'react'
import Container from './container'
import uniqid from 'uniqid'
import {
  TextField, withStyles,
  Card, CardMedia, CardContent,
  Divider, Typography, IconButton,
  Drawer } from '@material-ui/core'
import {
  Wallpaper as WallpaperIcon,
  Delete as DeleteIcon } from '@material-ui/icons'
import update from 'immutability-helper'
import ImageUpload from '../../../utils/image-upload'
import API from '../../../../API'
import {
  TextEditor, ImageEditor,
  CarouselEditor, AnswerEditor, EmbedVideoEditor, FileDownloadEditor
} from './module/module-editors'
import ModuleTypes from './module/module-types'

/***********
  Constants
************/
const nameMaxCharCount = 50
const contentLengthMaxCharCount = 50

const styles = theme => ({
  wrapper: {
    padding: '10px 50px 0',
    marginBottom: '500px',
    textAlign: 'center',
    position: 'relative'
  },
  wrapperLessonModules: {
    width: '40%',
    display: 'flex',
    flexDirection: 'column'
  },
  wrapperAllModules: {
    position: 'fixed',
    top: '250px',
    right: '170px'
  },
  wrapperLessonImage: {
    marginTop: '16px'
  },
  lessonImageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  },
  lessonImage: {
    height: '250px',
    backgroundSize: 'contain',
    width: '100%'
  },
  lessonImagePlaceholder: {
    height: '150px',
    width: '150px',
    margin: '0 auto'
  },
  divider: {
    margin: '10px 0',
    opacity: '0'
  }
})

const getImageUrl = content => {
  if(content.includes("base64")){
    return content;
  }
  return API.getImageUrl(content);
}

class LessonEditor extends Component {
  constructor(props) {
    super(props)
    this.state = {
      moduleTypes: [],
      nameCharCount: props.lesson.name.length + "/"
                      + nameMaxCharCount,
      contentLengthCharCount: props.lesson.contentLength.length + "/"
                      + contentLengthMaxCharCount,
      openDrawer: false,
      openModule: {}
    }
    this.lessonModulesContainerId = uniqid()
    this.allModulesContainerId = uniqid()
  }

  componentDidMount() {
    API.getAllContentTypes(types => {
      const moduleTypes = types.map(type => {
        const moduleType = Object.assign({}, type)
        moduleType.contentTypeId = moduleType.id
        moduleType.content = moduleType.description
        return moduleType
      })
      this.setState({ moduleTypes })
    })
  }

  handleLessonImageChange(value) {
    const newLesson = update(this.props.lesson, {
      image: {
        $set: typeof value === 'string' ? value : ''
      }
    })
    this.props.onChange(newLesson)
  }

  handleLessonThumbnailChange(value) {
    const newLesson = update(this.props.lesson, {
      thumbnail: {
        $set: typeof value === 'string' ? value : ''
      }
    })
    this.props.onChange(newLesson)
  }

  handleLessonNameChange(input) {
    let value = input.target.value
    if(value.length > nameMaxCharCount) {
      value = value.substring(0, nameMaxCharCount)
    }
    const newLesson = update(this.props.lesson, {
      name: {
        $set: value
      }
    })
    this.props.onChange(newLesson)

    this.setState(
      update(this.state, {
        nameCharCount: {
          $set: value.length + "/"
                + nameMaxCharCount
        }
      })
    )
  }

  handleLessonContentLengthChange(input) {
    let value = input.target.value
    if(value.length > contentLengthMaxCharCount) {
      value = value.substring(0, contentLengthMaxCharCount)
    }
    const newLesson = update(this.props.lesson, {
      contentLength: {
        $set: value
      }
    })
    this.props.onChange(newLesson)

    this.setState(
      update(this.state, {
        contentLengthCharCount: {
          $set: value.length + "/"
                + contentLengthMaxCharCount
        }
      })
    )
  }

  handleAddModule(module) {
    module.order = this.props.lesson.modules.length + 1
    const newLesson = update(this.props.lesson, {
      modules: {
        $push: [ module ]
      }
    })
    this.props.onChange(newLesson)
  }

  handleRemoveModule(module) {
    const index = this.props.lesson.modules
                  .findIndex(obj => obj.id === module.id)
    const newLesson = update(this.props.lesson, {
      modules: {
        $splice: [
          [index, 1]
        ]
      }
    })
    newLesson.modules.forEach((module, index) => {
      module.order = index + 1
    })
    this.props.onDelete(newLesson, module)
  }

  handleMoveModule(dragIndex, hoverIndex) {
    const dragModule = this.props.lesson.modules[dragIndex]
    const newLesson = update(this.props.lesson, {
      modules: {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragModule]
        ]
      }
    })
    newLesson.modules.forEach((module, index) => {
      module.order = index + 1
    })
    this.props.onChange(newLesson)
  }

  toggleDrawer(module) {
    if(this.state.openDrawer) {
      this.handleEditModule()
    }

    this.setState({
      openDrawer: !this.state.openDrawer,
      openModule: module || {}
    })
  }

  handleOpenModuleChange(value) {
    let newModule = Object.assign({}, this.state.openModule)
    switch(newModule.contentTypeId) {
      case ModuleTypes.IMAGE:
        if(value.includes("base64")) {
          newModule.content = value
        } else {
          newModule.caption = value === "" ? null : value
        }
        break
      case ModuleTypes.CAROUSEL:
        if(value.includes("base64") || /^m_img_\d+(_\w+)?/.test(value)) {
          newModule.content = value === "base64" ? "Image Carousel" : value
        } else {
          newModule.caption = value === "" ? null : value
        }
        break
      case ModuleTypes.ANSWER:
        if(typeof value === "boolean") {
          newModule.correctBool = value
        } else {
          newModule.answer = value
        }
        break
      case ModuleTypes.EMBED_VIDEO:
        if(value.field === 'LINK') {
          newModule.content = value.value
        } else {
          newModule.caption = value.value === "" ? null : value.value
        }
        break
      case ModuleTypes.FILE_DOWNLOAD:
        if(value.field === 'FILE') {
          newModule.content = {
            fileName: value.value.name,
            fileType: value.value.type,
            fileSize: value.value.size, //in bytes
            file: value.value // actual file
          };
        } else {
          newModule.caption = value.value === "" ? null : value.value
        }
        break
      default:
        newModule.content = value
        break
    }
    this.setState({ openModule: newModule})
  }

  handleEditModule() {
    let newModule = Object.assign({}, this.state.openModule)
    if(newModule.contentTypeId === ModuleTypes.TEXT) {
      newModule.content = newModule.content
                              .replace("<strong>", "<b>")
                              .replace("</strong>", "</b>")
                              .replace("<em>", "<i>")
                              .replace("</em>", "</i>")
    }
    const index = this.props.lesson.modules
                  .findIndex(obj => obj.id === newModule.id)
    const newLesson = update(this.props.lesson, {
      modules: {
        $splice: [
          [index, 1, newModule]
        ]
      }
    })
    this.props.onChange(newLesson)
    this.setState({ openModule: {} }) 
  }

  render(){
    const module = this.state.openModule
    let drawerContents
    switch(module.contentTypeId) {
      case ModuleTypes.TEXT:
        drawerContents =
          <TextEditor
            module={module}
            onChange={this.handleOpenModuleChange.bind(this)} />
        break
      case ModuleTypes.IMAGE:
        drawerContents =
          <ImageEditor
            module={module}
            onChange={this.handleOpenModuleChange.bind(this)} />
        break
      case ModuleTypes.CAROUSEL:
        drawerContents =
          <CarouselEditor
            module={module}
            onChange={this.handleOpenModuleChange.bind(this)} />
        break
      case ModuleTypes.ANSWER:
        drawerContents =
          <AnswerEditor
            module={module}
            onChange={this.handleOpenModuleChange.bind(this)} />
        break
      case ModuleTypes.EMBED_VIDEO:
        drawerContents =
          <EmbedVideoEditor
            module={module}
            onChange={this.handleOpenModuleChange.bind(this)} />
          break
      case ModuleTypes.FILE_DOWNLOAD:
        drawerContents =
          <FileDownloadEditor
            module={module}
            onChange={this.handleOpenModuleChange.bind(this)} />
          break
      default:
        drawerContents = <div></div>
        break
    }

    return(
      <div className={this.props.classes.wrapper}>
        <div className={this.props.classes.wrapperLessonModules}>
          <Card className={this.props.classes.wrapperLessonImage}>
            { this.props.lesson.image ? (
              <div className={this.props.classes.lessonImageContainer}>
                <IconButton
                  onClick={this.handleLessonImageChange.bind(this)}>
                  <DeleteIcon />
                </IconButton>
                <CardMedia
                  className={this.props.classes.lessonImage}
                  image={getImageUrl(this.props.lesson.image)} />
              </div>
            ) : (
              <WallpaperIcon
                className={this.props.classes.lessonImagePlaceholder}
                color={this.props.errorImage ? "secondary" : "primary"} />
            )}
            <CardContent>
              <ImageUpload
                label="Upload Lesson Image"
                error={this.props.errorImage}
                onImageChange={this.handleLessonImageChange.bind(this)} />
            </CardContent>
          </Card>
          <Card className={this.props.classes.wrapperLessonImage}>
            { this.props.lesson.thumbnail ? (
              <div className={this.props.classes.lessonImageContainer}>
                <IconButton
                  onClick={this.handleLessonThumbnailChange.bind(this)}>
                  <DeleteIcon />
                </IconButton>
                <CardMedia
                  className={this.props.classes.lessonImage}
                  image={getImageUrl(this.props.lesson.thumbnail)} />
              </div>
            ) : (
              <WallpaperIcon
                className={this.props.classes.lessonImagePlaceholder}
                color={this.props.errorThumbnail ? "secondary" : "primary"} />
            )}
            <CardContent>
              <ImageUpload
                label="Upload Lesson Thumbnail"
                error={this.props.errorThumbnail}
                onImageChange={this.handleLessonThumbnailChange.bind(this)} />
            </CardContent>
          </Card>
          <TextField
            required
            error={this.props.errorName}
            multiline
            rows="2"
            label="Lesson Name"
            margin="normal"
            value={this.props.lesson.name}
            helperText={this.state.nameCharCount}
            onChange={this.handleLessonNameChange.bind(this)} />
          <TextField
            required
            error={this.props.errorContentLength}
            multiline
            rows="2"
            label="Lesson Content Length"
            margin="normal"
            value={this.props.lesson.contentLength}
            helperText={this.state.contentLengthCharCount}
            onChange={this.handleLessonContentLengthChange.bind(this)} />
          <Divider className={this.props.classes.divider} />
          <Container
            id={this.lessonModulesContainerId}
            modules={this.props.lesson.modules}
            enableDrop={true}
            enableSort={true}
            column={true}
            allowEdits={true}
            onAddModule={this.handleAddModule.bind(this)}
            onRemoveModule={this.handleRemoveModule.bind(this)}
            onMoveModule={this.handleMoveModule.bind(this)}
            onEditModule={this.toggleDrawer.bind(this)} />
        </div>
        <div className={this.props.classes.wrapperAllModules}>
          <Typography variant="h6" gutterBottom>Modules</Typography>
          <Container
            id={this.allModulesContainerId}
            modules={this.state.moduleTypes}
            unlimitedModules={true} />
        </div>
        <Drawer
          anchor="right"
          open={this.state.openDrawer}
          onClose={this.toggleDrawer.bind(this)}
        >
          {drawerContents}
        </Drawer>
      </div>
    )
  }
}

export default withStyles(styles)(LessonEditor)
