import React, { Component } from 'react'
import {
  withStyles, FormControl,
  InputLabel, Select,
  MenuItem, Grid, Input,
  Checkbox, ListItemText,
  Chip, TextField
} from '@material-ui/core'

const styles = theme => ({
  wrapper: {
    paddingTop: theme.spacing(2)
  },
  flights: {
    width: '100%'
  },
  languages: {
    width: '100%'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing(0.25),
  },
})

class DataForm extends Component {
  state = {
    selectedLanguage: -1
  }

  handleLanguageChange(input) {
    this.setState({
      selectedLanguage: input.target.value
    })
  }

  render() {
    let selectedLanguage = this.state.selectedLanguage
    if(selectedLanguage === -1 && this.props.languages.length > 0) {
      selectedLanguage = this.props.languages[0].id
    }
    const flightsByLanguage = this.props.flights.filter(flight =>
      flight.languageId === selectedLanguage
    )
    return(
      <Grid
        container
        direction="column"
        spacing={2}
        className={this.props.classes.wrapper}
      >
        <Grid item>
          <FormControl
            className={this.props.classes.languages}
            margin="normal"
          >
            <InputLabel htmlFor="language">Filter Flights by Language</InputLabel>
            <Select
              value={selectedLanguage}
              onChange={this.handleLanguageChange.bind(this)}
              input={<Input id="language" />}
            >
              { this.props.languages.map(item =>
                  <MenuItem
                    key={item.id}
                    value={item.id}
                  >
                    {item.name}
                  </MenuItem>
                )
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl
            className={this.props.classes.flights}
            margin="normal"
          >
            <InputLabel htmlFor="flights">Flights to Complete</InputLabel>
            { this.props.flights.length > 0 &&
              <Select
                multiple
                value={this.props.selectedFlights}
                onChange={this.props.onDataChange}
                input={<Input id="flights" />}
                renderValue={selected => {
                  const selectedFlights = []
                  for(const id of selected) {
                    const flight = this.props.flights.find(flight =>
                      flight.id === id
                    )
                    if(!!flight){ // if undefined, don't push
                      selectedFlights.push(flight)
                    }
                  }
                  return (
                    <div className={this.props.classes.chips}>
                      {selectedFlights.map(flight => (
                        <Chip
                          key={flight.id}
                          label={flight.name}
                          className={this.props.classes.chip}
                          color="primary"
                        />
                      ))}
                    </div>
                  )
                }}
              >
                { flightsByLanguage.map(item =>
                    <MenuItem
                      key={item.id}
                      value={item.id}
                    >
                      <Checkbox
                        checked={
                          this.props.selectedFlights.indexOf(item.id) > -1
                        }
                      />
                      <ListItemText primary={item.name} />
                    </MenuItem>
                  )
                }
              </Select>
            }
          </FormControl>
        </Grid>
        <Grid item>
          <TextField
            label="Beercoin Amount Earned By User"
            margin="normal"
            fullWidth
            value={this.props.beercoinEarnAmount || ''}
            onChange={this.props.onBeercoinChange} />
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(DataForm)
