import React, { Component } from 'react'
import { withStyles, CardMedia, CardContent, Typography } from '@material-ui/core'

const styles = theme => ({
  cardMedia: {
    height: '250px'
  }
})

const isYoutubeLink = content => {
  // 'https://youtu.be/'
  // 'https://www.youtube.com/watch?v='
  const linkMatch = content.match(/^(?<url>(?:https?:\/\/)?(?:www\.)?(?:youtu.be\/|youtube\.com\/watch\?v=))(?<id>.*)/);
  const id = !!linkMatch && !!linkMatch.groups && !!linkMatch.groups.id && linkMatch.groups.id;
  return { isLink: !!id, linkId: id };
}

class EmbedVideoModule extends Component {
  render() {
    const { isLink, linkId } = isYoutubeLink(this.props.module.content);
    // console.log(isLink, linkId, this.props.module);
    return(
      <div>
        { isLink &&
          <div>
            <CardMedia
              className={this.props.classes.cardMedia}
              component={'iframe'}
              src={`https://www.youtube.com/embed/${linkId}`}
              />
            {this.props.module.caption &&
              <CardContent>
                {this.props.module.caption}
              </CardContent>
            }
          </div>
        }
        { !isLink &&
          <Typography component="p">
            {this.props.module.content}
          </Typography>
        }
      </div>
    )
  }
}

export default withStyles(styles)(EmbedVideoModule)
