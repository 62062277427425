import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import RichTextEditor from 'react-rte'
import { EditorState, Modifier } from 'draft-js'
import { stateFromHTML } from 'draft-js-import-html'

const styles = theme => ({
  textEditor: {
    width: '50vw',
    padding: '50px'
  },
  textField: {
    width: '100%',
    height: '100%'
  }
})

const toolbarConfig = {
  display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'HISTORY_BUTTONS'],
  INLINE_STYLE_BUTTONS: [
    {label: 'Bold', style: 'BOLD' },
    {label: 'Italic', style: 'ITALIC'},
    {label: 'Underline', style: 'UNDERLINE'}
  ],
  BLOCK_TYPE_BUTTONS: [
    {label: 'Bullets', style: 'unordered-list-item'},
    {label: 'Numbers', style: 'ordered-list-item'}
  ]
}

class TextEditor extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: RichTextEditor.createValueFromString(
        this.props.module.content, 'html'
      )
    }
  }

  handlePastedText(text, html) {
    //Remove any extra spaces when pasting text
    //from Word.
    // console.log(text);
    // console.log(html);
    const editorState = this.state.value.getEditorState()
    // same, but with negative look behind and ahead to only place <p> on spans not directly inside <p> tags
    //let html2 = html.replace(/(?<!<p[\w\W]*?>)<span[\w\W]*?>[\w\W]*?<\/span>(?!<\/p>)/g, full => 
    // let html2 = !!html && html.replace(/<span[\w\W]*?>[\w\W]*?<\/span>/g, full =>
    //   !full || full === ' ' ? full : `<p>${full}</p>`
    // );
    
    console.log(html);
    let html2 = !!html && html.split(/<\/?p[\w\W]*?>/g);
    console.log(html2);
    let html3 = html2.map((item, i) =>{
      if(i > 0 && i < html2.length - 1 && item.trim() !== ''){
        item = `<p>${item}</p>`;
      }
      else if(i === html2.length -1){
        let arr = item.split(/<\/?span[\w\W]*?>/g).map(item2 => item2.trim());
        let finalLine = arr.reduce((acc, item3, j) =>{
          if(html2.length === 1){
            if(j === 1){
              acc = !!acc ? `${acc} <p><span>${item3}` : `<p><span>${item3}`;
            } else {
              if(j < arr.length - 1){
                if(item3 !== ''){
                  acc = `${acc} ${item3}`;
                }
              } else {
                acc = `${acc}</span></p>${item3}`;
              }
            }
          } else {
            if(j < arr.length -1){
              if(item3 !== ''){
                acc = !!acc ? `${acc} ${item3}` : item3;
              }
            } else {
              acc = `<p><span>${acc}</span></p>${item3}`;
            }
          }
          return acc;
        });
        item = finalLine;
      }
      return item;
    })
    // console.log('split clipboard content ===> ', html3);
    let html4 = html3.join('');
    // console.log(html4);
    
    // let blockMap = stateFromHTML(html2 || text).blockMap
    let blockMap = stateFromHTML(html4 || text).blockMap

    // console.log(blockMap);
    //NOTE: one of the empty string checks is a special
    //blank character. It is not a duplicate check.
    //Please leave the check.
    let blockMap2 = blockMap.filter(block => block.text !== "" &&
                                        block.text !== " " &&
                                        block.text !== " ")

    let blockMap3 = blockMap2.map(block => {
      let newBlock = block
      if(newBlock.text.includes("◝")) {
        let newText = newBlock.text.replace("◝", "").trim()
        newBlock = newBlock.set("text", newText)
        newBlock = newBlock.set("type", "unordered-list-item")
      } else if(newBlock.text.includes("○")) {
        let newText = newBlock.text.replace("○", "").trim()
        newBlock = newBlock.set("text", newText)
        newBlock = newBlock.set("type", "unordered-list-item")
        newBlock = newBlock.set("depth", newBlock.depth + 1)
      }
      return newBlock
    })

    const newState = Modifier.replaceWithFragment(
                      editorState.getCurrentContent(),
                      editorState.getSelection(),
                      blockMap3)
    this.handleChange(
      this.state.value.setEditorState(
        EditorState.push(editorState, newState, 'insert-fragment')
      )
    )
    return true
  }

  handlePastedText2(text, html, editorState) {
    text = text.replaceAll(" ", " ");
    text = text.replace(/[^\S\r\n]+/g, ' ');
    text = text.split('\n').map(item => item.trim() );
    let blockMap = stateFromHTML(html || text).blockMap;
    console.log(text);
    // console.log(html);
    // console.log(blockMap);
    
    let listObj = {};
    let blockMap22 = blockMap.map((item) => {
      // console.log(item);
      if(!listObj[item.text]){
        listObj[item.text] = occurrences(text, item.text.replace(/[^\S\r\n]+/g, ' '));
      } else {
        listObj[item.text].last += 1;
      }
      let pos = 0;
      if(listObj[ item.text ].arrPos.length > 0){
        pos = listObj[ item.text ].arrPos[ listObj[item.text].last ];
      }
      let res = { 
        pos: pos, 
        block: item 
      };
      return res;
    })
    // console.log(blockMap22);
    let blockMap2 = blockMap22.sort((a, b) => {
      let newText_a, newText_b;
      newText_a = a.block.text ? a.block.text.replaceAll(" ", " ") : a.block.text;
      a.block = a.block.set("text", newText_a);
      a.block = a.block.set("type", a.block.type);
      newText_b = b.block.text ? b.block.text.replaceAll(" ", " ") : b.block.text;
      b.block = b.block.set("text", newText_b);
      b.block = b.block.set("type", b.block.type);

      return text.indexOf(a.block.text.replace(/[^\S\r\n]+/g, ' '), a.pos) - text.indexOf(b.block.text.replace(/[^\S\r\n]+/g, ' '), b.pos);
    }).map((item2) => {
      return item2.block;
    });

    console.log(listObj);

    let blockMap3 = blockMap2.filter(block => block.text !== "" &&
                                        block.text !== " " &&
                                        block.text !== " ");

    let blockMap4 = blockMap3.map(block => {
      let newBlock = block
      if(newBlock.text.includes("●")) {
        let newText = newBlock.text.replace("●", "").trim()
        let offset = newBlock.text.indexOf(newText)
        let newCharList = newBlock.getCharacterList().filter((item, i) => {
          return i >= offset;
        });
        newBlock = newBlock.set("text", newText)
        newBlock = newBlock.set("type", "unordered-list-item")
        newBlock = newBlock.set("characterList", newCharList)
      } else if(/^(○|o\s{2,})/.test(newBlock.text)){//newBlock.text.includes("○")) {
        // let newText = newBlock.text.replace("○", "").trim()
        let newText = newBlock.text.replace(/^(○|o\s{2,})/, "").trim()
        let offset = newBlock.text.indexOf(newText)
        let newCharList = newBlock.getCharacterList().filter((item, i) => {
          return i >= offset;
        });
        newBlock = newBlock.set("text", newText)
        newBlock = newBlock.set("type", "unordered-list-item")
        newBlock = newBlock.set("depth", newBlock.depth + 1)
        newBlock = newBlock.set("characterList", newCharList)
      }
      return newBlock
    })

    const newState = Modifier.replaceWithFragment(
                      editorState.getCurrentContent(),
                      editorState.getSelection(),
                      blockMap4)
    this.handleChange(
      this.state.value.setEditorState(
        EditorState.push(editorState, newState, 'insert-fragment')
      )
    )
    return true

    function occurrences(stringArr, subString, allowOverlapping = false) {
      // console.log(string, subString);
      // string += "";
      subString += "";
      if (subString.length <= 0) return (stringArr.length + 1);
  
      let n = 0,
          pos = 0,
          step = allowOverlapping ? 1 : subString.length;
      let arrPos = [];
      while (true) {
          pos = stringArr.indexOf(subString, pos);
          if (pos >= 0) {
            arrPos.push(pos);
            ++n;
            pos += step;
          } else break;
      }
      return {total: n, arrPos, last: 0};
  }
  }

  handleChange(value) {
    this.setState({ value })
    this.props.onChange(value.toString('html'))
  }

  render() {
    return(
      <div className={this.props.classes.textEditor}>
        <RichTextEditor
          rootStyle={{
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
          }}
          editorStyle={{
            fontSize: '0.875rem',
            lineHeight: '1.46429em'
          }}
          value={this.state.value}
          onChange={this.handleChange.bind(this)}
          toolbarConfig={toolbarConfig}
          handlePastedText={this.handlePastedText2.bind(this)} />
      </div>
    )
  }
}

export default withStyles(styles)(TextEditor)
