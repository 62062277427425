import React, { Component } from 'react'
import {
  Fade, Stepper, Step,
  StepButton, withStyles
} from '@material-ui/core'
import ItemGrid from '../item/item-grid'
import CreateBadgeGroupForm from '../item/item-form/create-badge-group-form'
import CreateBadgeForm from '../item/item-form/create-badge-form'
import DeleteItemForm from '../item/item-form/delete-item-form'
import update from 'immutability-helper'

const styles = theme => ({
  mainContainer: {
    width: '90%',
    margin: 'auto'
  },
  stepper: {
    backgroundColor: 'unset'
  },
  itemGrid: {
    width: '90%',
    margin: 'auto'
  }
})

const defaultColumnNames = ['GROUP', 'BADGE']

const animationDuration = 125

class ManageContent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeStep: 0,
      columnNames: defaultColumnNames,
      renderCreateBadgeGroupForm: false,
      renderCreateBadgeForm: false,
      renderDeleteForm: false,
      renderItemType: 'badgeGroup',
      openBadgeGroupId: -1,
      formItemType: '',
      formItemId: -1,
      mountGridAnimation: true
    }
  }

  /*************************
    PROGRESS BAR / STEPPER
  **************************/

  handleStep(step) {
    let newStep = this.state.activeStep - 1
    if(typeof step === "number") {
      newStep = step
    }
    this.setState({
      activeStep: newStep
    })
  }

  handleStepClick(step) {
    if(step === this.state.activeStep) { return }
    switch(step) {
      case 0:
        const numOfBadgeGroups = this.props.badgeGroups.length
        this.setState({
          mountGridAnimation: false
        }, () => {
          setTimeout(() => {
            this.setState({
              renderItemType: 'badgeGroup',
              openBadgeGroupId: -1,
              columnNames: defaultColumnNames,
              mountGridAnimation: true,
              activeStep: step
            })
          }, animationDuration * numOfBadgeGroups)
        })
        break
      default:
        break
    }
  }

  /***************
    BADGE GROUPS
  ****************/

  handleBadgeGroupClick(badgeGroup) {
    const numOfBadgeGroups = this.props.badgeGroups.length
    this.setState({
      mountGridAnimation: false
    }, () => {
      setTimeout(() => {
        this.setState({
          renderItemType: 'badge',
          openBadgeGroupId: badgeGroup.id,
          columnNames: update(this.state.columnNames, {
            $splice: [
              [0, 1, badgeGroup.name]
            ]
          }),
          mountGridAnimation: true
        })
      }, animationDuration * numOfBadgeGroups)
    })
    this.handleStep(1)
  }

  handleBadgeGroupEdit(badgeGroup) {
    this.toggleCreateBadgeGroupForm(badgeGroup.id)
  }

  handleBadgeGroupAdd() {
    this.toggleCreateBadgeGroupForm()
  }

  handleBadgeGroupDelete(badgeGroup) {
    this.toggleDeleteForm('badgeGroup', badgeGroup.id)
  }

  /*********
    BADGES
  **********/

  handleBadgeClick(badge) {
    this.handleBadgeEdit(badge)
  }

  handleBadgeEdit(badge) {
    this.toggleCreateBadgeForm(badge.id)
  }

  handleBadgeAdd() {
    this.toggleCreateBadgeForm()
  }

  handleBadgeDelete(badge) {
    this.toggleDeleteForm('badge', badge.id)
  }

  /******************
    SAVE/DELETE ITEM
  *******************/

  handleFormSave(newItem, deletedSubItems) {
    if(!newItem) { return }
    switch(this.state.formItemType) {
      case "badgeGroup":
        if(!newItem.order) {
          newItem.order = this.props.badgeGroups.length + 1
        }
        this.props.onBadgeGroupSave(newItem, newBadgeGroup => {
          const translations = newItem.translations.map(translation => {
            let id = parseInt(translation.id, 10)
            if(Number.isNaN(id)) { delete translation.id }
            translation.badgegroupId = newBadgeGroup.id
            return translation
          })
          this.props.onBadgeGroupTranslationSave(translations, () => {
            if(deletedSubItems && deletedSubItems.length > 0) {
              this.props.onBadgeGroupTranslationDelete(deletedSubItems)
            }
          })
        })
        break
      case "badge":
        if(!newItem.badgegroupId) {
          newItem.badgegroupId = this.state.openBadgeGroupId
        }
        this.props.onBadgeSave(newItem, newBadge => {
          const translations = newItem.translations.map(translation => {
            let id = parseInt(translation.id, 10)
            if(Number.isNaN(id)) { delete translation.id }
            translation.badgeId = newBadge.id
            return translation
          })
          this.props.onBadgeTranslationSave(translations, () => {
            if(deletedSubItems && deletedSubItems.length > 0) {
              this.props.onBadgeTranslationDelete(deletedSubItems)
            }
          })
        })
        break
      default:
        break
    }
  }

  handleFormDelete() {
    switch(this.state.formItemType) {
      case "badgeGroup":
        this.props.onBadgeGroupDelete(this.state.formItemId)
        break
      case "badge":
        this.props.onBadgeDelete(this.state.formItemId)
        break
      default:
        break
    }
  }

  /********
    FORMS
  *********/

  toggleCreateBadgeGroupForm(itemId) {
    this.setState({
      renderCreateBadgeGroupForm: !this.state.renderCreateBadgeGroupForm,
      formItemType: 'badgeGroup',
      formItemId: itemId ? itemId : -1
    })
  }

  toggleCreateBadgeForm(itemId) {
    this.setState({
      renderCreateBadgeForm: !this.state.renderCreateBadgeForm,
      formItemType: 'badge',
      formItemId: itemId ? itemId : -1
    })
  }

  toggleDeleteForm(itemType, itemId) {
    this.setState({
      renderDeleteForm: !this.state.renderDeleteForm,
      formItemType: itemType,
      formItemId: itemId ? itemId : -1
    })
  }

  render() {
    //Sort and filter data
    const badgeGroups = this.props.badgeGroups
    badgeGroups.sort((a,b) => a.order - b.order)
    const badges = this.props.badges.filter(badge =>
      badge.badgegroupId === this.state.openBadgeGroupId
    )

    let itemsToShow = []
    let allowEditing = true
    let allowAdding = true
    let allowDeleting = true
    let allowMoving = true
    let handleClick = () => {}
    let handleEdit = () => {}
    let handleAdd = () => {}
    let handleDelete = () => {}
    let handleMove = () => {}
    let handleMoveFinished = () => {}

    switch(this.state.renderItemType) {
      case "badgeGroup":
        itemsToShow = badgeGroups
        allowMoving = false
        handleClick = this.handleBadgeGroupClick.bind(this)
        handleEdit = this.handleBadgeGroupEdit.bind(this)
        handleAdd = this.handleBadgeGroupAdd.bind(this)
        handleDelete = this.handleBadgeGroupDelete.bind(this)
        break
      case "badge":
        itemsToShow = badges
        allowMoving = false
        handleClick = this.handleBadgeClick.bind(this)
        handleEdit = this.handleBadgeEdit.bind(this)
        handleAdd = this.handleBadgeAdd.bind(this)
        handleDelete = this.handleBadgeDelete.bind(this)
        break
      default:
        break
    }

    //If a form needs to be opened, find the
    //item that the form can consume.
    let badgeGroupFormItem
    let badgeFormItem
    if(this.state.formItemId > -1) {
      switch(this.state.formItemType) {
        case "badgeGroup":
          badgeGroupFormItem = badgeGroups.find(badgeGroup =>
            badgeGroup.id === this.state.formItemId
          )
          badgeGroupFormItem.translations =
            this.props.badgeGroupTranslations.filter(translation =>
              translation.badgegroupId === this.state.formItemId
            )
          break
        case "badge":
          badgeFormItem = badges.find(badge =>
            badge.id === this.state.formItemId
          )
          badgeFormItem.translations =
            this.props.badgeTranslations.filter(translation =>
              translation.badgeId === this.state.formItemId
            )
          break
        default:
          break
      }
    }

    //When rendering between the item form
    //and the delete form, make sure that
    //the input data is not shared between
    //the forms. Each form should have
    //its own data input to ensure that
    //there are no unexpected data changes.
    let deleteItem = null
    if(this.state.renderDeleteForm) {
      deleteItem = Object.assign({}, badgeGroupFormItem || badgeFormItem)
      badgeGroupFormItem = null
      badgeFormItem = null
    }

    return(
      <Fade in={this.props.mountAnimation}>
        <div className={this.props.classes.mainContainer}>
          <CreateBadgeGroupForm
            open = {this.state.renderCreateBadgeGroupForm}
            item = {badgeGroupFormItem}
            onClose = {this.toggleCreateBadgeGroupForm.bind(this)}
            onSave = {this.handleFormSave.bind(this)} />
          <CreateBadgeForm
            open = {this.state.renderCreateBadgeForm}
            item = {badgeFormItem}
            onClose = {this.toggleCreateBadgeForm.bind(this)}
            onSave = {this.handleFormSave.bind(this)}
            badges = {this.props.badges} />
          <DeleteItemForm
            open = {this.state.renderDeleteForm}
            item = {deleteItem}
            onClose = {this.toggleDeleteForm.bind(this)}
            onDelete = {this.handleFormDelete.bind(this)} />
          {!this.state.renderCreateBadgeForm &&
            <div>
              <Stepper
                className={this.props.classes.stepper}
                activeStep={this.state.activeStep}
                alternativeLabel
              >
                {this.state.columnNames.map((label, index) => {
                  return (
                    <Step key={label}>
                      <StepButton
                        onClick={e => { this.handleStepClick(index) } }
                      >
                        {label}
                      </StepButton>
                    </Step>
                  )
                })}
              </Stepper>
              <div className={this.props.classes.itemGrid}>
                <ItemGrid
                  mountAnimation = {this.state.mountGridAnimation}
                  animationDuration = {animationDuration}
                  items = {itemsToShow}
                  allowAdding = {allowAdding}
                  allowEditing = {allowEditing}
                  allowDeleting = {allowDeleting}
                  allowMoving = {allowMoving}
                  onItemClick = {handleClick}
                  onItemEdit = {handleEdit}
                  onItemAdd = {handleAdd}
                  onItemDelete = {handleDelete}
                  onItemMove = {handleMove}
                  onItemMoveFinished = {handleMoveFinished}
                />
              </div>
            </div>
          }
        </div>
      </Fade>
    )
  }
}

export default withStyles(styles)(ManageContent)
