import React, { Component } from 'react'
import {
  withStyles, Grid, TextField,
  FormControl, Select, MenuItem,
  InputLabel
} from '@material-ui/core'

const INTERVALS = [
  { name: "days" },
  { name: "weeks" },
  { name: "months" }
]

const styles = theme => ({
  wrapper: {
    paddingTop: theme.spacing(4.5)
  },
  streakCount: {
    width: 100
  },
  picker: {
    width: 130
  }
})

class CountStreakForm extends Component {
  render() {
    return(
      <Grid
        container
        direction="column"
        spacing={2}
        className={this.props.classes.wrapper}
      >
        <Grid item>
          <Grid container spacing={16}>
            <Grid item>
              <TextField
                className={this.props.classes.streakCount}
                label="Streak Count"
                margin="none"
                value={this.props.streakCount || ''}
                onChange={this.props.onStreakCountChange} />
            </Grid>
            <Grid item>
              <FormControl
                margin="none"
              >
                <InputLabel>Streak Interval</InputLabel>
                <Select
                  className={this.props.classes.picker}
                  value={this.props.streakInterval || ''}
                  onChange={this.props.onStreakIntervalChange}
                  inputProps={{
                    classes: {
                      select: this.props.classes.pickerInput
                    }
                  }}
                >
                  { INTERVALS.map(item =>
                    <MenuItem
                      key={item.name}
                      value={item.name}
                    >
                      {item.name}
                    </MenuItem>
                    )
                  }
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <TextField
            label="Beercoin Amount Earned By User"
            margin="normal"
            fullWidth
            value={this.props.beercoinEarnAmount || ''}
            onChange={this.props.onBeercoinChange} />
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(CountStreakForm)
