import React, { Component } from 'react'
import {
  Fade, Grid, Card,
  CardContent, withStyles,
  Typography, CircularProgress,
  Table, TableHead, TableBody,
  TableRow, TableCell, FormControl,
  Select, MenuItem, Tooltip, Chip, AppBar, Button
} from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import C3Chart from 'react-c3js'
import './analytics.css'
import { DatePicker } from '@material-ui/pickers'
import API from '../../API'
import update from 'immutability-helper'
import InputLabel from "@material-ui/core/InputLabel";
import { InView } from 'react-intersection-observer';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import XLSX from 'xlsx';
// import moment from "moment";
// import { CardTravel } from '@material-ui/icons'

const styles = theme => ({
  grid: {
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    width: '100%'
  },
  filter_grid: {
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    width: '100%'
  },
  title_grid: {
    height: "50px"
  },
  card: {
    height: '200px',
    minHeight: '200px',
    overflow: 'auto'
  },
  largeCard: {
    height: '425px',
    minHeight: '425px',
    overflow: 'auto'
  },
  printingCard:{
    boxShadow: 'unset !important',
    border: '1px solid #00000033 !important'
  },
  rowCard: {
    height: '200px',
    width: '100%'
  },
  pickerContainer: {
    paddingBottom: '7px'
  },
  picker: {
    // width: '45px'
    width: '90px'
  },
  pickerInput: {
    padding: '1px 0 3px',
    cursor: 'pointer'
  },
  pickerFrom: {
    paddingRight: '6px'
  },
  pickerTo: {
    paddingRight: '6px',
    paddingLeft: '6px'
  },
  progressContainer: {
    width: '100%',
    position: 'absolute'
  },
  progress: {
    margin: '35vh auto',
    display: 'block'
  },
  dropdown: {
    padding: '0 23px 2px 0'
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  analyticsNav: {
    padding: '10px',
    backgroundColor: '#C0C0C0',
    display: 'grid',
    // gridTemplateColumns: '50% 50%'
  },
  rightContainer: {
    padding: '5px',
    paddingRight: '20px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  rightContainerButton: {
    marginRight: '20px'
  },
  buttonProgress: {
    color: theme.palette.common.white,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -8,
    marginLeft: -8
  },
})

class Analytics extends Component {
  state = {
    overviewCharts: [],
    regionalCharts: [],
    startDate: {},
    endDate: {},
    selectedDropdownTableFilter: 0,
    registeredUsers: 0,
    zones: [],
    zoneFilterSelected: 0,
    offices: [],
    officeFilterSelected: 0,
    availableOffices: [],
    selectedOffices: [],
    lastSelectedOffices: [],
    countries: [],
    countryFilterSelected: 0,
    selectedCountries: [],
    lastSelectedCountries: [],
    startData: null,
    endData: null,
    downloadPdfInProgres: false,
    downloadXlsInProgres: false
  };

  cardsRefs = {};

  componentDidMount() {
    const startDate = new Date();
    startDate.setHours(0, 0, 0, 0);
    // startDate.setFullYear(startDate.getFullYear() - 1);
    startDate.setTime(startDate.getTime() - 3*28*24*60*60*1000);
    const endDate = new Date()
    
    let overviewPlaceholders = API.getChartOverviewPlaceholders();// API.getChartPlaceholders('', '');
    let regionalPlaceholders = API.getChartRegionalPlaceholders();

    let obj_startDate = {};
    let obj_endDate = {};

    overviewPlaceholders.forEach(oPh => {
      obj_startDate[oPh.id] = startDate;
      obj_endDate[oPh.id] = endDate;
    });
    regionalPlaceholders.forEach(rPh => {
      obj_startDate[rPh.id] = startDate;
      obj_endDate[rPh.id] = endDate;
    });

    this.setState({
      startDate: obj_startDate, endDate: obj_endDate
    });

    overviewPlaceholders.forEach(oPh => {
      API.getOneAnalyticsById({
        analyticsId: oPh.id,
        startDate,
        endDate,
        zoneId: '',
        // officeId: '',
        // country: ''
        offices: [],
        countries: []
      }, overviewChart => {
        if(overviewChart !== undefined && overviewChart !== null){
          const index = overviewPlaceholders.findIndex(
            pholder => pholder.id === overviewChart.id
          );
          overviewPlaceholders[index] = overviewChart;
          // before the first chart returns, there is nothing, after there is a chart + placeholders for each chart until they load
          this.setState({ overviewCharts: [...overviewPlaceholders] }); 

          if(!!overviewChart.originalRowCount && overviewChart.originalRowCount >= 25){ // default is 10
            API.getOneAnalyticsById({
              analyticsId: oPh.id,
              startDate,
              endDate,
              zoneId: '',
              // officeId: '',
              // country: ''
              offices: [],
              countries: [],
              maxPoints: 50 // 50 is enough
            }, largerOverviewChart => {
              if(largerOverviewChart !== undefined && largerOverviewChart !== null){
                const index = overviewPlaceholders.findIndex(
                  pholder => pholder.id === largerOverviewChart.id
                );
                overviewPlaceholders[index] = largerOverviewChart;
                this.setState({ overviewCharts: [...overviewPlaceholders] });
              }
            });
          }
        }
      });
    });

    regionalPlaceholders.forEach(rPh => {
      API.getOneAnalyticsById({
        analyticsId: rPh.id,
        startDate,
        endDate,
        zoneId: '',
        // officeId: '',
        // country: ''
        offices: [],
        countries: []
      }, regionalChart => {
        if(regionalChart !== undefined && regionalChart !== null){
          const index = regionalPlaceholders.findIndex(
            pholder => pholder.id === regionalChart.id
          );
          regionalPlaceholders[index] = regionalChart;
          this.setState({ regionalCharts: [...regionalPlaceholders] }); 

          if(!!regionalChart.originalRowCount && regionalChart.originalRowCount >= 25){ // default is 10
            API.getOneAnalyticsById({
              analyticsId: rPh.id,
              startDate,
              endDate,
              zoneId: '',
              // officeId: '',
              // country: ''
              offices: [],
              countries: [],
              maxPoints: 50 // 50 is enough
            }, largerRegionalChart => {
              if(largerRegionalChart !== undefined && largerRegionalChart !== null){
                const index = regionalPlaceholders.findIndex(
                  pholder => pholder.id === largerRegionalChart.id
                );
                regionalPlaceholders[index] = largerRegionalChart;
                this.setState({ regionalCharts: [...regionalPlaceholders] });
              }
            });
          }
        }
      });
    });

    // for (let i = 0; i < overviewPlaceholders.length; i++) {
    //   API.getOneAnalytics(i, startDate, endDate,'','', chart => {
    //     if(chart !== undefined && chart !== null){ // at least it wont throw a white screen now
    //       console.log(overviewPlaceholders);
    //       overviewPlaceholders[i] = chart
    //       this.setState({ overviewCharts: [...overviewPlaceholders] })
    //     }
    //   });
    // }

    API.getAllZones(zones => {
      zones.push({id: 'ext', name: 'External Users'});
      this.setState({
        zones
      })
    })

    API.getAllOffices(offices => {
      this.setState({
        offices
      })
    });
    
    API.getAllCountries(countries => {
      this.setState({
        countries
      })
    });

    // here get all countries

  }

  handleDateChange(chart, date, type) {
    // console.log(chart, date, type);
    const startDate = type === "startDate" ?
      date :
      (this.state.startDate[chart.id] || new Date())
    const endDate = type === "endDate" ?
      date :
      (this.state.endDate[chart.id] || new Date())

    const startDateState = this.state.startDate
    startDateState[chart.id] = startDate
    const endDateState = this.state.endDate
    endDateState[chart.id] = endDate

    this.setState({
      startDate: startDateState,
      endDate: endDateState
    });

    // console.log(this.state.startDate, this.state.endDate);
    // console.log(startDate, endDate);

    //If user chooses today's date, make sure the time
    //is set to 12 AM. That way, the start and end dates
    //are not equal in terms of time. This is helpful
    //if the user wants to see how many logins have
    //occurred so far today (ex. from 12 AM to 8 AM).
    if(startDate.toDateString() === (new Date()).toDateString()) {
      startDate.setHours(0, 0, 0, 0)
    }

    if (
      chart.id === 'current-registered-users-comparison' || 
      chart.id === 'daily-logins' ||
      chart.id === 'daily-logins-chart' ||
      chart.id === 'lifetime-content-completed' ||
      chart.id === 'daily-challenge-completions' ||
      chart.id === 'daily-challenge-completions-chart' ||
      chart.id === 'lifetime-beercoins-earned-chart' || 
      chart.id === 'internal-beercoins-comparison' ||
      chart.id === 'external-beercoins-comparison' ||
      chart.id === 'internal-users-comparison' ||
      chart.id === 'external-users-comparison' ||
      chart.id === 'users-beercoins-evolution' ||
      chart.id === 'count-users-zone-evolution-chart' ||
      chart.id === 'count-beercoins-zone-evolution-chart'
      ) {
      API.getOneAnalyticsById({
        analyticsId: chart.id,
        startDate: startDate,
        endDate : endDate
      }, response => {
        if(response !== undefined && response !== null){
          chart = response
          const index = this.state.overviewCharts.findIndex(
            oldChart => oldChart.id === chart.id
          )
          chart.data['unload'] = true;
          this.setState(update(this.state, {
            overviewCharts: {
              $splice: [
                [index, 1, chart]
              ]
            }
          }));

          if(!!response.originalRowCount && response.originalRowCount >= 25){ // default is 10
            API.getOneAnalyticsById({
              analyticsId: chart.id,
              startDate: startDate,
              endDate : endDate,
              maxPoints: 50 // 50 is enough
            }, largerResponse => {
              if(largerResponse !== undefined && largerResponse !== null){
                chart = largerResponse;
                const index = this.state.overviewCharts.findIndex(
                  oldChart => oldChart.id === chart.id
                )
                chart.data['unload'] = true;
                this.setState(update(this.state, {
                  overviewCharts: {
                    $splice: [
                      [index, 1, chart]
                    ]
                  }
                }));
              }
            });
          }
        }
      });
    } else if(
      chart.id === 'count-users-zone-country' ||
      chart.id === 'count-users-zone-country-chart' ||
      chart.id === 'lifetime-content-completed-region-chart' ||
      chart.id === 'lifetime-beercoins-earned-region-chart'||
      chart.id === 'top-user-beercoins-region'||
      chart.id === 'top-user-badges-region'
      ){
        let bolFilter = this.state.zoneFilterSelected === 'ext';
        API.getOneAnalyticsById({
          analyticsId: chart.id,
          startDate: startDate,
          endDate : endDate,
          zoneId: this.state.zoneFilterSelected,
          // officeId: bolFilter ? '' : this.state.officeFilterSelected,
          // country: bolFilter ? this.state.countryFilterSelected : ''
          offices: bolFilter ? [] : [...this.state.selectedOffices],
          countries: bolFilter ? [...this.state.selectedCountries] : []
        }, response => {
          if(response !== undefined && response !== null){
            chart = response
            const index = this.state.regionalCharts.findIndex(
              oldChart => oldChart.id === chart.id
            )
            chart.data['unload'] = true;
            this.setState(update(this.state, {
              regionalCharts: {
                $splice: [
                  [index, 1, response]
                ]
              }
            }));

            if(!!response.originalRowCount && response.originalRowCount >= 25){ // default is 10
              API.getOneAnalyticsById({
                analyticsId: chart.id,
                startDate: startDate,
                endDate : endDate,
                zoneId: this.state.zoneFilterSelected,
                // officeId: bolFilter ? '' : this.state.officeFilterSelected,
                // country: bolFilter ? this.state.countryFilterSelected : ''
                offices: bolFilter ? [] : [...this.state.selectedOffices],
                countries: bolFilter ? [...this.state.selectedCountries] : [],
                maxPoints: 50 // 50 is enough
              }, largerResponse => {
                if(largerResponse !== undefined && largerResponse !== null){
                  chart = largerResponse
                  const index = this.state.regionalCharts.findIndex(
                    oldChart => oldChart.id === chart.id
                  )
                  chart.data['unload'] = true;
                  this.setState(update(this.state, {
                    regionalCharts: {
                      $splice: [
                        [index, 1, largerResponse]
                      ]
                    }
                  }));
                }
              });
            }
          }
        });

    }
  }

  /**
   * Active when Zone filter changed
   */
  handleZoneFilterChange(event){
    if(this.state.zoneFilterSelected !== event.target.value){
      // Clone the offices
      let officesBckp = Object.assign([],this.state.offices);
      officesBckp = officesBckp.filter(office => {
        return office.zoneId === event.target.value
      });

      this.setState({
          zoneFilterSelected: event.target.value,
          officeFilterSelected: 0,
          availableOffices: officesBckp,
          countryFilterSelected: 0,
          selectedOffices: [],
          selectedCountries: [],
          lastSelectedOffices: [],
          lastSelectedCountries: []
        },
        this.updateRegionalAnalyticsData
      );
    }
  }

  /**
   * Active when Office filter changed
   */
  handleOfficeFilterChange(event){
    // // let self = this;
    // // console.log(event.target.value, this.state.selectedOffices);

    // // clearTimeout(this.state.multiselectTimeout);

    // let newSelection = [...event.target.value].sort();
    // let oldSelection = [...this.state.selectedOffices].sort();

    // let sameSize = newSelection.length === oldSelection.length;
    // let sameContent = newSelection.every((value, index) => value === oldSelection[index]);
    
    // // if(this.state.officeFilterSelected !== event.target.value) {
    // if(!sameSize || !sameContent) {
    //   this.setState({
    //       selectedOffices: event.target.value,
    //     }
    //   );
    // }
    let selection = [];
    if (event.target.value.length > 0){
      if(event.target.value.filter(item => item === 0).length === 0){
        selection = [...event.target.value];
      }
    }
    this.setState({
      selectedOffices: [...selection],
    });
  }

  handleCountryFilterChange(event){
    // // console.log(event);
    // // let self = this;
    // let newSelection = [...event.target.value].sort();
    // let oldSelection = [...this.state.selectedCountries].sort();

    // let sameSize = newSelection.length === oldSelection.length;
    // let sameContent = newSelection.every((value, index) => value === oldSelection[index]);

    // // if(this.state.countryFilterSelected !== event.target.value) {
    // if(!sameSize || !sameContent) {
    //   this.setState({
    //       selectedCountries: event.target.value,
    //     }
    //   );
    // }
    let selection = [];
    if (event.target.value.length > 0){
      if(event.target.value.filter(item => item === 0).length === 0){
        selection = [...event.target.value];
      }
    }
    this.setState({
      selectedCountries: [...selection],
    });
  }

  handleCloseMultiselect(){
    let newSelection, oldSelection;
    if(this.state.zoneFilterSelected === 'ext'){
      newSelection = [...this.state.lastSelectedCountries].sort();
      oldSelection = [...this.state.selectedCountries].sort();
    } else {
      newSelection = [...this.state.lastSelectedOffices].sort();
      oldSelection = [...this.state.selectedOffices].sort();
    }
    
    
    let sameSize = newSelection.length === oldSelection.length;
    let sameContent = newSelection.every((value, index) => value === oldSelection[index]);

    if(!sameSize || !sameContent) {
      
      if(this.state.zoneFilterSelected === 'ext') {
        this.setState({
          lastSelectedCountries: newSelection,
        });
      } else {
        this.setState({
          lastSelectedOffices: newSelection,
        });
      }

      this.updateRegionalAnalyticsData()
    }

  }

  /**
   * Update all regional analytics data when change main filter
   */
  updateRegionalAnalyticsData(){
    this.setState({
      regionalCharts: []
    });

    let regionalPlaceholders = API.getChartRegionalPlaceholders();

    regionalPlaceholders.forEach(rPh => {
      API.getOneAnalyticsById({
        analyticsId: rPh.id,
        startDate: this.state.startDate[rPh.id],
        endDate: this.state.endDate[rPh.id],
        zoneId: this.state.zoneFilterSelected,
        // officeId: this.state.officeFilterSelected,
        offices: [...this.state.selectedOffices],
        // country: this.state.countryFilterSelected
        countries: [...this.state.selectedCountries]
      }, regionalChart => {
        if(regionalChart !== undefined && regionalChart !== null){
          const index = regionalPlaceholders.findIndex(
            pholder => pholder.id === regionalChart.id
          );
          regionalPlaceholders[index] = regionalChart;
          this.setState({ regionalCharts: [...regionalPlaceholders] }); 

          if(!!regionalChart.originalRowCount && regionalChart.originalRowCount >= 25){ // default is 10
            API.getOneAnalyticsById({
              analyticsId: rPh.id,
              startDate: this.state.startDate[rPh.id],
              endDate: this.state.endDate[rPh.id],
              zoneId: this.state.zoneFilterSelected,
              // officeId: this.state.officeFilterSelected,
              offices: [...this.state.selectedOffices],
              // country: this.state.countryFilterSelected
              countries: [...this.state.selectedCountries],
              maxPoints: 50 // 50 is enough
            }, largerRegionalChart => {
              if(largerRegionalChart !== undefined && largerRegionalChart !== null){
                const index = regionalPlaceholders.findIndex(
                  pholder => pholder.id === largerRegionalChart.id
                );
                regionalPlaceholders[index] = largerRegionalChart;
                this.setState({ regionalCharts: [...regionalPlaceholders] }); 
              }
            });
          }
        }
      });
    });
  }

  handleDropdownTableFilterChange(event) {
    this.setState({ selectedDropdownTableFilter: event.target.value })
  }

  shouldComponentUpdate(prev, prevState){
    return this.state !== prevState
  }

  getZoneCountryTooltip(){
    return { 
      contents: function (d, defaultTitleFormat, defaultValueFormat, color) {
          let dd = [];
          let sum = d.reduce((i,c)=> i + c.value, 0);
          let totals_object = {};

          totals_object.x = d[0]['x'];
          totals_object.value = sum;// sum func
          totals_object.name = 'Total';//total will be shown in tooltip
          totals_object.index = d[0]['index'];
          totals_object.id = 'total';//c3 will use this
          
          dd = [...d];
          
          dd.push(totals_object);

          let $$ = this, config = $$.config,
          titleFormat = config.tooltip_format_title || defaultTitleFormat,
          nameFormat = config.tooltip_format_name || function (name) { return name; },
          valueFormat = config.tooltip_format_value || defaultValueFormat,
          text, i, title, value, name, bgcolor;

          dd = dd.sort((a,b) => {
              if(b.name === 'Total'){
                  return 1;
              }
              if(b.name === 'Remaining Offices' || b.name === 'Remaining Countries'){
                  return -1;
              }
              if(a.name === 'Remaining Offices' || a.name === 'Remaining Countries'){
                  return 1;
              }
              return b.value - a.value;
          });

          for (i = 0; i < dd.length; i++) {
              if (!(dd[i] && (dd[i].value || dd[i].value === 0))) { continue; }
              if (!text) {
                 title = titleFormat ? titleFormat(dd[i].x) : dd[i].x;
                 text = "<table class='" + $$.CLASS.tooltip + "'>" + (title || title === 0 ? "<tr><th colspan='2'>" + title + "</th></tr>" : "");
              }
              name = nameFormat(dd[i].name);
              value = valueFormat(dd[i].value, dd[i].ratio, dd[i].id, dd[i].index);
              bgcolor = $$.levelColor ? $$.levelColor(dd[i].value) : color(dd[i].id);
              text += "<tr class='" + $$.CLASS.tooltipName + "-" + dd[i].id + "'>";
              text += "<td class='name'><span style='background-color:" + bgcolor + "'></span>" + name + "</td>";
              text += "<td class='value'>" + value + "</td>";
              text += "</tr>";
           }
          return text + "</table>";
      }
    };
  }

  getPieChartLegend(arr_col){
    let toHide = [];
    let total = arr_col.reduce((a,b) => a + b[1], 0);
    // console.log(arr_col);
    arr_col.forEach(elem => {
      // console.log(elem[1]/total, elem);
        if(elem[1]/total <= 0.03){
            toHide.push(`${elem[0]}`);
        }
    });
    // console.log(total, toHide, typeof toHide, typeof toHide[0]);
    return {
        position: 'right',
        hide: [...toHide]
    };
  }

  getPieChartLabel(){
    return {
      label: {
        threshold: 0.1,
        format: function(value, ratio, id) {
          // return [value, ratio].join();
          return value;
        }
      }
    }
  }

  groupChartData(chartData){
    let columns = [...chartData.columns], newColumns = [], total = 0, sum = 0, thr = 0, idx = 0;
    if(chartData.type === 'pie'){
      
      columns.sort((a,b)=> a[1] - b[1]);
      for(let i = 0; i < columns.length; i++){
        total = total + columns[i][1];
      }
      thr = total * 0.15;
      for(let j = 0; j < columns.length; j++){
        if(sum > thr){
            idx = j;
            newColumns.push(['Others', sum]);
            break;
        }
        sum += columns[j][1];
      }
      for(let k = idx+1; k < columns.length; k++){
        newColumns.push(columns[k]);
      }
      chartData.columns = [...newColumns];
    } 
    return chartData;
  }

  getChartAxis(chart){
    let axis = chart.axis;
    if(!axis) { // axis undefined, so set it
      axis = { 
        y: {
          min: 0,
          padding: {
            bottom: 0
          }
        } 
      }; 
    } else{
      if(axis.y === undefined || axis.y === null){ // y undefined, so set it
        axis.y = { 
          min: 0,
          padding: { 
            bottom: 0 
          } 
        }; 
      } else {
        if(axis.y.min === undefined || axis.y.min === null){ // min undefined, so set it
          axis.y.min = 0;
        }
        if(axis.y.padding === undefined || axis.y.padding === null){
          axis.y.padding = { 
            bottom: 0 
          }
        }
      }
    }
    return axis;
  }

  getPieChartTotal(data){
    let total = 0;
    data.columns.forEach(element => {
      for(let i = 1; i< element.length; i++){
        total += element[i];
      }
    });
    return total;
  }

  getZoneName(zoneId){
    let zone = this.state.zones.find(elem => elem.id === zoneId);
    let filterName = '';
    if(zone){
      filterName = ` - ${zone.name}`
      // if(zoneId === 'ext'){
      //   let country = this.state.countries.find(elem2 => elem2.id === this.state.countryFilterSelected);
      //   filterName += !!country ? ` - ${country.name}` : '';
      // } else{
      //   let office = this.state.offices.find(elem3 => elem3.id === this.state.officeFilterSelected);
      //   filterName += !!office ? ` - ${office.name}` : '';
      // }
    }
    return filterName;
  }

  getOfficeName(officeId){
    let office = this.state.offices.find(elem => elem.id === officeId);
    let name = '';
    if(office){
      name = office.name;
    }
    return name;
  }
  
  getCountryName(countryId){
    let country = this.state.countries.find(elem => elem.id === countryId);
    let name = '';
    if(country){
      name = country.name;
    }
    return name;
  }

  setCardRef(refName, cardRef){
    const refs = {...this.cardsRefs};
    refs[refName] = cardRef;
    this.cardsRefs = {...refs};
  }


  _getCardImg(cardsList, index, callback){
    const ref = this.cardsRefs[cardsList[index].id];
    ref.style = 'height: auto !important; width: fit-content;';
    html2canvas(this.cardsRefs[cardsList[index].id]).then(canvas=>{
      setTimeout(() => {
        if(index < cardsList.length - 1){
          this._getCardImg(cardsList, index + 1, (arr)=>{
            callback([canvas, ...arr]);
          });
        } else {
          callback([canvas]);
        }
      }, 500);

    });
  }

  downloadXLS(){
    const cardsList = [...this.state.overviewCharts, ...this.state.regionalCharts];
    const startDateState = this.state.startDate;
    const endDateState = this.state.endDate;
    // console.log(cardsList);

    const small = [['Title', 'Value', 'Date Start', 'Date End']];
    const small2 = [['Title', 'Date Start', 'Date End', 'Starts', 'Wins', '(%)']];
    const big = [];
    
    cardsList.forEach(card => {
      // Small cards
      if ((card.type === "text" || card.type === "datetime" || card.type === "datetime-table") && !card.big){
        if(card.id === 'daily-logins'){
          const sd = (startDateState[card.id] || new Date(new Date().setHours(0,0,0,0)));
          const ed = (endDateState[card.id] || new Date(new Date().setHours(0,0,0,0)));
          small.push([
            card.title,
            card.data.value,
            sd,
            ed
          ]);
        } else if(card.id === 'daily-challenge-completions'){
          const sd = (startDateState[card.id] || new Date(new Date().setHours(0,0,0,0)));
          const ed = (endDateState[card.id] || new Date(new Date().setHours(0,0,0,0)));
          small2.push([
            card.title,
            sd,
            ed,
            card.data.columns[0][0],
            card.data.columns[0][1],
            card.data.columns[0][2]
          ]);
        } else {
          small.push([
            card.title,
            card.data.value ? card.data.value : ''
          ]);
        }
      } else {
        if(card.type === 'datetime-areachart'){
          let aux = new Array(card.data.columns[0].length + 1).fill(0).map(x => [null]);
          aux[0][0] = card.title;
          card.data.columns.forEach((column, i) => {
            column.forEach((val, j) => {
              aux[j][i + 1] = val;
              if(val === 'x' && i === 0 && j === 0){
                aux[j][i + 1] = 'Date';
              }
            });
          });

          let sheetData = {
            name: camelCase(card.id),
            data: XLSX.utils.aoa_to_sheet(aux)
          };

          sheetData.data['!cols'] = fitToColumn(aux);
          
          big.push(sheetData);

        } else if(card.type === 'dropdown-table'){
          let auxSheet = XLSX.utils.aoa_to_sheet([[card.title]]);
          auxSheet['!cols'] = fitToColumn([[card.title]]);
          // let tables = [];
          let previous = 0;
          card.data.dropdownItems.forEach((item, i) => {
            const table = card.data.tables[i];
            const aux = {
              subTitle: item.name,
              columnCount: table.columnNames.length,
              rowCount: table.columns.length + 1,
              data: [[...table.columnNames], ...table.columns]
            };
            // tables.push();
            XLSX.utils.sheet_add_aoa(auxSheet, [[aux.subTitle]], {
              origin: {
                r: 1, 
                c: previous
              }
            });
            XLSX.utils.sheet_add_aoa(auxSheet, aux.data, {
              origin: {
                r: 1, 
                c: previous + 1
              }
            });
            if(i > 0){
              auxSheet['!cols'].push(...fitToColumn([[aux.subTitle]]))
            }
            auxSheet['!cols'].push(...fitToColumn(aux.data), {wch: 8});
            previous += aux.columnCount + 2;
          });

          big.push({
            name: camelCase(card.id),
            data: auxSheet
          });
        } else {
          // console.log(card);
          const sd = (startDateState[card.id] || new Date(new Date().setHours(0,0,0,0)));
          const ed = (endDateState[card.id] || new Date(new Date().setHours(0,0,0,0)));

          let auxSheet = XLSX.utils.aoa_to_sheet([[card.title]]);
          auxSheet['!cols'] = fitToColumn([[card.title]]);

          let aux = [];
          let originCell = 'B1';

          if(card.type.includes('datetime')){
            // use sd, ed
            XLSX.utils.sheet_add_aoa(auxSheet, [['Date Start', 'Date End'], [sd, ed]], {origin: 'B1'});
            auxSheet['!cols'].push(...fitToColumn([['Date Start', 'Date End'], [sd, ed]]), {wch: 8});
            originCell = 'E1';
          }
          if(card.type.includes('table')){
            aux.push([...card.data.columnNames]);
          }
          aux.push(...card.data.columns);
          XLSX.utils.sheet_add_aoa(auxSheet, aux, {origin: originCell});
          auxSheet['!cols'].push(...fitToColumn(aux));

          big.push({
            name: camelCase(card.id),
            data: auxSheet
          });
        }
      }
    });

    let worksheet_01 = XLSX.utils.aoa_to_sheet(small);

    XLSX.utils.sheet_add_aoa(worksheet_01, small2, {origin: 'F1'});
    
    worksheet_01['!cols'] = fitToColumn(small);
    // worksheet_01['!cols'].push({wch: 8});
    worksheet_01['!cols'].push({wch: 8}, ...fitToColumn(small2));
    // console.log(worksheet_01, worksheet_01['!cols']);

    var workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet_01, "Small Cards");
    big.forEach(sheet => {
      XLSX.utils.book_append_sheet(workbook, sheet.data, sheet.name);
    })
    XLSX.writeFile(workbook,`HoppyAnalytics.xlsx`);



    function camelCase(str){
      let aux = str.split('-');
      aux = aux.map(item => {
        item = item.slice(0,1).toUpperCase() + item.slice(1);
        return item;
      });
      return aux.join('').slice(0,31);
    }
    
    function fitToColumn(arrayOfArray) {
      // get maximum character of each column
      return arrayOfArray[0].map((a, i) => ({ wch: Math.max(...arrayOfArray.map(a2 => {
        let w = 8;
        if(Object.prototype.toString.call(a2[i]) === '[object Date]'){
          w = a2[i].toJSON().split('T')[0].length;
        } else{
          w = `${a2[i]}`.length;
        }
        return w < 8 ? 8 : w;
      })) }));
    }
}


  downloadPDF(){
    this.setState({ downloadPdfInProgres: true }, () => {
      console.log(this.cardsRefs, this);
      const pdfSize = [704, 995.6];// a4 aspect 1:sqrt(2)
      const pdf = new jsPDF('p','pt', pdfSize);
      const imgCards = [];

      const cardsList = [...this.state.overviewCharts, ...this.state.regionalCharts];
      this._getCardImg(cardsList, 0, (arr) => {
        imgCards.push(...arr);
        let lastBig = false;
        let onThisLine = 0;
        const smallHeight = 200;
        const bigHeight = 425;
        let yPos = 16;
        let xPos = 16;
        let maxWidth = 0;
        let maxHeight = 0;
        let minWidth = 0;
        let minHeight = 0;
        let thisLineMaxHeight = 0;
        let lastLineMaxHeight = 0;
        let lastWidth = 0;
        imgCards.forEach((imgData) => {
          if(imgData.width > maxWidth){
            maxWidth = imgData.width;
          }
          if(imgData.height > maxHeight){
            maxHeight = imgData.height;
          }
          if(minWidth === 0 || imgData.width < minWidth){
            minWidth = imgData.width;
          }
          if(minHeight === 0 || imgData.height < minHeight){
            minHeight = imgData.height;
          }
        });

        let scale = smallHeight / minHeight;

        imgCards.forEach((imgData, i) => {
          let thisBig = false;
          let cardWidth = imgData.width * scale;
          let cardHeight = imgData.height * scale;
          console.log(scale, cardWidth, cardHeight, pdfSize);

          if(cardHeight > 200){
            thisBig = true;
          } 
          
          xPos += (lastWidth + 16);
          if(xPos > pdfSize[0] - cardWidth - 16){
            onThisLine = 0;
            lastLineMaxHeight = thisLineMaxHeight;
            thisLineMaxHeight = 0;
            xPos = 16;
          }

          if(onThisLine === 0){
            // yPos += lastBig ? bigHeight + 32 : smallHeight + 32;
            yPos += lastLineMaxHeight + 16;
            if(yPos > pdfSize[1] - cardHeight - 16){
              yPos = 16;
              pdf.addPage();
            }
          }

          if(cardHeight > pdfSize[1] - yPos - 16){
            let scale2 = (pdfSize[1] - yPos - 16) / cardHeight;
            cardHeight *= scale2;
            cardWidth *= scale2;
            console.log('===> ',scale2, cardWidth, cardHeight);
          }

          if(thisLineMaxHeight < cardHeight){
            thisLineMaxHeight = cardHeight;
          }

          // const img = imgData.toDataURL('image/png');
          pdf.addImage(imgData, 'JPG', xPos, yPos, cardWidth, cardHeight, `card_${i}`);
          lastBig = thisBig;
          lastWidth = cardWidth;
          onThisLine += 1;
        });
        pdf.save('analytics.pdf');
  
        setTimeout(() => {
          this.setState({
            downloadPdfInProgres: false
          })
        }, 500)
      });

    });
  }

  render() {
    return(
      <div>
      <Fade in={this.state.overviewCharts.length === 0 && this.state.regionalCharts.length === 0}>
        <div className={this.props.classes.progressContainer}>
          <CircularProgress
            className={this.props.classes.progress}
            size={64}
          />
        </div>
      </Fade>

      <AppBar position="sticky" className={this.props.classes.analyticsNav}>
        <div className={this.props.classes.rightContainer}>
        <Tooltip title="Generate a XLSX file for the analytics cards data using current filters.">
          <Button
            variant="contained"
            color="primary"
            className={this.props.classes.rightContainerButton}
            onClick={this.downloadXLS.bind(this)}
            disabled={
              (this.state.overviewCharts.length === 0 && this.state.regionalCharts.length === 0) ||
              this.state.downloadXlsInProgres
            }
          >
            <div
              style={{ opacity: this.state.downloadXlsInProgres ? 0 : 1 }}
            >
              Download XLS
            </div>
            {this.state.downloadXlsInProgres &&
              <CircularProgress size={16} className={this.props.classes.buttonProgress} />
            }
                
          </Button>
        </Tooltip>
          <Tooltip title="Generate a PDF file for the analytics cards as images, using current filters. For parseable data, use the XLS extraction instead. WARNING: This is a slow operation.">
            <Button
              variant="contained"
              color="primary"
              className={this.props.classes.rightContainerButton}
              onClick={this.downloadPDF.bind(this)}
              disabled={
                (this.state.overviewCharts.length === 0 && this.state.regionalCharts.length === 0) ||
                this.state.downloadPdfInProgres
              }
            >
              <div
                style={{ opacity: this.state.downloadPdfInProgres ? 0 : 1 }}
              >
                Download PDF
              </div>
              {this.state.downloadPdfInProgres &&
                <CircularProgress size={16} className={this.props.classes.buttonProgress} />
              }
                  
            </Button>
          </Tooltip>
        </div>
      </AppBar>

      <Fade in={this.state.overviewCharts.length > 0}>
        <Grid
          container
          className={this.props.classes.grid}
          spacing={4}
        >
          { this.state.overviewCharts.map(o_chart =>
            <Grid
              item  
              xs={
                (o_chart.type === "text" ||
                o_chart.type === "datetime" ||
                o_chart.type === "datetime-table") &&
                !o_chart.big ?
                  3 : 6 // datetime-areachart is 6 (large)
              }
              key={o_chart.id}
            >
              <Card
                className={`${
                  (o_chart.type === "text" ||
                  o_chart.type === "datetime" ||
                  o_chart.type === "datetime-table") &&
                  !o_chart.big ?
                    this.props.classes.card :
                    this.props.classes.largeCard
                } ${this.state.downloadPdfInProgres ? this.props.classes.printingCard : ''}`}
                ref={(ref) => this.setCardRef(o_chart.id, ref)}
              >
                <CardContent>
                  <div className={this.props.classes.headerContainer}>
                  <Typography variant="h5">
                    {o_chart.title + (o_chart.type === 'datetime-pie' ? `. Total: ${this.getPieChartTotal(o_chart.data)}` : '')}
                  </Typography>
                  <Tooltip title={o_chart.description}>
                      <InfoIcon color="action"/>
                  </Tooltip>
                  </div>
                  { o_chart.type === "text" ? (
                    <Typography variant="h2" color="primary">
                      {o_chart.data.value.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}
                    </Typography>
                  ) : o_chart.type === "datetime" ? (
                    <div>
                      <Grid
                        container
                        className={this.props.classes.pickerContainer}
                      >
                        <Grid item>
                          <Typography
                            variant="body1"
                            className={this.props.classes.pickerFrom}
                          >
                            From
                          </Typography>
                        </Grid>
                        <Grid item>
                          <DatePicker
                            className={this.props.classes.picker}
                            value={this.state.startDate[o_chart.id] || new Date()}
                            onChange={(date) => {
                              this.handleDateChange(o_chart, date, "startDate")
                            }}
                            disableFuture
                            format="yyyy/MM/dd"
                            InputProps={{
                              classes: {
                                input: this.props.classes.pickerInput
                              }
                            }}
                            showTodayButton
                          />
                        </Grid>
                        <Grid item>
                          <Typography
                            variant="body1"
                            className={this.props.classes.pickerTo}
                          >
                            to
                          </Typography>
                        </Grid>
                        <Grid item>
                          <DatePicker
                            className={this.props.classes.picker}
                            value={this.state.endDate[o_chart.id] || new Date()}
                            onChange={(date) => {
                              this.handleDateChange(o_chart, date, "endDate")
                            }}
                            disableFuture
                            format="yyyy/MM/dd"
                            InputProps={{
                              classes: {
                                input: this.props.classes.pickerInput
                              }
                            }}
                            showTodayButton
                            minDate={this.state.startDate[o_chart.id] || new Date()}
                          />
                        </Grid>
                      </Grid>
                      <Typography variant="h2" color="primary">
                        {o_chart.data.value}
                      </Typography>
                    </div>
                  ) : o_chart.type === "table" ? (
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          {o_chart.data.columnNames.map((column, index) =>
                            <TableCell key={index}>{column}</TableCell>
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {o_chart.data.columns.map((column, index) =>
                          <TableRow
                            key={index}
                            className={this.props.classes.tableRow}
                          >
                            {column.map((cell, index) => {
                                  if(typeof cell === "number"){
                                    cell = cell.toLocaleString(navigator.language, { minimumFractionDigits: 0 })
                                  }
                                  return <TableCell key={index}>{cell}</TableCell>
                                }
                            )}
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  ) : o_chart.type === "datetime-table" ? (
                  <div>
                    <Grid
                      container
                      className={this.props.classes.pickerContainer}
                    >
                      <Grid item>
                        <Typography
                          variant="body1"
                          className={this.props.classes.pickerFrom}
                        >
                          From
                        </Typography>
                      </Grid>
                      <Grid item>
                        <DatePicker
                          className={this.props.classes.picker}
                          value={this.state.startDate[o_chart.id] || new Date()}
                          onChange={(date) => {
                            this.handleDateChange(o_chart, date, "startDate")
                          }}
                          disableFuture
                          format="yyyy/MM/dd"
                          InputProps={{
                            classes: {
                              input: this.props.classes.pickerInput
                            }
                          }}
                          showTodayButton
                        />
                      </Grid>
                      <Grid item>
                        <Typography
                          variant="body1"
                          className={this.props.classes.pickerTo}
                        >
                          to
                        </Typography>
                      </Grid>
                      <Grid item>
                        <DatePicker
                          className={this.props.classes.picker}
                          value={this.state.endDate[o_chart.id] || new Date()}
                          onChange={(date) => {
                            this.handleDateChange(o_chart, date, "endDate")
                          }}
                          disableFuture
                          format="yyyy/MM/dd"
                          InputProps={{
                            classes: {
                              input: this.props.classes.pickerInput
                            }
                          }}
                          showTodayButton
                          minDate={this.state.startDate[o_chart.id] || new Date()}
                        />
                      </Grid>
                    </Grid>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          {o_chart.data.columnNames.map((column, index) =>
                            <TableCell key={index}>{column}</TableCell>
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {o_chart.data.columns.map((column, index) =>
                          <TableRow
                            key={index}
                            className={this.props.classes.tableRow}
                          >
                            {column.map((cell, index) => {
                                  if(typeof cell === "number"){
                                    cell = cell.toLocaleString(navigator.language, { minimumFractionDigits: 0 })
                                  }
                                  return <TableCell key={index}>{cell}</TableCell>
                                }
                            )}
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </div>
                )
                  : o_chart.type === "dropdown-table" ? (
                    <div>
                      <Grid
                        container
                        alignItems="center"
                        justify="flex-start"
                        spacing={2}
                      >
                        <Grid item>
                          <Typography variant="body1">
                            {o_chart.data.dropdownLabel}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <FormControl>
                            <Select
                              value={ this.state.selectedDropdownTableFilter}
                              onChange={this.handleDropdownTableFilterChange.bind(this)}
                              classes={{
                                selectMenu: this.props.classes.dropdown
                              }}
                            >
                              { 
                              //if 'office-leaderboards' then add default menu item, else do not
                              o_chart.id === 'office-leaderboards' && <MenuItem value={0} >Select an Office...</MenuItem> 
                              }
                              { o_chart.data.dropdownItems.map(item =>
                                <MenuItem
                                  key={item.id}
                                  value={item.id}
                                >
                                  {item.name}
                                </MenuItem>
                                )
                              }
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            {
                              typeof o_chart.data.tables[this.state.selectedDropdownTableFilter] !== "undefined" ?
                                o_chart.data.tables[this.state.selectedDropdownTableFilter].columnNames.map((column, index) =>
                                  <TableCell key={index}>{column}</TableCell>
                                )
                                : <TableCell>Name</TableCell>
                            }
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {
                            typeof o_chart.data.tables[this.state.selectedDropdownTableFilter] !== "undefined" ?
                              o_chart.data.tables[this.state.selectedDropdownTableFilter].columns.map((column, index) =>
                              {
                                return <TableRow
                                    key={index}
                                    className={this.props.classes.tableRow}
                                >
                                  {column.map((cell, index) => {
                                        if(typeof cell === "number"){
                                          cell = cell.toLocaleString(navigator.language, { minimumFractionDigits: 0 })
                                        }
                                        return <TableCell key={index}>{cell}</TableCell>
                                      }
                                  )}
                                </TableRow>
                              }
                            )
                            : null
                          }
                        </TableBody>
                      </Table>
                    </div>
                  ) : o_chart.type === "datetime-areachart" || o_chart.type === 'datetime-pie' ? (
                    <InView>
                      {({ ref, inView, entry }) => (
                        <div ref={ref}>
                          <Grid
                            container
                            className={this.props.classes.pickerContainer}
                          >
                            <Grid item>
                              <Typography
                                variant="body1"
                                className={this.props.classes.pickerFrom}
                              >
                                From
                              </Typography>
                            </Grid>
                            <Grid item>
                              <DatePicker
                                className={this.props.classes.picker}
                                value={this.state.startDate[o_chart.id] || new Date()}
                                onChange={(date) => {
                                  this.handleDateChange(o_chart, date, "startDate")
                                }}
                                disableFuture
                                format="yyyy/MM/dd"
                                InputProps={{
                                  classes: {
                                    input: this.props.classes.pickerInput
                                  }
                                }}
                                showTodayButton
                              />
                            </Grid>
                            <Grid item>
                              <Typography
                                variant="body1"
                                className={this.props.classes.pickerTo}
                              >
                                to
                              </Typography>
                            </Grid>
                            <Grid item>
                              <DatePicker
                                className={this.props.classes.picker}
                                value={this.state.endDate[o_chart.id] || new Date()}
                                onChange={(date) => {
                                  this.handleDateChange(o_chart, date, "endDate")
                                }}
                                disableFuture
                                format="yyyy/MM/dd"
                                InputProps={{
                                  classes: {
                                    input: this.props.classes.pickerInput
                                  }
                                }}
                                showTodayButton
                                minDate={this.state.startDate[o_chart.id] || new Date()}
                              />
                            </Grid>
                          </Grid>
                          {
                            inView || this.state.downloadPdfInProgres ? 
                            <C3Chart
                              data={(o_chart.id === 'internal-users-comparison' || o_chart.id === 'internal-beercoins-comparison') ? o_chart.data : this.groupChartData(o_chart.data)}
                              legend={o_chart.type === 'datetime-pie' ? 
                              (
                                (o_chart.id === 'internal-users-comparison' || o_chart.id === 'internal-beercoins-comparison') ? {position: 'right'} : this.getPieChartLegend(o_chart.data.columns)
                              ) : o_chart.legend}
                              axis={this.getChartAxis(o_chart)}
                              tooltip={ o_chart.type === 'datetime-pie' ? {
                                format: { 
                                  value: function (value, ratio, id) { 
                                    return value; 
                                  } 
                                } 
                              } : undefined}
                              pie={o_chart.type === 'datetime-pie' ? this.getPieChartLabel() : undefined}
                            /> : null
                          }
                        </div>
                      )}
                    </InView>

                  ) :

                  <InView>
                      {({ ref, inView, entry }) => (
                        <div ref={ref}>
                          {
                            inView || this.state.downloadPdfInProgres ?
                            <C3Chart
                              data={(o_chart.id === 'internal-users-comparison' || o_chart.id === 'internal-beercoins-comparison') ? o_chart.data : this.groupChartData(o_chart.data)}
                              legend={o_chart.type === 'datetime-pie' ? 
                              (
                                (o_chart.id === 'internal-users-comparison' || o_chart.id === 'internal-beercoins-comparison') ? {position: 'right'} : this.getPieChartLegend(o_chart.data.columns)
                              ) : o_chart.legend}
                              axis={this.getChartAxis(o_chart)}
                              tooltip={ o_chart.type === 'datetime-pie' ? {
                                format: { 
                                  value: function (value, ratio, id) { 
                                    return value; 
                                  } 
                                } 
                              } : undefined}
                              pie={o_chart.type === 'datetime-pie' ? this.getPieChartLabel() : undefined}
                              
                            /> : null
                          }
                        </div>
                      )}
                  </InView>

                  }
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>
      </Fade>

      { /* FADE IN: Zone/Office FILTERS */ }
      <Fade in={this.state.zones.length > 0 && this.state.offices.length > 0 && this.state.countries.length > 0} >
        <Grid
          container
          spacing={3}
          className={this.props.classes.filter_grid}
        >
          <Grid item xs={12} className={this.props.classes.title_grid} >
            <h3>Filters</h3>
          </Grid>

          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel htmlFor="office">Zone</InputLabel> { /* Zone DROPDOWN */ }
              <Select
                value={this.state.zoneFilterSelected}
                required={true}
                onChange={this.handleZoneFilterChange.bind(this)}
              >
                <MenuItem value={0} > Select a Zone... </MenuItem>
                {
                  this.state.zones.map((zone, i) =>
                    <MenuItem key={i} value={zone.id} > {zone.name} </MenuItem>
                  )
                }
              </Select>
            </FormControl>
          </Grid>

          {
            this.state.zoneFilterSelected === 'ext' ? 
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel htmlFor="office">Country</InputLabel> { /* Country DROPDOWN */ }
                <Select
                  // value={this.state.countryFilterSelected} // if multiple, use array
                  value={this.state.selectedCountries} // if multiple, use array
                  required={true}
                  onChange={this.handleCountryFilterChange.bind(this)}
                  multiple
                  onClose={this.handleCloseMultiselect.bind(this)}
                  renderValue={(selected) => (
                    <div className={this.props.classes.chips}>
                      {selected.map((value) => (
                        <Chip key={value} label={this.getCountryName(value)} className={this.props.classes.chip} />
                      ))}
                    </div>
                  )}
                >
                  <MenuItem value={0}> Select a country... </MenuItem>
                  {
                    this.state.countries.map((country, i) =>
                      <MenuItem key={i} value={country.id} > {country.name} </MenuItem>
                    )
                  }
                </Select>
              </FormControl>
            </Grid>
            : 
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel htmlFor="office">Office</InputLabel> { /* Office DROPDOWN */ }
                <Select
                  // value={this.state.officeFilterSelected} // if multiple, use array
                  value={this.state.selectedOffices} // if multiple, use array
                  required={true}
                  onChange={this.handleOfficeFilterChange.bind(this)}
                  multiple
                  onClose={this.handleCloseMultiselect.bind(this)}
                  renderValue={(selected) => (
                    <div className={this.props.classes.chips}>
                      {selected.map((value) => (
                        <Chip key={value} label={this.getOfficeName(value)} className={this.props.classes.chip} />
                      ))}
                    </div>
                  )}
                >
                  <MenuItem value={0}> Select an office... </MenuItem>
                  {
                    this.state.availableOffices.map((office, i) =>
                      <MenuItem key={i} value={office.id} > {office.name} </MenuItem>
                    )
                  }
                </Select>
              </FormControl>
            </Grid>
          }

        </Grid>
      </Fade> { /* # filter zone/office */ }

      <Fade in={this.state.regionalCharts.length > 0 && this.state.zones.length > 0 && this.state.offices.length > 0 && this.state.countries.length > 0}>
      <Grid
          container
          className={this.props.classes.grid}
          spacing={4}
        >
          { this.state.regionalCharts.map(r_chart =>
            <Grid
              item  
              xs={
                (r_chart.type === "text" ||
                r_chart.type === "datetime" ||
                r_chart.type === "datetime-table") &&
                !r_chart.big ?
                  3 : 6 // datetime-areachart is 6 (large)
              }
              key={r_chart.id}
            >
              <Card
                className={`${
                  (r_chart.type === "text" ||
                  r_chart.type === "datetime" ||
                  r_chart.type === "datetime-table") &&
                  !r_chart.big ?
                    this.props.classes.card :
                    this.props.classes.largeCard
                } ${this.state.downloadPdfInProgres ? this.props.classes.printingCard : ''}`}
                ref={(ref) => this.setCardRef(r_chart.id, ref)}
              >
                <CardContent>
                  <div className={this.props.classes.headerContainer}>
                  <Typography variant="h5">
                    {r_chart.title + this.getZoneName(this.state.zoneFilterSelected) + (r_chart.type === 'datetime-pie' ? `. Total: ${this.getPieChartTotal(r_chart.data)}` : '')}
                  </Typography>
                  <Tooltip title={r_chart.description}>
                      <InfoIcon color="action"/>
                  </Tooltip>
                  </div>
                  { r_chart.type === "text" ? (
                    <Typography variant="h2" color="primary">
                      {r_chart.data.value.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}
                    </Typography>
                  ) : r_chart.type === "datetime" ? (
                    <div>
                      <Grid
                        container
                        className={this.props.classes.pickerContainer}
                      >
                        <Grid item>
                          <Typography
                            variant="body1"
                            className={this.props.classes.pickerFrom}
                          >
                            From
                          </Typography>
                        </Grid>
                        <Grid item>
                          <DatePicker
                            className={this.props.classes.picker}
                            value={this.state.startDate[r_chart.id] || new Date()}
                            onChange={(date) => {
                              this.handleDateChange(r_chart, date, "startDate")
                            }}
                            disableFuture
                            format="yyyy/MM/dd"
                            InputProps={{
                              classes: {
                                input: this.props.classes.pickerInput
                              }
                            }}
                            showTodayButton
                          />
                        </Grid>
                        <Grid item>
                          <Typography
                            variant="body1"
                            className={this.props.classes.pickerTo}
                          >
                            to
                          </Typography>
                        </Grid>
                        <Grid item>
                          <DatePicker
                            className={this.props.classes.picker}
                            value={this.state.endDate[r_chart.id] || new Date()}
                            onChange={(date) => {
                              this.handleDateChange(r_chart, date, "endDate")
                            }}
                            disableFuture
                            format="yyyy/MM/dd"
                            InputProps={{
                              classes: {
                                input: this.props.classes.pickerInput
                              }
                            }}
                            showTodayButton
                            minDate={this.state.startDate[r_chart.id] || new Date()}
                          />
                        </Grid>
                      </Grid>
                      <Typography variant="h2" color="primary">
                        {r_chart.data.value}
                      </Typography>
                    </div>
                  ) : r_chart.type === "table" ? (
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          {r_chart.data.columnNames.map((column, index) =>
                            <TableCell key={index}>{column}</TableCell>
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {r_chart.data.columns.map((column, index) =>
                          <TableRow
                            key={index}
                            className={this.props.classes.tableRow}
                          >
                            {column.map((cell, index) => {
                                  if(typeof cell === "number"){
                                    cell = cell.toLocaleString(navigator.language, { minimumFractionDigits: 0 })
                                  }
                                  return <TableCell key={index}>{cell}</TableCell>
                                }
                            )}
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  ) : r_chart.type === "datetime-table" ? (
                  <div>
                    <Grid
                      container
                      className={this.props.classes.pickerContainer}
                    >
                      <Grid item>
                        <Typography
                          variant="body1"
                          className={this.props.classes.pickerFrom}
                        >
                          From
                        </Typography>
                      </Grid>
                      <Grid item>
                        <DatePicker
                          className={this.props.classes.picker}
                          value={this.state.startDate[r_chart.id] || new Date()}
                          onChange={(date) => {
                            this.handleDateChange(r_chart, date, "startDate")
                          }}
                          disableFuture
                          format="yyyy/MM/dd"
                          InputProps={{
                            classes: {
                              input: this.props.classes.pickerInput
                            }
                          }}
                          showTodayButton
                        />
                      </Grid>
                      <Grid item>
                        <Typography
                          variant="body1"
                          className={this.props.classes.pickerTo}
                        >
                          to
                        </Typography>
                      </Grid>
                      <Grid item>
                        <DatePicker
                          className={this.props.classes.picker}
                          value={this.state.endDate[r_chart.id] || new Date()}
                          onChange={(date) => {
                            this.handleDateChange(r_chart, date, "endDate")
                          }}
                          disableFuture
                          format="yyyy/MM/dd"
                          InputProps={{
                            classes: {
                              input: this.props.classes.pickerInput
                            }
                          }}
                          showTodayButton
                          minDate={this.state.startDate[r_chart.id] || new Date()}
                        />
                      </Grid>
                    </Grid>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          {r_chart.data.columnNames.map((column, index) =>
                            <TableCell key={index}>{column}</TableCell>
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {r_chart.data.columns.map((column, index) =>
                          <TableRow
                            key={index}
                            className={this.props.classes.tableRow}
                          >
                            {column.map((cell, index) => {
                                  if(typeof cell === "number"){
                                    cell = cell.toLocaleString(navigator.language, { minimumFractionDigits: 0 })
                                  }
                                  return <TableCell key={index}>{cell}</TableCell>
                                }
                            )}
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </div>
                )
                  : r_chart.type === "dropdown-table" ? (
                    <div>
                      <Grid
                        container
                        alignItems="center"
                        justify="flex-start"
                        spacing={2}
                      >
                        <Grid item>
                          <Typography variant="body1">
                            {r_chart.data.dropdownLabel}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <FormControl>
                            <Select
                              value={ this.state.selectedDropdownTableFilter}
                              onChange={this.handleDropdownTableFilterChange.bind(this)}
                              classes={{
                                selectMenu: this.props.classes.dropdown
                              }}
                            >
                              { 
                              //if 'office-leaderboards' then add default menu item, else do not
                              r_chart.id === 'office-leaderboards' && <MenuItem value={0} >Select an Office...</MenuItem> 
                              }
                              { r_chart.data.dropdownItems.map(item =>
                                <MenuItem
                                  key={item.id}
                                  value={item.id}
                                >
                                  {item.name}
                                </MenuItem>
                                )
                              }
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            {
                              typeof r_chart.data.tables[this.state.selectedDropdownTableFilter] !== "undefined" ?
                                r_chart.data.tables[this.state.selectedDropdownTableFilter].columnNames.map((column, index) =>
                                  <TableCell key={index}>{column}</TableCell>
                                )
                                : <TableCell>Name</TableCell>
                            }
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {
                            typeof r_chart.data.tables[this.state.selectedDropdownTableFilter] !== "undefined" ?
                              r_chart.data.tables[this.state.selectedDropdownTableFilter].columns.map((column, index) =>
                              {
                                return <TableRow
                                    key={index}
                                    className={this.props.classes.tableRow}
                                >
                                  {column.map((cell, index) => {
                                        if(typeof cell === "number"){
                                          cell = cell.toLocaleString(navigator.language, { minimumFractionDigits: 0 })
                                        }
                                        return <TableCell key={index}>{cell}</TableCell>
                                      }
                                  )}
                                </TableRow>
                              }
                            )
                            : null
                          }
                        </TableBody>
                      </Table>
                    </div>
                  ) : r_chart.type === "datetime-areachart" || r_chart.type === 'datetime-pie' ? (

                    <InView>
                      {({ ref, inView, entry }) => (
                        <div ref={ref}>
                          <Grid
                            container
                            className={this.props.classes.pickerContainer}
                          >
                            <Grid item>
                              <Typography
                                variant="body1"
                                className={this.props.classes.pickerFrom}
                              >
                                From
                              </Typography>
                            </Grid>
                            <Grid item>
                              <DatePicker
                                className={this.props.classes.picker}
                                value={this.state.startDate[r_chart.id] || new Date()}
                                onChange={(date) => {
                                  this.handleDateChange(r_chart, date, "startDate")
                                }}
                                disableFuture
                                format="yyyy/MM/dd"
                                InputProps={{
                                  classes: {
                                    input: this.props.classes.pickerInput
                                  }
                                }}
                                showTodayButton
                              />
                            </Grid>
                            <Grid item>
                              <Typography
                                variant="body1"
                                className={this.props.classes.pickerTo}
                              >
                                to
                              </Typography>
                            </Grid>
                            <Grid item>
                              <DatePicker
                                className={this.props.classes.picker}
                                value={this.state.endDate[r_chart.id] || new Date()}
                                onChange={(date) => {
                                  this.handleDateChange(r_chart, date, "endDate")
                                }}
                                disableFuture
                                format="yyyy/MM/dd"
                                InputProps={{
                                  classes: {
                                    input: this.props.classes.pickerInput
                                  }
                                }}
                                showTodayButton
                                minDate={this.state.startDate[r_chart.id] || new Date()}
                              />
                            </Grid>
                          </Grid>
                          {
                            inView || this.state.downloadPdfInProgres ?
                            <C3Chart
                              data={this.groupChartData(r_chart.data)}
                              legend={r_chart.type === 'datetime-pie' ? 
                              (
                                (r_chart.id === 'internal-users-comparison' || r_chart.id === 'internal-beercoins-comparison') ? {position: 'right'} : this.getPieChartLegend(r_chart.data.columns)
                              ) : r_chart.legend}
                              axis={this.getChartAxis(r_chart)}
                              tooltip={ r_chart.type === 'datetime-pie' ? {
                                format: { 
                                  value: function (value, ratio, id) { 
                                    return value; 
                                  } 
                                } 
                              } : (r_chart.id === 'count-users-zone-country-chart' ? this.getZoneCountryTooltip() : undefined)}
                              pie={r_chart.type === 'datetime-pie' ? this.getPieChartLabel() : undefined}
                            />: null
                          }
                        </div>
                      )}
                    </InView>

                  ) :
                      <InView>
                          {({ ref, inView, entry }) => (
                            <div ref={ref}>
                              {
                                inView || this.state.downloadPdfInProgres ?
                                <C3Chart
                                  data={this.groupChartData(r_chart.data)}
                                  legend={r_chart.type === 'datetime-pie' ? 
                                    (
                                      (r_chart.id === 'internal-users-comparison' || r_chart.id === 'internal-beercoins-comparison') ? {position: 'right'} : this.getPieChartLegend(r_chart.data.columns)
                                    ) : r_chart.legend}
                                  axis={this.getChartAxis(r_chart)}
                                  tooltip={ r_chart.type === 'datetime-pie' ? {
                                    format: { 
                                      value: function (value, ratio, id) { 
                                        return value; 
                                      } 
                                    } 
                                  } : (r_chart.id === 'count-users-zone-country-chart' ? this.getZoneCountryTooltip() : undefined)}
                                  pie={r_chart.type === 'datetime-pie' ? this.getPieChartLabel() : undefined}
                                /> : null
                              }
                            </div>
                          )}
                      </InView>
                      
                  }
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>
      </Fade>


      </div>
    )
  }
}

export default withStyles(styles)(Analytics)
