import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { DropTarget } from 'react-dnd'
import ModuleTypes from '../../module-types'
import CarouselThumbnailModule from './carousel-thumbnail-module'
import uniqid from 'uniqid'
import { withStyles, GridList, GridListTile, Typography } from '@material-ui/core'
import { blue } from '@material-ui/core/colors'

const styles = theme => ({
  containerActive: {
    backgroundColor: blue[500],
    paddingBottom: '100px',
    transition: 'background-color 0.4s'
  },
  containerHover: {
    backgroundColor: '#F09723',
    paddingBottom: '100px',
    transition: 'background-color 0.4s'
  },
  placeholderText: {
    marginTop: '30px',
    marginBottom: '30px'
  }
})

const moduleTarget = {
  drop(props, monitor) {
    const module = monitor.getItem()
    if ( props.id !== module.containerId && props.enableDrop) {
      let newModule = Object.assign({}, module.contents)
      newModule.id = uniqid()
      props.onAddModule(newModule)
    }
    return {
      containerId: props.id,
      enableDrop: props.enableDrop
    }
  }
}

const collect = (connect, monitor) => {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop()
  }
}

class CarouselThumbnailContainer extends Component {
  render(){
    let modules = <div></div>
    if(this.props.modules) {
      modules = this.props.modules.map((module, index) => {
        return <GridListTile key={module.id} cols={1}>
          <CarouselThumbnailModule
            index={index}
            containerId={this.props.id}
            module={module}
            onRemoveModule={this.props.onRemoveModule}
            onMoveModule={this.props.onMoveModule}
            onEditModule={this.props.onEditModule}
            allowEdits={this.props.allowEdits}
            enableSort={this.props.enableSort}
          />
        </GridListTile>
        }
      )
    }

    const { connectDropTarget } = this.props

    return connectDropTarget(
      <div>
        <GridList
          cellHeight={225}
          cols={3}
        >
          {modules}
        </GridList>
        {modules.length === 0 &&
          <Typography component="h2" className={this.props.classes.placeholderText}>
            {this.props.emptyContainerLabel ?
              this.props.emptyContainerLabel : 'Please drag and drop a module.'}
          </Typography>
        }
      </div>
    )
  }
}

CarouselThumbnailContainer.propTypes = {
  connectDropTarget: PropTypes.func.isRequired,
  isOver: PropTypes.bool.isRequired,
  canDrop: PropTypes.bool.isRequired
};

export default withStyles(styles)(
  DropTarget(ModuleTypes.CAROUSEL_THUMBNAIL, moduleTarget, collect)(
    CarouselThumbnailContainer
  )
)
