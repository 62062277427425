import React, { Component } from 'react'
import {
  List, ListItem, ListItemText,
  ListItemIcon, Collapse, withStyles
} from '@material-ui/core'
import { Add as AddIcon } from '@material-ui/icons'
import Item from './item'

const styles = theme => ({

})

class ItemList extends Component {
  state = { mountAnimation: true }

  componentDidUpdate(prevProps) {
    if(this.props.items) {
      let oldIds = prevProps.items.map(item => item.id)
      oldIds.sort((a, b) => a.id - b.id)
      let newIds = this.props.items.map(item => item.id)
      newIds.sort((a, b) => a.id - b.id)

      if(oldIds.length > 0 && newIds.length > 0) {
        if(JSON.stringify(oldIds) !== JSON.stringify(newIds)) {
          this.setState({ mountAnimation: false })
          setTimeout(
            () => this.setState({ mountAnimation: true }),
            200
          )
        }
      }
    }
  }
 
  render() {
    let marginTop = []
    if(this.props.marginTop) {
      let marginTopHeight = '48px'
      if(this.props.allowEditing || this.props.allowDeleting) {
        marginTopHeight = '70px'
      }
      for(let count = 0; count < this.props.marginTop; count++) {
        marginTop.push(<div key={count} style={{ height: marginTopHeight }}></div>)
      }
    }
    return(
      <Collapse
        in={this.props.mountAnimation && this.state.mountAnimation}
        timeout={200}>
        <List>
          { marginTop }
          {this.props.items.map(item => {
            return(
              <Item
                key={item.id}
                item={item}
                itemAttributeForLabel={this.props.itemAttributeForLabel}
                selected={this.props.selectedItemId === item.id}
                allowEditing={this.props.allowEditing}
                allowDeleting={this.props.allowDeleting}
                onItemClick={this.props.onItemClick}
                onItemEdit={this.props.onItemEdit}
                onItemDelete={this.props.onItemDelete}
              />
            )
          })}
          {this.props.allowAdding &&
            <ListItem
              button
              onClick={this.props.onItemAdd}>
              <ListItemText
                primary={this.props.addItemLabel || ''} />
              <ListItemIcon>
                <AddIcon />
              </ListItemIcon>
            </ListItem>
          }
        </List>
      </Collapse>
    )
  }
}

export default withStyles(styles)(ItemList)
