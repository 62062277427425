import React, { Component } from 'react'
import ManageContentList from './manage-content-list'
import ManageContentGrid from './manage-content-grid'

export default class ManageContent extends Component {
  render() {
    return (
      <div>
        {this.props.showList ? (
          <ManageContentList
            mountAnimation = {this.props.mountAnimation}
            badgeGroups = {this.props.badgeGroups}
            badgeGroupTranslations = {this.props.badgeGroupTranslations}
            badges = {this.props.badges}
            badgeTranslations = {this.props.badgeTranslations}
            onBadgeGroupSave = {this.props.onBadgeGroupSave}
            onBadgeGroupDelete = {this.props.onBadgeGroupDelete}
            onBadgeGroupTranslationSave = {this.props.onBadgeGroupTranslationSave}
            onBadgeGroupTranslationDelete = {this.props.onBadgeGroupTranslationDelete}
            onBadgeSave = {this.props.onBadgeSave}
            onBadgeDelete = {this.props.onBadgeDelete}
            onBadgeTranslationSave = {this.props.onBadgeTranslationSave}
            onBadgeTranslationDelete = {this.props.onBadgeTranslationDelete}
          />
        ) : (
          <ManageContentGrid
            mountAnimation = {this.props.mountAnimation}
            badgeGroups = {this.props.badgeGroups}
            badgeGroupTranslations = {this.props.badgeGroupTranslations}
            badges = {this.props.badges}
            badgeTranslations = {this.props.badgeTranslations}
            onBadgeGroupSave = {this.props.onBadgeGroupSave}
            onBadgeGroupDelete = {this.props.onBadgeGroupDelete}
            onBadgeGroupTranslationSave = {this.props.onBadgeGroupTranslationSave}
            onBadgeGroupTranslationDelete = {this.props.onBadgeGroupTranslationDelete}
            onBadgeSave = {this.props.onBadgeSave}
            onBadgeDelete = {this.props.onBadgeDelete}
            onBadgeTranslationSave = {this.props.onBadgeTranslationSave}
            onBadgeTranslationDelete = {this.props.onBadgeTranslationDelete}
          />
        )}
      </div>
    )
  }
}
