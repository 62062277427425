import React, { Component } from 'react'
import {
  Button, Dialog, AppBar, Toolbar,
  IconButton, Typography, Slide,
  withStyles, DialogContent,
  DialogContentText, DialogActions } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import BadgeEditor from '../item-editor/badge-editor'
import update from 'immutability-helper'

const styles = theme => ({
  appBar: {
    position: 'relative'
  },
  flex: {
    flex: 1
  }
})

class CreateBadgeForm extends Component {
  state = {
    item: this.props.item || {
      name: '',
      description: '',
      parentBool: false,
      secretBool: false,
      repeatableBool: false,
      badgeType: '',
      icon: '',
      shape: '',
      translations : [],
      activityActions: []
    },
    deletedSubItems: [],
    errorName: false,
    errorDescription: false,
    errorType: false,
    errorIcon: false,
    errorShape: false,
    errorTranslation: false,
    errorUserActivityAction: false,
    errorDialog: false,
    errorDialogContentText: '',
    closeDialog: false
  }

  componentDidUpdate(prevProps) {
    if(this.props.item) {
      let oldItemId = -1
      if(prevProps.item && prevProps.item.id) {
        oldItemId = prevProps.item.id
      }
      if(oldItemId !== this.props.item.id) {
        this.setState({
          item: this.props.item
        })
      }
    }
  }

  handleChange(item) {
    this.setState({ item, ...this.isItemValid(item) })
  }

  handleDelete(item, subItem) {
    this.setState(update(this.state, {
      item: { $set: item },
      deletedSubItems: { $push: [ subItem ] }
    }))
  }

  handleSubmit() {
    let newState = this.isItemValid(this.state.item)
    if(Object.values(newState).includes(true)) {
      let errorMessage
      if(newState.errorName === true) {
        errorMessage = "Please add a badge name."
      }
      if(newState.errorDescription === true) {
        errorMessage = "Please add a badge description."
      }
      if(newState.errorType === true) {
        errorMessage = "Please select a badge type."
      }
      if(newState.errorIcon === true) {
        errorMessage = "Please add a badge icon."
      }
      if(newState.errorShape === true) {
        errorMessage = "Please select a badge shape."
      }
      if(newState.errorTranslation) {
        errorMessage = "Please add a translation."
      }
      if(newState.errorUserActivityAction === true) {
        errorMessage = "Please select a user action."
      }
      this.setState({
        ...newState,
        errorDialog: true,
        errorDialogContentText: errorMessage
      })
      return
    }
    this.props.onSave(this.state.item, this.state.deletedSubItems)
    this.handleClose()
  }

  handleTransition = React.forwardRef(function Transition(props, ref) {
    let newProps = Object.assign({}, props)
    newProps.onEntered = node => {
      setTimeout(() => node.style.transform = "none", 10)
    }
    return <Slide direction="up" ref={ref} {...newProps} />
  })

  handleErrorDialogClose() {
    this.setState({ errorDialog: false })
  }

  handleCloseButtonClick() {
    this.setState({ closeDialog: true })
  }

  handleCloseDialogCloseNo() {
    this.setState({ closeDialog: false })
  }

  handleCloseDialogCloseYes() {
    this.setState({ closeDialog: false })
    this.handleClose()
  }

  handleClose() {
    this.setState({
      item: {
        name: '',
        description: '',
        parentBool: false,
        secretBool: false,
        repeatableBool: false,
        badgeType: '',
        icon: '',
        shape: '',
        translations : [],
        activityActions: []
      },
      deletedSubItems: [],
      errorName: false,
      errorDescription: false,
      errorType: false,
      errorIcon: false,
      errorShape: false,
      errorTranslation: false,
      errorUserActivityAction: false,
      errorDialog: false,
      errorDialogContentText: '',
      closeDialog: false
    })

    this.props.onClose()
  }

  isItemValid(item) {
    let newState = {}
    if(item.name === '') {
      newState.errorName = true
    } else {
      newState.errorName = false
    }
    if(item.description === '') {
      newState.errorDescription = true
    } else {
      newState.errorDescription = false
    }
    if(item.badgeType === '') {
      newState.errorType = true
    } else {
      newState.errorType = false
    }
    if(item.icon === '') {
      newState.errorIcon = true
    } else {
      newState.errorIcon = false
    }
    if(item.shape === '') {
      newState.errorShape = true
    } else {
      newState.errorShape = false
    }
    if(item.translations.length === 0) {
      newState.errorTranslation = true
    } else {
      newState.errorTranslation = false
    }
    if(item.activityActions.length === 0) {
      newState.errorUserActivityAction = true
    } else {
      newState.errorUserActivityAction = false
    }
    return newState
  }

  render() {
    return (
      <Dialog
        fullScreen
        open={this.props.open}
        onClose={this.handleCloseButtonClick.bind(this)}
        TransitionComponent={this.handleTransition}>
        <AppBar className={this.props.classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={this.handleCloseButtonClick.bind(this)}
              aria-label="Close">
              <CloseIcon />
            </IconButton>
            <Typography
              variant="h5"
              color="inherit"
              className={this.props.classes.flex}>
              Badge Editor
            </Typography>
            <Button
              color="inherit"
              onClick={this.handleSubmit.bind(this)}>
              Save
            </Button>
          </Toolbar>
        </AppBar>
        <BadgeEditor
          badge={this.state.item}
          badges={this.props.badges}
          onChange={this.handleChange.bind(this)}
          onDelete={this.handleDelete.bind(this)}
          errorName={this.state.errorName}
          errorDescription={this.state.errorDescription}
          errorType={this.state.errorType}
          errorIcon={this.state.errorIcon}
          errorShape={this.state.errorShape}
          errorTranslation={this.state.errorTranslation}
          errorUserActivityAction={this.state.errorUserActivityAction}
        />
        <Dialog
          open={this.state.errorDialog}
          onClose={this.handleErrorDialogClose.bind(this)}
        >
          <DialogContent>
            <DialogContentText>
              {this.state.errorDialogContentText}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleErrorDialogClose.bind(this)}
              color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.closeDialog}
          onClose={this.handleCloseDialogCloseNo.bind(this)}
        >
          <DialogContent>
            <DialogContentText>
              Are you sure that you want to close this screen?
              All of the changes you made will be lost.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleCloseDialogCloseNo.bind(this)}
              color="primary">
              No. I want to keep my changes.
            </Button>
            <Button
              onClick={this.handleCloseDialogCloseYes.bind(this)}
              color="secondary">
              Yes. I want to lose my changes.
            </Button>
          </DialogActions>
        </Dialog>
      </Dialog>
    );
  }
}

export default withStyles(styles)(CreateBadgeForm)
