import React, { Component } from 'react'
import { withStyles, Typography, Divider, TextField } from '@material-ui/core'
import ImageUpload from '../../../../../utils/image-upload'
import CarouselThumbnailContainer from './carousel-thumbnail/carousel-thumbnail-container'
import uniqid from 'uniqid'
import ModuleTypes from '../module-types'
import update from 'immutability-helper'

const styles = theme => ({
  carouselEditor: {
    width: '50vw',
    padding: '50px',
    textAlign: 'center'
  },
  divider: {
    margin: '10px 0',
    opacity: '0'
  },
  textField: {
    width: '100%',
    height: '100%'
  }
})

const isCarousel = content => {
  // return content.includes("base64")
  return content.includes("base64") || /^m_img_\d+(_\w+)?/.test(content);
}
class CarouselEditor extends Component {
  handleAddThumbnail(thumbnail) {
    if(!isCarousel(this.props.module.content)) {
      this.props.onChange(thumbnail)
    } else {
      this.props.onChange(this.props.module.content + "|" + thumbnail)
    }
  }

  handleAddThumbnails(thumbnails) {
    let thumbnailString = ""
    thumbnails.forEach(thumbnail => {
      thumbnailString += thumbnail + "|"
    })
    //Remove last pipe character
    thumbnailString = thumbnailString.substring(0, thumbnailString.length - 1)
    this.handleAddThumbnail(thumbnailString)
  }

  handleRemoveThumbnail(index) {
    let thumbnails = []
    if(this.props.module.content.includes("|")) {
      thumbnails = this.props.module.content.split("|")
    } else {
      thumbnails.push(this.props.module.content)
    }

    thumbnails.splice(index, 1)

    if(thumbnails.length > 1) {
      this.props.onChange(thumbnails.join("|"))
    } else if(thumbnails.length === 1) {
      this.props.onChange(thumbnails[0])
    } else {
      this.props.onChange('base64')
    }
  }

  handleMoveThumbnail(dragIndex, hoverIndex) {
    const thumbnails = this.props.module.content.split("|")
    const dragThumbnail = thumbnails[dragIndex]
    const newThumbnails = update(thumbnails, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, dragThumbnail]
      ]
    })
    this.props.onChange(newThumbnails.join("|"))
  }

  handleTextChange(input) {
    this.props.onChange(input.target.value)
  }

  render() {
    let thumbnails = []
    if(isCarousel(this.props.module.content)) {
      if(this.props.module.content.includes("|")) {
        let thumbnailContents = this.props.module.content.split("|")
        for(const thumbnailContent of thumbnailContents) {
          thumbnails.push({
            id: uniqid(),
            contentTypeId: ModuleTypes.CAROUSEL_THUMBNAIL,
            content: thumbnailContent
          })
        }
      } else {
        thumbnails.push({
          id: uniqid(),
          contentTypeId: ModuleTypes.CAROUSEL_THUMBNAIL,
          content: this.props.module.content
        })
      }
    }

    return(
      <div className={this.props.classes.carouselEditor}>
        <ImageUpload
          label="Upload Carousel Image"
          multiple
          onImageChange={this.handleAddThumbnail.bind(this)}
          onImagesChange={this.handleAddThumbnails.bind(this)} />
        <Typography
          variant="subtitle1"
          color="textSecondary">
          Note: the optimal sizes are 1080 pixels wide by 566 to 1350 pixels high.
        </Typography>
        <Divider className={this.props.classes.divider} />
        <CarouselThumbnailContainer
          id={uniqid()}
          modules={thumbnails}
          enableSort={true}
          column={true}
          allowEdits={true}
          onRemoveModule={this.handleRemoveThumbnail.bind(this)}
          onMoveModule={this.handleMoveThumbnail.bind(this)}
          emptyContainerLabel="Upload an image to see it here." />
        <TextField
          className={this.props.classes.textField}
          multiline
          rows="4"
          label="Enter Caption"
          margin="normal"
          value={this.props.module.caption || ""}
          onChange={this.handleTextChange.bind(this)} 
          inputProps={{maxLength :140}} />
      </div>
    )
  }
}

export default withStyles(styles)(CarouselEditor)
