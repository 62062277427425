import React, { Component } from "react";
import {
  Paper
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers"

class CalendarPicker extends Component {
  render() {
    return (
      <Paper>
        <DatePicker 
          value={this.props.date}
          variant="static"
          onChange={this.props.onDateChange}
          shouldDisableDate = {date =>
            this.props.notifications.length !== 0 ? (
              this.props.notifications.find(
                notification =>
                  date.toISOString().substring(0,10) ===
                  notification.date.substring(0,10) &&
                  notification.id !== this.props.currentNotificationId
              )
            ) : false
          }
        />
      </Paper>
    );
  }
}

export default CalendarPicker
