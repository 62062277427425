import React, { Component } from "react";
import {
  AppBar, Dialog, DialogContent,
  DialogTitle, DialogContentText,
  DialogActions, Button, Tab,
  Tabs, Paper, Slide, Toolbar,
  Typography, Tooltip, IconButton,
  withStyles
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import UserActivitySection from "../sections/user-activity-section";
import UserBeercoinSection from "../sections/user-beercoin-section";
import UserBadgesSection from '../sections/user-badges-section_table';
import BeercodeSvgPlaceholder from "@material-ui/icons/AccountBox";
import API from "../../../API";
import update from 'immutability-helper'

const styles = theme => ({
  dialogContainer: {
    paddingTop: 110,
    paddingRight: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    flexGrow: 0
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-evenly'
  },
  column: {
    flexDirection: 'column',
    width: '60vw',
  },
  userInfoColumn: {
    flexDirection: 'column',
  },
  beerCode: {
    width: '200px',
    margin: '30px auto'
  },
  beercodePlaceholder: {
    width: '100%',
    height: '300px', 
    color: '#C0C0C0'
  },
  userInfo: {
    padding: '20px'
  },
  userDetailsNav: {
    backgroundColor: '#C0C0C0'
  },
  span: {
    display: 'block'
  },
  userDataButton: {
    marginBottom: theme.spacing(3)
  }
});

class UserDetailsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userBadges: [],
      userBeercoinHistory: [],
      userActivity: [],
      selectedVal: 0,
      selectedAction: {
        showBadges: false,
        showBeercoin: false,
        showActivity: false
      }
    }
    this.handleItemState = this.handleItemState.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  handleItemState() {
    API.getUserBadges(this.props.item.id, async response => {
      if(response) {
        let uniqueBadgeIDs = new Set([])
        let badges = []
        if(response.userBadges) {
          for (let badge of response.userBadges) {
            if (!uniqueBadgeIDs.has(badge.badgeId)){
              uniqueBadgeIDs.add(badge.badgeId)
              badge.count = 1
              badges.push(badge)
            } else {
              badges.find(currentBadge => 
                currentBadge.badgeId === badge.badgeId
              ).count++
            }
          }

          this.setState(update(this.state, {
            userBadges: {
              $push: badges
            }
          }))
        }

        if(response.pagination) {
          const page = response.pagination.page
          const pageCount = response.pagination.pageCount
          const pageSize = response.pagination.pageSize
          for(let count = page + 1; count <= pageCount; count++) {
            const response = await API.getUserBadges(
              this.props.item.id,
              null,
              `?page=${count}&limit=${pageSize}`
            )
            for (let badge of response.userBadges) {
              if (!uniqueBadgeIDs.has(badge.badgeId)){
                uniqueBadgeIDs.add(badge.badgeId)
                badge.count = 1
                badges.push(badge)
              } else {
                badges.find(currentBadge => 
                  currentBadge.badgeId === badge.badgeId
                ).count++
              }
            }
            this.setState({userBadges: badges})
          }
        }

      }
    },'')

    API.getUserBeercoinHistory(
      this.props.item.userHash,
      userBeercoinHistory => {
        this.setState({ userBeercoinHistory });
      }
    );
    API.getAllUserActivity(this.props.item.id, userActivity => {
      this.setState({ userActivity });
    });
  }

  handleTransition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  })

  handleActionClick(input, value) {
    const selectedObj = this.state.selectedAction;
    for (let action in selectedObj) {
      if (action === input) {
        this.setState({ [input]: true, selectedVal: value });
      } else {
        this.setState({ [action]: false, selectedVal: value });
      }
    }
  }

  handleClose(e) {
    this.props.handleClose("");
    this.setState({
      userBadges: [],
      userBeercoinHistory: [],
      userActivity: []
    });
  }

  renderBeercode(svg) {
    if (svg === null || svg === undefined) {
      return (
        <BeercodeSvgPlaceholder
          className={this.props.classes.beercodePlaceholder}
        />
      );
    } else {
      return (
        <div
          className={this.props.classes.beerCode}
          dangerouslySetInnerHTML={{ __html: `${svg}` }}
        />
      );
    }
  }

  render() {
    const selectedVal = this.state.selectedVal;
    const data = this.props.appFacingData
    const formattedData = Object.keys(data).map((key) => {
      return [key, data[key]]
    })

    const items = formattedData.map(item => {
      if (typeof item[1] !== 'string'){
        return (
          <span className = {this.props.classes.span} key= {item[0]}>{item[0]}: {JSON.stringify(item[1])}</span>
        )
      } else {
        return (
          <span className = {this.props.classes.span} key= {item[0]}>{item[0]}: {item[1]}</span>
        )
      }
    })

    return (
      <Dialog
        fullScreen
        open={this.props.open}
        onClose={this.handleClose}
        TransitionComponent={this.handleTransition}
        onEnter={this.handleItemState}
      >
        <Dialog
          open={this.props.openModal}
          onClose={this.props.closeModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title"> App Facing Data </DialogTitle>
          <DialogContent>
            <DialogContentText>
            {items}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.closeModal} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <AppBar>
          <Toolbar>
            <Tooltip title="Close">
              <IconButton
                aria-label="Cancel"
                onClick={this.handleClose}
                color="inherit"
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
            <Typography
              variant="h5"
              className={this.props.classes.grow}
              color="inherit"
            >
              User Details
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent
          className={`${this.props.classes.dialogContainer} ${
            this.props.classes.flex
          }`}
        >
          <Paper className={this.props.classes.userInfo}>
            <div className={`${this.props.classes.userInfoColumn} ${this.props.classes.flex}`}>
              <Button
                variant="contained"
                color="secondary"
                className={this.props.classes.userDataButton}
                onClick={e =>
                  this.props.handleAppFacingData(this.props.item.id)
                }
              >
                Get app-facing user data
              </Button>
              <Typography variant="h5">
                {this.props.item.firstName || ""}{" "}
                {this.props.item.lastName || ""}
              </Typography>
              <Typography variant="body2">
                Email Address: {this.props.item.emailAddress}
                <br />
                Language ID: {this.props.item.languageId}
                <br />
                Office: {
                  this.props.item.office ?
                    this.props.item.office.name :
                    "None"
                }
                <br />
                Department: {
                  this.props.item.department ?
                    this.props.item.department.name :
                    "None"
                }
              </Typography>
              {/*this.renderBeercode(this.props.item.beercodeSvg)*/}
            </div>
          </Paper>
          <div className={this.props.classes.column}>
            <Paper square style={{ margin: "0 auto" }}>
              <Tabs
                value={selectedVal}
                onChange={this.handleActionClick.bind(this)}
                className={this.props.classes.userDetailsNav}
                centered
              >
                <Tab label="Badges"/>
                <Tab label="Beercoin" />
                <Tab label="Activity" />
              </Tabs>
            </Paper>
            {selectedVal === 0 && (
              <UserBadgesSection userBadges={this.state.userBadges} />
            )}
            {selectedVal === 1 && (
              <UserBeercoinSection
                userBeercoinHistory={this.state.userBeercoinHistory}
              />
            )}
            {selectedVal === 2 && (
              <UserActivitySection userActivity={this.state.userActivity} />
            )}
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

export default withStyles(styles)(UserDetailsForm);
