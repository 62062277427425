import React, { Component } from 'react'
import Login from './Login'
import Admin from './Admin'
import API from './API'
import jwt_decode from 'jwt-decode'

export default class App extends Component {
  state = {
    showLogin: false,
    showLoginError: false,
    showLoginErrorMessage: '',
    showAdmin: false,
    currentUser: {}
  }

  async componentDidMount() {
    //Try to obtain a jwt token for user authentication.
    let jwtToken

    //If a code is present in the URL, retrieve it
    //and then use it to generate a new jwt token.
    const url = window.location.href
    const urlParams = new URLSearchParams(window.location.search)

    if(url.indexOf('code') !== -1) {
      const code = urlParams.get('code').replace('#', '')
      const codeResponse = await API.postLoginCode(code)
      if(codeResponse.status) {
        const tokenResponse = await API.postAccessToken(
          codeResponse.accessToken
        )
        if(tokenResponse.status) {
          jwtToken = tokenResponse.jwt
          this.setJwtToken(jwtToken)
        } else if(
          tokenResponse.details &&
          tokenResponse.details.type === "UserNotFound"
        ) {
          this.setState({
            showLoginError: true,
            showLoginErrorMessage: `
              Unfortunately, we were unable to find your account.
              To set up a new account, please download the Hoppy
              app from Google Play or the App Store and then log
              in. After that, please visit this page and try
              logging in again.
            `
          })
        }
      } else {
        this.setState({
          showLoginError: true,
          showLoginErrorMessage: `
            System was unable to log you in.
            Please try again at a later time.
          `
        })
      }

      //Remove code from url.
      window.history.pushState('', '', '/')
    }

    //If an access token is present in the URL, retrieve it
    //and then use it to generate a new jwt token.
    if(url.indexOf('accessToken') !== -1) {
      const token = urlParams.get('accessToken').replace('#', '')
      const tokenResponse = await API.postAccessToken(token)
      if(tokenResponse.status) {
        jwtToken = tokenResponse.jwt
        this.setJwtToken(jwtToken)
      } else if(
        tokenResponse.details &&
        tokenResponse.details.type === "UserNotFound"
      ) {
        this.setState({
          showLoginError: true,
          showLoginErrorMessage: `
            Unfortunately, we were unable to find your account.
            To set up a new account, please download the Hoppy
            app from Google Play or the App Store and then log
            in. After that, please visit this page and try
            logging in again.
          `
        })
      }

      //Remove token from url.
      window.history.pushState('', '', '/')
    }

    //If a jwt token is present in local storage, retrieve it.
    if(!jwtToken) {
      jwtToken = this.getJwtToken()
    }

    //If a jwt token is found and is valid, show the admin system.
    if(jwtToken) {
      const response = await API.getAllLanguages()
      if(!response.error && response.languages) {
        const decodedToken = jwt_decode(jwtToken)
        this.setState({
          showAdmin: true,
          currentUser: decodedToken.user
        })
      } else if(response.error) {
        jwtToken = null
        if(response.error === "not admin") {
          this.setState({
            showLoginError: true,
            showLoginErrorMessage: `
              Unfortunately, your account does not have
              administrative access. Please speak to
              an administrator to obtain this access.
            `
          })
        }
      }
    }

    //If no token is found, show the login screen.
    if(!jwtToken) {
      this.setState({
        showLogin: true
      })
    }
  }

  getJwtToken() {
    return localStorage.getItem("jwt-token")
  }

  setJwtToken(token) {
    localStorage.setItem("jwt-token", token)
  }

  async handleSubmit() {
    this.setState({
      showLoginError: false,
      showLoginErrorMessage: ''
    })
    const config = await API.getOAuthConfig()
    if(!config) return
    window.location = config.authorizeUrl
  }

  clearJwtToken() {
    localStorage.removeItem("jwt-token")
  }

  render() {
    return (
      <div>
        { this.state.showAdmin &&
          <Admin
            currentUser={this.state.currentUser}
            clearJwtToken={this.clearJwtToken.bind(this)}
          />
        }
        { this.state.showLogin &&
          <Login
            error={this.state.showLoginError}
            errorMessage={this.state.showLoginErrorMessage}
            onSubmit={this.handleSubmit.bind(this)}
          />
        }
      </div>
    )
  }
}
