import React, { Component } from 'react'
import {
  withStyles, Typography,
  FormControlLabel, Checkbox
} from '@material-ui/core'

const styles = theme => ({
})

class AnswerModule extends Component {
  render() {
    return(
      <div>
        <Typography component="p">
          {this.props.module.answer}
        </Typography>
        {this.props.module.hasOwnProperty("correctBool") &&
          <FormControlLabel
            control={
              <Checkbox
                checked={this.props.module.correctBool}
                disabled />
            }
            label="Correct" />
        }
      </div>
    )
  }
}

export default withStyles(styles)(AnswerModule)
