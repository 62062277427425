import React, {Component} from 'react'
import BadgesTable from "./badges-table";
import Fade from "@material-ui/core/Fade";
import BadgesDetailsForm from "./forms/badges-details-form";

class Achievements extends Component {
    constructor(props) {
        super(props)
        this.state = {
            renderDetailsForm: false,
            appFacingDataModal: false,
            appFacingData: {},
            badgeItem: {}
        }
        this.toggleDetailsForm = this.toggleDetailsForm.bind(this)
        this.closeAppFacingDataModal = this.closeAppFacingDataModal.bind(this)
    }

    /**
     * Show/Close modal badges details
     * @param item
     */
    toggleDetailsForm(item) {
        this.setState({
            renderDetailsForm: !this.state.renderDetailsForm,
            appFacingDataModal: !this.state.appFacingDataModal,
            badgeItem: item
        })
    }

    closeAppFacingDataModal(e){
        this.setState({
            appFacingDataModal: false
        })
    }

    render() {

        return (
            <Fade in={this.props.mountAnimation}>
                <div>
                    <BadgesDetailsForm
                        open={this.state.renderDetailsForm}
                        openModal={this.state.appFacingDataModal}
                        closeModal={this.closeAppFacingDataModal}
                        toggleDetailsForm = {this.toggleDetailsForm}
                        badge={this.state.badgeItem}
                        usersList={this.props.usersList}
                    />
                    <BadgesTable
                        renderDetailsForm={this.toggleDetailsForm}
                    />
                </div>
            </Fade>
        )
    }
}

export default(Achievements)
