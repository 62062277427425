import React, { Component } from 'react'
import {
  Button, TextField, Dialog,
  CardMedia, Typography,
  Slide, AppBar, Toolbar,
  IconButton, Grid, withStyles,
  DialogContent, DialogContentText,
  DialogActions
} from '@material-ui/core'
import {
  Wallpaper as WallpaperIcon,
  Close as CloseIcon
} from '@material-ui/icons'
import ImageUpload from '../../../utils/image-upload'

const styles = theme => ({
  appBar: {
    position: 'relative',
    marginBottom: theme.spacing(2) + "px"
  },
  flex: {
    flex: 1
  },
  image: {
    height: '250px',
    backgroundSize: 'contain'
  },
  imagePlaceholder: {
    height: '150px',
    width: '150px',
    margin: '0 auto',
    display: 'block'
  },
  imageUpload: {
    textAlign: 'center'
  },
  imageUploadText: {
    textAlign: 'center',
    marginBottom: theme.spacing(1) + "px"
  }
})

const isImage = content => {
  if(!content) { return false }
  return content.includes("base64")
}

const nameMaxCharCount = 50
const teaserMaxCharCount = 50
const descriptionMaxCharCount = 250

class CreatePackForm extends Component {
  constructor(props){
    super(props)
    let item = {
      name: '',
      teaser: '',
      description: '',
      thumbnail: '',
      icon: ''
    }
    if(this.props.item) { item = this.props.item }
    this.state = {
      item,
      errorName: false,
      errorTeaser: false,
      errorDescription: false,
      errorThumbnail: false,
      errorIcon: false,
      nameCharCount: item.name.length + "/" + nameMaxCharCount,
      teaserCharCount: item.teaser.length + "/" + teaserMaxCharCount,
      descriptionCharCount: item.description.length + "/" + descriptionMaxCharCount,
      errorDialog: false,
      errorDialogContentText: '',
      closeDialog: false
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  componentDidUpdate(prevProps) {
    if(this.props.item && this.props.item !== prevProps.item) {
      let nameCharCount = "0/" + nameMaxCharCount
      let teaserCharCount = "0/" + teaserMaxCharCount
      let descriptionCharCount = "0/" + descriptionMaxCharCount
      if(this.props.item.name) {
        nameCharCount = this.props.item.name.length + "/" + nameMaxCharCount
      }
      if(this.props.item.teaser) {
        teaserCharCount = this.props.item.teaser.length + "/" + teaserMaxCharCount
      }
      if(this.props.item.description) {
        descriptionCharCount = this.props.item.description.length + "/"
                                + descriptionMaxCharCount
      }
      this.setState({
        item: this.props.item,
        nameCharCount,
        teaserCharCount,
        descriptionCharCount
      })
    }
  }

  handleChange(value, input) {
    if(input === "name" && value.length > nameMaxCharCount) {
      value = value.substring(0, nameMaxCharCount)
    } else if(input === "teaser" && value.length > teaserMaxCharCount) {
      value = value.substring(0, teaserMaxCharCount)
    } else if(input === "description" && value.length > descriptionMaxCharCount) {
      value = value.substring(0, descriptionMaxCharCount)
    }
    let newItem = Object.assign({}, this.state.item)
    newItem[input] = value
    this.setState({ item: newItem, ...this.isItemValid(newItem) })

    switch(input) {
      case "name":
        this.setState({
          nameCharCount:
            value.length + "/" + nameMaxCharCount
        })
        break
      case "teaser":
        this.setState({
          teaserCharCount:
            value.length + "/" + teaserMaxCharCount
        })
        break
      case "description":
        this.setState({
          descriptionCharCount:
            value.length + "/" + descriptionMaxCharCount
        })
        break
      default:
        break
    }
  }

  handleSubmit(e){
    let newState = this.isItemValid(this.state.item)
    if(Object.values(newState).includes(true)) {
      let errorMessage
      if(newState.errorName === true) {
        errorMessage = "Please add a pack name."
      }
      if(newState.errorTeaser === true) {
        errorMessage = "Please add a pack teaser."
      }
      if(newState.errorDescription === true) {
        errorMessage = "Please add a pack description."
      }
      if(newState.errorThumbnail === true) {
        errorMessage = "Please add a pack thumbnail."
      }
      if(newState.errorIcon === true) {
        errorMessage = "Please add a pack icon."
      }
      this.setState({
        ...newState,
        errorDialog: true,
        errorDialogContentText: errorMessage
      })
      return
    }
    this.props.onSave(this.state.item)
    this.handleClose()
  }

  handleTransition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  })

  handleErrorDialogClose() {
    this.setState({ errorDialog: false })
  }

  handleCloseButtonClick() {
    this.setState({ closeDialog: true })
  }

  handleCloseDialogCloseNo() {
    this.setState({ closeDialog: false })
  }

  handleCloseDialogCloseYes() {
    this.setState({ closeDialog: false })
    this.handleClose()
  }

  handleClose() {
    this.setState({
      item: {
        name: '',
        teaser: '',
        description: '',
        thumbnail: '',
        icon: ''
      },
      errorName: false,
      errorTeaser: false,
      errorDescription: false,
      errorThumbnail: false,
      errorIcon: false,
      nameCharCount: "0/" + nameMaxCharCount,
      teaserCharCount: "0/" + teaserMaxCharCount,
      descriptionCharCount: "0/" + descriptionMaxCharCount,
      errorDialog: false,
      errorDialogContentText: '',
      closeDialog: false
    })
    this.props.onClose()
  }

  isItemValid(item) {
    let newState = {}
    if(item.name === '') {
      newState.errorName = true
    } else {
      newState.errorName = false
    }
    if(item.teaser === '') {
      newState.errorTeaser = true
    } else {
      newState.errorTeaser = false
    }
    if(item.description === '') {
      newState.errorDescription = true
    } else {
      newState.errorDescription = false
    }
    if(item.thumbnail === '') {
      newState.errorThumbnail = true
    } else {
      newState.errorThumbnail = false
    }
    if(item.icon === '') {
      newState.errorIcon = true
    } else {
      newState.errorIcon = false
    }
    return newState
  }

  render(){
    return(
      <Dialog
        fullScreen
        open={this.props.open}
        onClose={this.handleCloseButtonClick.bind(this)}
        TransitionComponent={this.handleTransition}>
        <AppBar className={this.props.classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={this.handleCloseButtonClick.bind(this)}
              aria-label="Close">
              <CloseIcon />
            </IconButton>
            <Typography
              variant="h5"
              color="inherit"
              className={this.props.classes.flex}>
              Create/Edit Pack
            </Typography>
            <Button
              color="inherit"
              onClick={this.handleSubmit.bind(this)}>
              Save
            </Button>
          </Toolbar>
        </AppBar>
        <Grid
          container
          justify="space-evenly"
          alignItems="center"
        >
          <Grid item>
            { isImage(this.state.item.thumbnail) ? (
              <CardMedia
                className={this.props.classes.image}
                image={this.state.item.thumbnail} />
            ) : (
              <WallpaperIcon
                className={this.props.classes.imagePlaceholder}
                color={this.state.errorThumbnail ? "secondary" : "primary"} />
            )}
            <ImageUpload
              label="Upload Thumbnail"
              className={this.props.classes.imageUpload}
              onImageChange={e => this.handleChange(e, 'thumbnail')}
              error={this.state.errorThumbnail} />
            <Typography
              className={this.props.classes.imageUploadText}
              variant="caption">
              Note: pack thumbnails should be 162px by 162px.
            </Typography>
          </Grid>
          <Grid item>
            { isImage(this.state.item.icon) ? (
              <CardMedia
                className={this.props.classes.image}
                image={this.state.item.icon} />
            ) : (
              <WallpaperIcon
                className={this.props.classes.imagePlaceholder}
                color={this.state.errorIcon ? "secondary" : "primary"} />
            )}
            <ImageUpload
              label="Upload Icon"
              className={this.props.classes.imageUpload}
              onImageChange={e => this.handleChange(e, 'icon')}
              error={this.state.errorIcon} />
            <Typography
              className={this.props.classes.imageUploadText}
              variant="caption">
              Note: pack icons should be 132px by 144px.
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          justify="space-evenly"
          alignItems="center"
        >
          <Grid item xs={4}>
            <TextField
              required
              autoFocus
              margin="normal"
              id="name"
              label="Name"
              type="text"
              value={this.state.item.name}
              helperText={this.state.nameCharCount}
              onChange={e => this.handleChange(e.target.value, 'name')}
              fullWidth
              error={this.state.errorName}
            />
            <TextField
              required
              margin="normal"
              id="teaser"
              label="Teaser"
              type="text"
              value={this.state.item.teaser}
              helperText={this.state.teaserCharCount}
              onChange={e => this.handleChange(e.target.value, 'teaser')}
              fullWidth
              error={this.state.errorTeaser}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              margin="normal"
              multiline
              rows="5"
              id="description"
              label="Description"
              type="text"
              value={this.state.item.description}
              helperText={this.state.descriptionCharCount}
              onChange={e => this.handleChange(e.target.value, 'description')}
              fullWidth
              error={this.state.errorDescription}
            />
          </Grid>
        </Grid>
        <Dialog
          open={this.state.errorDialog}
          onClose={this.handleErrorDialogClose.bind(this)}
        >
          <DialogContent>
            <DialogContentText>
              {this.state.errorDialogContentText}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleErrorDialogClose.bind(this)}
              color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.closeDialog}
          onClose={this.handleCloseDialogCloseNo.bind(this)}
        >
          <DialogContent>
            <DialogContentText>
              Are you sure that you want to close this screen?
              All of the changes you made will be lost.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleCloseDialogCloseNo.bind(this)}
              color="primary">
              No. I want to keep my changes.
            </Button>
            <Button
              onClick={this.handleCloseDialogCloseYes.bind(this)}
              color="secondary">
              Yes. I want to lose my changes.
            </Button>
          </DialogActions>
        </Dialog>
      </Dialog>
    )
  }
}

export default withStyles(styles)(CreatePackForm)
