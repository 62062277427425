import React, { Component } from 'react'
import {
  Dialog, DialogActions, DialogContent,
  DialogTitle, Grid, FormControl,
  InputLabel, Select, MenuItem,
  Button, CircularProgress, Typography,
  withStyles
} from '@material-ui/core'
import { Forward as ForwardIcon } from '@material-ui/icons'

const styles = theme => ({
  dropdown: {
    minWidth: 200
  },
  icon: {
    marginLeft: 20,
    marginRight: 20
  }
})

class CopyFlightForm extends Component {
  constructor(props){
    super(props)
    this.state = {
      item: this.props.item,
      selectedLanguageId: -1,
      selectedPackId: -1,
      copyFinished: false
    }
    this.handleLanguageChange = this.handleLanguageChange.bind(this)
    this.handlePackChange = this.handlePackChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  componentDidUpdate(prevProps) {
    if(this.props.item && this.props.item !== prevProps.item) {
      this.setState({
        item: this.props.item,
        selectedLanguageId: this.props.languages.find(language =>
          language.id !== this.props.item.languageId
        ).id
      })
    }
    if(!this.props.copyInProgress && prevProps.copyInProgress) {
      this.setState({
        copyFinished: true
      })
      localStorage.setItem("onLoad", JSON.stringify({
        page: 'CMS',
        options: {
          languageId: this.state.selectedLanguageId
        }
      }))
      setTimeout(() => {
        window.location.reload()
      }, 3000)
    }
  }

  handleLanguageChange(input) {
    this.setState({
      selectedLanguageId: input.target.value,
      selectedPackId: -1
    })
  }

  handlePackChange(input) {
    this.setState({
      selectedPackId: input.target.value
    })
  }

  handleSubmit(e){
    this.props.onCopy(
      this.state.item.id,
      this.state.selectedLanguageId,
      this.state.selectedPackId
    )
  }

  handleClose() {
    if(this.props.copyInProgress || this.state.copyFinished) {
      return
    }
    this.props.onClose()
  }

  getTargetPackList(packs){
    let packList = packs.filter(pack1 => pack1.languageId === this.state.selectedLanguageId)
        .map(pack2 => {
          return (
            <MenuItem
              key={pack2.id}
              value={pack2.id}
            >
              {pack2.name}
            </MenuItem>
          ) 
        }); 
    if(packList.length > 0){
      return packList;
    }
    return (
      <MenuItem
        key={-1}
        value={-1}
        disabled
      >
        No Packs for this Language
      </MenuItem>
    )
  }

  render() {
    let languages = this.props.languages
    let packs = this.props.packs
    if(this.state.item) {
      languages = languages.filter(
        language => language.id !== this.state.item.languageId
      )
      packs = packs.filter(
        pack => pack.languageId !== this.state.item.languageId
      )
    }
    languages = languages.map(language => {
      return (
        <MenuItem
          key={language.id}
          value={language.id}
        >
          {language.name}
        </MenuItem>
      )
    })
    return(
      <Dialog open={this.props.open} onClose={this.handleClose}>
        <DialogTitle>
          Copy Flight {this.state.item ? `"${this.state.item.name}"` : ""}
        </DialogTitle>
        <DialogContent>
          { !this.props.copyInProgress && !this.state.copyFinished &&
            <Grid container alignItems="flex-end">
              <Grid item>
                <FormControl className={this.props.classes.dropdown}>
                  <InputLabel htmlFor="currentLanguage">
                    Current Language
                  </InputLabel>
                  <Select
                    value={
                      (this.state.item && this.state.item.languageId) ||
                      -1
                    }
                    inputProps={{ id: 'currentLanguage' }}
                    disabled
                  >
                    {this.state.item &&
                      <MenuItem
                        key={this.state.item.languageId}
                        value={this.state.item.languageId}
                      >
                        {
                          this.props.languages.find(language =>
                            language.id === this.state.item.languageId
                          ).name
                        }
                      </MenuItem>
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <ForwardIcon className={this.props.classes.icon} />
              </Grid>
              <Grid item>
                <FormControl className={this.props.classes.dropdown}>
                  <InputLabel htmlFor="targetLanguage">
                    Target Language
                  </InputLabel>
                  <Select
                    value={this.state.selectedLanguageId}
                    onChange={this.handleLanguageChange.bind(this)}
                    inputProps={{ id: 'targetLanguage' }}
                  >
                    {languages}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          }
          { !this.props.copyInProgress && !this.state.copyFinished && 
            <Grid container alignItems="flex-end">
              <Grid item>
                <FormControl className={this.props.classes.dropdown}>
                  <InputLabel htmlFor="currentPack">
                    Current Pack
                  </InputLabel>
                  <Select
                    value={
                      (this.state.item && this.state.item.packId) ||
                      -1
                    }
                    inputProps={{ id: 'currentPack' }}
                    disabled
                  >
                    {this.state.item &&
                      <MenuItem
                        key={this.state.item.packId}
                        value={this.state.item.packId}
                      >
                        {
                          this.props.packs.find(pack =>
                            pack.id === this.state.item.packId
                          ).name
                        }
                      </MenuItem>
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <ForwardIcon className={this.props.classes.icon} />
              </Grid>
              <Grid item>
                <FormControl className={this.props.classes.dropdown}>
                  <InputLabel htmlFor="targetPack">
                    Target Pack
                  </InputLabel>
                  <Select
                    value={this.state.selectedPackId}
                    onChange={this.handlePackChange.bind(this)}
                    inputProps={{ id: 'targetPack' }}
                    disabled={this.state.selectedLanguageId === -1}
                  >
                    { this.state.selectedLanguageId !== -1 ?
                      this.getTargetPackList(packs)
                      :
                      <MenuItem
                        key={-1}
                        value={-1}
                        disabled
                      >
                        Select a Pack
                      </MenuItem>
                    }
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          }
          { this.props.copyInProgress &&
            <Grid container justify="center" alignItems="center">
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
          }
          { !this.props.copyInProgress && this.state.copyFinished &&
            <Typography>
              Copy Finished! Reloading the CMS now.
            </Typography>
          }
        </DialogContent>
        { !this.props.copyInProgress && !this.state.copyFinished &&
          <DialogActions>
            <Button
              onClick={this.handleClose}
              color="secondary">
              Cancel
            </Button>
            <Button
              onClick={this.handleSubmit}
              color="primary"
              disabled={this.state.selectedPackId === -1}>
              Copy
            </Button>
          </DialogActions>
        }
      </Dialog>
    )
  }
}

export default withStyles(styles)(CopyFlightForm)
