import React, { Component } from 'react'
import {
  AppBar, TextField, MenuItem,
  Dialog, DialogContent, DialogContentText,
  DialogTitle, Slide, Toolbar,
  Typography, Tooltip, IconButton,
  withStyles
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import CloseIcon from '@material-ui/icons/Close'
// import SaveIcon from '@material-ui/icons/Save'
import PrintIcon from '@material-ui/icons/Print'

const styles = theme => ({
  appBar: {
    flexGrow: .25,
    '@media print': {
      display: 'none'
    }
  },
  grow: {
    flexGrow: 1
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-evenly'
  },
  eventDetails: {
    '@media print': {
      display: 'none'
    }
  },
  eventCodeAndCopy: {
    marginTop: '50px'
  },
  eventCode: {
    '@media print': {
      width: '900px',
      marginTop: '10vh'
    }
  },
  eventCopy: {
    '@media print': {
      display: 'none'
    }
  },
})

class EventsEditForm extends Component {
  constructor(props){
    super(props)
    this.state = {
      item: {
        id: -1,
        name: '',
        description: '',
        zoneId: '',
        languageId: ''
      }
    }
    this.onClose = this.onClose.bind(this)
  }

  componentDidMount(){
    this.handleItemState()
  }

  componentDidUpdate(prevProps){
    if (this.props.item && prevProps.item.id !== this.props.item.id){
      this.handleItemState()
    }
  }

  onClose(e){
    this.props.handleClose('')
    this.setState({
      item: {
        id: -1,
        name: '',
        description: '',
        zoneId: '',
        languageId: ''
      }
    })
  }

  handleInput(e, input){
    const val = e.target.value;
    let newItem = Object.assign({}, this.state.item)
    newItem[input] = val
    const {current, ...inputs} = newItem
    this.setState({
      item: inputs
    })
  }

  handleItemState(){
    this.setState({
      item: {
        id: this.props.item.id,
        name: this.props.item.name,
        description: this.props.item.description,
        zoneId: this.props.item.zoneId,
        languageId: this.props.item.languageId
      }
    })
  }

  renderZoneField(zoneArr, id){
    return (
      <TextField
        select
        fullWidth
        margin="normal"
        id="zoneId"
        label="Zone Id"
        type="text"
        value={id || ''}
        onChange={e => this.handleInput(e, 'zoneId')}
        disabled
      >
        {zoneArr.map(zone => (
            <MenuItem key={zone.id} value={zone.id}>
              {zone.name}
            </MenuItem>
        ))}
      </TextField>
    )
  }

  renderLanguageField(langArr, id){
      return (
        <TextField
          select
          fullWidth
          margin="normal"
          id="languageId"
          label="Language"
          type="text"
          value={id || ''}
          ref={this.input}
          onChange={e => this.handleInput(e, 'languageId')}
          disabled
        >
          {langArr.map(lang => (
              <MenuItem key={lang.id} value={lang.id}>
                {lang.name}
              </MenuItem>
          ))}
        </TextField>
      )
  }

  handleTransition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  })

  render(){
    return(
      <Dialog
        fullScreen
        open={this.props.open}
        onClose={this.onClose}
        TransitionComponent={this.handleTransition}
        >
        <div className={this.props.classes.appBar}>
          <AppBar>
            <Toolbar>
              <Tooltip title="Close">
                <IconButton aria-label="Cancel"
                  onClick={e => this.props.handleClose('')}
                  color="inherit">
                  <CloseIcon />
                </IconButton>
              </Tooltip>
              <Typography variant="h5"
                className={this.props.classes.grow}
                color="inherit">Edit Event</Typography>
              <Tooltip title="Print">
                <IconButton aria-label="Print item"
                  color="inherit"
                  onClick={e => { window.print() }}>
                  <PrintIcon />
                </IconButton>
              </Tooltip>
              {/*<Tooltip title="Save Changes">
                <IconButton aria-label="Save Event"
                  onClick={e => {
                    this.props.onEventSave(this.state.item)
                    this.onClose()}}
                    color="inherit">
                  <SaveIcon />
                </IconButton>
              </Tooltip>*/}
              <Tooltip title="Delete Event">
                <IconButton aria-label="Delete Event"
                  onClick={e => this.props.renderDeleteForm(this.props.item)}
                  color="inherit">
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Toolbar>
          </AppBar>
        </div>
        <DialogContent>
          <div className={this.props.classes.flex}>
            <div className={this.props.classes.eventDetails}>
              <DialogTitle>{this.props.item.name || ""}</DialogTitle>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Name"
                helperText="(Max Length: 50)"
                type="text"
                value={this.state.item.name}
                onChange={e => this.handleInput(e, 'name')}
                inputProps={ {maxLength: 50} }
                fullWidth
                disabled
              />
              <TextField
                margin="dense"
                id="description"
                label="Description"
                type="text"
                value={this.state.item.description}
                onChange={e => this.handleInput(e, 'description')}
                fullWidth
                disabled
              />
              <div className={this.props.classes.grow}>
                {this.renderZoneField(this.props.zones, this.state.item.zoneId)}
                {this.renderLanguageField(this.props.languages, this.state.item.languageId)}
              </div>
            </div>
            <div className={this.props.classes.eventCodeAndCopy}>
              <div
                className={this.props.classes.eventCode}
                dangerouslySetInnerHTML={{__html: `${this.props.item.beercodeSvg}`}}>
              </div>
              <DialogContentText className={this.props.classes.eventCopy}>
                * Event copy cannot be modified
              </DialogContentText>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    )
  }
}

export default withStyles(styles)(EventsEditForm)
