import React, { Component } from 'react'
import { withStyles, Typography } from '@material-ui/core'

const styles = theme => ({
})

class QuestionModule extends Component {
  render() {
    return(
      <div>
        <Typography component="p">
          {this.props.module.question}
        </Typography>
      </div>
    )
  }
}

export default withStyles(styles)(QuestionModule)
