import React, { Component } from 'react'
import { withStyles, Typography } from '@material-ui/core'
import ImageUpload from '../../../../../utils/image-upload'

const styles = theme => ({
  video: {
    margin: '0 auto',
    display: 'block'
  },
  videoEditor: {
    width: '50vw',
    padding: '50px'
  },
  videoEditorHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around'
  }
})

class VideoEditor extends Component {
  render() {
    return(
      <div className={this.props.classes.videoEditor}>
        <div className={this.props.classes.videoEditorHeader}>
          <ImageUpload
            label="Upload Video"
            onImageChange={this.props.onChange} />
          <Typography component="p" color="textSecondary">
            Note: please keep your videos under 50 MB
          </Typography>
        </div>
        <img
          className={this.props.classes.video}
          width="400"
          alt="Video"
          src={this.props.module.content} />
      </div>
    )
  }
}

export default withStyles(styles)(VideoEditor)
