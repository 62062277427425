export default {
  BASE: '0',
  TEXT: 1,
  IMAGE: 2,
  CAROUSEL: 3,
  EMBED_VIDEO: 4,
  FILE_DOWNLOAD: 5,
  CAROUSEL_THUMBNAIL: '6',
  ANSWER: 7
}
