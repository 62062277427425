import React, { Component } from "react";
import {
  Fade, Grid
} from "@material-ui/core";
import Zones from "./zonesTable";
import AdminSections from "./adminSectionsTable";
import Departments from "./departmentsTable";
import Languages from "./languagesTable";
import Offices from "./officesTable";
import { AddForm, EditForm, DeleteForm } from "./forms/index";
import FormTypes from "./form-types";

export default class Settings extends Component {
  render() {
    let form;
    switch (this.props.form) {
      case FormTypes.ZONEADD.NAME:
        form = (
          <AddForm
            tableName={FormTypes.ZONEADD.NAME}
            formFields={FormTypes.ZONEADD.FIELDS}
            zoneForm = {true}
            renderLanguage={FormTypes.ZONEADD.LANGUAGEDROPDOWN}
            renderZone={FormTypes.ZONEADD.ZONEDROPDOWN}
            renderCheckbox={FormTypes.ZONEADD.RENDERCHECKBOX}
            readOnly={FormTypes.ZONEADD.READONLY}
            zones={this.props.zones}
            open={this.props.addFormState}
            handleClose={this.props.toggleAddForm}
            url={this.props.zoneURL}
            updateData={this.props.updateData}
            table={this.props.table}
            handleSubmit={this.props.handleSubmit}
            languages={this.props.languages}
          />
        );
        break;
      case FormTypes.ADMINSECTIONADD.NAME:
        form = (
          <AddForm
            tableName={FormTypes.ADMINSECTIONADD.NAME}
            formFields={FormTypes.ADMINSECTIONADD.FIELDS}
            renderLanguage={FormTypes.ADMINSECTIONADD.LANGUAGEDROPDOWN}
            renderZone={FormTypes.ADMINSECTIONADD.ZONEDROPDOWN}
            renderCheckbox={FormTypes.ADMINSECTIONADD.RENDERCHECKBOX}
            readOnly={FormTypes.ADMINSECTIONADD.READONLY}
            zones={this.props.zones}
            open={this.props.addFormState}
            handleClose={this.props.toggleAddForm}
            url={this.props.sectionURL}
            updateData={this.props.updateData}
            table={this.props.table}
            handleSubmit={this.props.handleSubmit}
          />
        );
        break;
        case FormTypes.DEPARTMENTSECTIONADD.NAME:
        form = (
          <AddForm
            tableName={FormTypes.DEPARTMENTSECTIONADD.NAME}
            formFields={FormTypes.DEPARTMENTSECTIONADD.FIELDS}
            renderLanguage={FormTypes.DEPARTMENTSECTIONADD.LANGUAGEDROPDOWN}
            renderZone={FormTypes.DEPARTMENTSECTIONADD.ZONEDROPDOWN}
            renderCheckbox={FormTypes.DEPARTMENTSECTIONADD.RENDERCHECKBOX}
            readOnly={FormTypes.DEPARTMENTSECTIONADD.READONLY}
            zones={this.props.zones}
            open={this.props.addFormState}
            handleClose={this.props.toggleAddForm}
            url={this.props.departmentURL}
            updateData={this.props.updateData}
            table={this.props.table}
            handleSubmit={this.props.handleSubmit}
          />
        );
        break;
        case FormTypes.OFFICESECTIONADD.NAME:
        form = (
          <AddForm
            tableName={FormTypes.OFFICESECTIONADD.NAME}
            formFields={FormTypes.OFFICESECTIONADD.FIELDS}
            renderLanguage={FormTypes.OFFICESECTIONADD.LANGUAGEDROPDOWN}
            renderZone={FormTypes.OFFICESECTIONADD.ZONEDROPDOWN}
            renderCheckbox={FormTypes.OFFICESECTIONADD.RENDERCHECKBOX}
            readOnly={FormTypes.OFFICESECTIONADD.READONLY}
            zones={this.props.zones}
            open={this.props.addFormState}
            handleClose={this.props.toggleAddForm}
            url={this.props.officeURL}
            updateData={this.props.updateData}
            table={this.props.table}
            handleSubmit={this.props.handleSubmit}
          />
        );
        break;
        case FormTypes.LANGUAGESECTIONADD.NAME:
        form = (
          <AddForm
            tableName={FormTypes.LANGUAGESECTIONADD.NAME}
            languageForm = {true}
            formFields={FormTypes.LANGUAGESECTIONADD.FIELDS}
            renderLanguage={FormTypes.LANGUAGESECTIONADD.LANGUAGEDROPDOWN}
            renderZone={FormTypes.LANGUAGESECTIONADD.ZONEDROPDOWN}
            renderCheckbox={FormTypes.LANGUAGESECTIONADD.RENDERCHECKBOX}
            renderLanguageCheckbox={FormTypes.LANGUAGESECTIONADD.ACTIVELANGUAGECHECKBOX}
            readOnly={FormTypes.LANGUAGESECTIONADD.READONLY}
            zones={this.props.zones}
            open={this.props.addFormState}
            handleClose={this.props.toggleAddForm}
            url={this.props.languageURL}
            updateData={this.props.updateData}
            table={this.props.table}
            handleSubmit={this.props.handleSubmit}
          />
        );
        break;
      case FormTypes.ZONEEDIT.NAME:
        form = (
          <EditForm
            tableName={FormTypes.ZONEEDIT.NAME}
            formFields={FormTypes.ZONEEDIT.FIELDS}
            renderLanguage={FormTypes.ZONEEDIT.LANGUAGEDROPDOWN}
            renderZone={FormTypes.ZONEEDIT.ZONEDROPDOWN}
            renderCheckbox={FormTypes.ZONEEDIT.RENDERCHECKBOX}
            readOnly={FormTypes.ZONEEDIT.READONLY}
            zones={this.props.zones}
            open={this.props.editFormState}
            handleClose={this.props.toggleEditForm}
            updateData={this.props.updateData}
            item={this.props.editItem}
            url={this.props.zoneURL}
            handleSubmit={this.props.handleSubmit}
          />
        );
        break;
      case FormTypes.ADMINSECTIONEDIT.NAME:
        form = (
          <EditForm
            tableName={FormTypes.ADMINSECTIONEDIT.NAME}
            formFields={FormTypes.ADMINSECTIONEDIT.FIELDS}
            renderLanguage={FormTypes.ADMINSECTIONEDIT.LANGUAGEDROPDOWN}
            renderZone={FormTypes.ADMINSECTIONEDIT.ZONEDROPDOWN}
            renderCheckbox={FormTypes.ADMINSECTIONEDIT.RENDERCHECKBOX}
            readOnly={FormTypes.ADMINSECTIONEDIT.READONLY}
            zones={this.props.zones}
            open={this.props.editFormState}
            handleClose={this.props.toggleEditForm}
            updateData={this.props.updateData}
            item={this.props.editItem}
            url={this.props.sectionURL}
            handleSubmit={this.props.handleSubmit}
          />
        );
        break;
        case FormTypes.DEPARTMENTSECTIONEDIT.NAME:
        form = (
          <EditForm
            tableName={FormTypes.DEPARTMENTSECTIONEDIT.NAME}
            formFields={FormTypes.DEPARTMENTSECTIONEDIT.FIELDS}
            renderLanguage={FormTypes.DEPARTMENTSECTIONEDIT.LANGUAGEDROPDOWN}
            renderZone={FormTypes.DEPARTMENTSECTIONEDIT.ZONEDROPDOWN}
            renderCheckbox={FormTypes.DEPARTMENTSECTIONEDIT.RENDERCHECKBOX}
            readOnly={FormTypes.DEPARTMENTSECTIONEDIT.READONLY}
            zones={this.props.zones}
            open={this.props.editFormState}
            handleClose={this.props.toggleEditForm}
            updateData={this.props.updateData}
            item={this.props.editItem}
            url={this.props.departmentURL}
            handleSubmit={this.props.handleSubmit}
          />
        );
        break;
        case FormTypes.OFFICESECTIONEDIT.NAME:
        form = (
          <EditForm
            tableName={FormTypes.OFFICESECTIONEDIT.NAME}
            formFields={FormTypes.OFFICESECTIONEDIT.FIELDS}
            renderLanguage={FormTypes.OFFICESECTIONEDIT.LANGUAGEDROPDOWN}
            renderZone={FormTypes.OFFICESECTIONEDIT.ZONEDROPDOWN}
            renderCheckbox={FormTypes.OFFICESECTIONEDIT.RENDERCHECKBOX}
            readOnly={FormTypes.OFFICESECTIONEDIT.READONLY}
            zones={this.props.zones}
            open={this.props.editFormState}
            handleClose={this.props.toggleEditForm}
            updateData={this.props.updateData}
            item={this.props.editItem}
            url={this.props.officeURL}
            handleSubmit={this.props.handleSubmit}
          />
        );
        break;
        case FormTypes.LANGUAGESECTIONEDIT.NAME:
        form = (
          <EditForm
            tableName={FormTypes.LANGUAGESECTIONEDIT.NAME}
            formFields={FormTypes.LANGUAGESECTIONEDIT.FIELDS}
            renderLanguage={FormTypes.LANGUAGESECTIONEDIT.LANGUAGEDROPDOWN}
            renderZone={FormTypes.LANGUAGESECTIONEDIT.ZONEDROPDOWN}
            renderCheckbox={FormTypes.LANGUAGESECTIONEDIT.RENDERCHECKBOX}
            readOnly={FormTypes.LANGUAGESECTIONEDIT.READONLY}
            zones={this.props.zones}
            open={this.props.editFormState}
            handleClose={this.props.toggleEditForm}
            updateData={this.props.updateData}
            item={this.props.editItem}
            url={this.props.languageURL}
            handleSubmit={this.props.handleSubmit}
          />
        );
        break;
      case "zoneDelete":
        form = (
          <DeleteForm
            onClose={this.props.toggleDeleteForm}
            open={this.props.deleteFormState}
            item={this.props.deleteItem}
            onDelete={this.props.handleDelete}
            url={this.props.zoneURL}
          />
        );
        break;
        case "departmentDelete":
        form = (
          <DeleteForm
            onClose={this.props.toggleDeleteForm}
            open={this.props.deleteFormState}
            item={this.props.deleteItem}
            onDelete={this.props.handleDelete}
            url={this.props.departmentURL}
          />
        );
        break;
        case "officeDelete":
        form = (
          <DeleteForm
            onClose={this.props.toggleDeleteForm}
            open={this.props.deleteFormState}
            item={this.props.deleteItem}
            onDelete={this.props.handleDelete}
            url={this.props.officeURL}
          />
        );
        break;
        case "languageDelete":
        form = (
          <DeleteForm
            onClose={this.props.toggleDeleteForm}
            open={this.props.deleteFormState}
            item={this.props.deleteItem}
            onDelete={this.props.handleDelete}
            url={this.props.languageURL}
          />
        );
        break;
      default:
        form = this.props.form;
        break;
    }

    const styles = {
      container: {
        marginTop: "20px",
        marginBottom: "20px",
        textAlign: "left"
      },
      header: {
        marginLeft: "5%"
      },
      flex: {
        display: "flex",
        width: "90%",
        margin: "auto",
        flexWrap: "wrap",
        justifyContent: "space-between"
      }
    };

    return (
      <Fade in={this.props.mountAnimation}>
        <div style={styles.container}>
          {form}
          <Grid
            container
            justify="center"
            spacing={5}
          >
            <Grid item xs={5}>
              <Zones
                zones={this.props.zones}
                zoneURL={this.props.zoneURL}
                renderAddForm={this.props.toggleAddForm}
                renderEditForm={this.props.toggleEditForm}
                renderDeleteForm={this.props.toggleDeleteForm}
              />
            </Grid>
            <Grid item xs={5}>
              <AdminSections
                sections={this.props.sections}
                sectionURL={this.props.sectionURL}
                renderAddForm={this.props.toggleAddForm}
                renderEditForm={this.props.toggleEditForm}
              />
            </Grid>
            <Grid item xs={5}>
              <Departments
                  departments={this.props.departments}
                  departmentURL={this.props.departmentURL}
                  renderAddForm={this.props.toggleAddForm}
                  renderEditForm={this.props.toggleEditForm}
                  renderDeleteForm={this.props.toggleDeleteForm}
                />
            </Grid>
            <Grid item xs={5}>
              <Offices
                  offices={this.props.offices}
                  officeURL={this.props.officeURL}
                  renderAddForm={this.props.toggleAddForm}
                  renderEditForm={this.props.toggleEditForm}
                  renderDeleteForm={this.props.toggleDeleteForm}
                />
            </Grid>
            <Grid item xs={10}>
              <Languages
                  languages={this.props.languages}
                  renderAddForm={this.props.toggleAddForm}
                  renderEditForm={this.props.toggleEditForm}
                  renderDeleteForm={this.props.toggleDeleteForm}
                />
            </Grid>
          </Grid>
        </div>
      </Fade>
    );
  }
}
