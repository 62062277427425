import React, { Component } from 'react'
import { Button, withStyles } from '@material-ui/core'
import uniqid from 'uniqid'

const styles = theme => ({
  button: {
    margin: theme.spacing(1)
  },
  input: {
    display: 'none'
  }
})

class FileUpload extends Component {
  constructor(props) {
    super(props)
    this.fileInput = React.createRef()
    this.inputId = uniqid()
  }

  handleFileChange() {
    // TODO, check if need to do something here
    console.log(this.fileInput);
    console.log(this.fileInput.current);
    console.log(this.fileInput.current.files);
    this.props.onFileChange(this.fileInput.current.files[0]) // TODO, on file download editor

    // this.fileInput.current.value = ""
  }

  render() {
    return(
      <div className={this.props.className}>
        <input
          accept="image/*,application/pdf"
          id={this.inputId}
          type="file"
          className={this.props.classes.input}
          ref={this.fileInput}
          onChange={this.handleFileChange.bind(this)}/>
        <label htmlFor={this.inputId}>
          <Button
            variant="contained"
            color={this.props.error ? "secondary" : "primary"}
            component="span"
            className={this.props.classes.button}>
            {this.props.label}
          </Button>
        </label>
      </div>
    )
  }
}

export default withStyles(styles)(FileUpload)
