import React, { Component } from 'react'
import {
  withStyles, CardMedia,
  Typography, MobileStepper,
  Button, CardContent
} from '@material-ui/core'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons'
import API from '../../../../../API'

const styles = theme => ({
  cardMedia: {
    height: '200px',
    width: 'auto',
    maxWidth: '400px',
    margin: '0 auto'
  },
  mobileStepper: {
    backgroundColor: theme.palette.background.paper
  }
})

const isCarousel = content => {
  // return content.includes("base64")
  return content.includes("base64") || /^m_img_\d+(_\w+)?/.test(content);
}

const getImageUrl = content => {
  if(content.includes("base64")){
    return content;
  }
  return API.getImageUrl(content);
}

class CarouselModule extends Component {
  state = {
    activeStep: 0
  }

  componentDidUpdate(prevProps) {
    if(this.props.module &&
        this.props.module.content !== prevProps.module.content) {
      let items = this.getThumbnails()
      if(items.length === 0) {
        this.setState({ activeStep: 0 })
      } else if(this.state.activeStep > items.length - 1) {
        this.setState({
          activeStep: items.length - 1
        })
      }
    }
  }

  handleNext = (event) => {
    this.setState(prevState => ({
      activeStep: prevState.activeStep + 1
    }))
    event.stopPropagation()
  }

  handleBack = (event) => {
    this.setState(prevState => ({
      activeStep: prevState.activeStep - 1
    }))
    event.stopPropagation()
  }

  getThumbnails() {
    let items = []
    if(isCarousel(this.props.module.content)) {
      if(this.props.module.content.includes("|")) {
        items = this.props.module.content.split("|")
      } else {
        items.push(this.props.module.content)
      }
    }
    return items
  }

  render() {
    const items = this.getThumbnails()
    return(
      <div>
      { items.length === 0 ? (
        <Typography component="p">
          {this.props.module.content}
        </Typography>
      ) : (
        <div>
          <CardMedia
            className={this.props.classes.cardMedia}
            component="img"
            image={getImageUrl(items[
              this.state.activeStep > items.length - 1 ?
                items.length - 1 : this.state.activeStep
            ])} />
          {this.props.module.caption &&
            <CardContent>
              {this.props.module.caption}
            </CardContent>
          }
          <MobileStepper
            steps={items.length}
            position="static"
            activeStep={this.state.activeStep}
            className={this.props.classes.mobileStepper}
            nextButton={
              <Button
                size="small"
                onClick={this.handleNext.bind(this)}
                disabled={this.state.activeStep === items.length - 1}>
                Next
                <KeyboardArrowRight />
              </Button>
            }
            backButton={
              <Button
                size="small"
                onClick={this.handleBack.bind(this)}
                disabled={this.state.activeStep === 0}>
                <KeyboardArrowLeft />
                Back
              </Button>
            }
          />
        </div>
      ) }
      </div>
    )
  }
}

export default withStyles(styles)(CarouselModule)
