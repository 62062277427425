import React, { Component } from 'react'
import Container from './container'
import uniqid from 'uniqid'
import {
  TextField, withStyles, Drawer,
  Button, Typography, Collapse
} from '@material-ui/core'
import update from 'immutability-helper'
import ModuleTypes from './module/module-types'
import {
  TextEditor, ImageEditor,
  CarouselEditor, AnswerEditor
} from './module/module-editors'

/***********
  Constants
************/
const questionMaxCharCount = 400

const styles = theme => ({
  wrapper: {
    padding: '20px 50px'
  },
  wrapperQuestionModules: {
    width: '35%',
    display: 'inline-flex',
    flexDirection: 'column',
    marginRight: '100px',
    textAlign: 'center'
  },
  wrapperContainer: {
    display: 'inline-block',
    width: '99%'
  },
  wrapperOtherQuestions: {
    width: '40%',
    display: 'inline-flex',
    flexDirection: 'column',
    marginTop: '-20px',
    textAlign: 'center'
  },
  question: {
    marginBottom: '30px',
    width: '100%'
  },
  addAnswer: {
    marginTop: '30px',
    width: '100%'
  },
  addQuestion: {
    marginTop: '30px'
  }
})

class QuizEditor extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openDrawer: false,
      openModule: {},
      showOpenQuestion: this.props.openQuestion ? true : false
    }
    this.questionModulesContainerId = uniqid()
    this.otherQuestionsContainerId = uniqid()
  }

  handleQuestionChange(input) {
    let value = input.target.value
    if(value.length > questionMaxCharCount) {
      value = value.substring(0, questionMaxCharCount)
    }
    const newQuestion = update(this.props.openQuestion, {
      question: {
        $set: value
      }
    })
    this.props.onChange(newQuestion)
  }

  handleAddAnswer() {
    this.handleAddModule()
  }

  handleAddModule(module) {
    if(!module) {
      module = {
        id: uniqid(),
        contentTypeId: ModuleTypes.ANSWER,
        answer: 'New Answer'
      }
    }
    module.correctBool = false
    const newQuestion = update(this.props.openQuestion, {
      answers: {
        $push: [ module ]
      }
    })
    this.props.onChange(newQuestion)
  }

  handleAddQuestion() {
    const newQuestion = {
      id: uniqid(),
      contentTypeId: ModuleTypes.QUESTION,
      question: '',
      answers: []
    }
    this.props.onAdd(newQuestion)
  }

  handleRemoveModule(module) {
    const index = this.props.openQuestion.answers
                  .findIndex(obj => obj.id === module.id)
    const newQuestion = update(this.props.openQuestion, {
      answers: {
        $splice: [
          [index, 1]
        ]
      }
    })
    this.props.onAnswerDelete(newQuestion, module)
  }

  handleRemoveQuestion(question) {
    this.props.onQuestionDelete(question)
  }

  toggleDrawer(module) {
    if(this.state.openDrawer) {
      this.handleEditModule()
    }

    this.setState({
      openDrawer: !this.state.openDrawer,
      openModule: module || {}
    })
  }

  handleOpenModuleChange(value) {
    let newModule = Object.assign({}, this.state.openModule)
    if(newModule.contentTypeId === ModuleTypes.ANSWER) {
      if(typeof value === "boolean") {
        newModule.correctBool = value
      } else {
        newModule.answer = value
      }
    } else {
      newModule.content = value
    }
    this.setState({ openModule: newModule})
  }

  handleEditModule() {
    const module = this.state.openModule
    const index = this.props.openQuestion.answers
                  .findIndex(obj => obj.id === module.id)
    const newQuestion = update(this.props.openQuestion, {
      answers: {
        $splice: [
          [index, 1, module]
        ]
      }
    })
    this.props.onChange(newQuestion)
    this.setState({ openModule: {} })
  }

  handleEditQuestion(question) {
    this.setState({ showOpenQuestion: false })
    setTimeout(
      () => {
        this.props.onChange(question)
        this.setState({ showOpenQuestion: true })
      },
      200
    )
  }

  render() {
    let question = Object.assign({}, this.props.openQuestion)
    let isQuestionEmpty = false
    if(Object.keys(question).length === 0) {
      question = {
        id: uniqid(),
        contentTypeId: ModuleTypes.QUESTION,
        question: '',
        answers: []
      }
      isQuestionEmpty = true
    }

    let answers = Object.assign([], question.answers)
    answers.forEach(answer => {
      answer.contentTypeId = ModuleTypes.ANSWER
    })
    answers.sort((a,b) => a.id - b.id)

    const module = this.state.openModule
    let drawerContents
    switch(module.contentTypeId) {
      case ModuleTypes.TEXT:
        drawerContents =
          <TextEditor
            module={module}
            onChange={this.handleOpenModuleChange.bind(this)} />
        break
      case ModuleTypes.IMAGE:
        drawerContents =
          <ImageEditor
            module={module}
            onChange={this.handleOpenModuleChange.bind(this)} />
        break
      case ModuleTypes.CAROUSEL:
        drawerContents =
          <CarouselEditor
            module={module}
            onChange={this.handleOpenModuleChange.bind(this)} />
        break
      case ModuleTypes.ANSWER:
        drawerContents =
          <AnswerEditor
            module={module}
            onChange={this.handleOpenModuleChange.bind(this)} />
        break
      default:
        drawerContents = <div></div>
        break
    }

    return(
      <div className={this.props.classes.wrapper}>
        <div className={this.props.classes.wrapperQuestionModules}>
          <Collapse in={this.state.showOpenQuestion} timeout={200}>
            { !isQuestionEmpty ? (
              <div>
                <TextField
                  required
                  autoFocus
                  error={this.props.errorQuestion}
                  multiline
                  rows="8"
                  className={this.props.classes.question}
                  label="Enter Your Question"
                  margin="normal"
                  value={question.question}
                  helperText={question.question.length + "/" + questionMaxCharCount}
                  onChange={this.handleQuestionChange.bind(this)} />
                <div className={this.props.classes.wrapperContainer}>
                  <Container
                    id={this.questionModulesContainerId}
                    modules={answers}
                    column={true}
                    allowEdits={true}
                    onAddModule={this.handleAddModule.bind(this)}
                    onRemoveModule={this.handleRemoveModule.bind(this)}
                    onEditModule={this.toggleDrawer.bind(this)}
                    emptyContainerLabel="No answers yet. Please add one." />
                </div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleAddAnswer.bind(this)}
                  className={this.props.classes.addAnswer}>
                  Add Answer
                </Button>
              </div>
            ) : (
              <Typography
                variant="subtitle1"
                gutterBottom>
                Please select a question for "{this.props.lessonName}" lesson.
              </Typography>
            )}
          </Collapse>
        </div>
        <div className={this.props.classes.wrapperOtherQuestions}>
          <Typography
            variant="h6"
            gutterBottom>
            Questions
          </Typography>
          <Container
            id={this.otherQuestionsContainerId}
            modules={this.props.questions}
            column={true}
            allowEdits={true}
            selectedModule={question}
            emptyContainerLabel="No questions yet. Please add one."
            onRemoveModule={this.handleRemoveQuestion.bind(this)}
            onEditModule={this.handleEditQuestion.bind(this)} />
          <Button
            variant="contained"
            color="primary"
            onClick={this.handleAddQuestion.bind(this)}
            className={this.props.classes.addQuestion}>
            Add Question
          </Button>
        </div>
        <Drawer
          anchor="right"
          open={this.state.openDrawer}
          onClose={this.toggleDrawer.bind(this)}
        >
          {drawerContents}
        </Drawer>
      </div>
    )
  }
}

export default withStyles(styles)(QuizEditor)
