const config = require(`./config/${process.env.REACT_APP_HOST_ENV}.json`)
const FileDownload = require("js-file-download")
const async = require('async')
const domain = config.domain
const url = {
  user: `${domain}/user`,
  users: `${domain}/users`,
  zone: `${domain}/zone`,
  zones: `${domain}/zones`,
  section: `${domain}/admin-section`,
  sections: `${domain}/admin-sections`,
  images: `${domain}/content/images`,
  pack: `${domain}/content/pack`,
  packs: `${domain}/content/packs`,
  flight: `${domain}/content/flight`,
  flights: `${domain}/content/flights`,
  lesson: `${domain}/content/lesson`,
  lessons: `${domain}/content/lessons`,
  lessonModule: `${domain}/content/lesson-module`,
  lessonModules: `${domain}/content/lesson-modules`,
  contentTypes: `${domain}/content-types`,
  question: `${domain}/content/question`,
  questions: `${domain}/content/questions`,
  answer: `${domain}/content/answer`,
  answers: `${domain}/content/answers`,
  events: `${domain}/events`,
  event: `${domain}/event`,
  languages: `${domain}/languages`,
  language: `${domain}/language`,
  library: `${domain}/library`,
  funFact: `${domain}/content/fun-fact`,
  funFacts: `${domain}/content/fun-facts`,
  oAuthConfig: `${domain}/config/oauth`,
  office: `${domain}/office`,
  offices: `${domain}/offices`,
  countries: `${domain}/countries`,
  department: `${domain}/department`,
  departments: `${domain}/departments`,
  login: `${domain}/login`,
  code: `${domain}/hoppylogin`,
  analytics: `${domain}/analytics`,
  analyticsUsersLogins: `${domain}/users/all/logins`,
  analyticsUsersSignUps: `${domain}/users/all/signups/date`,
  analyticsRepeatingUsersLogins: `${domain}/users/all/repeating/logins/date`,
  analyticsDailyChallengeCompletions: `${domain}/analytics/daily-challenge`,
  badgegroup: `${domain}/badgegroup`,
  badgegroups: `${domain}/badgegroups`,
  badgegroupTranslation: `${domain}/badgegrouptranslation`,
  badgegroupTranslations: `${domain}/badgegrouptranslations`,
  badge: `${domain}/badge`,
  badges: `${domain}/badges`,
  badgeTranslation: `${domain}/badgetranslation`,
  badgeTranslations: `${domain}/badgetranslations`,
  calendarNotifications: `${domain}/calendarnotifications`,
  calendarNotification: `${domain}/calendarnotification`,
  calendarNotificationTranslations: `${domain}/calendarnotificationtranslations`,
  calendarNotificationTranslation: `${domain}/calendarnotificationtranslation`,
  pushNotifications: `${domain}/pushnotifications`,
  pushNotification: `${domain}/pushnotification`,
  userActivityActions: `${domain}/user-activity-actions`,
  usersMostPlayedMoment: `${domain}/user-activity-actions/mostplayedmoment`
}

function getJwtToken() {
  return localStorage.getItem("jwt-token")
}

/******************************************************/
/*** GET REQUESTS *************************************/
/******************************************************/

// GET USER SIMULATION FOR DEBUG
async function getUserSimulation(id, cb) {
  try {
    const settings = {
      headers: {
        'Authorization': 'Bearer ' + getJwtToken()
      }
    }
    const promise = await fetch(`${url.user}/${id}/simulate-app`, settings)
    const data = await promise.json()
    cb(data.user)
  } catch (error) {
    console.log(`Error: ${error}`)
    cb(null)
  }
}
// GET USER INFO
async function getCurrentUser(id, cb) {
  try {
    const settings = {
      headers: {
        'Authorization': 'Bearer ' + getJwtToken()
      }
    }
    const promise = await fetch(`${url.user}/${id}`, settings)
    const data = await promise.json()
    cb(data.user)
  } catch (error) {
    console.log(`Error: ${error}`)
    cb(null)
  }
}

// GET ALL USERS
async function getAllUsers(cb){
  try {
    const settings = {
      headers: {
        'Authorization': 'Bearer ' + getJwtToken()
      }
    }
    const promise = await fetch(`${url.users}/all`, settings)
    const data = await promise.json()
    cb(data.users)
  } catch (error) {
    console.log(`Error: ${error}`)
    cb(null)
  }
}

// GET ALL USER ACTIVITY
async function getAllUserActivity(id, cb){
  try {
    const settings = {
      headers: {
        'Authorization': 'Bearer ' + getJwtToken()
      }
    }
    const promise = await fetch(`${url.user}/${id}/activity/all`, settings)
    const data = await promise.json()
    cb(data.userActivity)
  } catch (error) {
    console.log(`Error: ${error}`)
    cb(null)
  }
}

// GET ALL USER BADGES
async function getAllUserBadges(id, cb){
  try {
    const settings = {
      headers: {
        'Authorization': 'Bearer ' + getJwtToken()
      }
    }
    const promise = await fetch(`${url.user}/${id}/badges/all`, settings)
    const data = await promise.json()
    cb(data.userBadges)
  } catch (error) {
    console.log(`Error: ${error}`)
    cb(null)
  }
}

// GET ALL USER BADGES AND PAGINATION
async function getUserBadges(id, cb, query){
  try {
    const settings = {
      headers: {
        'Authorization': 'Bearer ' + getJwtToken()
      }
    }
    const promise = await fetch(`${url.user}/${id}/badges/${query}`, settings)
    const data = await promise.json()
    cb && cb(data)
    return data
  } catch (error) {
    console.log(`Error: ${error}`)
    cb && cb(null)
    return null
  }
}

// GET ALL USER HISTORY
async function getUserBeercoinHistory(hash, cb){
  try {
    const settings = {
      headers: {
        'Authorization': 'Bearer ' + getJwtToken()
      }
    }
    const promise = await fetch(`${url.user}/hash/${hash}/history`, settings)
    const data = await promise.json()
    cb(data.history)
  } catch (error) {
    console.log(`Error: ${error}`)
    cb(null)
  }
}

// GET ALL ZONES
async function getAllZones(cb){
  try {
    const settings = {
      headers: {
        'Authorization': 'Bearer ' + getJwtToken()
      }
    }
    const promise = await fetch(`${url.zones}/all`, settings)
    const data = await promise.json()
    cb(data.zones)
  } catch (error) {
    console.log(`Error: ${error}`)
    cb(null)
  }
}

// GET ALL ADMIN SECTIONS
async function getAllSections(cb){
  try {
    const settings = {
      headers: {
        'Authorization': 'Bearer ' + getJwtToken()
      }
    }
    const promise = await fetch(`${url.sections}/all`, settings)
    const data = await promise.json()
    cb(data.adminSections)
  } catch (error) {
    console.log(`Error: ${error}`)
    cb(null)
  }
}

// GET ALL LANGUAGES
async function getAllLanguages(cb){
  try {
    const settings = {
      headers: {
        'Authorization': 'Bearer ' + getJwtToken()
      }
    }
    const promise = await fetch(`${url.languages}/all`, settings)
    if(promise.status === 401) {
      cb && cb({ error: 'logged out' })
      return { error: 'logged out' }
    } else if(promise.status === 500) {
      cb && cb({ error: 'not admin' })
      return { error: 'not admin' }
    }
    const data = await promise.json()
    cb && cb(data)
    return data
  } catch (error) {
    console.log(`Error: ${error}`)
    cb && cb(null)
    return null
  }
}

// GET ALL PACKS FOR CONTENT BROWSER
async function getAllPacks(cb){
  try {
    const settings = {
      headers: {
        'Authorization': 'Bearer ' + getJwtToken()
      }
    }
    const promise = await fetch(`${url.packs}/all`, settings)
    const data = await promise.json()
    cb(data.packs)
  } catch (error) {
    console.log(`error: ${error}`)
    cb(null)
  }
}

// GET ALL FLIGHTS FOR CONTENT BROWSER
async function getAllFlights(cb){
  try {
    const settings = {
      headers: {
        'Authorization': 'Bearer ' + getJwtToken()
      }
    }
    const promise = await fetch(`${url.flights}/all`, settings)
    const data = await promise.json()
    return(cb(data.flights)) // on callback, return as well, to avoid sending response twice
  } catch (error) {
    console.error(`Error: ${error}`)
    return(cb(null))
  }
}

// GET ALL LESSONS FOR CONTENT BROWSER
async function getAllLessons(cb){
  try {
    const settings = {
      headers: {
        'Authorization': 'Bearer ' + getJwtToken()
      }
    }
    const promise = await fetch(`${url.lessons}/all`, settings)
    const data = await promise.json()
    cb(data.lessons)
  } catch (error) {
    console.log(`Error: ${error}`)
    cb(null)
  }
}

function getImageUrl(imgName){
  return `${url.images}/${imgName}`;
}

// GET PAGINATED LESSONS FOR CONTENT BROWSER
async function getLessons(cb, query){
  try {
    const settings = {
      headers: {
        'Authorization': 'Bearer ' + getJwtToken()
      }
    }
    let urlLessons = url.lessons
    if(query) {
      urlLessons += "/" + query
    }
    const promise = await fetch(urlLessons, settings)
    const data = await promise.json()
    cb && cb(data)
    return data
  } catch (error) {
    console.log(`Error: ${error}`)
    cb && cb(null)
    return null
  }
}

// GET ALL LESSONS FOR CONTENT BROWSER
async function getAllLessonModules(cb){
  try {
    const settings = {
      headers: {
        'Authorization': 'Bearer ' + getJwtToken()
      }
    }
    const promise = await fetch(`${url.lessonModules}/all`, settings)
    const data = await promise.json()
    cb(data.lessonModules)
  } catch (error) {
    console.log(`Error: ${error}`)
    cb(null)
  }
}

// GET PAGINATED LESSON MODULES FOR CONTENT BROWSER
async function getLessonModules(cb, query){
  try {
    const settings = {
      headers: {
        'Authorization': 'Bearer ' + getJwtToken()
      }
    }
    let urlLessonModules = url.lessonModules
    if(query) {
      urlLessonModules += "/" + query
    }
    const promise = await fetch(urlLessonModules, settings)
    const data = await promise.json()
    cb && cb(data)
    return data
  } catch (error) {
    console.log(`Error: ${error}`)
    cb && cb(null)
    return null
  }
}

// GET ALL LESSON MODULE CONTENT TYPES FOR CONTENT BROWSER
async function getAllContentTypes(cb){
  try {
    const settings = {
      headers: {
        'Authorization': 'Bearer ' + getJwtToken()
      }
    }
    const promise = await fetch(`${url.contentTypes}/all`, settings)
    const data = await promise.json()
    cb(data.contentTypes)
  } catch (error) {
    console.log(`Error: ${error}`)
    cb(null)
  }
}

// GET ALL QUESTIONS FOR CONTENT BROWSER
async function getAllQuestions(cb){
  try {
    const settings = {
      headers: {
        'Authorization': 'Bearer ' + getJwtToken()
      }
    }
    const promise = await fetch(`${url.questions}/all`, settings)
    const data = await promise.json()
    cb(data.questions)
  } catch (error) {
    console.log(`Error: ${error}`)
    cb(null)
  }
}

// GET ALL QUESTIONS FOR CONTENT BROWSER
async function getAllAnswers(cb){
  try {
    const settings = {
      headers: {
        'Authorization': 'Bearer ' + getJwtToken()
      }
    }
    const promise = await fetch(`${url.answers}/all`, settings)
    const data = await promise.json()
    cb(data.answers)
  } catch (error) {
    console.log(`Error: ${error}`)
    cb(null)
  }
}

// GET ALL EVENTS
async function getAllEvents(cb){
  try {
    const settings = {
      headers: {
        'Authorization': 'Bearer ' + getJwtToken()
      }
    }
    const promise = await fetch(`${url.events}/all`, settings)
    const data = await promise.json()
    cb(data.events)
  } catch (error) {
    console.log(`Error: ${error}`)
    cb(null)
  }
}

// GET ALL FUN FACTS
async function getAllFunFacts(cb){
  try {
    const settings = {
      headers: {
        'Authorization': 'Bearer ' + getJwtToken()
      }
    }
    const promise = await fetch(`${url.funFacts}/all`, settings)
    const data = await promise.json()
    cb(data.funFacts)
  } catch (error) {
    console.log(`Error: ${error}`)
    cb(null)
  }
}

//  GET ALL DEPARTMENTS
async function getAllDepartments(cb){
  try {
    const settings = {
      headers: {
        'Authorization': 'Bearer ' + getJwtToken()
      }
    }
    const promise = await fetch(`${url.departments}/all`, settings)
    const data = await promise.json()
    cb(data.departments)
  } catch (error) {
    console.log(`Error: ${error}`)
    cb(null)
  }
}

// GET ALL OFFICES
async function getAllOffices(cb){
  try {
    const settings = {
      headers: {
        'Authorization': 'Bearer ' + getJwtToken()
      }
    }
    const promise = await fetch(`${url.offices}/all`, settings)
    const data = await promise.json()
    cb(data.offices)
  } catch (error) {
    console.log(`Error: ${error}`)
    cb(null)
  }
}


async function getAllCountries(cb){
  try {
    const settings = {
      headers: {
        'Authorization': 'Bearer ' + getJwtToken()
      }
    }
    const promise = await fetch(`${url.countries}/filter-analytics/`, settings)
    const data = await promise.json()
    cb(data.countries)
  } catch (error) {
    console.log(`Error: ${error}`)
    cb(null)
  }
}

// GET OAUTH CONFIG
async function getOAuthConfig(){
  try {
    const promise = await fetch(`${url.oAuthConfig}`)
    const data = await promise.json()
    return data
  } catch (error) {
    console.log(`Error: ${error}`)
    return null
  }
}

// GET ALL ANALYTICS
async function getAllAnalytics(startDate, endDate, zoneId, officeId, cb){
  try {
    zoneId = zoneId === 0 ? '': zoneId;
    officeId = officeId === 0 ? '': officeId;
    const settings = {
      headers: {
        'Authorization': 'Bearer ' + getJwtToken()
      }
    }
    const promise = await fetch(
      url.analytics
      + "/all/?startDate=" + startDate.toJSON()
      + "&endDate=" + endDate.toJSON()
      + "&zoneId=" + zoneId
      + "&officeId=" + officeId
    , settings)
    const data = await promise.json()
    cb(data)
  } catch (error) {
    console.log(`Error: ${error}`)
    cb(null)
  }
}


function getChartOverviewPlaceholders(){
  let placeholders = [];

  placeholders.push(getPlaceholder('current-registered-internal-users', 'Currently Registered Internal Users', 'text', false)); 
  placeholders.push(getPlaceholder('current-registered-external-users', 'Currently Registered External Users', 'text', false)); 
  placeholders.push(getPlaceholder('current-registered-users-comparison', 'Chart of Currently Registered Users by Date', 'text', true)); 

  placeholders.push(getPlaceholder('daily-logins', 'Daily Users', 'text', false));
  
  placeholders.push(getPlaceholder('lifetime-packs-completed', 'Lifetime Packs Completed', 'text', false));
  placeholders.push(getPlaceholder('lifetime-flights-completed', 'Lifetime Flights Completed', 'text', false));
  placeholders.push(getPlaceholder('lifetime-lessons-completed', 'Lifetime Lessons Completed', 'text', false));
  
  placeholders.push(getPlaceholder('daily-logins-chart', 'Daily Users on Date Range', 'text', true));
  placeholders.push(getPlaceholder('lifetime-content-completed', 'Lifetime acumulated Packs, Flights, and Lessons Completed', 'text', true));

  placeholders.push(getPlaceholder('daily-challenge-completions', 'Daily Challenge Completions', 'text', false)); // check if win value is sum or %, make it %
  placeholders.push(getPlaceholder('lifetime-beercoins-earned', 'Lifetime Beercoins Earned', 'text', false));

  placeholders.push(getPlaceholder('daily-challenge-completions-chart', 'Daily Challenge Completions by Day', 'text', true)); 
  placeholders.push(getPlaceholder('lifetime-beercoins-earned-chart', 'Lifetime Beercoins Earned by Day', 'text', true)); 

  placeholders.push(getPlaceholder('top-user-beercoins', 'Top Beercoins Owners', 'text', true)); 

  // placeholders.push(getPlaceholder('count-users-country', 'Count Users per Country', 'text', true)); // both are for the Zone Analytics
  // placeholders.push(getPlaceholder('lifetime-signups-office', 'Lifetime Sign Ups By Office', 'text', true));

  // placeholders.push(getPlaceholder('count-users-zone', 'Count Users per Zone', 'text', true)); // this wont be needed, and if it is, it's in Zone section Analytics
  
  placeholders.push(getPlaceholder('internal-beercoins-comparison', 'Comparison of Internal Users Beercoins by Zone', 'text', true)); // pizza chart beercoins per zone
  placeholders.push(getPlaceholder('external-beercoins-comparison', 'Comparison of External Users Beercoins by Country', 'text', true)); // pizza chart beercoins per country

  placeholders.push(getPlaceholder('internal-users-comparison', 'Comparison of Internal Users by Zone', 'text', true)); // pizza chart users per zone
  placeholders.push(getPlaceholder('external-users-comparison', 'Comparison of External Users by Country', 'text', true)); // pizza chart users per country

  placeholders.push(getPlaceholder('users-beercoins-evolution', 'Evolution of Users and Beercoins', 'text', true)); // users and beercoins evolution

  placeholders.push(getPlaceholder('count-users-zone-evolution-chart', 'Users by Zone Evolution', 'text', true)); // users evolution by zone
  placeholders.push(getPlaceholder('count-beercoins-zone-evolution-chart', 'Beercoins by Zone Evolution', 'text', true)); // beercoins evolution by zone

  return placeholders;

  function getPlaceholder(id, title, type, bolBig = false){
    let chart = {}
    chart.id = id
    chart.title = title
    chart.description = `${title} chart is loading`
    chart.type = type
    chart.data = {}
    chart.data.type = "string"
    chart.data.value = "Loading..."
    chart.big = bolBig

    return chart;
  }
}

function getChartRegionalPlaceholders(){

  let placeholders = [];

  placeholders.push(getPlaceholder('count-users-zone-country', 'Count Users By Region', 'text', true)); 
  placeholders.push(getPlaceholder('count-users-zone-country-chart', 'Users by Region Evolution', 'text', true)); 
  placeholders.push(getPlaceholder('lifetime-content-completed-region-table', 'Content Completed By Region', 'text', true)); 
  placeholders.push(getPlaceholder('lifetime-content-completed-region-chart', 'Lifetime Content Completed by Region', 'text', true)); 
  placeholders.push(getPlaceholder('lifetime-beercoins-earned-region-table', 'Lifetime Beercoins Earned by Region', 'text', true)); 
  placeholders.push(getPlaceholder('lifetime-beercoins-earned-region-chart', 'Lifetime Beercoins Earned by Date', 'text', true)); 
  placeholders.push(getPlaceholder('top-user-beercoins-region', 'Top Beercoins Owners by Region', 'text', true)); 
  placeholders.push(getPlaceholder('top-user-badges-region', 'Top Badges Owners by Region', 'text', true)); 
  placeholders.push(getPlaceholder('users-per-badge', 'Users per Badge', 'text', true)); 

  return placeholders;
  
  function getPlaceholder(id, title, type, bolBig = false){
    let chart = {}
    chart.id = id
    chart.title = title
    chart.description = `${title} chart is loading`
    chart.type = type
    chart.data = {}
    chart.data.type = "string"
    chart.data.value = "Loading..."
    chart.big = bolBig

    return chart;
  }
}

function getChartPlaceholders(zoneId, officeId){
  zoneId = zoneId === 0 ? '': zoneId;
  officeId = officeId === 0 ? '': officeId;

  let placeholders = [];

  placeholders.push(getPlaceholder('all-users', 'Daily Logins', 'text'));
  placeholders.push(getPlaceholder('current-registered-users', 'Currently Registered Users', 'text'));
  if(zoneId === '' && officeId === ''){
    placeholders.push(getPlaceholder('lifetime-registered-users', 'Lifetime Registered Users', 'text'));
  }
  placeholders.push(getPlaceholder('daily-sign-ups', 'Daily Sign Ups', 'text'));

  placeholders.push(getPlaceholder('daily-repeating-users', 'Daily Returning Users', 'text'));
  placeholders.push(getPlaceholder('daily-challenge-completions', 'Daily Challenge Completions', 'text'));
  placeholders.push(getPlaceholder('lifetime-beercoins-earned', 'Lifetime Beercoins Earned', 'text'));
  placeholders.push(getPlaceholder('lifetime-events-completed', 'Lifetime Events Completed', 'text'));
  
  placeholders.push(getPlaceholder('lifetime-packs-completed', 'Lifetime Packs Completed', 'text'));
  placeholders.push(getPlaceholder('lifetime-flights-completed', 'Lifetime Flights Completed', 'text'));
  placeholders.push(getPlaceholder('lifetime-lessons-completed', 'Lifetime Lessons Completed', 'text'));
  placeholders.push(getPlaceholder('lifetime-quizzes-completed', 'Lifetime Quizzes Completed', 'text'));

  // new table here
  placeholders.push(getPlaceholder('count-users-country', 'Count Users per Country', 'text', true))
  placeholders.push(getPlaceholder('lifetime-signups-office', 'Lifetime Sign Ups By Office', 'text', true));

  placeholders.push(getPlaceholder('lifetime-beercoins-earned-office', 'Lifetime Beercoins Earned By Office', 'text', true));
  placeholders.push(getPlaceholder('office-leaderboards', 'Office Leaderboards', 'text', true));

  placeholders.push(getPlaceholder('lessons-leaderboard-completions', 'Lessons Leaderboard', 'text', true));
  placeholders.push(getPlaceholder('flights-leaderboard-completions', 'Flights Leaderboard', 'text', true));

  placeholders.push(getPlaceholder('most-played-moment', 'Most hour Played Moment of the Day', 'text', true));

  return placeholders;
  
  function getPlaceholder(id, title, type, bolBig = false){
    let chart = {}
    chart.id = id
    chart.title = title
    chart.description = `${title} chart is loading`
    chart.type = type
    chart.data = {}
    chart.data.type = "string"
    chart.data.value = "Loading..."
    chart.big = bolBig

    return chart;
  }

}
// GET ALL ANALYTICS
// Make endpoint by id/name instead of index
// Make separete endpoints for analytics with or without zone and office
function getOneAnalyticsById(params, cb){
  const analyticsId = params.analyticsId || false;
  const zoneId = params.zoneId === 0 ? '' : params.zoneId || '';
  // const officeId = params.officeId === 0 ? '' : params.officeId || '';
  // const country = params.country === 0 ? '' : params.country || '';
  const startDate = params.startDate || new Date(new Date().setHours(0, 0, 0, 0));
  const endDate = params.endDate || new Date();

  const offices = (!!params.offices && params.offices.length > 0) ? [...params.offices] : []; // for multiselect
  const countries = (!!params.countries && params.countries.length > 0) ? [...params.countries] : [];

  const maxPoints = !!params.maxPoints ? params.maxPoints : 10; // set max number of points, where applicable
  
  // console.log(startDate, endDate);

  if(analyticsId){
    requestAnalytics(analyticsId, (err, results) => {
      if(err){
        console.log(`Error: ${err}`);
        return(cb(null));
      }
      // console.log(results);
      return(cb(results));
    });
  }else {
    return(cb(null));
  }


  async function requestAnalytics(endpoint, callback){
    try {
      const settings = {
        method: 'POST',
        body: JSON.stringify({
          startDate,
          endDate,
          zoneId,
          // officeId, // decide if remove or keep (here and index and endpoint)
          // country, // decide if remove or keep (here and index and endpoint)
          offices,
          countries,
          maxPoints
        }),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + getJwtToken()
        }
      }
      // const promise = await fetch(
      //   url.analytics
      //   + "/one/" + endpoint + "/?startDate=" + startDate.toJSON()
      //   + "&endDate=" + endDate.toJSON()
      //   + "&zoneId=" + zoneId
      //   + "&officeId=" + officeId
      //   + "&country=" + country
      // , settings)
      const promise = await fetch(
        url.analytics
        + "/one/" + endpoint 
      , settings)
      const data = await promise.json()
      callback(false, data);
    } catch (error) {
      console.log(`Error fetching ${url.analytics}/${endpoint}: ${error}`)
      callback(error);
    }
  }

}

async function getOneAnalytics(index, startDate, endDate, zoneId, officeId, cb){
  zoneId = zoneId === 0 ? '': zoneId;
  officeId = officeId === 0 ? '': officeId;
  
  let flow = [
    _getAllUsersLogins,
    _getCountUsersRegistered,
    _getAllUsersSignUpsByDate,
    _getAllRepeatingUsersLoginsByDate,
    _getAllDailyChallengeCompletions,
    _getAllUsersBeercoinEarnings,
    _getAllUsersEventsCompleted,
    _getAllUsersPacksCompleted,
    _getAllUsersFlightsCompleted,
    _getAllUsersLessonsCompleted,
    _getAllUsersQuizzesCompleted,
    _getCountUsersByCountry,
    _getAllUsersSignUpsByOffice,
    _getAllUsersBeercoinEarningsByOffice,
    _getAllOfficeBasedLeaderboards,
    _getLeaderBoardLessons,
    _getLeaderBoardFlights,
    _getMostPlayedMoment,
  ];
  if(zoneId === '' && officeId === ''){
    flow = [
      _getAllUsersLogins,
      _getCountUsersRegistered,
      _getLifetimeRegisteredUsers,
      _getAllUsersSignUpsByDate,
      _getAllRepeatingUsersLoginsByDate,
      _getAllDailyChallengeCompletions,
      _getAllUsersBeercoinEarnings,
      _getAllUsersEventsCompleted,
      _getAllUsersPacksCompleted,
      _getAllUsersFlightsCompleted,
      _getAllUsersLessonsCompleted,
      _getAllUsersQuizzesCompleted,
      _getCountUsersByCountry,
      _getAllUsersSignUpsByOffice,
      _getAllUsersBeercoinEarningsByOffice,
      _getAllOfficeBasedLeaderboards,
      _getLeaderBoardLessons,
      _getLeaderBoardFlights,
      _getMostPlayedMoment,
    ];
  }
  // async.parallel(flow, (err, results)=>{
  //   if(err){
  //     console.log(`Error: ${err}`);
  //     return(cb(null));
  //   }
  //   console.log(results);
  //   cb(results)
  // });

  flow[index]((err, results)=>{
    if(err){
      console.log(`Error: ${err}`);
      return(cb(null));
    }
    // console.log(results);
    cb(results)
  });

  function _getAllUsersLogins(callback){
    requestAnalytics('all-users', (err, data)=>{
      callback(err, data);
    });
  }
  function _getCountUsersRegistered(callback){
    requestAnalytics('current-registered-users', (err, data)=>{
      callback(err, data);
    });
  }
  function _getLifetimeRegisteredUsers(callback){
    requestAnalytics('lifetime-registered-users', (err, data)=>{
      callback(err, data);
    });
  }
  function _getAllUsersSignUpsByDate(callback){
    requestAnalytics('daily-sign-ups', (err, data)=>{
      callback(err, data);
    });
  }
  function _getAllRepeatingUsersLoginsByDate(callback){
    requestAnalytics('daily-repeating-users', (err, data)=>{
      callback(err, data);
    });
  }
  function _getAllDailyChallengeCompletions(callback){
    requestAnalytics('daily-challenge-completions', (err, data)=>{
      callback(err, data);
    });
  }
  function _getCountUsersByCountry(callback){
    requestAnalytics('count-users-country', (err, data)=>{
      callback(err, data);
    });
  }
  function _getAllUsersSignUpsByOffice(callback){
    requestAnalytics('lifetime-signups-office', (err, data)=>{
      callback(err, data);
    });
  }
  function _getAllUsersPacksCompleted(callback){
    requestAnalytics('lifetime-packs-completed', (err, data)=>{
      callback(err, data);
    });
  }
  function _getAllUsersLessonsCompleted(callback){
    requestAnalytics('lifetime-lessons-completed', (err, data)=>{
      callback(err, data);
    });
  }
  function _getAllUsersBeercoinEarningsByOffice(callback){
    requestAnalytics('lifetime-beercoins-earned-office', (err, data)=>{
      callback(err, data);
    });
  }
  function _getAllUsersBeercoinEarnings(callback){
    requestAnalytics('lifetime-beercoins-earned', (err, data)=>{
      callback(err, data);
    });
  }
  function _getAllUsersFlightsCompleted(callback){
    requestAnalytics('lifetime-flights-completed', (err, data)=>{
      callback(err, data);
    });
  }
  function _getAllOfficeBasedLeaderboards(callback){
    requestAnalytics('office-leaderboards', (err, data)=>{
      callback(err, data);
    });
  }
  function _getAllUsersQuizzesCompleted(callback){
    requestAnalytics('lifetime-quizzes-completed', (err, data)=>{
      callback(err, data);
    });
  }
  function _getAllUsersEventsCompleted(callback){
    requestAnalytics('lifetime-events-completed', (err, data)=>{
      callback(err, data);
    });
  }
  function _getLeaderBoardLessons(callback){
    requestAnalytics('lessons-leaderboard-completions', (err, data)=>{
      callback(err, data);
    });
  }
  function _getLeaderBoardFlights(callback){
    requestAnalytics('flights-leaderboard-completions', (err, data)=>{
      callback(err, data);
    });
  }
  function _getMostPlayedMoment(callback){
    requestAnalytics('most-played-moment', (err, data)=>{
      callback(err, data);
    });
  }

  async function requestAnalytics(endpoint, callback){
    try {
      const settings = {
        headers: {
          'Authorization': 'Bearer ' + getJwtToken()
        }
      }
      const promise = await fetch(
        url.analytics
        + "/one/" + endpoint + "/?startDate=" + startDate.toJSON()
        + "&endDate=" + endDate.toJSON()
        + "&zoneId=" + zoneId
        + "&officeId=" + officeId
      , settings)
      const data = await promise.json()
      callback(false, data);
    } catch (error) {
      console.log(`Error fetching ${url.analytics}/${endpoint}: ${error}`)
      callback(error);
    }
  }

}

// GET ALL USER LOGINS FOR ANALYTICS
async function getAllAnalyticsUsersLogins(startDate, endDate, cb){
  try {
    const settings = {
      headers: {
        'Authorization': 'Bearer ' + getJwtToken()
      }
    }
    const promise = await fetch(
      url.analyticsUsersLogins
      + "/?startDate=" + startDate
      + "&endDate=" + endDate
    , settings)
    const data = await promise.json()
    cb(data)
  } catch (error) {
    console.log(`Error: ${error}`)
    cb(null)
  }
}

// GET ALL USER SIGN UPS FOR ANALYTICS
async function getAllAnalyticsUsersSignUps(startDate, endDate, cb){
  try {
    const settings = {
      headers: {
        'Authorization': 'Bearer ' + getJwtToken()
      }
    }
    const promise = await fetch(
      url.analyticsUsersSignUps
      + "/?startDate=" + startDate
      + "&endDate=" + endDate
    , settings)
    const data = await promise.json()
    cb(data)
  } catch (error) {
    console.log(`Error: ${error}`)
    cb(null)
  }
}

// GET ALL REPEATING USERS LOGINS FOR ANALYTICS
async function getAllAnalyticsRepeatingUsersLogins(startDate, endDate, cb){
  try {
    const settings = {
      headers: {
        'Authorization': 'Bearer ' + getJwtToken()
      }
    }
    const promise = await fetch(
      url.analyticsRepeatingUsersLogins
      + "/?startDate=" + startDate
      + "&endDate=" + endDate
    , settings)
    const data = await promise.json()
    cb(data)
  } catch (error) {
    console.log(`Error: ${error}`)
    cb(null)
  }
}

// GET ALL USER DAILY CHALLENGE STARTS AND WINS
async function getAllDailyChallengeCompletions(startDate, endDate, cb){
  try {
    const settings = {
      headers: {
        'Authorization': 'Bearer ' + getJwtToken()
      }
    }
    const promise = await fetch(
      url.analyticsDailyChallengeCompletions
      + "/?startDate=" + startDate
      + "&endDate=" + endDate
    , settings)
    const data = await promise.json()
    cb(data)
  } catch (error) {
    console.log(`Error: ${error}`)
    cb(null)
  }
}

// GET ALL BADGE GROUPS
async function getAllBadgeGroups(cb){
  try {
    const settings = {
      headers: {
        'Authorization': 'Bearer ' + getJwtToken()
      }
    }
    const promise = await fetch(`${url.badgegroups}/all`, settings)
    const data = await promise.json()
    cb(data.badgegroups)
  } catch (error) {
    console.log(`Error: ${error}`)
    cb(null)
  }
}

// GET ALL BADGE GROUP TRANSLATIONS
async function getAllBadgeGroupTranslations(cb){
  try {
    const settings = {
      headers: {
        'Authorization': 'Bearer ' + getJwtToken()
      }
    }
    const promise = await fetch(`${url.badgegroupTranslations}/all`, settings)
    const data = await promise.json()
    cb(data.badgegroupTranslations)
  } catch (error) {
    console.log(`Error: ${error}`)
    cb(null)
  }
}

// GET ALL BADGES
async function getAllBadges(cb){
  try {
    const settings = {
      headers: {
        'Authorization': 'Bearer ' + getJwtToken()
      }
    }
    const promise = await fetch(`${url.badges}/all`, settings)
    const data = await promise.json()
    cb(data.badges)
  } catch (error) {
    console.log(`Error: ${error}`)
    cb(null)
  }
}

// GET ALL BADGE TYPES
async function getAllBadgeTypes(cb){
  try {
    const settings = {
      headers: {
        'Authorization': 'Bearer ' + getJwtToken()
      }
    }
    const promise = await fetch(`${url.badges}/all/types`, settings)
    const data = await promise.json()
    cb(data.badgeTypes)
  } catch (error) {
    console.log(`Error: ${error}`)
    cb(null)
  }
}

// GET ALL BADGE TRANSLATIONS
async function getAllBadgeTranslations(cb){
  try {
    const settings = {
      headers: {
        'Authorization': 'Bearer ' + getJwtToken()
      }
    }
    const promise = await fetch(`${url.badgeTranslations}/all`, settings)
    const data = await promise.json()
    cb(data.badgeTranslations)
  } catch (error) {
    console.log(`Error: ${error}`)
    cb(null)
  }
}

// GET ALL CALENDAR NOTIFICATIONS
async function getAllCalendarNotifications(cb){
  try {
    const settings = {
      headers: {
        'Authorization': 'Bearer ' + getJwtToken()
      }
    }
    const promise = await fetch(`${url.calendarNotifications}/all`, settings)
    const data = await promise.json()
    cb(data.calendarNotifications)
  } catch (error) {
    console.log(`Error: ${error}`)
    cb(null)
  }
}

// GET ALL CALENDAR NOTIFICATION TRANSLATIONS
async function getAllCalendarNotificationTranslations(cb){
  try {
    const settings = {
      headers: {
        'Authorization': 'Bearer ' + getJwtToken()
      }
    }
    const promise = await fetch(`${url.calendarNotificationTranslations}/all`, settings)
    const data = await promise.json()
    cb(data.calendarNotificationTranslations)
  } catch (error) {
    console.log(`Error: ${error}`)
    cb(null)
  }
}

// GET ALL QUESTIONS FOR DAILY CHALLENGE
async function getAllDailyChallengeQuestions(cb){
  try {
    const settings = {
      headers: {
        'Authorization': 'Bearer ' + getJwtToken()
      }
    }
    const promise = await fetch(`${url.questions}/all?lessonId=-1`, settings)
    const data = await promise.json()
    cb(data.questions)
  } catch (error) {
    console.log(`Error: ${error}`)
    cb(null)
  }
}

// GET ALL PUSH NOTIFICATIONS
async function getAllPushNotifications(cb){
  try {
    const settings = {
      headers: {
        'Authorization': 'Bearer ' + getJwtToken()
      }
    }
    const promise = await fetch(`${url.pushNotifications}/all`, settings)
    const data = await promise.json()
    cb(data.pushNotifications)
  } catch (error) {
    console.log(`Error: ${error}`)
    cb(null)
  }
}

// GET ALL USER ACTIVITY ACTIONS
async function getAllUserActivityActions(cb){
  try {
    const settings = {
      headers: {
        'Authorization': 'Bearer ' + getJwtToken()
      }
    }
    const promise = await fetch(`${url.userActivityActions}/all`, settings)
    const data = await promise.json()
    cb(data.userActivityActions)
  } catch (error) {
    console.log(`Error: ${error}`)
    cb(null)
  }
}


// GET ALL USER ACTIVITY ACTIONS
async function getUsersMostPlayedTime(startDate, endDate, cb){
  try {
    const settings = {
      headers: {
        'Authorization': 'Bearer ' + getJwtToken()
      }
    }
    const promise = await fetch(
      `${url.usersMostPlayedMoment}?startDate=${startDate}&endDate=${endDate}`,
      settings)
    const data = await promise.json()
    cb(data)
  } catch (error) {
    console.log(`Error: ${error}`)
    cb(null)
  }
}

/******************************************************/
/*** PUT REQUESTS *************************************/
/******************************************************/

// PUT PACK
async function putPack(pack, cb){
  const { id, creationDate, lastModifiedDate, flights, language,  ...parsedPack} = { ...pack }
  const settings = {
    method: 'PUT',
    body: JSON.stringify(parsedPack),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getJwtToken()
    }
  }
  try {
    const promise = await fetch(`${url.pack}/${pack.id}`, settings)
    const response = await promise.json()
    cb(response)
  } catch (error) {
    console.log(`Error: ${error}`)
    cb(null)
  }
}

// PUT FLIGHT
async function putFlight(flight, cb) {
  const { id, creationDate, lastModifiedDate, ...parsedFlight } = { ...flight }
  const settings = {
    method: 'PUT',
    body: JSON.stringify(parsedFlight),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getJwtToken()
    }
  }
  try {
    const promise = await fetch(`${url.flight}/${flight.id}`, settings)
    const response = await promise.json()
    cb(response)
  } catch (error) {
    console.log(`Error: ${error}`)
    cb(null)
  }
}

// PUT LESSON
async function putLesson(lesson, cb) {
  const { id, creationDate, lastModifiedDate, modules, ...parsedLesson } = { ...lesson }
  const settings = {
    method: 'PUT',
    body: JSON.stringify(parsedLesson),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getJwtToken()
    }
  }
  try {
    const promise = await fetch(`${url.lesson}/${lesson.id}`, settings)
    const response = await promise.json()
    cb(response)
  } catch (error) {
    console.log(`Error: ${error}`)
    cb(null)
  }
}

// PUT LESSON MODULE
async function putLessonModule(lessonModule, cb) {
  const { id, creationDate, lastModifiedDate, ...parsedLessonModule } = { ...lessonModule }
  
  const data = new FormData();
  let aux = {};
  Object.entries(parsedLessonModule).forEach((kv)=>{ 
    let [key, value] = kv;
    if(key === 'file'){
      if(!!value){
        data.append(key, value);
      }
    } else{
      // data.append(key, value);
      // console.log(key, value);
      aux[key] = value;
    }
  });
  console.log(aux);
  data.append('params', JSON.stringify(aux));
  // console.log(data);
  const settings = {
    method: 'PUT',
    body: data,
    headers: {
      //'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getJwtToken()
      , 'Expect': '100-continue'
    }
  }
  try {
    const promise = await fetch(`${url.lessonModule}/${lessonModule.id}`, settings)
    const response = await promise.json()
    cb(response)
  } catch (error) {
    console.log(`Error: ${error}`)
    cb(null)
  }
}

// PUT QUESTION
async function putQuestion(question, cb){
  const { id, oldId, creationDate, lastModifiedDate, answers, order, ...parsedQuestion } = question
  const settings = {
    method: 'PUT',
    body: JSON.stringify(parsedQuestion),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getJwtToken()
    }
  }
  try {
    const promise = await fetch(`${url.question}/${question.id}`, settings)
    const response = await promise.json()
    cb(response.question)
  } catch (error) {
    console.log(`Error: ${error}`)
    cb(null)
  }
}

// PUT ANSWER
async function putAnswer(answer, cb){
  const { id, creationDate, lastModifiedDate, contentTypeId, ...parsedAnswer } = answer
  const settings = {
    method: 'PUT',
    body: JSON.stringify(parsedAnswer),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getJwtToken()
    }
  }
  try {
    const promise = await fetch(`${url.answer}/${answer.id}`, settings)
    const response = await promise.json()
    cb(response)
  } catch (error) {
    console.log(`Error: ${error}`)
    cb(null)
  }
}

// PUT EVENT
async function putEvent(event, cb){
  const {id, ...withoutId} = event
  const settings = {
    method: 'PUT',
    body: JSON.stringify(withoutId),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getJwtToken()
    }
  }
  try {
    const promise = await fetch(`${url.event}/${event.id}`, settings)
    const response = await promise.json()
    cb(response)
  } catch (error) {
    console.log(`Error: ${error}`)
    cb(null)
  }
}

// PUT FUN FACT
async function putFunFact(funFact, cb){
  const { id, creationDate, lastModifiedDate, languageId, number, ...parsedFunFact} = { ...funFact }
  const settings = {
    method: 'PUT',
    body: JSON.stringify(parsedFunFact),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getJwtToken()
    }
  }
  try {
    const promise = await fetch(`${url.funFact}/${funFact.id}`, settings)
    const response = await promise.json()
    cb(response)
  } catch (error) {
    console.log(`Error: ${error}`)
    cb(null)
  }
}

// PUT BADGE GROUP
async function putBadgeGroup(badgeGroup, cb){
  const {
    id,
    creationDate,
    lastModifiedDate,
    translations,
    ...parsedBadgeGroup
  } = { ...badgeGroup }
  const settings = {
    method: 'PUT',
    body: JSON.stringify(parsedBadgeGroup),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getJwtToken()
    }
  }
  try {
    const promise = await fetch(`${url.badgegroup}/${badgeGroup.id}`, settings)
    const response = await promise.json()
    cb(response.badgegroup)
  } catch (error) {
    console.log(`Error: ${error}`)
    cb(null)
  }
}

// PUT BADGE GROUP TRANSLATION
async function putBadgeGroupTranslation(translation, cb){
  const { id, languageName, ...parsedTranslation } = { ...translation }
  const settings = {
    method: 'PUT',
    body: JSON.stringify(parsedTranslation),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getJwtToken()
    }
  }
  try {
    const promise = await fetch(`${url.badgegroupTranslation}/${translation.id}`, settings)
    const response = await promise.json()
    cb && cb(response.badgegroupTranslation)
    return response.badgegroupTranslation
  } catch (error) {
    console.log(`Error: ${error}`)
    cb && cb(null)
    return null
  }
}

// PUT BADGE
async function putBadge(badge, cb){
  const {
    id, creationDate, lastModifiedDate,
    translations, badgegroup, ...parsedBadge
  } = { ...badge }
  const settings = {
    method: 'PUT',
    body: JSON.stringify(parsedBadge),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getJwtToken()
    }
  }
  try {
    const promise = await fetch(`${url.badge}/${badge.id}`, settings)
    const response = await promise.json()
    cb(response.badge)
  } catch (error) {
    console.log(`Error: ${error}`)
    cb(null)
  }
}

// PUT BADGE TRANSLATION
async function putBadgeTranslation(translation, cb){
  const { id, languageName, ...parsedTranslation } = { ...translation }
  const settings = {
    method: 'PUT',
    body: JSON.stringify(parsedTranslation),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getJwtToken()
    }
  }
  try {
    const promise = await fetch(`${url.badgeTranslation}/${translation.id}`, settings)
    const response = await promise.json()
    cb && cb(response.badgeTranslation)
    return response.badgeTranslation
  } catch (error) {
    console.log(`Error: ${error}`)
    cb && cb(null)
    return null
  }
}

// PUT CALENDAR NOTIFICATION
async function putCalendarNotification(notification, cb){
  const {id, creationDate, lastModifiedDate, translations, ...withoutId} = notification
  const settings = {
    method: 'PUT',
    body: JSON.stringify(withoutId),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getJwtToken()
    }
  }
  try {
    const promise = await fetch(`${url.calendarNotification}/${notification.id}`, settings)
    const response = await promise.json()
    cb(response.calendarNotification)
  } catch (error) {
    console.log(`Error: ${error}`)
    cb(null)
  }
}

// PUT CALENDAR NOTIFICATION TRANSLATION
async function putCalendarNotificationTranslation(translation, cb){
  const { id, languageName, ...parsedTranslation } = { ...translation }
  const settings = {
    method: 'PUT',
    body: JSON.stringify(parsedTranslation),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getJwtToken()
    }
  }
  try {
    const promise = await fetch(`${url.calendarNotificationTranslation}/${translation.id}`, settings)
    const response = await promise.json()
    cb && cb(response.calendarNotificationTranslation)
    return response.calendarNotificationTranslation
  } catch (error) {
    console.log(`Error: ${error}`)
    cb && cb(null)
    return null
  }
}

// PUT PUSH NOTIFICATION
async function putPushNotification(notification, cb){
  const {id, ...withoutId} = notification
  const settings = {
    method: 'PUT',
    body: JSON.stringify(withoutId),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getJwtToken()
    }
  }
  try {
    const promise = await fetch(`${url.pushNotification}/${notification.id}`, settings)
    const response = await promise.json()
    cb(response.pushNotification)
  } catch (error) {
    console.log(`Error: ${error}`)
    cb(null)
  }
}

/******************************************************/
/*** POST REQUESTS ************************************/
/******************************************************/

// POST PACK
async function postPack(pack, cb){
  const settings = {
    method: 'POST',
    body: JSON.stringify(pack),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getJwtToken()
    }
  }
  try {
    const promise = await fetch(`${url.pack}`, settings)
    const response = await promise.json()
    cb(response)
  } catch (error) {
    console.log(`Error: ${error}`)
    cb(null)
  }
}

// POST FLIGHT
async function postFlight(flight, cb){
  const settings = {
    method: 'POST',
    body: JSON.stringify(flight),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getJwtToken()
    }
  }
  try {
    const promise = await fetch(`${url.flight}`, settings)
    const response = await promise.json()
    cb(response)
  } catch (error) {
    console.log(`Error: ${error}`)
    cb(null)
  }
}

// POST LESSON
async function postLesson(lesson, cb){
  const { modules, ...parsedLesson } = lesson
  const settings = {
    method: 'POST',
    body: JSON.stringify(parsedLesson),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getJwtToken()
    }
  }
  try {
    const promise = await fetch(`${url.lesson}`, settings)
    const response = await promise.json()
    cb(response.lesson)
  } catch (error) {
    console.log(`Error: ${error}`)
    cb(null)
  }
}

// POST LESSON MODULE
async function postLessonModule(lessonModule) {
  const { id, name, description, creationDate, lastModifiedDate,  ...parsedLessonModule } = { ...lessonModule }
  const data = new FormData();
  let aux = {};
  Object.entries(parsedLessonModule).forEach((kv)=>{ 
    let [key, value] = kv;
    if(key === 'file'){
      if(!!value){
        data.append(key, value);
      }
    } else{
      // data.append(key, value);
      // console.log(key, value);
      aux[key] = value;
    }
  });
  console.log(aux);
  data.append('params', JSON.stringify(aux));
  // console.log(data);
  const settings = {
    method: 'POST',
    body: data, // JSON.stringify(parsedLessonModule),
    headers: {
      // 'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getJwtToken()
      , 'Expect': '100-continue'
    }
  }
  try {
    const promise = await fetch(`${url.lessonModule}`, settings)
    const response = await promise.json()
    return response
  } catch (error) {
    console.log(`Error: ${error}`)
    return null
  }
}

// POST QUESTION
async function postQuestion(question, cb){
  const { answers, ...parsedQuestion } = question
  const settings = {
    method: 'POST',
    body: JSON.stringify(parsedQuestion),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getJwtToken()
    }
  }
  try {
    const promise = await fetch(`${url.question}`, settings)
    const response = await promise.json()
    cb && cb(response)
    return response
  } catch (error) {
    console.log(`Error: ${error}`)
    cb && cb(null)
    return null
  }
}

// POST ANSWER
async function postAnswer(answer) {
  const { id, contentTypeId, ...parsedAnswer } = { ...answer }
  const settings = {
    method: 'POST',
    body: JSON.stringify(parsedAnswer),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getJwtToken()
    }
  }
  try {
    const promise = await fetch(`${url.answer}`, settings)
    const response = await promise.json()
    return response
  } catch (error) {
    console.log(`Error: ${error}`)
    return null
  }
}

// POST EVENT
async function postEvent(event, cb){
  const settings ={
    method: 'POST',
    body: JSON.stringify(event),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getJwtToken()
    }
  }
  try {
    const promise = await fetch(`${url.event}`, settings)
    const response = await promise.json()
    cb(response)
  } catch (error) {
    console.log(`Error: ${error}`)
    cb(null)
  }
}

// POST FUN FACT
async function postFunFact(funFact, cb){
  const settings = {
    method: 'POST',
    body: JSON.stringify(funFact),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getJwtToken()
    }
  }
  try {
    const promise = await fetch(`${url.funFact}`, settings)
    const response = await promise.json()
    cb(response)
  } catch (error) {
    console.log(`Error: ${error}`)
    cb(null)
  }
}

// POST PUBLISH
async function postPublish(languageId, packId, cb){
  const settings ={
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getJwtToken()
    }
  }
  try {
    const promise = await fetch(`${url.library}/${languageId}/publish/${packId}`, settings)
    const response = await promise.json()
    cb(response)
  } catch (error) {
    console.log(`Error: ${error}`)
    cb(null)
  }
}

// POST LOGIN CODE
async function postLoginCode(code){
  const settings ={
    method: 'POST',
    body: JSON.stringify({
      code
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  }
  try {
    const promise = await fetch(`${url.code}`, settings)
    const response = await promise.json()
    return response
  } catch (error) {
    console.log(`Error: ${error}`)
    return null
  }
}

// POST ACCESS TOKEN
async function postAccessToken(accessToken){
  const settings ={
    method: 'POST',
    body: JSON.stringify({
      accessToken
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  }
  try {
    const promise = await fetch(`${url.login}`, settings)
    const response = await promise.json()
    return response
  } catch (error) {
    console.log(`Error: ${error}`)
    return null
  }
}

// POST BADGE GROUP
async function postBadgeGroup(badgeGroup, cb){
  const { translations, ...parsedBadgeGroup } = { ...badgeGroup }
  const settings = {
    method: 'POST',
    body: JSON.stringify(parsedBadgeGroup),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getJwtToken()
    }
  }
  try {
    const promise = await fetch(`${url.badgegroup}`, settings)
    const response = await promise.json()
    cb(response.badgegroup)
  } catch (error) {
    console.log(`Error: ${error}`)
    cb(null)
  }
}

// POST BADGE GROUP TRANSLATION
async function postBadgeGroupTranslation(translation, cb){
  const { languageName, ...parsedTranslation } = translation
  const settings = {
    method: 'POST',
    body: JSON.stringify(parsedTranslation),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getJwtToken()
    }
  }
  try {
    const promise = await fetch(`${url.badgegroupTranslation}`, settings)
    const response = await promise.json()
    cb && cb(response.badgegroupTranslation)
    return response.badgegroupTranslation
  } catch (error) {
    console.log(`Error: ${error}`)
    cb && cb(null)
    return null
  }
}


// POST BADGE
async function postBadge(badge, cb){
  const { translations, ...parsedBadge } = { ...badge }
  const settings = {
    method: 'POST',
    body: JSON.stringify(parsedBadge),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getJwtToken()
    }
  }
  try {
    const promise = await fetch(`${url.badge}`, settings)
    const response = await promise.json()
    cb(response.badge)
  } catch (error) {
    console.log(`Error: ${error}`)
    cb(null)
  }
}

// POST BADGE TRANSLATION
async function postBadgeTranslation(translation, cb){
  const { languageName, ...parsedTranslation } = translation
  const settings = {
    method: 'POST',
    body: JSON.stringify(parsedTranslation),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getJwtToken()
    }
  }
  try {
    const promise = await fetch(`${url.badgeTranslation}`, settings)
    const response = await promise.json()
    cb && cb(response.badgeTranslation)
    return response.badgeTranslation
  } catch (error) {
    console.log(`Error: ${error}`)
    cb && cb(null)
    return null
  }
}

// POST CALENDAR NOTIFICATION
async function postCalendarNotification(notification, cb){
  const { translations, ...parsedNotification } = { ...notification }
  const settings ={
    method: 'POST',
    body: JSON.stringify(parsedNotification),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getJwtToken()
    }
  }
  try {
    const promise = await fetch(`${url.calendarNotification}`, settings)
    const response = await promise.json()
    cb(response.calendarNotification)
  } catch (error) {
    console.log(`Error: ${error}`)
    cb(null)
  }
}

// POST CALENDAR NOTIFICATION TRANSLATION
async function postCalendarNotificationTranslation(translation, cb){
  const { languageName, ...parsedTranslation } = translation
  const settings = {
    method: 'POST',
    body: JSON.stringify(parsedTranslation),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getJwtToken()
    }
  }
  try {
    const promise = await fetch(`${url.calendarNotificationTranslation}`, settings)
    const response = await promise.json()
    cb && cb(response.calendarNotificationTranslation)
    return response.calendarNotificationTranslation
  } catch (error) {
    console.log(`Error: ${error}`)
    cb && cb(null)
    return null
  }
}

// POST PUSH NOTIFICATION
async function postPushNotification(notification, cb){
  const settings ={
    method: 'POST',
    body: JSON.stringify(notification),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getJwtToken()
    }
  }
  try {
    const promise = await fetch(`${url.pushNotification}`, settings)
    const response = await promise.json()
    cb(response.pushNotification)
  } catch (error) {
    console.log(`Error: ${error}`)
    cb(null)
  }
}

/******************************************************/
/*** DELETE REQUESTS **********************************/
/******************************************************/

// DELETE PACK
async function deletePack(packId, cb){
  const settings = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getJwtToken()
    }
  }
  try {
    const promise = await fetch(`${url.pack}/${packId}`, settings)
    const response = await promise.json()
    cb(response)
  } catch (error) {
    console.log(`Error: ${error}`)
    cb(null)
  }
}

// DELETE FLIGHT
async function deleteFlight(flightId, cb){
  const settings = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getJwtToken()
    }
  }
  try {
    const promise = await fetch(`${url.flight}/${flightId}`, settings)
    const response = await promise.json()
    cb(response)
  } catch (error) {
    console.log(`Error: ${error}`)
    cb(null)
  }
}

// DELETE LESSON
async function deleteLesson(lessonId, cb){
  const settings = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getJwtToken()
    }
  }
  try {
    const promise = await fetch(`${url.lesson}/${lessonId}`, settings)
    const response = await promise.json()
    cb(response)
  } catch (error) {
    console.log(`Error: ${error}`)
    cb(null)
  }
}

// DELETE LESSON MODULE
async function deleteLessonModule(lessonModuleId, cb){
  const settings = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getJwtToken()
    }
  }
  try {
    const promise = await fetch(`${url.lessonModule}/${lessonModuleId}`, settings)
    const response = await promise.json()
    cb(response)
  } catch (error) {
    console.log(`Error: ${error}`)
    cb(null)
  }
}

// DELETE QUESTION
async function deleteQuestion(questionId, cb){
  const settings = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getJwtToken()
    }
  }
  try {
    const promise = await fetch(`${url.question}/${questionId}`, settings)
    const response = await promise.json()
    cb(response)
  } catch (error) {
    console.log(`Error: ${error}`)
    cb(null)
  }
}

//DELETE ANSWER
async function deleteAnswer(answerId, cb){
  const settings = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getJwtToken()
    }
  }
  try {
    const promise = await fetch(`${url.answer}/${answerId}`, settings)
    const response = await promise.json()
    cb(response)
  } catch (error) {
    console.log(`Error: ${error}`)
    cb(null)
  }
}

//DELETE EVENT
async function deleteEvent(eventId, cb){
  const settings = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getJwtToken()
    }
  }
  try {
    const promise = await fetch(`${url.event}/${eventId}`, settings)
    const response = await promise.json()
    cb(response)
  } catch (error) {
    console.log(`Error: ${error}`)
    cb(null)
  }
}

// DELETE FUN FACT
async function deleteFunFact(funFactId, cb){
  const settings = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getJwtToken()
    }
  }
  try {
    const promise = await fetch(`${url.funFact}/${funFactId}`, settings)
    const response = await promise.json()
    cb(response)
  } catch (error) {
    console.log(`Error: ${error}`)
    cb(null)
  }
}

// DELETE BADGE GROUP
async function deleteBadgeGroup(badgeGroupId, cb){
  const settings = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getJwtToken()
    }
  }
  try {
    const promise = await fetch(`${url.badgegroup}/${badgeGroupId}`, settings)
    const response = await promise.json()
    cb(response)
  } catch (error) {
    console.log(`Error: ${error}`)
    cb(null)
  }
}

// DELETE BADGE GROUP TRANSLATION
async function deleteBadgeGroupTranslation(translationId, cb){
  const settings = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getJwtToken()
    }
  }
  try {
    const promise = await fetch(`${url.badgegroupTranslation}/${translationId}`, settings)
    const response = await promise.json()
    cb(response)
  } catch (error) {
    console.log(`Error: ${error}`)
    cb(null)
  }
}

// DELETE BADGE
async function deleteBadge(badgeId, cb){
  const settings = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getJwtToken()
    }
  }
  try {
    const promise = await fetch(`${url.badge}/${badgeId}`, settings)
    const response = await promise.json()
    cb(response)
  } catch (error) {
    console.log(`Error: ${error}`)
    cb(null)
  }
}

// DELETE BADGE TRANSLATION
async function deleteBadgeTranslation(translationId, cb){
  const settings = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getJwtToken()
    }
  }
  try {
    const promise = await fetch(`${url.badgeTranslation}/${translationId}`, settings)
    const response = await promise.json()
    cb(response)
  } catch (error) {
    console.log(`Error: ${error}`)
    cb(null)
  }
}

// DELETE CALENDAR NOTIFICATION
async function deleteCalendarNotification(notificationId, cb){
  const settings = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getJwtToken()
    }
  }
  try {
    const promise = await fetch(`${url.calendarNotification}/${notificationId}`, settings)
    const response = await promise.json()
    cb(response)
  } catch (error) {
    console.log(`Error: ${error}`)
    cb(null)
  }
}

// DELETE CALENDAR NOTIFICATION TRANSLATION
async function deleteCalendarNotificationTranslation(translationId, cb){
  const settings = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getJwtToken()
    }
  }
  try {
    const promise = await fetch(`${url.calendarNotificationTranslation}/${translationId}`, settings)
    const response = await promise.json()
    cb(response)
  } catch (error) {
    console.log(`Error: ${error}`)
    cb(null)
  }
}

// DELETE PUSH NOTIFICATION
async function deletePushNotification(notificationId, cb){
  const settings = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getJwtToken()
    }
  }
  try {
    const promise = await fetch(`${url.pushNotification}/${notificationId}`, settings)
    const response = await promise.json()
    cb(response)
  } catch (error) {
    console.log(`Error: ${error}`)
    cb(null)
  }
}

// Copy Pack to New Language
async function copyPack(packId, languageId, cb){
  try {
    const settings = {
      headers: {
        'Authorization': 'Bearer ' + getJwtToken()
      }
    }
    const promise = await fetch(
      `${url.pack}/copy/${packId}/${languageId}`,
      settings
    )
    const data = await promise.json()
    cb(data.pack)
  } catch (error) {
    console.log(`error: ${error}`)
    cb(null)
  }
}

async function copyFlight(flightId, languageId, targetPackId, newOrder, cb){
  try {
    const settings = {
      headers: {
        'Authorization': 'Bearer ' + getJwtToken()
      }
    }
    const promise = await fetch(
      `${url.flight}/copy/${flightId}/${languageId}/${targetPackId}/${newOrder}`,
      settings
    )
    const data = await promise.json()
    cb(data.flight)
  } catch (error) {
    console.log(`error: ${error}`)
    cb(null)
  }
}

async function copyLesson(lessonId, languageId, targetFlightId, newOrder, cb){
  try {
    const settings = {
      headers: {
        'Authorization': 'Bearer ' + getJwtToken()
      }
    }
    const promise = await fetch(
      `${url.lesson}/copy/${lessonId}/${languageId}/${targetFlightId}/${newOrder}`,
      settings
    )
    const data = await promise.json()
    cb(data.lesson)
  } catch (error) {
    console.log(`error: ${error}`)
    cb(null)
  }
}

// GET ALL BADGES
async function getAllBadgeAchievements(badgeId, cb){
  try {
    const settings = {
      headers: {
        'Authorization': 'Bearer ' + getJwtToken()
      }
    }
    const promise = await fetch(`${url.badges}/achievement/${badgeId}`, settings)
    const data = await promise.json()
    cb(data)
  } catch (error) {
    console.log(`Error: ${error}`)
    cb(null)
  }
}


// GET COUNT OF ALL REGISTERED USERS
async function getCountRegisteredUsers(cb){
  try {
    const settings = {
      headers: {
        'Authorization': 'Bearer ' + getJwtToken()
      }
    }
    const promise = await fetch(`${url.users}/countregister`, settings)
    const data = await promise.json()
    cb(data)
  } catch (error) {
    console.log(`Error: ${error}`)
    cb(null)
  }
}

function downloadFile(path, name){
  try{
    const settings = {
      headers: {
        'Authorization': 'Bearer ' + getJwtToken()
      }
    }
    fetch(`${domain}/content/file/library/${path}/${name}`, settings).then(res => {
      if(res.status === 200){
        return res.blob();
      }
      return false;
    }).then(data => {
      if(!!data){
        FileDownload(data, name);
      }
    });

  }
  catch (error){
    console.log(`Error: ${error}`)
  }
}

function getDownloadContentScripts(languageId, cb){
  //get-content-scripts-link
    try {
      const settings = {
        headers: {
          'Authorization': 'Bearer ' + getJwtToken()
        }
      }
      // const promise = await fetch(`${domain}/get-content-scripts-link/${languageId}`, settings);
      // const data = await promise.blob();
      // FileDownload(data, 'Hoppy-content-scripts.docx');
      fetch(`${domain}/get-content-scripts-link/${languageId}`, settings)
          .then(response => {
            return response.blob()
          }, err=>{
            cb(false);
          })
          .then(data => {
            FileDownload(data, 'Hoppy-content-scripts.docx');
            cb(true);
          }, err=>{
            cb(false);
          });
      
      // cb(false)
    } catch (error) {
      console.log(`Error: ${error}`)
      cb(null)
    }
  }
  

export default {
  url,
  getUserSimulation,
  getJwtToken,
  getCurrentUser,
  getAllUsers,
  getAllUserActivity,
  getAllUserBadges,
  getUserBadges,
  getUserBeercoinHistory,
  getAllZones,
  getAllSections,
  getAllPacks,
  getAllFlights,
  getAllLessons,
  getImageUrl,
  getLessons,
  getAllLessonModules,
  getLessonModules,
  getAllQuestions,
  getAllAnswers,
  getAllContentTypes,
  getAllEvents,
  getAllLanguages,
  getAllFunFacts,
  getAllDepartments,
  getAllOffices,
  getAllCountries,
  getOAuthConfig,
  getAllAnalytics,
  getOneAnalytics,
  getOneAnalyticsById,
  getChartPlaceholders,
  getChartOverviewPlaceholders,
  getChartRegionalPlaceholders,
  getAllAnalyticsUsersLogins,
  getAllAnalyticsUsersSignUps,
  getAllAnalyticsRepeatingUsersLogins,
  getAllDailyChallengeCompletions,
  getAllBadgeGroups,
  getAllBadgeGroupTranslations,
  getAllBadges,
  getAllBadgeTypes,
  getAllBadgeTranslations,
  getAllCalendarNotifications,
  getAllCalendarNotificationTranslations,
  getAllDailyChallengeQuestions,
  getAllPushNotifications,
  getAllUserActivityActions,
  putPack,
  putFlight,
  putLesson,
  putLessonModule,
  putQuestion,
  putAnswer,
  putEvent,
  putFunFact,
  putBadgeGroup,
  putBadgeGroupTranslation,
  putBadge,
  putBadgeTranslation,
  putCalendarNotification,
  putCalendarNotificationTranslation,
  putPushNotification,
  postPack,
  postFlight,
  postLesson,
  postLessonModule,
  postQuestion,
  postAnswer,
  postEvent,
  postFunFact,
  postPublish,
  postLoginCode,
  postAccessToken,
  postBadgeGroup,
  postBadgeGroupTranslation,
  postBadge,
  postBadgeTranslation,
  postCalendarNotification,
  postCalendarNotificationTranslation,
  postPushNotification,
  deletePack,
  deleteFlight,
  deleteLesson,
  deleteLessonModule,
  deleteQuestion,
  deleteAnswer,
  deleteEvent,
  deleteFunFact,
  deleteBadgeGroup,
  deleteBadgeGroupTranslation,
  deleteBadge,
  deleteBadgeTranslation,
  deleteCalendarNotification,
  deleteCalendarNotificationTranslation,
  deletePushNotification,
  copyPack,
  copyFlight,
  copyLesson,
  getAllBadgeAchievements,
  getCountRegisteredUsers,
  getUsersMostPlayedTime,
  getDownloadContentScripts,
  downloadFile
}
